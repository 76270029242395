import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { translate } from "react-i18next";

import Translation from "../common/Translation";
import Alerts from "../common/Alerts";
import Tile from "../common/Tile";

import { isLoading } from "../../reducers/ecomailReducer";
import { exportSubscribersToEcomail } from "../../actions/ecomailActions";

class Ecomail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.translatedFormValidation = this.translatedFormValidation.bind(this);
  }

  translatedFormValidation() {
    const errors = {};

    return errors;
  }

  onExportSubscribersToEcomail = event => {
    event.preventDefault();

    this.props.exportSubscribersToEcomail();
  };

  render() {
    return (
      <div>
        <div className="content-header">
          <h1>
            <Translation tKey="Ecomail" />
          </h1>
        </div>
        <div className="content-wrapper">
          <Alerts />
          <div className="container">
            <div className="row justify-content-center">
              <div className="col-4 align-self-center">    
                <Tile
                  onClick={this.onExportSubscribersToEcomail}
                  loading={this.props.loading}
                  text="ExportContactsToEcomail"
                  title="ExportContactsToEcomail"
                  icon="export"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

Ecomail.defaultProps = {};

Ecomail.propTypes = {
  t: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  exportSubscribersToEcomail: PropTypes.func.isRequired
};

const mapStateToProps = state => {
  return {
    loading: isLoading(state)
  };
};

const mapDispatchToProps = dispatch => {
  return {
    exportSubscribersToEcomail: bindActionCreators(
      exportSubscribersToEcomail,
      dispatch
    )
  };
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate("common")
)(Ecomail);
