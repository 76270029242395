import {
    EnumDescription,
    DATASOURCE_QUERY_TYPE_ENUM,
    DATASOURCE_TYPE_ENUM,
    DATASOURCE_TYPE_TYPES,
    getEnumItemByValue,
    DATASOURCE_QUERY_TYPE_TYPES,
    PLATFORMA_TYPES_ENUM,
    PLATFORMA_TYPES,
    PUSH_NOTIFICATION_CHANNEL_TYPE_TYPES,
} from '../common/enums';

export default class PushNotificationConfiguration {
    id: number;
    name: string;
    code: string;
    message: string;
    title: string;
    targetPageId: number;
    newsId: number;
    channelType: number;
    channelTypeEnumDescription: EnumDescription | undefined;
    /*  query: string;
    queryContextId: number;
    queryType: number;
    queryTypeEnumDescription: EnumDescription | undefined;
    type: number;
    typeEnumDescription: EnumDescription | undefined;
    platform: number;
    platformEnumDescription: EnumDescription | undefined; */

    constructor(properties: Partial<PushNotificationConfiguration> & Pick<PushNotificationConfiguration, 'channelType'>) {
        this.id = 0;
        this.name = '';
        this.code = '';
        this.message = '';
        this.title = '';
        this.targetPageId = 0;
        this.newsId = 0;
        this.channelType = 1;

        /*  this.query = '';
        this.queryContextId = 0; 
        this.type = DATASOURCE_TYPE_ENUM.Query;
        this.queryType = DATASOURCE_QUERY_TYPE_ENUM.OneRecord;
        this.platform = PLATFORMA_TYPES_ENUM.ALL; */

        Object.assign(this, properties);

        this.channelTypeEnumDescription = getEnumItemByValue(properties.channelType, PUSH_NOTIFICATION_CHANNEL_TYPE_TYPES);

        /* this.typeEnumDescription = getEnumItemByValue(properties.type, DATASOURCE_TYPE_TYPES);
        this.queryTypeEnumDescription = getEnumItemByValue(properties.queryType, DATASOURCE_QUERY_TYPE_TYPES);
        this.platformEnumDescription = getEnumItemByValue(properties.platform, PLATFORMA_TYPES); */
    }
}
