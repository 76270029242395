import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import DataSourceDetail, { DataSourceDetailDispatchProps, DataSourceDetailProps } from './dataSourceDetail';
import { createDataSource, fetchDataSourcesDependencies } from '../../actions/dataSourceActions';
import { navigateToDataSources } from '../../actions/navigationActions';
import DataSource from '../../entities/dataSource';
import { DATASOURCE_EXECUTION_POLICY_ENUM, DATASOURCE_QUERY_TYPE_ENUM, DATASOURCE_TYPE_ENUM, PLATFORMA_TYPES_ENUM } from '../../common/enums';

const initialValues = new DataSource({
    type: DATASOURCE_TYPE_ENUM.Query,
    queryType: DATASOURCE_QUERY_TYPE_ENUM.OneRecord,
    platform: PLATFORMA_TYPES_ENUM.ALL,
    executionPolicy: DATASOURCE_EXECUTION_POLICY_ENUM.FullDataSource,
});

interface DataSourceDetailCreateProps extends DataSourceDetailProps {}

const DataSourceDetailCreate = ({ submitAction, cancelAction, t, fetchDataSourcesDependencies }: DataSourceDetailCreateProps) => {
    return (
        <DataSourceDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('DataSourceCreateLabel')}
            cancelAction={cancelAction}
            t={t}
            fetchDataSourcesDependencies={fetchDataSourcesDependencies}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<DataSourceDetailCreateProps>): DataSourceDetailDispatchProps => {
    return {
        submitAction: bindActionCreators(createDataSource, dispatch),
        cancelAction: bindActionCreators(navigateToDataSources, dispatch),
        fetchDataSourcesDependencies: bindActionCreators(fetchDataSourcesDependencies, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(DataSourceDetailCreate);
