import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { bindActionCreators } from 'redux';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import DataSource from '../../entities/dataSource';
import { nameofFactory } from '../../helpers/nameOfFactory';
import * as enums from '../../common/enums';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import DropDown from '../common/DropDown';
import DropDownWithValueField from '../common/DropDownWithValueField';
import DataSourceQueryContext from '../../entities/dataSourceQueryContext';
import {
    getData as getDataSourceQueryContexts,
    getUserReadableProperty as getDataSourceQueryContextUserReadableProperty,
} from '../../reducers/dataSourceQueryContextReducer';

const nameof = nameofFactory<DataSource>();

export interface DataSourceDetailProps extends BaseDetailProps<DataSource>, DataSourceDetailStateProps, DataSourceDetailDispatchProps {
    headerText: string;

    fetchDataSourcesDependencies: () => {};
}

export interface DataSourceDetailDispatchProps extends BaseDetailDispatchProps<DataSource> {
    fetchDataSourcesDependencies: () => {};
}

export interface DataSourceDetailStateProps {
    lang: string;
    dataSourceQueryContexts: DataSourceQueryContext[];
    dataSourceQueryContextUserReadableProperty: string;
}

class DataSourceDetail extends Component<DataSourceDetailProps> {
    componentDidMount() {
        this.props.fetchDataSourcesDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as DataSource);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;

        const typedValues = values as DataSource;
        const errors: Partial<Record<keyof DataSource, string>> = {};

        if (!typedValues.name) {
            errors.name = t('NameRequired');
        }

        if (!typedValues.itemsCode) {
            errors.itemsCode = t('ItemsCodeRequired');
        }
      
        return errors;
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('name')}>
                                            <span>
                                                <Translation tKey="Name" />
                                            </span>
                                            <Field
                                                name={nameof('name')}
                                                component={Input}                                             
                                            />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('itemsCode')}>
                                            <span>
                                                <Translation tKey="ItemsCode" />
                                            </span>
                                            <Field
                                                name={nameof('itemsCode')}
                                                component={Input}                                    
                                            />
                                        </label>
                                    </div>

                                    <label className="k-form-field" htmlFor={nameof('queryContextId')}>
                                        <span>
                                            <Translation tKey="DataContext" />
                                        </span>
                                        <Field
                                            name={nameof('queryContextId')}
                                            component={DropDownWithValueField}
                                            textField={this.props.dataSourceQueryContextUserReadableProperty}
                                            valueField="id"
                                            dataSource={this.props.dataSourceQueryContexts}
                                        />
                                    </label>
                                    <label className="k-form-field" htmlFor={nameof('query')}>
                                        <span>
                                            <Translation tKey="Query" />
                                        </span>
                                        <Field
                                            name={nameof('query')}
                                            component="textarea"
                                            className="k-textarea"
                                            rows="4"
                                            style={{ width: '100%' }}
                                        />
                                    </label>
                                    <label className="k-form-field" htmlFor={nameof('queryTypeEnumDescription')}>
                                        <span>
                                            <Translation tKey="Type" />
                                        </span>
                                        <Field
                                            name={nameof('queryTypeEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.DATASOURCE_QUERY_TYPE_TYPES}
                                        />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('typeEnumDescription')}>
                                        <span>
                                            <Translation tKey="Type" />
                                        </span>
                                        <Field
                                            name={nameof('typeEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.DATASOURCE_TYPE_TYPES}
                                        />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="Platform" />
                                        </span>
                                        <Field
                                            name={nameof('platformEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.PLATFORMA_TYPES}
                                        />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="ExecutionPolicy" />
                                        </span>
                                        <Field
                                            name={nameof('executionPolicyEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.DATASOURCE_EXECUTION_POLICY_TYPES}
                                        />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): DataSourceDetailStateProps => {
    return {
        lang: state.app.lang,
        dataSourceQueryContexts: getDataSourceQueryContexts(state),
        dataSourceQueryContextUserReadableProperty: getDataSourceQueryContextUserReadableProperty(state),
    };
};

export default connect(mapStateToProps)(DataSourceDetail);
