import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { translate } from 'react-i18next';

import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { Form, Field } from 'react-final-form';
import SubmitButton from '../common/SubmitButton';
import NumericTextBox from './../common/NumericTextBox';
import DatePicker from './../common/DatePicker';
import Switch from './../common/Switch';
import LoadingIndicator from '../common/LoadingIndicator';
import Condition from '../common/utils/Condition';
import Input from '../common/Input';
import DropDown from '../common/DropDown';

import { updateExtra, fetchExtra } from '../../actions/extraActions';
import { getData, isLoading } from '../../reducers/extraReducer';
import * as enums from '../../common/enums';

class Extra extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
        this.translatedFormValidation = this.translatedFormValidation.bind(this);
    }

    componentDidMount() {
        this.props.fetchData();
    }

    translatedFormValidation(values) {
        //const { t } = this.props;
        const errors = {};

        // if (!values.name) {
        //   errors.name = t('NameIsRequired');
        // }

        // if (!values.validFrom) {
        //   errors.validFrom = 'Datum Platný od je povinné';
        // }

        // if (values.validTo && values.validFrom && values.validTo < values.validFrom) {
        //   errors.validTo = 'Datum Platný do, musí být větší než datum Platný od';
        // }

        if (values.hvcForGroup && !values.hvcForGroupName) {
            errors.hvcForGroupName = 'Skupina je povinná';
        }

        if (values.hvcForGroupUseDateSuffix && !values.hvcForGroupDateSuffixType) {
            errors.hvcForGroupDateSuffixType = 'Typ sufixu je povunný';
        }

        return errors;
    }

    render() {
        const { initialValues } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>
                        <Translation tKey="Extra" />
                    </h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />

                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.props.onSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, reset, submitting, pristine, values, valid }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    {this.props.loading && <LoadingIndicator />}

                                    <label className="k-form-field" htmlFor="hvcLimit">
                                        <span>
                                            <Translation tKey="HvcLimit" />
                                        </span>
                                        <Field name="hvcLimit" component={NumericTextBox} />
                                    </label>

                                    <label className="k-form-field" htmlFor="hvcDaysForSpentCalculation">
                                        <span>
                                            <Translation tKey="HvcDaysForSpentCalculation" />
                                        </span>
                                        <Field name="hvcDaysForSpentCalculation" component={NumericTextBox} />
                                    </label>

                                    <Condition when="hvcForGroup" is={false}>
                                        <label className="k-form-field" htmlFor="hvcForAll">
                                            <span>
                                                <Translation tKey="HvcForAll" />
                                            </span>
                                            <Field name="hvcForAll" component={Switch} />
                                        </label>
                                    </Condition>

                                    <Condition when="hvcForAll" is={false}>
                                        <label className="k-form-field" htmlFor="hvcForGroup">
                                            <span>
                                                <Translation tKey="HvcForGroup" />
                                            </span>
                                            <Field name="hvcForGroup" component={Switch} />
                                        </label>
                                    </Condition>

                                    <Condition when="hvcForAll" is={true}>
                                        <div className={'row'}>
                                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor="hvcForAllValidFrom">
                                                <span>
                                                    <Translation tKey="ValidFrom" />
                                                </span>
                                                <Field name="hvcForAllValidFrom" component={DatePicker} disabled={!values.hvcForAll} />
                                            </label>

                                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor="hvcForAllValidTo">
                                                <span>
                                                    <Translation tKey="ValidTo" />
                                                </span>
                                                <Field name="hvcForAllValidTo" component={DatePicker} disabled={!values.hvcForAll} />
                                            </label>
                                        </div>
                                    </Condition>

                                    <Condition when="hvcForGroup" is={true}>
                                        <label className="k-form-field" htmlFor="hvcForGroupName">
                                            <span>
                                                <Translation tKey="HvcForGroupName" />
                                            </span>
                                            <Field name="hvcForGroupName" component={Input} />
                                        </label>

                                        <div className={'row'}>
                                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor="hvcForGroupUseDateSuffix">
                                                <span>
                                                    <Translation tKey="HvcForGroupUseDateSuffix" />
                                                    <Field name="hvcForGroupUseDateSuffix" component={Switch} />
                                                </span>
                                            </label>

                                            <Condition when="hvcForGroupUseDateSuffix" is={true}>
                                                <label className="k-form-field col-xs-12 col-sm-6" htmlFor="hvcForGroupDateSuffixType">
                                                    <span>
                                                        <Translation tKey="HvcForGroupDateSuffixType" />
                                                    </span>
                                                    <Field
                                                        name="hvcForGroupDateSuffixType"
                                                        component={DropDown}
                                                        textField="text"
                                                        dataSource={enums.TARGETED_GROUP_SUFFIX_TYPE}
                                                    />
                                                </label>
                                            </Condition>
                                        </div>
                                    </Condition>

                                    <label className="k-form-field" htmlFor="isOneNetEnabled">
                                        <span>
                                            <Translation tKey="IsOneNetEnabled" />
                                        </span>
                                        <Field name="isOneNetEnabled" component={Switch} />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton loading={submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

Extra.defaultProps = {
    initialValues: {
        hvcLimit: 300,
        isOneNetEnabled: false,
    },
};

Extra.propTypes = {
    t: PropTypes.func.isRequired,
    initialValues: PropTypes.shape({
        hvcLimit: PropTypes.number,
        hvcForAll: PropTypes.bool,
        isOneNetEnabled: PropTypes.bool,
        hvcForAllValidFrom: PropTypes.instanceOf(Date),
        hvcForAllValidTo: PropTypes.instanceOf(Date),
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    fetchData: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        initialValues: getData(state),
        loading: isLoading(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        onSubmit: bindActionCreators(updateExtra, dispatch),
        fetchData: bindActionCreators(fetchExtra, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(Extra);
