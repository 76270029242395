import axios from 'axios';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
//import {navigateTo} from "./navigationActions";
import ResponseStatus from '../utils/responseStatus';
import { alertAdd } from './alertActions';
import { ALERT_TYPE } from '../common/constants';
import extraTransformationBeforeSave from '../data-transformations/extraTransformationBeforeSave';
import extraDataTransformationAfterFetch from '../data-transformations/extraDataTransformationAfterFetch';

export const updateExtra = (data) => (dispatch) => {
    dispatch(beginAjaxCall());

    const extra = extraTransformationBeforeSave(data);
    axios
        .put(`extra`, extra)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.EXTRA_UPDATE,
                    data: response.data,
                });

                dispatch(alertAdd('ExtraSaveOk', ALERT_TYPE.SUCCESS));

                fetchExtra()(dispatch);
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const fetchExtra = () => (dispatch) => {
    dispatch(beginAjaxCall());
    dispatch(LOADING_BEGIN);

    axios
        .get(`extra`)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                const extra = extraDataTransformationAfterFetch(response.data);

                dispatch({
                    type: types.EXTRA_FETCH,
                    data: extra,
                });
            } else {
                dispatch(ajaxCallError());
            }

            dispatch(LOADING_END);
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            dispatch(LOADING_END);

            throw error;
        });
};

const LOADING_BEGIN = {
    type: types.EXTRA_LOADING_BEGIN,
};

const LOADING_END = {
    type: types.EXTRA_LOADING_END,
};
