import React from 'react';
import PropTypes from 'prop-types';
import { GridCell } from '@progress/kendo-react-grid';
import { provideIntlService } from '@progress/kendo-react-intl';
import {translate} from "react-i18next";
import { isNotNullDate } from '../../../utils/commonFunctions';

class DateCell extends GridCell {
 
  render() {
    const utcDate = this.props.dataItem[this.props.field];
    const {t, onClick, style} = this.props;

    if (isNotNullDate(utcDate)) {
      const date = new Date(utcDate);

      return (
        <td onClick={onClick} style={style}>        
          {provideIntlService(this).formatDate(date, {
            date: "medium"
          })}
        </td>
      );
    }

    return (
      <td className="k-cell-date">
        {t('NotSet')}
      </td>
    );
  }
}

DateCell.propTypes = {
  t: PropTypes.func.isRequired,
};

export default translate('common')(DateCell);
