import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import SimCardTypeDetail, { BaseSimCardTypeDetailProps } from './simCardTypeDetail';
import { getRowById } from '../../reducers/tableReducer';
import { navigateToSimCardTypes } from '../../actions/navigationActions';
import { updateSimCardType } from '../../actions/simCardTypeActions';

interface SimCardTypeDetailUpdateProps extends BaseSimCardTypeDetailProps {
    t: (key: string) => string;
}

const SimCardTypeDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: SimCardTypeDetailUpdateProps) => {
    return (
        <SimCardTypeDetail
            initialValues={initialValues}
            t={t}
            submitAction={submitAction}
            headerText={t('UpdateSimCardType') + ' - ' + initialValues.name}
            cancelAction={cancelAction}
        />
    );
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<SimCardTypeDetailUpdateProps>) => {
    return {
        submitAction: bindActionCreators(updateSimCardType, dispatch),
        cancelAction: bindActionCreators(navigateToSimCardTypes, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(SimCardTypeDetailUpdate);
//export default connect(mapStateToProps, mapDispatchToProps)(RewardDetailUpdate);
