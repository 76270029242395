import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { Form, Field } from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import Switch from '../common/Switch';
import LoadingIndicator from '../common/LoadingIndicator';
import DropDownWithValueField from '../common/DropDownWithValueField';
import User from '../../entities/user';
import { fetchUserDependencies } from '../../actions/userActions';
import { nameofFactory } from '../../helpers/nameOfFactory';
import Role from '../../entities/role';
import { getData as getRoles, getUserReadableProperty as getRoleUserReadableProperty } from '../../reducers/roleReducer';
import { isLoadingDependencies } from '../../reducers/userReducer';
import { BaseDetailProps } from '../common/types/baseDetail';

const nameof = nameofFactory<User>();

export interface BaseUserDetailProps extends BaseDetailProps<User> {}

export interface UserDetailProps extends BaseUserDetailProps, UserDetailStateProps, UserDetailDispatchProps {
    headerText: string;
    emailIsReadOnly: boolean;
    submittButtonLabel: string;
}

export interface UserDetailStateProps {
    lang: string;
    loadingDependencies: boolean;
    roleUserReadableProperty: string;
    roles: Role[];
}
export interface UserDetailDispatchProps {
    fetchUserDependencies: typeof fetchUserDependencies;
}

class UserDetail extends Component<UserDetailProps> {
    componentDidMount() {
        this.props.fetchUserDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as User);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };
    translatedFormValidation = (values: object) => {
        const { t } = this.props;
        const typedValues = values as User;
        const errors: Partial<Record<keyof User, string>> = {};

        if (!typedValues.email) {
            errors.email = t('EmailIsRequired');
        }

        if (!typedValues.firstname) {
            errors.firstname = t('FirstNameIsRequired');
        }

        if (!typedValues.lastname) {
            errors.lastname = t('LastNameIsRequired');
        }

        return errors;
    };

    render() {
        console.debug('UserDetail.render');

        const { initialValues, headerText, submittButtonLabel, emailIsReadOnly } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    {this.props.loadingDependencies && <LoadingIndicator />}

                                    <label className="k-form-field" htmlFor={nameof('email')}>
                                        <span>
                                            <Translation tKey="Email" />
                                        </span>
                                        <Field
                                            name={nameof('email')}
                                            component={Input}
                                            readonly={emailIsReadOnly}
                                            // placeholder={t("Name")}
                                        />
                                    </label>

                                    <div className={'row'}>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="Firstname">
                                            <span>
                                                <Translation tKey="Firstname" />
                                            </span>
                                            <Field
                                                name={nameof('firstname')}
                                                component={Input}
                                                // placeholder={t("Firstname")}
                                            />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="Lastname">
                                            <span>
                                                <Translation tKey="Lastname" />
                                            </span>
                                            <Field
                                                name={nameof('lastname')}
                                                component={Input}
                                                // placeholder={t("Lastname")}
                                            />
                                        </label>
                                    </div>

                                    <label className="k-form-field" htmlFor={nameof('isAdmin')}>
                                        <span>
                                            <Translation tKey="Admin" />
                                        </span>
                                        <Field name={nameof('isAdmin')} component={Switch} />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('roleId')}>
                                        <span>
                                            <Translation tKey="Role" />
                                        </span>
                                        <Field
                                            name={nameof('roleId')}
                                            component={DropDownWithValueField}
                                            textField={this.props.roleUserReadableProperty}
                                            valueField="id"
                                            dataSource={this.props.roles}
                                        />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>{submittButtonLabel}</SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

/* UserDetail.propTypes = {
    t: PropTypes.func.isRequired,
    headerText: PropTypes.string.isRequired,
    initialValues: PropTypes.shape({
        email: PropTypes.string,
        firstname: PropTypes.string,
        lastname: PropTypes.string,
        isAdmin: PropTypes.bool,
    }).isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    loading: PropTypes.bool.isRequired,
    emailIsReadOnly: PropTypes.bool.isRequired,
    submittButtonLabel: PropTypes.string.isRequired,
}; */

const mapStateToProps = (state: any): UserDetailStateProps => {
    return {
        lang: state.app.lang,
        loadingDependencies: isLoadingDependencies(state),
        roleUserReadableProperty: getRoleUserReadableProperty(state),
        roles: getRoles(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): UserDetailDispatchProps => {
    return {
        fetchUserDependencies: bindActionCreators(fetchUserDependencies, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);
