import * as types from '../actions/actionTypes';

const initialState = {
  open: true
};

const sideBar = (state = initialState, action) => {
  switch (action.type) {
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        open: !state.open
      };
    default:
      return state;
  }
};

export default sideBar;
