import {
    getEnumItemByValue,
    PLATFORMA_TYPES,
    SOURCE_OF_SUBSCRIBERS_TYPES,
    REWARD_TYPES,
    EnumDescription,
    REWARD_VARIANT,
    EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM,
    SOURCE_OF_SUBSCRIBERS_TYPES_ENUM,
} from '../common/enums';

export default class Reward {
    id: number;
    name: string;
    extId: number;
    campaignCode: string;
    code: string;
    maxDraws: number;
    productCode: string;
    platform: any;
    type: any;
    sourceOfSubscribers: number;
    sourceOfSubscribersEnumDescription: EnumDescription | undefined;
    availableFrom?: Date;
    availableTo?: Date;
    detailShortDescription: string;
    shortDescription: string;
    description: string;
    buyFromCreditButtonText: string;
    buyFromCreditCardButtonText: string;
    textLine1: string;
    textLine2: string;
    detailImageUrl: string;
    detailImage: File | undefined;
    detailName: string;
    isDigi: boolean;
    priceByCreditCard: number;
    numberOfPeriods: number;
    takeValidityFromClaim: boolean;
    isAllowedMultipleUse: boolean;
    dateOfCreate?: Date;
    useInServicePriceCalculator: boolean;
    useWhenProductIsActivated: boolean;
    usePriceWhenO2PriceIsNull: boolean;
    variant: any;
    followingRewardId: number;
    price: number;
    takeOverOnlyPriceSummary: boolean;
    dataSourceId: number;

    constructor(properties: Partial<Reward> & Pick<Reward, 'sourceOfSubscribers'>) {
        this.id = 0;
        this.extId = 0;
        this.name = '';
        this.code = '';
        this.campaignCode = '';
        this.maxDraws = 0;
        this.productCode = '';
        this.detailShortDescription = '';
        this.shortDescription = '';
        this.description = '';
        this.buyFromCreditButtonText = '';
        this.buyFromCreditCardButtonText = '';
        this.textLine1 = '';
        this.textLine2 = '';
        this.detailImageUrl = '';
        this.detailImage = undefined;
        this.detailName = '';
        this.isDigi = false;
        this.priceByCreditCard = 0;
        this.numberOfPeriods = 0;
        this.takeValidityFromClaim = false;
        this.isAllowedMultipleUse = false;
        this.useInServicePriceCalculator = false;
        this.useWhenProductIsActivated = false;
        this.followingRewardId = -1;
        this.usePriceWhenO2PriceIsNull = false;
        this.price = 0;
        this.takeOverOnlyPriceSummary = false;
        this.dataSourceId = -1;
        this.sourceOfSubscribers = SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.ALL;

        Object.assign(this, properties);

        this.platform = getEnumItemByValue(properties.platform, PLATFORMA_TYPES);
        this.type = getEnumItemByValue(properties.type, REWARD_TYPES);
        this.variant = getEnumItemByValue(properties.variant, REWARD_VARIANT);
        this.sourceOfSubscribersEnumDescription = getEnumItemByValue(properties.sourceOfSubscribers, SOURCE_OF_SUBSCRIBERS_TYPES);
        this.availableFrom = properties.availableFrom ? new Date(properties.availableFrom) : properties.availableFrom;
        this.availableTo = properties.availableTo ? new Date(properties.availableTo) : properties.availableTo;
        this.dateOfCreate = properties.dateOfCreate ? new Date(properties.dateOfCreate) : properties.dateOfCreate;
    }
}
