import { CAMPRO_MODULES, getEnumItemsByFlaggedValue } from '../common/enums';

export default class Role {
    id: number;
    name: string;
    permittedModules : any;

    constructor(properties: Partial<Role>) {
        this.id = 0;
        this.name = '';

        Object.assign(this, properties);

        this.permittedModules = getEnumItemsByFlaggedValue(properties.permittedModules, CAMPRO_MODULES);
    }
}
