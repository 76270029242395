import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import * as propTypes from '../../common/propTypes';
import RatingDetail from './ratingDetail';
import {getRowById} from "../../reducers/tableReducer";
import {updateRating} from "../../actions/ratingActions";
import {navigateToRatings} from "../../actions/navigationActions";

const RatingDetailUpdate = ({initialValues, updateRating, cancelAction, t}) => {
  return <RatingDetail initialValues={initialValues} onSubmit={updateRating} headerText={t('UpdateRating')+ ' - ' +initialValues.msisdn} onCancel={cancelAction}/>;
};

RatingDetailUpdate.propTypes = {
  initialValues: propTypes.ratingPropTypes.isRequired,
  updateRating: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;

  return {
    lang: state.app.lang,
    initialValues: getRowById(state, id)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateRating: bindActionCreators(updateRating, dispatch),
    cancelAction: bindActionCreators(navigateToRatings, dispatch)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(RatingDetailUpdate);

