// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import Rating from "../entities/rating";

const ratingDataTransformationAfterFetch = (data) => {
  return data.map((row) => {
    return new Rating(row);
  });
};

export default ratingDataTransformationAfterFetch;
