import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import PushNotificationConfigurationDetail from './pushNotificationConfigurationDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updatePushNotificationConfiguration } from '../../actions/pushNotificationConfigurationActions';
import { navigateToPushNotificationConfigurations } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import PushNotificationConfiguration from '../../entities/pushNotificationConfiguration';

interface PushNotificationConfigurationDetailUpdateProps extends BaseDetailProps<PushNotificationConfiguration> {}

const PushNotificationConfigurationDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: PushNotificationConfigurationDetailUpdateProps) => {
    return <PushNotificationConfigurationDetail initialValues={initialValues} submitAction={submitAction} headerText={t('PushNotificationConfigurationUpdateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<PushNotificationConfigurationDetailUpdateProps>): BaseDetailDispatchProps<PushNotificationConfiguration> => {
    return {
        submitAction: bindActionCreators(updatePushNotificationConfiguration, dispatch),
        cancelAction: bindActionCreators(navigateToPushNotificationConfigurations, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(PushNotificationConfigurationDetailUpdate);
