import {
    EnumDescription,
    DATASOURCE_QUERY_TYPE_ENUM,
    DATASOURCE_TYPE_ENUM,
    DATASOURCE_TYPE_TYPES,
    getEnumItemByValue,
    DATASOURCE_QUERY_TYPE_TYPES,
    PLATFORMA_TYPES_ENUM,
    PLATFORMA_TYPES,
    DATASOURCE_EXECUTION_POLICY_ENUM,
    DATASOURCE_EXECUTION_POLICY_TYPES,
} from '../common/enums';

export default class DataSource {
    id: number;
    name: string;
    query: string;
    itemsCode: string;
    queryContextId: number;
    queryType: number;
    queryTypeEnumDescription: EnumDescription | undefined;
    type: number;
    typeEnumDescription: EnumDescription | undefined;
    platform: number;
    platformEnumDescription: EnumDescription | undefined;
    executionPolicy: number;
    executionPolicyEnumDescription: EnumDescription | undefined;

    constructor(properties: Partial<DataSource> & Pick<DataSource, 'type' | 'queryType' | 'platform' | 'executionPolicy'>) {
        this.id = 0;
        this.name = '';
        this.query = '';
        this.itemsCode = '';
        this.queryContextId = 0;
        this.type = DATASOURCE_TYPE_ENUM.Query;
        this.queryType = DATASOURCE_QUERY_TYPE_ENUM.OneRecord;
        this.platform = PLATFORMA_TYPES_ENUM.ALL;
        this.executionPolicy = DATASOURCE_EXECUTION_POLICY_ENUM.FullDataSource;

        Object.assign(this, properties);

        this.typeEnumDescription = getEnumItemByValue(properties.type, DATASOURCE_TYPE_TYPES);
        this.queryTypeEnumDescription = getEnumItemByValue(properties.queryType, DATASOURCE_QUERY_TYPE_TYPES);
        this.platformEnumDescription = getEnumItemByValue(properties.platform, PLATFORMA_TYPES);
        this.executionPolicyEnumDescription = getEnumItemByValue(properties.executionPolicy, DATASOURCE_EXECUTION_POLICY_TYPES);
    }
}
