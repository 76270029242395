import React from "react";
import { GridCell } from "@progress/kendo-react-grid";

import CellActionButton from "./CellActionButton";

class ButtonCell extends GridCell {
  onClick = event => {
    let { keyField, onClick } = this.props;
    const id = this.props.dataItem[keyField];

    onClick(id);
  };

  render() {
    let { text, icon } = this.props;

    return (
      <td
        style={{ cursor: "pointer"}}
        onClick={this.onClick}
      >
        <CellActionButton
          text={text}
          /*           onClick={this.onClick}
           */ icon={icon}
        />
      </td>
    );
  }
}

const ButtonCellWrapper = (keyField, text, icon, onClick) => props => {
  return (
    <ButtonCell
      {...props}
      keyField={keyField}
      text={text}
      icon={icon}
      onClick={onClick}
    />
  );
};

ButtonCellWrapper.displayName = "ButtonCellWrapper";

export default ButtonCellWrapper;
