import React from "react";

class NavigableCell extends React.Component {
  onCellClick = event => {
    event.preventDefault();

    const { navigateTo, actionUrl } = this.props;
    const { id } = this.props.dataItem;

    navigateTo(`/${actionUrl}/${id}`);
  };

  render() {
    const { component, ...rest } = this.props;
    const Component = component;

    return (
      <Component
        {...rest}
        onClick={this.onCellClick}
        style={{ cursor: "pointer" }}
      />
    );
  }
}

export default NavigableCell;
