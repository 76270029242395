import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {Form, Field} from 'react-final-form';
import { bindActionCreators, compose } from 'redux';

import * as userActions from '../../actions/userActions';
import SubmitButton from '../common/SubmitButton';
import { Link } from 'react-router-dom';
import Translation from '../common/Translation';
import { translate } from 'react-i18next';
import Alerts from '../common/Alerts';
import Input from '../common/Input';
import LoadingIndicator from "../common/LoadingIndicator";

class ForgottenPassword extends React.Component {
  constructor(props) {
    super(props);  
  }

  componentDidMount() {
    this.props.actions.clear();
  }

  renderInstructions = () => {
    return (
      <div className="body">
        <div>
          <h2><Translation tKey="WhatHasHappened"/></h2>
          <p>
            <Translation tKey="ResetPasswordSent" />
          </p>
        </div>
        <div className="links">
          <div>
            <Link to="/signin"><Translation tKey="DoYouKnowYourPassword" /></Link>
          </div>
        </div>
      </div>
    );
  }

  translatedFormValidation = (values) =>{
    const { t } = this.props;
    const errors = {};

    if (!values.email) {
      errors.email = t('EmailIsRequired');
    }
    
    return errors;
  }

  onSubmit = (values) => {      
    this.props.actions.resetPassword(values.email);
  };

  renderForm = () => {
    let { loading } = this.props;
    return (
      <div className="body">
        <div>
          <h2><Translation tKey="NeedHelpWithPassword" /></h2>
          <p>
            <Translation tKey="EnterEmailToCreatePassword" />
        </p>
        </div>
        <div>
        <Form
                    validate={this.translatedFormValidation}
                    onSubmit={this.onSubmit}
                    render={({handleSubmit, reset, submitting, pristine, values, valid, form}) => (
                    <form className="k-form" onSubmit={handleSubmit}>
                      {this.props.loading && <LoadingIndicator/>}                
                      <label className="k-form-field-white" htmlFor="email">
                          <span><Translation tKey="Email"/></span>
                          <Field name="email"
                              component={Input}
                          // placeholder={t("Name")}
                          />
                      </label>

                          <div className="actions">
                            <SubmitButton loading={loading || submitting}><Translation tKey="Next" /></SubmitButton>
                          </div> 
                          <div className="links">
                          <div>
                            <Link to="/signin"><Translation tKey="DoYouKnowYourPassword" /></Link>
                          </div> 
                          </div>                 
                    </form>
                )}/>           
        </div>
      </div>
    );
  }

  render() {
    let { emailSent } = this.props;
    return (
      <div className="signIn-wrapper">
        <div className="signIn">
          <div className="box">
            <Alerts />
            <div className="box-wrapper">
              <div className="header">&nbsp;</div>
              {!emailSent && this.renderForm()}
              {emailSent && this.renderInstructions()}
              <div className="footer">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>);
  }
}

ForgottenPassword.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  emailSent: PropTypes.object
};

const mapStateToProps = (state) => {
  return {
    loading: state.ajaxStatus > 0,
    emailSent: state.user.forgottenPasswordSent
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
};

export default compose(translate('common'), connect(mapStateToProps, mapDispatchToProps))(ForgottenPassword);
