import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import PushNotificationTask from '../../entities/pushNotificationTask';
import { nameofFactory } from '../../helpers/nameOfFactory';
import * as enums from '../../common/enums';
import { BaseDetailProps } from '../common/types/baseDetail';
import DropDown from '../common/DropDown';
import PushNotificationConfiguration from '../../entities/pushNotificationConfiguration';
import { Dispatch, bindActionCreators } from 'redux';
import { fetchPushNotificationTaskDependencies } from '../../actions/pushNotificationTaskActions';
import DataSource from '../../entities/dataSource';
import { getData as getDataSources, getUserReadableProperty as getDataSourceUserReadableProperty } from '../../reducers/dataSourceReducer';
import {
    getData as getPushNotificationConfigurations,
    getUserReadableProperty as getPushNotificationConfigurationUserReadableProperty,
} from '../../reducers/pushNotificationConfigurationReducer';
import DropDownWithValueField from '../common/DropDownWithValueField';
import Switch from '../common/Switch';
import Condition from '../common/utils/Condition';
import DateTimePicker from '../common/DateTimePicker';

const nameof = nameofFactory<PushNotificationTask>();

export interface PushNotificationTaskDetailProps
    extends BaseDetailProps<PushNotificationTask>,
        PushNotificationTaskDetailStateProps,
        PushNotificationTaskDetailDispatchProps {
    headerText: string;
}

export interface PushNotificationTaskDetailStateProps {
    lang: string;
    pushNotificationConfigurations: PushNotificationConfiguration[];
    pushNotificationConfigurationUserReadableProperty: string;
    dataSources: DataSource[];
    dataSourceUserReadableProperty: string;
}

export interface PushNotificationTaskDetailDispatchProps {
    fetchPushNotificationTaskDependencies: () => void;
}

class PushNotificationTaskDetail extends Component<PushNotificationTaskDetailProps> {
    componentDidMount() {
        this.props.fetchPushNotificationTaskDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as PushNotificationTask);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;

        const typedValues = values as PushNotificationTask;
        const errors: Partial<Record<keyof PushNotificationTask, string>> = {};

        if (!typedValues.name) {
            errors.name = t('NameIsRequired');
        }

        if (!typedValues.isOneTime && !typedValues.cronExpression) {
            errors.cronExpression = t('CronExpressionIsRequired');
        }

        if (typedValues.isOneTime && !typedValues.nextRunTime) {
            errors.nextRunTime = t('NextRunTimeIsRequired');
        }

        return errors;
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <label className="k-form-field" htmlFor={nameof('name')}>
                                        <span>
                                            <Translation tKey="Name" />
                                        </span>
                                        <Field name={nameof('name')} component={Input} />
                                    </label>
                                    <label className="k-form-field" htmlFor={nameof('cronExpression')}>
                                        <span>
                                            <Translation tKey="CronExpression" />
                                        </span>
                                        <Field name={nameof('cronExpression')} component={Input} />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('dataSourceId')}>
                                        <span>
                                            <Translation tKey="DataSourcet" />
                                        </span>
                                        <Field
                                            name={nameof('dataSourceId')}
                                            component={DropDownWithValueField}
                                            textField={this.props.dataSourceUserReadableProperty}
                                            valueField="id"
                                            dataSource={this.props.dataSources}
                                        />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('pushNotificationConfigurationId')}>
                                        <span>
                                            <Translation tKey="PushNotificationConfiguration" />
                                        </span>
                                        <Field
                                            name={nameof('pushNotificationConfigurationId')}
                                            component={DropDownWithValueField}
                                            textField={this.props.pushNotificationConfigurationUserReadableProperty}
                                            valueField="id"
                                            dataSource={this.props.pushNotificationConfigurations}
                                        />
                                    </label>
                                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('isOneTime')}>
                                        <span>
                                            <Translation tKey="IsOneTime" />
                                        </span>
                                        <Field
                                            name={nameof('isOneTime')}
                                            component={Switch}
                                            // placeholder={t("Name")}
                                        />
                                    </label>

                                    <Condition when={nameof('isOneTime')} is={true}>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('nextRunTime')}>
                                            <span>
                                                <Translation tKey="NextRunTime" />
                                            </span>
                                            <Field name={nameof('nextRunTime')} component={DateTimePicker} />
                                        </label>
                                    </Condition>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): PushNotificationTaskDetailStateProps => {
    return {
        lang: state.app.lang,
        pushNotificationConfigurations: getPushNotificationConfigurations(state),
        pushNotificationConfigurationUserReadableProperty: getPushNotificationConfigurationUserReadableProperty(state),
        dataSources: getDataSources(state),
        dataSourceUserReadableProperty: getDataSourceUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<PushNotificationTaskDetailProps>): PushNotificationTaskDetailDispatchProps => {
    return {
        fetchPushNotificationTaskDependencies: bindActionCreators(fetchPushNotificationTaskDependencies, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationTaskDetail);
