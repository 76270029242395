import React from 'react';
import ReactDropzone from 'react-dropzone';
import Img from 'react-image';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Field } from 'react-final-form';

import WhenFieldIsEmpty from './utils/WhenFieldIsEmpty';
import WhenFieldHasValue from './utils/WhenFieldHasValue';
import ErrorValidationMessage from './ErrorValidationMessage';

import { MAX_IMAGE_SIZE_IN_BYTES, MAX_IMAGE_SIZE_IN_MEGA_BYTES } from '../../common/configs';
import { finalFormInput, finalFormMeta } from '../../common/propTypes';
import Translation from './Translation';

interface IDropzoneProps {
    input: any;
    meta: any;
    recommendedResolution: any;
    imageUrl: any;
}

/* const Dropzone = ({ input, meta, recommendedResolution, imageUrl, ...rest }: IDropzoneProps): React.ReactElement => {
    const onChange = (filesToUpload: any) => {
        input.onChange(filesToUpload[0]);
    };

    const onRemoveImage = (event: any) => {
        event.preventDefault();
        input.onChange(null);
    };

    const isInvalid = meta.error && meta.touched;
    const wrapperClass = classNames({
        'validable-control': true,
        'validable-control__error': isInvalid,
    });

    return (
        <div className={wrapperClass}>
            <Field name={imageUrl}>
                {(imageUrlProps) => (
                    <ReactDropzone
                        className="drop-zone"
                        onDrop={onChange}
                        accept="image/png, image/jpeg"
                        multiple={false}
                        maxSize={MAX_IMAGE_SIZE_IN_BYTES}
                    >
                        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                            if (isDragReject) {
                                return (
                                    <div className="drop-zone empty">
                                        <span className="__text">
                                            <Translation tKey={'InvalidImage'} />
                                            {MAX_IMAGE_SIZE_IN_MEGA_BYTES} MB!!
                                        </span>
                                    </div>
                                );
                            }

                            return (
                                <div>
                                    <WhenFieldHasValue when={imageUrl}>
                                        <span className="cross-close" onClick={onRemoveImage} />
                                    </WhenFieldHasValue>

                                    <WhenFieldIsEmpty when={imageUrl}>
                                        <div className="drop-zone empty">
                                            <span className="__text">
                                                <Translation tKey={'DragYourPngHere'} />
                                                {recommendedResolution}
                                            </span>
                                        </div>
                                    </WhenFieldIsEmpty>

                                    <WhenFieldHasValue when={imageUrl}>
                                        <Img style={{ width: '100%', padding: '6px' }} src={[imageUrlProps.input.value]} />
                                    </WhenFieldHasValue>
                                </div>
                            );
                        }}
                    </ReactDropzone>
                )}
            </Field>
            {isInvalid && <ErrorValidationMessage errorMessage={meta.error} />}
        </div>
    );
};

Dropzone.propTypes = {
    input: finalFormInput.isRequired,
    meta: finalFormMeta.isRequired,
    recommendedResolution: PropTypes.string.isRequired,
    imageUrl: PropTypes.string.isRequired,
}; */

const Dropzone = (options: any): React.ReactElement => {
    const { input, meta, recommendedResolution, imageUrl, ...rest } = options;

    const onChange = (filesToUpload: any) => {
        input.onChange(filesToUpload[0]);
    };

    const onRemoveImage = (event: any) => {
        event.preventDefault();
        input.onChange(null);
    };

    const isInvalid = meta.error && meta.touched;
    const wrapperClass = classNames({
        'validable-control': true,
        'validable-control__error': isInvalid,
    });

    return (
        <div className={wrapperClass}>
            <Field name={imageUrl}>
                {(imageUrlProps) => (
                    <ReactDropzone
                        className="drop-zone"
                        onDrop={onChange}
                        accept="image/png, image/jpeg"
                        multiple={false}
                        maxSize={MAX_IMAGE_SIZE_IN_BYTES}
                    >
                        {({ isDragAccept, isDragReject, acceptedFiles, rejectedFiles }) => {
                            if (isDragReject) {
                                return (
                                    <div className="drop-zone empty">
                                        <span className="__text">
                                            <Translation tKey={'InvalidImage'} />
                                            {MAX_IMAGE_SIZE_IN_MEGA_BYTES} MB!!
                                        </span>
                                    </div>
                                );
                            }

                            return (
                                <div>
                                    <WhenFieldHasValue when={imageUrl}>
                                        <span className="cross-close" onClick={onRemoveImage} />
                                    </WhenFieldHasValue>

                                    <WhenFieldIsEmpty when={imageUrl}>
                                        <div className="drop-zone empty">
                                            <span className="__text">
                                                <Translation tKey={'DragYourPngHere'} />
                                                {recommendedResolution}.
                                                <Translation tKey={'MaxImageSize'} />
                                                {MAX_IMAGE_SIZE_IN_MEGA_BYTES} MB!
                                            </span>                                            
                                        </div>
                                    </WhenFieldIsEmpty>

                                    <WhenFieldHasValue when={imageUrl}>
                                        <Img style={{ width: '100%', padding: '6px' }} src={[imageUrlProps.input.value]} />
                                    </WhenFieldHasValue>
                                </div>
                            );
                        }}
                    </ReactDropzone>
                )}
            </Field>
            {isInvalid && <ErrorValidationMessage errorMessage={meta.error} />}
        </div>
    );
};

export default Dropzone;
