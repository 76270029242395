import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import { edit, filterChange, filterClear } from '../../actions/tableActions';
import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import StringCell from '../common/formatters/StringCell';
import EnumCell from '../common/formatters/EnumCelll';
import DateTimeCell from '../common/formatters/DateTimeCell';
import NavigableCell from '../common/formatters/navigableCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { OUTAGE_NOTIFICATION } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import * as enums from '../../common/enums';
import { TEXT_HORIZONTAL_ALIGN, NAVIGATION_PATHS } from '../../common/constants';
import OutageNotificationActionsBar from './outageNotificationActionsBar';

const defaultRatingTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

const actionUrl = 'outageNotification';

const OutageNotification = ({ t, navigateTo, edit, filter, clearFilter }) => {
    let table;

    const customRowRender = (trElement, rowProps, data) => {
        console.warn('OutageNotification.rowRender');

        /*const isRowWithSameOrder = data.some((item)=>{
      return item.order === rowProps.dataItem.order && item.id !== rowProps.dataItem.id;
    });
    const orange = { backgroundColor: "rgba(255,190, 12,1)" };
    const trProps = { style: isRowWithSameOrder ? orange : null };
    */
        //return React.cloneElement(trElement, { ...trProps }, trElement.props.children);

        return trElement;
    };

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.OUTAGE_NOTIFICATION_CREATE);
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="OutageNotifications" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <OutageNotificationActionsBar edit={edit} filter={filter} clearFilter={clearFilter} new={onNew} remove={onRemove} />
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'outageNotification'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={OUTAGE_NOTIFICATION}
                            name="OutageNotificationTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultRatingTabelSort}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field="order"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Order')}
                                width="75px"
                            />
                            <Column
                                field="name"
                                width="250"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Name')}
                            />
                            <Column
                                field="platform"
                                width="150"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={EnumCell(enums.PLATFORMA_TYPES)}
                                    />
                                )}
                                title={t('Platform')}
                            />
                            <Column
                                field="notificationFrom"
                                width="150"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={DateTimeCell} />}
                                title={t('NotificationFrom')}
                            />
                            <Column
                                field="outageFrom"
                                width="150"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={DateTimeCell} />}
                                title={t('OutageFrom')}
                            />
                            <Column
                                field="outageTo"
                                width="150"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={DateTimeCell} />}
                                title={t('OutageTo')}
                            />
                            <Column
                                field="notificationTo"
                                width="150"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={DateTimeCell} />}
                                title={t('NotificationTo')}
                            />
                            <Column
                                field="text"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Text')}
                            />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

OutageNotification.propTypes = {
    t: PropTypes.func.isRequired,
    navigateTo: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        clearFilter: bindActionCreators(filterClear, dispatch),
        filter: bindActionCreators(filterChange, dispatch),
        edit: bindActionCreators(edit, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(OutageNotification);
