import {Switch as KendoSwitch} from "@progress/kendo-react-inputs";
import KendoHOC from "./utils/KendoHOC";

const Switch = (options) => {
    const switchOPTIONS={
        ...options,
       input: {          
            ...options.input,
            checked:options.input.value,
       }
    };

    return KendoHOC(KendoSwitch, switchOPTIONS);
};

export default Switch;
