import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { translate } from 'react-i18next';

import OutageNotificationDetail from './outageNotificationDetail';
import { createOutageNotification } from '../../actions/outageNotificationActions';
import { navigateToOutageNotifications } from '../../actions/navigationActions';
import * as enums from '../../common/enums';

const initialValues = {
    name: '',
    filter: enums.OUTAGE_NOTIFICATION_FILTER_TYPE[0],
    order: 0,
};

const OutageNotificationDetailCreate = ({ saveAction, cancelAction, t }) => {
    return (
        <OutageNotificationDetail
            initialValues={initialValues}
            onSubmit={saveAction}
            headerText={t('OutageNotificationLabel')}
            onCancel={cancelAction}
        />
    );
};

OutageNotificationDetailCreate.propTypes = {
    saveAction: PropTypes.func.isRequired,
    cancelAction: PropTypes.func.isRequired,
    t: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        saveAction: bindActionCreators(createOutageNotification, dispatch),
        cancelAction: bindActionCreators(navigateToOutageNotifications, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(OutageNotificationDetailCreate);
