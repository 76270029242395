import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import queryString from 'query-string';
import {Form, Field} from 'react-final-form';

import * as userActions from '../../actions/userActions';
import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import Input from '../common/Input';
import * as propTypes from '../../common/propTypes';
import LoadingIndicator from '../common/LoadingIndicator';

class UserInvite extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        email: '',
        password: ''
      };
    }

    componentDidMount(){
        this.props.actions.clear();
        
        const search = this.props.location.search;
        const parsed = queryString.parse(search);
        
        if(!parsed.code){
            // TODO - neco je spatne, meni code
        }
    }

    translatedFormValidation = (values) =>{
        const { t } = this.props;
        const errors = {};
    
        if (!values.password) {
          errors.password = t('PasswordIsRequired');
        }        
    
        if (values.password && (values.password !== values.confirmPassword)) {
            errors.confirmPassword = t('PasswordsDontMatchRetype');
        }
        
        return errors;
    }
  
    onSubmit = (values) => {
        const search = this.props.location.search;
        const parsed = queryString.parse(search);
        
        this.props.actions.setPassword(values.password, parsed.code);
    };
  
    render() {
      let { loading } = this.props;
  
      return (
        <div className="signIn-wrapper">
          <div className="signIn">
            <div className="box">
              <Alerts />
              <div className="box-wrapper">
                <div className="header">&nbsp;</div>
                <div className="body">
                <Form
                    validate={this.translatedFormValidation}
                    onSubmit={this.onSubmit}
                    render={({handleSubmit, reset, submitting, pristine, values, valid, form}) => (
                    <form className="k-form" onSubmit={handleSubmit}>
                    {this.props.loading && <LoadingIndicator/>}                
                    <label className="k-form-field-white" htmlFor="password">
                        <span><Translation tKey="Password"/></span>
                        <Field name="password"
                            component={Input}
                            type="password"
                        // placeholder={t("Name")}
                        />
                    </label>

                    <label className="k-form-field-white" htmlFor="confirmPassword">
                        <span><Translation tKey="ConfirmPassword"/></span>
                        <Field name="confirmPassword"
                            component={Input}
                            type="password"
                        // placeholder={t("Name")}
                        />
                    </label>

                        <div className="actions">
                        <SubmitButton loading={loading || submitting}><Translation tKey="SetPassword" /></SubmitButton>
                        </div> 
                        <div className="links">
                        <div>
                            <Link to="/signin"><Translation tKey="SignIn" /></Link>
                        </div> 
                        </div>                 
                    </form>
                )}/>                
                  </div>
              </div>
            </div>
          </div>
        </div>
        );
    }
}
  
  UserInvite.propTypes = {
    actions: PropTypes.shape({
        setPassword: PropTypes.func.isRequired,
        clear: PropTypes.func.isRequired,
    }).isRequired,
    loading: PropTypes.bool,
    location: propTypes.location.isRequired,
    t: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
  };
  
  const mapStateToProps = (state, ownProps, a) => {
    return {
      loading: state.ajaxStatus > 0
    };
  };
  
  const mapDispatchToProps = (dispatch) => {
    return {
      actions: bindActionCreators(userActions, dispatch)
    };
  };
  
  export default compose(translate('common', { wait: true }), connect(mapStateToProps, mapDispatchToProps))(UserInvite);