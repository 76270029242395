import * as types from '../actions/actionTypes';
import AffiliateProgramPartner from '../entities/affiliateProgramPartner';

const affiliateProgramPartnersInitialState = {
    userReadableProperty: 'name',
    loadingDependencies: false,
    loaded: false,
    data: [],
};

const affiliateProgramPartners = (state = affiliateProgramPartnersInitialState, action: any) => {
    switch (action.type) {
        case types.AFFILIATE_PROGRAM_PARTNERS_FETCH:
            return {
                ...state,
                data: action.data.map((affiliateProgramPartner: AffiliateProgramPartner) => {
                    return { ...affiliateProgramPartner };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default affiliateProgramPartners;

export const isLoaded = (state: any): boolean => {
    return state.affiliateProgramPartners.loaded === true;
};

export const getData = (state: any): AffiliateProgramPartner[] => {
    return state.affiliateProgramPartners.data;
};

export const getUserReadableProperty = (state: any): keyof AffiliateProgramPartner => {
    return state.affiliateProgramPartners.userReadableProperty;
};
