const ratingSettingsTransformationBeforeSave = ratingSettings => {
 
  const transformedData = {
    ...ratingSettings,
    allowedVariantAfterOrder: ratingSettings.allowedVariantAfterOrder.value,
    allowedVariantAfterRecharge: ratingSettings.allowedVariantAfterRecharge.value,
    allowedVariantAfterCompetition: ratingSettings.allowedVariantAfterCompetition.value,
    allowedVariantAfterLeavingPageExtra: ratingSettings.allowedVariantAfterLeavingPageExtra.value,  
    allowedVariantAfterLeavingPageSuriGame: ratingSettings.allowedVariantAfterLeavingPageSuriGame.value, 
  }; 

  return transformedData;
};

export default ratingSettingsTransformationBeforeSave;
