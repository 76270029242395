import PushNotificationTask from '../entities/pushNotificationTask';

const PushNotificationTaskItemTransformationBeforeSave = (item: PushNotificationTask) => {
    const transformedData = {
        ...item,
        nextRunTime: item.isOneTime ? item.nextRunTime?.toUTCString() : null,
        cronExpression: item.isOneTime ? null : item.cronExpression,
    } as { [key: string]: any };

    return transformedData;
};

export default PushNotificationTaskItemTransformationBeforeSave;
