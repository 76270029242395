import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import BetatesterDetail from './betatesterDetail';
import {createBetatester} from "../../actions/betatesterAction";
import {navigateToBetatester} from "../../actions/navigationActions";


const initialValues= {
  msisdn:'',
  description: ''
};

const BetatesterDetailCreate =({saveAction, cancelAction, t})=>{
  return <BetatesterDetail initialValues={initialValues} onSubmit={saveAction} headerText={t('BetaTesterLabel')} onCancel={cancelAction}/>;
};

BetatesterDetailCreate.propTypes = {
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAction: bindActionCreators(createBetatester, dispatch),
    cancelAction: bindActionCreators(navigateToBetatester, dispatch)
  };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(BetatesterDetailCreate);

