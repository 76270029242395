import Extra from '../entities/extra';

const extraTransformationBeforeSave = (extra: Extra) => {
    const hvcForAllValidFrom =
        extra.hvcForAll && extra.hvcForAllValidFrom
            ? new Date(extra.hvcForAllValidFrom.getFullYear(), extra.hvcForAllValidFrom.getMonth(), extra.hvcForAllValidFrom.getDate(), 0, 0, 0, 0)
            : null;
    const hvcForAllValidTo =
        extra.hvcForAll && extra.hvcForAllValidTo
            ? new Date(extra.hvcForAllValidTo.getFullYear(), extra.hvcForAllValidTo.getMonth(), extra.hvcForAllValidTo.getDate(), 23, 59, 59, 0)
            : null;

    const transformedData = {
        ...extra,
        hvcForGroupName: extra.hvcForGroup ? extra.hvcForGroupName : null,
        hvcForGroupDateSuffixType: extra.hvcForGroupUseDateSuffix ? extra.hvcForGroupDateSuffixType.value : null,
        hvcForAllValidFrom: hvcForAllValidFrom ? hvcForAllValidFrom.toUTCString() : null,
        hvcForAllValidTo: hvcForAllValidTo ? hvcForAllValidTo.toUTCString() : null,
    };

    return transformedData;
};

export default extraTransformationBeforeSave;
