import React from "react";
import PropTypes from "prop-types";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import { Field, reduxForm } from "redux-form";
import { translate } from "react-i18next";

import * as userActions from "../../actions/userActions";
import TextBox from "../common/TextBox";
import SubmitButton from "../common/SubmitButton";
import { Link } from "react-router-dom";
import Alerts from "../common/Alerts";
import Translation from "../common/Translation";

class SignIn extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
    };
  }

  signIn = (values) => {
    this.props.actions.signIn(values.email, values.password);
  };

  render() {
    let { t, handleSubmit } = this.props;

    return (
      <div className="signIn-wrapper">
        <div className="signIn">
          <div className="box">
            <Alerts />
            <div className="box-wrapper">
              <div className="header">&nbsp;</div>
              <div className="body">
                <form onSubmit={handleSubmit(this.signIn)}>
                  <div className="email">
                    <Field
                      icon="user"
                      name="email"
                      placeholder="Email"
                      component={TextBox}
                    />
                  </div>
                  <div className="password">
                    <Field
                      icon="lock"
                      name="password"
                      type="password"
                      placeholder={t("Password")}
                      component={TextBox}
                      autoComplete="off"
                    />
                  </div>
                  <div className="actions">
                    <SubmitButton loading={this.props.loading}>
                      <Translation tKey="SignIn" />
                    </SubmitButton>
                  </div>
                  <div className="links">
                    <div>
                      <Link to="/forgotten-password">
                        <Translation tKey="HavingTroubleSignIn" />
                      </Link>
                    </div>
                  </div>
                </form>
              </div>
              <div className="footer">&nbsp;</div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

SignIn.propTypes = {
  actions: PropTypes.shape({
    signIn: PropTypes.func.isRequired,
  }).isRequired,
  loading: PropTypes.bool,
  t: PropTypes.func.isRequired,
  handleSubmit: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    loading: state.ajaxStatus > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch),
  };
};

export default compose(
  translate("common", { wait: true }),
  connect(mapStateToProps, mapDispatchToProps),
  reduxForm({ form: "news" })
)(SignIn);
