import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { compose, bindActionCreators } from 'redux';
import { translate } from 'react-i18next';

import { edit, filterChange, filterClear } from '../../actions/tableActions';
import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import RatingCell from '../common/formatters/ratingCell';
import StringCell from '../common/formatters/StringCell';
import EnumCell from '../common/formatters/EnumCelll';
import DateCell from '../common/formatters/DateCell';
import NavigableCell from '../common/formatters/navigableCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { GAME_CLAN, RATING } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import { changeBlockedState } from '../../actions/gameClanActions';

import * as enums from '../../common/enums';
import { TEXT_HORIZONTAL_ALIGN } from '../../common/constants';
import GameClansActionsBar from './gameClansActionsBar';
import GameClansSearchBox from './gameClansSearchBox';
import BoolCell from '../common/formatters/BoolCell';

const defaultGameClansTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

const actionUrl = 'gameClan';

const GameClans = ({ t, navigateTo, edit, changeBlockedState, filter, clearFilter }) => {
    let table;

    const customRowRender = (trElement, rowProps, data) => {
       /*  console.warn('GameClans.rowRender'); */

        /*const isRowWithSameOrder = data.some((item)=>{
      return item.order === rowProps.dataItem.order && item.id !== rowProps.dataItem.id;
    });
    const orange = { backgroundColor: "rgba(255,190, 12,1)" };
    const trProps = { style: isRowWithSameOrder ? orange : null };
    */
        //return React.cloneElement(trElement, { ...trProps }, trElement.props.children);

        return trElement;
    };

    const changeBlockedStateHanlder = (newValue, id) => {
        console.warn('changeBlockedStateHanlder');
        console.warn(newValue);

        changeBlockedState({ id: id, isBlocked: newValue });
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="Game clans" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <GameClansActionsBar changeBlockedState={changeBlockedStateHanlder} filter={filter} clearFilter={clearFilter} />
                        <div className="filter" />
                        <GameClansSearchBox />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'gameClan'}
                            actionPath={actionUrl}
                            /* selectedField="selected" */
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={GAME_CLAN}
                            name="GameClanTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultGameClansTabelSort}
                        >
                            {/* <Column field="selected" width="50" /> */}
                            <Column field="id" width="120" cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)} title={t('Id')} />
                            <Column field="name" width="250" cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)} title={t('GameClanName')} />
                            <Column field="isBlocked" width="250" cell={BoolCell(changeBlockedStateHanlder, 'id')} title={t('GameClanIsBlocked')} />
                            {/* <Column
                field="description"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                  />
                )}
                title={t("RatingText")}
              />
              <Column
                field="trigger"
                width="130"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={EnumCell(enums.RATING_TRIGGER_TYPES)}
                  />
                )}
                title={t("Event")}
              />
              <Column
                field="dateOfCreate"
                width="150"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={DateCell}
                  />
                )}
                title={t("DateOfRating")}
              />
              <Column
                field="platform"
                width="130"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={EnumCell(enums.PLATFORMA_TYPES)}
                  />
                )}
                title={t("Platform")}
              />
              <Column
                field="variant"
                width="130"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={EnumCell(enums.RATING_VARIANT_TYPES)}
                  />
                )}
                title={t("Variant")}
              /> */}
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

GameClans.propTypes = {
    t: PropTypes.func.isRequired,
    navigateTo: PropTypes.func.isRequired,
    edit: PropTypes.func.isRequired,
    filter: PropTypes.func.isRequired,
    clearFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        clearFilter: bindActionCreators(filterClear, dispatch),
        filter: bindActionCreators(filterChange, dispatch),
        changeBlockedState: bindActionCreators(changeBlockedState, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(GameClans);
