import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import * as appActions from '../../actions/tableActions';
import SubmitButton from '../common/SubmitButton';
import TranslatedTextBox from '../common/TranslatedTextBox';
import Translation from '../common/Translation';

class LinksSearchBox extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: '',
        };
    }
    setFilter = (value) => {
        this.props.actions.filterChange([
            {
                name: 'key',
                field: 'key',
                operator: 'contains',
                value: value,
                logicalOperator:3
            },{
                name: 'link',
                field: 'link',
                operator: 'contains',
                value: value,
                logicalOperator:3
            },
        ]);
    };

    clear = () => {
        this.setState({ value: '' });
        this.setFilter('');
    };

    handleSearch = (e) => {
        e.preventDefault();
        const { value } = this.state;

        this.setFilter(value);
    };

    handleChange = (e) => {
        this.setState({ value: e.target.value });
    };

    render() {
        return (
            <div className="search">
                <TranslatedTextBox name="search" value={this.state.value} onChange={this.handleChange} placeholder="Search" />
                &nbsp;
                {this.state.value && <span className={`icon icon-cancel`} onClick={this.clear} />}
                <SubmitButton onClick={this.handleSearch}>
                    <Translation tKey="Search" />
                </SubmitButton>
            </div>
        );
    }
}

LinksSearchBox.propTypes = {
    actions: PropTypes.object,
};

const mapDispatchToProps = (dispatch) => {
    return {
        actions: bindActionCreators(appActions, dispatch),
    };
};

export default connect(null, mapDispatchToProps, null, { withRef: true })(LinksSearchBox);
