import React from 'react';
import PropTypes from 'prop-types';
import NavLink from './NavLink';
// import NavGroup from './NavGroup';
import Translation from './Translation';
import HasAccess from '../security/hasAccess';
import { NAVIGATION_PATHS } from '../../common/constants';
import { CAMPRO_MODULE_ENUM } from '../../common/enums';

interface SideBarProps {
    open: boolean;
    path: string;
}

const SideBar = ({ path, open }: SideBarProps) => {
    let sidebar = 'sidebar';
    if (!open) sidebar += ' sidebar-close';

    return (
        <div>
            <nav className={sidebar}>
                <div className="avatar">
                    &nbsp;
                    <span className="icon logo" />
                </div>
                <div className="sidebar-menu">
                    <div className="sidebar-label">
                        <Translation tKey="Agenda" />
                    </div>
                    <ul>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.NEWS}>
                            <NavLink to="/news" path={path}>
                                <span className="icon icon-envelope" />
                                <Translation tKey="News" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.COMPETITIONS}>
                            <NavLink to="/competition" path={path}>
                                <span className="icon icon-lightbulb" />
                                <Translation tKey="Competitions" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.EXTRA}>
                            <NavLink to="/extra" path={path}>
                                <span className="icon icon-star-empty" />
                                <Translation tKey="Extra" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.USERS}>
                            <NavLink to="/users" path={path}>
                                <span className="icon icon-user-circle" />
                                <Translation tKey="Users" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.ROLES}>
                            <NavLink to={NAVIGATION_PATHS.ROLES} path={path}>
                                <span className="icon icon-user-circle" />
                                <Translation tKey="Roles" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.BETA_TESTERS}>
                            <NavLink to="/betatester" path={path}>
                                <span className="icon icon-user-circle" />
                                <Translation tKey="BetaTestersLabel" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.CHARGINGCODES}>
                            <NavLink to="/chargingcode" path={path}>
                                <span className="icon icon-address-card-o " />
                                <Translation tKey="ChargingCodesLabel" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.REPORTS}>
                            <NavLink to="/report" path={path}>
                                <span className="icon icon-chart-pie" />
                                <Translation tKey="Reports" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.RATING}>
                            <NavLink to="/rating" path={path}>
                                <span className="icon icon-commenting-o" />
                                <Translation tKey="Ratings" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.RATING_SETTINGS}>
                            <NavLink to="/ratingsettings" path={path}>
                                <span className="icon icon-cog" />
                                <Translation tKey="RatingSettings" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.ECOMAIL}>
                            <NavLink to="/ecomail" path={path}>
                                <span className="icon icon-th-large" />
                                <Translation tKey="Ecomail" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.OUTAGE_NOTIFICATIONS}>
                            <NavLink to="/outagenotification" path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="OutageNotifications" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.FOR_ME}>
                            <NavLink to={NAVIGATION_PATHS.REWARDS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="Rewards" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.CMT}>
                            <NavLink to={NAVIGATION_PATHS.TARGET_CAMPAIGNS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="TargetCampaigns" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.SIMCARD_TYPES}>
                            <NavLink to={NAVIGATION_PATHS.SIM_CARD_TYPES} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="SimCardTypes" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.AFFILIATE_PROGRAM_PARTNERS}>
                            <NavLink to={NAVIGATION_PATHS.AFFILIATE_PROGRAM_PARTNERS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="AffiliateProgramPartners" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.AFFILIATE_PROGRAM_REWARDS}>
                            <NavLink to={NAVIGATION_PATHS.AFFILIATE_PROGRAM_REWARDS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="AffiliateProgramRewards" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS}>
                            <NavLink to={NAVIGATION_PATHS.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="AffiliateProgramClaims" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.GAME_CLANS}>
                            <NavLink to={NAVIGATION_PATHS.GAME_CLANS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="GameClans" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.TEXTS}>
                            <NavLink to={NAVIGATION_PATHS.TEXTS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="Texts" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.LINKS}>
                            <NavLink to={NAVIGATION_PATHS.LINKS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="Links" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.DATASOURCES}>
                            <NavLink to={NAVIGATION_PATHS.DATASOURCES} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="DataSources" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.DATASOURCEQUERYCONTEXTS}>
                            <NavLink to={NAVIGATION_PATHS.DATASOURCEQUERYCONTEXTS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="DataSourceQueryContexts" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.PUSHNOTIFICATIONCONFIGURATIONS}>
                            <NavLink to={NAVIGATION_PATHS.PUSHNOTIFICATIONCONFIGURATIONS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="PushNotificationConfigurations" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.PUSHNOTIFICATIONTASKS}>
                            <NavLink to={NAVIGATION_PATHS.PUSHNOTIFICATIONTASKS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="PushNotificationTasks" />
                            </NavLink>
                        </HasAccess>
                        <HasAccess requestedPermittedModule={CAMPRO_MODULE_ENUM.EVENTTRIGGERCONFIGURATIONS}>
                            <NavLink to={NAVIGATION_PATHS.EVENTTRIGGERCONFIGURATIONS} path={path}>
                                <span className="icon icon-wrench" />
                                <Translation tKey="EventTriggerConfigurations" />
                            </NavLink>
                        </HasAccess>
                        {/*entityLink*/}
                    </ul>
                </div>
            </nav>
        </div>
    );
};

SideBar.propTypes = {
    open: PropTypes.bool,
    path: PropTypes.string,
};

export default SideBar;
