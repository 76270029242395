import axios from 'axios';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import {navigateToChargingCode} from "./navigationActions";
import ResponseStatus from "../utils/responseStatus";

export const createChargingCode = (data) => (dispatch) => {
  dispatch(beginAjaxCall());

  axios.post(`chargingcode`, data)
    .then((response) => {
      if (ResponseStatus.IsCreated(response.status)) {
        dispatch({
          type: types.CHARGINGCODE_ADD
        });

        dispatch(navigateToChargingCode());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch((error) => {
      dispatch(ajaxCallError());
      throw error;
    });
};

export const updateChargingCode = (data) => (dispatch) => {
  dispatch(beginAjaxCall());

   axios.put(`chargingcode`, data)
    .then((response) => {
      if (ResponseStatus.IsOK(response.status)) {
        dispatch({
          type: types.CHARGINGCODE_UPDATE,
          data: response.data
        });

        dispatch(navigateToChargingCode());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch((error) => {
      dispatch(ajaxCallError());
      throw error;
    });
};
