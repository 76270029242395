import React from "react";

import {TEXT_HORIZONTAL_ALIGN} from "../../../common/constants";

class StringCell extends React.Component {
  render() {
    const value = this.props.dataItem[this.props.field];
    let {textAlign, onClick, style} = this.props;

    if(textAlign===null){
      textAlign=TEXT_HORIZONTAL_ALIGN.CENTER;
    }

    style.textAlign = textAlign;

    return (
      <td style={style} onClick={onClick}>
        {value}
      </td>
    );
  }
}

const StringCellWrapper = (horizontalTextAlign) => (props) => {
  return <StringCell {...props} textAlign={horizontalTextAlign} />;
};

StringCellWrapper.displayName = 'StringCellWrapper';

export default StringCellWrapper;
