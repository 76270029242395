import React from 'react';

import ActionButton from '../common/ActionButton';

interface AffiliateProgramSubscriberRewardClaimsActionsBarProps {
    onNew: () => void;
    /* onEdit: () => void; */
    onRemove: () => void;
    /* onClone: () => void; */
}

export default class AffiliateProgramSubscriberRewardClaimsActionsBar extends React.Component<AffiliateProgramSubscriberRewardClaimsActionsBarProps> {
    render() {
        return (
            <div className="actions">
                <ActionButton text="Add" onClick={this.props.onNew} icon="plus" />
                {/* <ActionButton text="Edit" onClick={this.props.onEdit} icon="pencil" selection="one" /> */}
                <ActionButton text="Remove" onClick={this.props.onRemove} icon="delete" selection="multi" />
                {/* <ActionButton text="Clone" onClick={this.props.onClone} icon="copy" selection="one" /> */}
            </div>
        );
    }
}
