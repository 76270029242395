export const tableInitialState = {
  data: [],
  sort: [{ field: "id", dir: "asc" }],
  filter: [],
  total: 0,
  skip: 0,
  take: 25,
  cache: {
    data:[],
    skip: 0,
    take: 100
  },
  gridFilter: {},
  current: 0,
  loaded: false,
};

export const byIdInitialState={};