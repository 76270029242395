import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-calculate';
import { Dispatch, bindActionCreators } from 'redux';

import * as enums from '../../common/enums';

import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import DropDown from '../common/DropDown';
import Reward from '../../entities/reward';
import SubmitButton from '../common/SubmitButton';
import { nameofFactory } from '../../helpers/nameOfFactory';
import { isEmpty } from '../../utils/isEmpty';
import { MAX_IMAGE_SIZE_IN_BYTES, MAX_IMAGE_SIZE_IN_MEGA_BYTES } from '../../common/configs';
import Condition from '../common/utils/Condition';
import { BaseDetailProps } from '../common/types/baseDetail';
import { getData as getRewards, getUserReadableProperty as getFollowingRewardUserReadableProperty } from '../../reducers/rewardReducer';
import { getData as getDataSources, getUserReadableProperty as getDataSourceUserReadableProperty } from '../../reducers/dataSourceReducer';
import RewardDetailStandardPanel from './rewardDetailStandardPanel';
import RewardDetailFollowingPanel from './rewardDetailFollowingPanel';
import { EnumDescription } from '../../common/enums';
import DataSource from '../../entities/dataSource';
import { fetchRewardDependencies } from '../../actions/rewardActions';

const nameof = nameofFactory<Reward>();

const getImageUrl = (imageValue: any, oldImageUrl: any) => {
    if (oldImageUrl) {
        URL.revokeObjectURL(oldImageUrl);
    }

    let newImageUrl = null;
    if (!isEmpty(imageValue)) {
        newImageUrl = URL.createObjectURL(imageValue);
    }

    return newImageUrl;
};

const formDecorator = createDecorator({
    field: 'detailImage',
    updates: {
        detailImageUrl: (imageValue: any, allValues: any) => {
            const oldImageUrl = allValues.detailImageUrl;

            return getImageUrl(imageValue, oldImageUrl);
        },
    },
});

export interface RewardDetailProps extends BaseDetailProps<Reward>, RewardDetailStateProps, RewardDetailDispatchProps {
    headerText: string;
}

export interface RewardDetailStateProps {
    lang: string;
    rewards: Reward[];
    followingRewardUserReadableProperty: keyof Reward;
    dataSources: DataSource[];
    dataSourceUserReadableProperty: string;
}

export interface RewardDetailDispatchProps {
    fetchRewardDependencies: () => void;
}

class RewardDetail extends React.Component<RewardDetailProps> {
    componentDidMount() {
        this.props.fetchRewardDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as Reward);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    showIsDigiCondition = (value: any) => {
        return value !== enums.SOURCE_OF_SUBSCRIBERS_TYPES[1];
    };

    showIsO2VopeCondition = (value: any) => {
        return value === enums.SOURCE_OF_SUBSCRIBERS_TYPES[1];
    };

    variantFollowing = (value: any) => {
        return value === enums.REWARD_VARIANT[enums.REWARD_VARIANT_ENUM.FOLLOWING];
    };

    variantStandard = (value: any) => {
        return value === enums.REWARD_VARIANT[enums.REWARD_VARIANT_ENUM.STANDARD];
    };

    showIsGameOrAffiliateProgramCondition = (value: any) => {
        return value === enums.SOURCE_OF_SUBSCRIBERS_TYPES[3] || value === enums.SOURCE_OF_SUBSCRIBERS_TYPES[9];
    };

    showIsDataSourceCondition = (enumDescription: EnumDescription) => {
        return enumDescription.value === enums.SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.DATA_SOURCE;
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;
        const typedValues = values as Reward;
        const errors: any = {};

        if (!typedValues.name) {
            errors[nameof('name')] = t('NameIsRequired');
        }

        if (!typedValues.productCode) {
            errors[nameof('productCode')] = 'Kód produktu je povinný';
        }

        if (!typedValues.type) {
            errors[nameof('type')] = 'Typ je povinný';
        }

        if (!typedValues.variant) {
            errors[nameof('variant')] = 'Varianta je povinná';
        }

        if (typedValues.isDigi && !typedValues.priceByCreditCard) {
            errors[nameof('priceByCreditCard')] = 'Cena pro DIGI je poninná';
        }

        if (typedValues.isDigi && typedValues.numberOfPeriods < 1) {
            errors[nameof('numberOfPeriods')] = 'Pro platbu kartou musí být nastaven počet opakování';
        }

        if (!typedValues.code) {
            errors[nameof('code')] = 'Kód je povinný';
        }

        if (
            typedValues.sourceOfSubscribersEnumDescription?.value === enums.SOURCE_OF_SUBSCRIBERS_TYPES[1].value &&
            typedValues.usePriceWhenO2PriceIsNull &&
            typedValues.price <= 0
        ) {
            errors[nameof('price')] = 'Cena je povinná';
        }

        if (
            typedValues.sourceOfSubscribersEnumDescription?.value === enums.SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.DATA_SOURCE &&
            !typedValues.dataSourceId
        ) {
            errors[nameof('dataSourceId')] = 'Datový zdroj je povinný';
        }

        if (typedValues.variant.value === enums.REWARD_VARIANT_ENUM.STANDARD) {
            if (!typedValues.campaignCode && typedValues.sourceOfSubscribersEnumDescription?.value === enums.SOURCE_OF_SUBSCRIBERS_TYPES[2].value) {
                errors[nameof('campaignCode')] = 'Kód kampaně je povinný pro zdroj uživatelů CMT';
            }

            if (!typedValues.isDigi && !typedValues.extId) {
                errors[nameof('extId')] = 'Externí id je povinné';
            }

            if (typedValues.extId && typedValues.extId <= 0) {
                errors[nameof('extId')] = 'Externí id musí být větší jak 0';
            }

            if (!typedValues.availableFrom) {
                errors[nameof('availableFrom')] = 'Datum Dostupný od je povinné';
            }

            if (!typedValues.availableTo) {
                errors[nameof('availableTo')] = 'Datum Dostupný do je povinné';
            }

            if (typedValues.availableTo && typedValues.availableFrom && typedValues.availableTo < typedValues.availableFrom) {
                errors[nameof('availableTo')] = 'Datum Dostupný do, musí být větší než datum Dostupný od';
            }

            if (!typedValues.platform) {
                errors[nameof('platform')] = 'Platforma je povinná';
            }

            if (!typedValues.description) {
                errors[nameof('description')] = 'Popis je povinný';
            }

            if (typedValues.detailImage && typedValues.detailImage.size > MAX_IMAGE_SIZE_IN_BYTES) {
                errors[nameof('detailImage')] = `Obrázek je příliš velký. Maximální veliskot je ${MAX_IMAGE_SIZE_IN_MEGA_BYTES} MB`;
            }

            if (!typedValues.detailName) {
                errors[nameof('detailName')] = t('DetailNameIsRequired');
            }

            if (!typedValues.sourceOfSubscribersEnumDescription) {
                errors[nameof('sourceOfSubscribers')] = 'Zdroj uživatelů je povinný';
            }

            if (typedValues.isDigi && typedValues.sourceOfSubscribersEnumDescription === enums.SOURCE_OF_SUBSCRIBERS_TYPES[1]) {
                errors[nameof('sourceOfSubscribers')] = 'Pro DIGI nemůže být zdroj uživatelu O2 - VOPE';
            }

            if (typedValues.useInServicePriceCalculator && !typedValues.isDigi) {
                errors[nameof('useInServicePriceCalculator')] = 'Použití ceny při výpočtu ceny služby lze pouze pro DIGI';
            }

            if (typedValues.useWhenProductIsActivated && !typedValues.isDigi) {
                errors[nameof('useWhenProductIsActivated')] = 'Použití ceny v případě že je služba aktivní lze pouze pro DIGI';
            }
        }

        if (typedValues.variant.value === enums.REWARD_VARIANT_ENUM.FOLLOWING) {
            if (!typedValues.isDigi) {
                errors[nameof('isDigi')] = 'Pro variantu Navazujicí je podporována pouze platba kartou';
            }
        }

        return errors;
    };

    render() {
        console.debug('RewardDetail.render');

        const { initialValues, headerText } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            decorators={[formDecorator]}
                            render={({ handleSubmit }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <div className="k-form-field">
                                        <span>
                                            <Translation tKey="Variant" />
                                        </span>
                                        <Field name={nameof('variant')} component={DropDown} textField="text" dataSource={enums.REWARD_VARIANT} />
                                    </div>
                                    <Condition when={nameof('variant')} is={this.variantStandard}>
                                        <RewardDetailStandardPanel
                                            nameof={nameof}
                                            showIsDigiCondition={this.showIsDigiCondition}
                                            showIsGameOrAffiliateProgramCondition={this.showIsGameOrAffiliateProgramCondition}
                                            showIsO2VopeCondition={this.showIsO2VopeCondition}
                                            showIsDataSourceCondition={this.showIsDataSourceCondition}
                                            rewards={this.props.rewards.filter((reward) => reward.variant === enums.REWARD_VARIANT_ENUM.FOLLOWING)}
                                            dataSources={this.props.dataSources}
                                            dataSourceUserReadableProperty={this.props.dataSourceUserReadableProperty}
                                            followingRewardUserReadableProperty={this.props.followingRewardUserReadableProperty}
                                        />
                                    </Condition>

                                    <Condition when={nameof('variant')} is={this.variantFollowing}>
                                        <RewardDetailFollowingPanel
                                            nameof={nameof}
                                            showIsDigiCondition={this.showIsDigiCondition}
                                            showIsGameOrAffiliateProgramCondition={this.showIsGameOrAffiliateProgramCondition}
                                            showIsO2VopeCondition={this.showIsO2VopeCondition}
                                            showIsDataSourceCondition={this.showIsDataSourceCondition}
                                            rewards={this.props.rewards}
                                            dataSources={this.props.dataSources}
                                            dataSourceUserReadableProperty={this.props.dataSourceUserReadableProperty}
                                            followingRewardUserReadableProperty={this.props.followingRewardUserReadableProperty}
                                        />
                                    </Condition>

                                    <div className="footer">
                                        <SubmitButton>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): RewardDetailStateProps => {
    return {
        lang: state.app.lang,
        rewards: getRewards(state),
        followingRewardUserReadableProperty: getFollowingRewardUserReadableProperty(state),
        dataSources: getDataSources(state),
        dataSourceUserReadableProperty: getDataSourceUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RewardDetailProps>): RewardDetailDispatchProps => {
    return {
        fetchRewardDependencies: bindActionCreators(fetchRewardDependencies, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RewardDetail);
