import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import RoleDetail from './roleDetail';
import { createRole } from '../../actions/roleActions';
import { navigateToRoles } from '../../actions/navigationActions';
import Role from '../../entities/role';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';

const initialValues = new Role({});

interface RoleDetailCreateProps extends BaseDetailProps<Role> {}

const RoleDetailCreate = ({ submitAction, cancelAction, t }: RoleDetailCreateProps) => {
    return <RoleDetail initialValues={initialValues} submitAction={submitAction} headerText={t('RoleLabel')} cancelAction={cancelAction} t={t} />;
};

const mapDispatchToProps = (dispatch: Dispatch<RoleDetailCreateProps>): BaseDetailDispatchProps<Role> => {
    return {
        submitAction: bindActionCreators(createRole, dispatch),
        cancelAction: bindActionCreators(navigateToRoles, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(RoleDetailCreate);
