import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToDataSources, navigateToLinks } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/roleReducer';
import DataSource from '../entities/dataSource';
import DataSourceItemTransformationBeforeSave from '../data-transformations/dataSourceItemTransformationBeforeSave';
import { fetchDataSourceQueryContexts } from './dataSourceQueryContextActions';

export const fetchDataSources = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`DataSource/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.DATASOURCE_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};

export const createDataSource = (data: DataSource) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = DataSourceItemTransformationBeforeSave(data);

    axios
        .post(`DataSource`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.DATASOURCE_ADD,
                });

                dispatch(navigateToDataSources());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updateDataSource = (data: DataSource) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = DataSourceItemTransformationBeforeSave(data);

    axios
        .put(`DataSource`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.DATASOURCE_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToDataSources());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const fetchDataSourcesDependencies = () => async (dispatch: Dispatch<any>, getState: any) => {
    try {
        dispatch(LOADING_DEPENDENCIES_BEGIN);

        await fetchDataSourceQueryContexts()(dispatch, getState);
    } catch (exception) {
        throw exception; //FUJ
    } finally {
        dispatch(LOADING_DEPENDENCIES_END);
    }
};

const LOADING_DEPENDENCIES_BEGIN = {
    type: types.NEWS_LOADING_DEPENDENCIES_BEGIN,
};

const LOADING_DEPENDENCIES_END = {
    type: types.NEWS_LOADING_DEPENDENCIES_END,
};
