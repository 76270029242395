// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import AffiliateProgramReward from '../entities/affiliateProgramReward';

const affiliateProgramRewardDataTransformationAfterFetch = (data: any) => {
    return data.map((row: any) => {
        return new AffiliateProgramReward(row);
    });
};

export default affiliateProgramRewardDataTransformationAfterFetch;
