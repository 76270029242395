import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import Table from "../common/Table";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import DateCell from "../common/formatters/DateCell";
import EnumCell from "../common/formatters/EnumCelll";
import StringCell from "../common/formatters/StringCell";
import BoolCell from "../common/formatters/BoolCell";
import NavigableCell from "../common/formatters/navigableCell";
import Translation from "../common/Translation";
import { translate } from "react-i18next";
import Alerts from "../common/Alerts";
import ActionButton from "../common/ActionButton";
import { COMPETITION } from "../../data-transformations/dataTransformationTypes";
import { navigateTo } from "../../actions/navigationActions";
import * as enums from "../../common/enums";
import {
  TEXT_HORIZONTAL_ALIGN,
  NAVIGATION_PATHS
} from "../../common/constants";

const defaultCompetitionTabelSort = [
  {
    dir: "desc",
    field: "validFrom"
  }
];

const actionUrl = "competition";

const Competitions = ({ t, navigateTo }) => {
  let table;

  const onNew = () => {
    navigateTo(NAVIGATION_PATHS.COMPETITION_CREATE);
  };

  const onEdit = () => {
    table.getWrappedInstance().editSelected();
  };

  const onRemove = () => {
    table.getWrappedInstance().removeSelected();
  };

  return (
    <div>
      <div className="content-header">
        <h1>
          <Translation tKey="Competitions" />
        </h1>
      </div>
      <div className="content-wrapper">
        <Alerts />
        <div className="panel">
          <div className="panel-header">
            {/*<NewsSearchBox/>*/}
            <div className="actions">
              <ActionButton text="Add" onClick={onNew} icon="plus" />
              <ActionButton
                text="Edit"
                onClick={onEdit}
                icon="pencil"
                selection="one"
              />
              <ActionButton
                text="Remove"
                onClick={onRemove}
                icon="delete"
                selection="multi"
              />
            </div>
            <div className="filter" />
          </div>
          <div className="panel-content">
            <Table
              path={"competition"}
              actionPath={actionUrl}
              selectedField="selected"
              ref={el => {
                table = el;
              }}
              dataTransformation={COMPETITION}
              name="CompetitionTable"
              defaultSort={defaultCompetitionTabelSort}
            >
              <Column field="selected" width="50" />
              <Column
                field="name"
                cell={props => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                  />
                )}
                title={t("Name")}
              />
              <Column
                field="validFrom"
                cell={DateCell}
                title={t("ValidFrom")}
              />
              <Column field="validTo" cell={DateCell} title={t("ValidTo")} />
              <Column
                field="targetCustomer"
                cell={EnumCell(enums.COMPETITION_TARGET_CUSTOMER_TYPES)}
                title={t("Customers")}
              />
              <Column
                field="published"
                cell={BoolCell}
                title={t("Published")}
                width="110px"
              />
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

Competitions.propTypes = {
  t: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: path => {
      dispatch(navigateTo(path));
    }
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  translate("common")
)(Competitions);
