import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import RewardDetail from './rewardDetail';
import { createReward } from '../../actions/rewardActions';
import { navigateToRewards } from '../../actions/navigationActions';
import Reward from '../../entities/reward';
import { PLATFORMA_TYPES, REWARD_TYPES, REWARD_VARIANT, SOURCE_OF_SUBSCRIBERS_TYPES } from '../../common/enums';
import { BaseDetailProps } from '../common/types/baseDetail';

const initialValues = new Reward({
    maxDraws: 1,
    availableFrom: new Date(),
    platform: PLATFORMA_TYPES[0].value,
    type: REWARD_TYPES[0].value,
    sourceOfSubscribers: SOURCE_OF_SUBSCRIBERS_TYPES[0].value,
    variant: REWARD_VARIANT[0].value,
});

interface RewardDetailCreateProps extends BaseDetailProps<Reward> {}

const RewardDetailCreate = ({ submitAction, cancelAction, t }: BaseDetailProps<Reward>) => {
    return (
        <RewardDetail t={t} initialValues={initialValues} submitAction={submitAction} headerText={t('CreateReward')} cancelAction={cancelAction} />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<RewardDetailCreateProps>) => {
    return {
        submitAction: bindActionCreators(createReward, dispatch),
        cancelAction: bindActionCreators(navigateToRewards, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(RewardDetailCreate);
