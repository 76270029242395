import { loadMessages } from '@progress/kendo-react-intl';
const language = 'cs';

const messagesObj = {
  calendar: {
    today: "Dnes",
  },
  "datetimepicker": {
    "date": "Datum",
    "time": "Čas",
    "cancel": "Zrušit",
    "set": "Nastavit"
},
"timepicker": {
    "set": "Nastavit",
    "cancel": "Zrušit",
    "now": "Nyní",
    "selectNow": "Nastav aktuální čas",
    "toggleTimeSelector": "toggleTimeSelector",
    "toggleClock": "toggleClock"
},
  grid: {
    groupPanelEmpty: "Drag a column header and drop it here to group by that column",
    pagerItemsPerPage: "záznamů na stránku",
    pagerInfo: "{0} - {1} z {2} záznamů",
    pagerFirstPage: "Go to the first page",
    pagerPreviousPage: "Go to the previous page",
    pagerNextPage: "Go to the next page",
    pagerLastPage: "Go to the last page",
    pagerPage: "Page",
    pagerOf: "of",
    filterClearButton: "Clear",
    filterEqOperator: "Is equal to",
    filterNotEqOperator: "Is not equal to",
    filterIsNullOperator: "Is null",
    filterIsNotNullOperator: "Is not null",
    filterIsEmptyOperator: "Is empty",
    filterIsNotEmptyOperator: "Is not empty",
    filterStartsWithOperator: "Starts with",
    filterContainsOperator: "Contains",
    filterNotContainsOperator: "Does not contain",
    filterEndsWithOperator: "Ends with",
    filterGteOperator: "Is greater than or equal to",
    filterGtOperator: "Is greater than",
    filterLteOperator: "Is less than or equal to",
    filterLtOperator: "Is less than",
    filterIsTrue: "Is true",
    filterIsFalse: "Is false",
    filterBooleanAll: "(All)",
    filterAfterOrEqualOperator: "Is after or equal to",
    filterAfterOperator: "Is after",
    filterBeforeOperator: "Is before",
    filterBeforeOrEqualOperator: "Is before or equal to",
    noRecords: "Není dostupný žádný záznam.",
  }
};

export default ()=>{
  loadMessages(messagesObj, language);
};
