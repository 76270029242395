import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const NavLink = ({to, path, children, className}) => {
  let linkClassName = path == to ? "active" : "";
  if (className)
    linkClassName = `${linkClassName} ${className}`;

  return (
    <li className={`${linkClassName}`} ><Link to={to}>{children}</Link></li>
  );
};

NavLink.propTypes = {
  to: PropTypes.string.isRequired,
  path: PropTypes.string.isRequired,
  children: PropTypes.array.isRequired,
  className: PropTypes.string
};

export default NavLink;
