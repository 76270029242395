import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { translate } from 'react-i18next';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import Table from '../common/Table';
import StringCell from '../common/formatters/StringCell';
import Translation from '../common/Translation';
import EnumCell from '../common/formatters/EnumCelll';
import Alerts from '../common/Alerts';
import ActionButton from '../common/ActionButton';
import { navigateTo } from '../../actions/navigationActions';
import { TEXT_HORIZONTAL_ALIGN, NAVIGATION_PATHS } from '../../common/constants';
import { nameofFactory } from '../../helpers/nameOfFactory';
import DataSourceQueryContext from '../../entities/dataSourceQueryContext';
import NavigableCell from '../common/formatters/navigableCell';
import { DATASOURCEQUERYCONTEXT } from '../../data-transformations/dataTransformationTypes';
import { BaseListDispatchProps, BaseListProps } from '../common/types/baseDetail';
import * as enums from '../../common/enums';

const nameof = nameofFactory<DataSourceQueryContext>();

const actionUrl = 'datasourcequerycontexts';

interface DataSourceQueryContextProps extends BaseListProps {}

const DataSourceQueryContexts = ({ t, navigateTo }: DataSourceQueryContextProps) => {
    let table: any;

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.DATASOURCEQUERYCONTEXT_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="DataSourceQueryContexts" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <div className="actions">
                            <ActionButton text="Add" onClick={onNew} icon="plus" />
                            <ActionButton text="Edit" onClick={onEdit} icon="pencil" selection="one" />
                            <ActionButton text="Remove" onClick={onRemove} icon="delete" selection="multi" />
                        </div>
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'datasourcequerycontext'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            name="LinkTable"
                            dataTransformation={ DATASOURCEQUERYCONTEXT }
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('name')}
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Name')}
                            />
                            <Column
                                field={nameof('connectionString')}
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('ConnectionString')}
                            />
                            {/* <Column
                                field={nameof('typeEnumDescription')}
                                width="100"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={EnumCell(enums.LINK_TYPE_TYPES)}
                                    />
                                )}
                                title={t('Type')}
                            /> */}
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<any>): BaseListDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(DataSourceQueryContexts);
