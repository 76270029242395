import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import LoadingIndicator from '../common/LoadingIndicator';
import SimCardType from '../../entities/simCardType';
import SubmitButton from '../common/SubmitButton';
import { nameofFactory } from '../../helpers/nameOfFactory';
import Switch from '../common/Switch';
import { isLoadingDependencies } from '../../reducers/simCardTypeReducer';

const nameof = nameofFactory<SimCardType>();

export interface SimCardTypeDetailProps extends BaseSimCardTypeDetailProps, SimCardTypeDetailStateProps {
    headerText: string;
}

export interface SimCardTypeDetailStateProps {
    lang: string;
    loadingDependencies: boolean;
}

export interface BaseSimCardTypeDetailProps {
    initialValues: SimCardType;

    t: (key: string) => string;
    submitAction: (simCardType: SimCardType) => void;
    cancelAction: () => void;
}

class SimCardTypeDetail extends React.Component<SimCardTypeDetailProps> {
    componentDidMount() {}

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as SimCardType);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;
        const typedValues = values as SimCardType;
        const errors: any = {};

        if (!typedValues.name) {
            errors[nameof('name')] = t('NameIsRequired');
        }

        return errors;
    };

    render() {
        console.debug('SimCardTypeDetail.render');

        const { initialValues, headerText } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    {this.props.loadingDependencies && <LoadingIndicator />}

                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('name')}>
                                            <span>
                                                <Translation tKey="Name" />
                                            </span>
                                            <Field name={nameof('name')} component={Input} />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('isManagerType')}>
                                            <span>
                                                <Translation tKey="IsManagerType" />
                                            </span>
                                            <Field
                                                name="isManagerType"
                                                component={Switch}
                                            />
                                        </label>
                                    </div>

                                    <div className="footer">
                                        <SubmitButton>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): SimCardTypeDetailStateProps => {
    return {
        lang: state.app.lang,
        loadingDependencies: isLoadingDependencies(state)
    };
};

export default connect(mapStateToProps)(SimCardTypeDetail);
