import * as types from '../actions/actionTypes';

const navigationMiddleware = history => store => next => (action) => {
  if (action.type !== types.NAVIGATE_TO) {
    return next(action);
  }

  history.push(action.path);
};

export default navigationMiddleware;
