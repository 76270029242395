import axios from "axios";

import * as types from "./actionTypes";
import { navigateToOutageNotifications } from "./navigationActions";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import ResponseStatus from "../utils/responseStatus";
import outageNotificationItemTransformationBeforeSave from "../data-transformations/outageNotificationItemTransformationBeforeSave";

export const createOutageNotification = data => dispatch => {
  dispatch(beginAjaxCall());

  const transformedData = outageNotificationItemTransformationBeforeSave(data);

  axios
    .post(`outagenotification`, transformedData)
    .then(response => {
      if (ResponseStatus.IsCreated(response.status)) {
        dispatch({
          type: types.OUTAGE_NOTIFICATION_ADD
        });

        dispatch(navigateToOutageNotifications());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch(error => {
      dispatch(ajaxCallError());
      throw error;
    });
};

export const updateOutageNotification = data => dispatch => {
  dispatch(beginAjaxCall());

  const transformedData = outageNotificationItemTransformationBeforeSave(data);

  axios
    .put(`outagenotification`, transformedData)
    .then(response => {
      if (ResponseStatus.IsOK(response.status)) {
        dispatch({
          type: types.OUTAGE_NOTIFICATION_UPDATE,
          data: response.data
        });

        dispatch(navigateToOutageNotifications());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch(error => {
      dispatch(ajaxCallError());
      throw error;
    });
};
