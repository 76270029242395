import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import EventTriggerConfigurationDetail from './eventTriggerConfigurationDetail';
import { createEventTriggerConfiguration } from '../../actions/eventTriggerConfigurationActions';
import { navigateToEventTriggerConfigurations } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import EventTriggerConfiguration from '../../entities/eventTriggerConfiguration';
import { EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM } from '../../common/enums';

const initialValues = new EventTriggerConfiguration({ source: EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.NewVoucherClaim });

interface EventTriggerConfigurationDetailCreateProps extends BaseDetailProps<EventTriggerConfiguration> {}

const EventTriggerConfigurationDetailCreate = ({ submitAction, cancelAction, t }: EventTriggerConfigurationDetailCreateProps) => {
    return (
        <EventTriggerConfigurationDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('EventTriggerConfigurationCreateLabel')}
            cancelAction={cancelAction}
            t={t}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<EventTriggerConfigurationDetailCreateProps>): BaseDetailDispatchProps<EventTriggerConfiguration> => {
    return {
        submitAction: bindActionCreators(createEventTriggerConfiguration, dispatch),
        cancelAction: bindActionCreators(navigateToEventTriggerConfigurations, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(EventTriggerConfigurationDetailCreate);
