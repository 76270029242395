// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import TargetCampaignGroup from "../entities/targetCampaignGroup";

const targetCampaignDataTransformationAfterFetch = (data) => {
  return data.map((row) => {
    return new TargetCampaignGroup(row);
  });
};

export default targetCampaignDataTransformationAfterFetch;
