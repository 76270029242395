import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import { createAffiliateProgramPartner } from '../../actions/affiliateProgramPartnerActions';
import { navigateToAffiliateProgramPartners } from '../../actions/navigationActions';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';
import AffiliateProgramPartnerDetail from './affiliateProgramPartnerDetail';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';

const initialValues = new AffiliateProgramPartner({});

interface RewardDetailCreateProps extends BaseDetailProps<AffiliateProgramPartner> {}

const AffiliateProgramPartnerDetailCreate = ({ submitAction, cancelAction, t }: RewardDetailCreateProps) => {
    return (
        <AffiliateProgramPartnerDetail
            t={t}
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('CreateAffiliateProgramPartner')}
            cancelAction={cancelAction}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<RewardDetailCreateProps>): BaseDetailDispatchProps<AffiliateProgramPartner> => {
    return {
        submitAction: bindActionCreators(createAffiliateProgramPartner, dispatch),
        cancelAction: bindActionCreators(navigateToAffiliateProgramPartners, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(AffiliateProgramPartnerDetailCreate);
