import React from 'react';
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import { Route, Switch } from 'react-router-dom';
import { IntlProvider, LocalizationProvider } from '@progress/kendo-react-intl';

import ForgottenPassword from "../security/ForgottenPassword";
import ResetPassword from "../security/ResetPassword";
import App from "../app/App";
import SignIn from "../security/SignIn";
import UserInvite from "../security/userInvite";
import ActivateAccount from "../security/ActivateAccount";

class Layout extends React.Component {
  componentWillMount() {
    console.warn("Layout.componentWillMount");
  }

  render() {
    return (
      <LocalizationProvider language={this.props.lang}>
        <IntlProvider locale={this.props.lang}>
          <Switch>
            <Route path="/signin" component={SignIn}/>
            <Route path="/forgotten-password" component={ForgottenPassword}/>
            <Route path="/reset-password" component={ResetPassword}/>
            <Route path="/activate-account" component={ActivateAccount}/>
            <Route path="/invite" component={UserInvite}/>

            <Route component={App}/>
          </Switch>
        </IntlProvider>
      </LocalizationProvider>
    );
  }
}

Layout.contextTypes = {
  //router: PropTypes.object
};

Layout.propTypes = {
  lang: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.app.lang,
  };
};


export default connect(mapStateToProps, undefined)(Layout);

