import React from "react";
import PropTypes from "prop-types";
import { default as ReactRating } from "react-rating";

import { finalFormInput, finalFormMeta } from "../../common/propTypes";

export const Rating = ({ value, readonly }) => {
  return (
    <ReactRating
      initialRating={value}
      readonly={readonly}
      emptySymbol="icon icon-star-empty rationg-star--empty"
      fullSymbol="icon icon-star rationg-star--full"
    />
  );
};

Rating.propTypes = {
  value: PropTypes.number,
  readonly: PropTypes.bool
};

const RatingWrapper = ({
  input,
  placeholder,
  type,
  className,
  icon,
  ...rest
}) => {
  return <Rating value={input.value} readonly />;
};

RatingWrapper.propTypes = {
  value: PropTypes.number,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  input: finalFormInput.isRequired,
  meta: finalFormMeta.isRequired
};

export default RatingWrapper;
