import axios from 'axios';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import {navigateToBetatester} from "./navigationActions";
import ResponseStatus from "../utils/responseStatus";

export const createBetatester = (data) => (dispatch) => {
  dispatch(beginAjaxCall());

  axios.post(`betatester`, data)
    .then((response) => {
      if (ResponseStatus.IsCreated(response.status)) {
        dispatch({
          type: types.BETATESTER_ADD
        });

        dispatch(navigateToBetatester());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch((error) => {
      dispatch(ajaxCallError());
      throw error;
    });
};

export const updateBetatester = (data) => (dispatch) => {
  dispatch(beginAjaxCall());

   axios.put(`betatester`, data)
    .then((response) => {
      if (ResponseStatus.IsOK(response.status)) {
        dispatch({
          type: types.BETATESTER_UPDATE,
          data: response.data
        });

        dispatch(navigateToBetatester());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch((error) => {
      dispatch(ajaxCallError());
      throw error;
    });
};
