import { REWARD_VARIANT_ENUM } from '../common/enums';
import Reward from '../entities/reward';
import { isIgrnoredProperty } from './ignoredProperties';

const rewardItemTransformationBeforeSave = (rewardItem: Reward) => {
    const availableFrom = rewardItem.availableFrom;
    const availableTo = rewardItem.availableTo;

    const transformedData = {
        ...rewardItem,
        type: rewardItem.type.value,
        platform: rewardItem.platform.value,
        variant: rewardItem.variant.value,
        sourceOfSubscribers: rewardItem.sourceOfSubscribersEnumDescription?.value,
        availableFrom: rewardItem.variant.value === REWARD_VARIANT_ENUM.STANDARD ? (availableFrom ? availableFrom.toUTCString() : null) : null,
        availableTo: rewardItem.variant.value === REWARD_VARIANT_ENUM.STANDARD ? (availableTo ? availableTo.toUTCString() : null) : null,
        numberOfPeriods: rewardItem.isDigi ? rewardItem.numberOfPeriods : 0,
        followingRewardId: rewardItem.followingRewardId > -1 ? rewardItem.followingRewardId : null,
        dataSourceId: rewardItem.dataSourceId > -1 ? rewardItem.dataSourceId : null,
    } as { [key: string]: any };

    const body = new FormData();

    for (const key in transformedData) {
        if (!isIgrnoredProperty(key)) {
            if (transformedData[key] !== null && transformedData[key] !== undefined) {
                body.append(key, transformedData[key]);
            }
        }
    }

    return body;
};

export default rewardItemTransformationBeforeSave;
