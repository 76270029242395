import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import { reportDetailPropTypes } from "../../common/propTypes";
import ReportDetail from './reportDetail';
import {getRowById} from "../../reducers/tableReducer";
import {updateReport} from "../../actions/reportActions";
import {navigateToReports} from "../../actions/navigationActions";

const ReportDetailUpdate = ({initialValues, saveAction, cancelAction, t}) => {
  return <ReportDetail initialValues={initialValues} onSubmit={saveAction} headerText={t('ReportLabel')} onCancel={cancelAction}/>;
};

ReportDetailUpdate.propTypes = {
  initialValues: reportDetailPropTypes.isRequired,
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;

  return {
    lang: state.app.lang,
    initialValues: getRowById(state, id)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAction: bindActionCreators(updateReport, dispatch),
    cancelAction: bindActionCreators(navigateToReports, dispatch)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(ReportDetailUpdate);

