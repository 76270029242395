import * as types from '../actions/actionTypes';
import DataSource from '../entities/dataSource';

const dataSourceInitialState = {
    userReadableProperty: 'name',
    loadingDependencies: false,
    loaded: false,
    data: [],
};

const dataSources = (state = dataSourceInitialState, action: any) => {
    switch (action.type) {
        case types.DATASOURCE_FETCH:
            return {
                ...state,
                data: action.data.map((dataSource: DataSource) => {
                    return { ...dataSource };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default dataSources;

export const getData = (state: any): DataSource[] => {
    return state.dataSources.data;
};

export const getUserReadableProperty = (state: any): keyof DataSource => {
    return state.dataSources.userReadableProperty;
};
