import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";
import { translate } from "react-i18next";

import { edit, filterChange, filterClear } from "../../actions/tableActions";
import Table from "../common/Table";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import RatingCell from "../common/formatters/ratingCell";
import StringCell from "../common/formatters/StringCell";
import EnumCell from "../common/formatters/EnumCelll";
import DateCell from "../common/formatters/DateCell";
import NavigableCell from "../common/formatters/navigableCell";
import Translation from "../common/Translation";
import Alerts from "../common/Alerts";
import { RATING } from "../../data-transformations/dataTransformationTypes";
import { navigateTo } from "../../actions/navigationActions";
import * as enums from "../../common/enums";
import { TEXT_HORIZONTAL_ALIGN } from "../../common/constants";
import RatingActionsBar from "./ratingActionsBar";

const defaultRatingTabelSort = [
  {
    dir: "desc",
    field: "dateOfCreate",
  },
];

const actionUrl = "rating";

const Rating = ({ t, navigateTo, edit, filter, clearFilter }) => {
  let table;

  const customRowRender = (trElement, rowProps, data) => {
    console.warn("Rating.rowRender");

    /*const isRowWithSameOrder = data.some((item)=>{
      return item.order === rowProps.dataItem.order && item.id !== rowProps.dataItem.id;
    });
    const orange = { backgroundColor: "rgba(255,190, 12,1)" };
    const trProps = { style: isRowWithSameOrder ? orange : null };
    */
    //return React.cloneElement(trElement, { ...trProps }, trElement.props.children);

    return trElement;
  };

  return (
    <div>
      <div className="content-header">
        <h1>
          <Translation tKey="Rating" />
        </h1>
      </div>
      <div className="content-wrapper">
        <Alerts />
        <div className="panel">
          <div className="panel-header">
            {/*<NewsSearchBox/>*/}
            <RatingActionsBar
              edit={edit}
              filter={filter}
              clearFilter={clearFilter}
            />
            <div className="filter" />
          </div>
          <div className="panel-content">
            <Table
              path={"rating"}
              actionPath={actionUrl}
              selectedField="selected"
              ref={(el) => {
                table = el;
              }}
              dataTransformation={RATING}
              name="RatingTable"
              customRowRender={customRowRender}
              defaultSort={defaultRatingTabelSort}
            >
              <Column field="selected" width="50" />
              <Column
                field="msisdn"
                width="120"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                  />
                )}
                title={t("MSISDN")}
              />
              <Column
                field="value"
                width="155"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={RatingCell}
                  />
                )}
                title={t("RatingValue")}
              />
              <Column
                field="description"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                  />
                )}
                title={t("RatingText")}
              />
              <Column
                field="trigger"
                width="130"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={EnumCell(enums.RATING_TRIGGER_TYPES)}
                  />
                )}
                title={t("Event")}
              />
              <Column
                field="dateOfCreate"
                width="150"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={DateCell}
                  />
                )}
                title={t("DateOfRating")}
              />
              <Column
                field="platform"
                width="130"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={EnumCell(enums.PLATFORMA_TYPES)}
                  />
                )}
                title={t("Platform")}
              />
              <Column
                field="variant"
                width="130"
                cell={(props) => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={EnumCell(enums.RATING_VARIANT_TYPES)}
                  />
                )}
                title={t("Variant")}
              />
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

Rating.propTypes = {
  t: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  edit: PropTypes.func.isRequired,
  filter: PropTypes.func.isRequired,
  clearFilter: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    navigateTo: bindActionCreators(navigateTo, dispatch),
    clearFilter: bindActionCreators(filterClear, dispatch),
    filter: bindActionCreators(filterChange, dispatch),
    edit: bindActionCreators(edit, dispatch),
  };
};

export default compose(
  connect(null, mapDispatchToProps),
  translate("common")
)(Rating);
