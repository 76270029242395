import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToPushNotificationConfigurations } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/roleReducer';
import PushNotificationConfiguration from '../entities/pushNotificationConfiguration';
import PushNotificationConfigurationItemTransformationBeforeSave from '../data-transformations/pushNotificationConfigurationItemTransformationBeforeSave';
import { fetchNewsPages } from './newsPageActions';
import { fetchNews } from './newsActions';
import * as alertActions from './alertActions';

export const fetchPushNotificationConfigurations = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`PushNotificationConfiguration/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.PUSHNOTIFICATIONCONFIGURATION_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};

export const createPushNotificationConfiguration = (data: PushNotificationConfiguration) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = PushNotificationConfigurationItemTransformationBeforeSave(data);

    axios
        .post(`PushNotificationConfiguration`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.PUSHNOTIFICATIONCONFIGURATION_ADD,
                });

                dispatch(navigateToPushNotificationConfigurations());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updatePushNotificationConfiguration = (data: PushNotificationConfiguration) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = PushNotificationConfigurationItemTransformationBeforeSave(data);

    axios
        .put(`PushNotificationConfiguration`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.PUSHNOTIFICATIONCONFIGURATION_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToPushNotificationConfigurations());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const fetchPushNotificationConfigurationDependencies = () => async (dispatch: Dispatch<any>, getState: any) => {
    try {
        dispatch(LOADING_DEPENDENCIES_BEGIN);

        await fetchNews()(dispatch, getState);
        await fetchNewsPages()(dispatch, getState);
    } catch (exception) {
        throw exception; //FUJ
    } finally {
        dispatch(LOADING_DEPENDENCIES_END);
    }
};

export const sendPushNotificationsToBetaTesters = () => async (dispatch: Dispatch<any>, getState: any) => {
    let { table } = getState();
    let { actionUrl, data } = table;
    let { id } = data.find((i: any) => i.selected);

    if (id) {
        axios
            .post(`pushnotificationconfiguration/betaTesterPushNotification/${id}`)
            .then((response) => {
                if (ResponseStatus.IsOK(response.status)) {                  
                    dispatch(navigateToPushNotificationConfigurations());
                } else {
                    dispatch(ajaxCallError());
                }
            })
            .catch((error) => {
                ajaxCallError();
                dispatch(alertActions.alertErrorAdd('Chyba, nepodařilo se odeslat push notifikace.'));
                throw error;
            });
    }
};

const LOADING_DEPENDENCIES_BEGIN = {
    type: types.NEWS_LOADING_DEPENDENCIES_BEGIN,
};

const LOADING_DEPENDENCIES_END = {
    type: types.NEWS_LOADING_DEPENDENCIES_END,
};
