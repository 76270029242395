import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToEventTriggerConfigurations } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/roleReducer';
import EventTriggerConfiguration from '../entities/eventTriggerConfiguration';
import EventTriggerConfigurationItemTransformationBeforeSave from '../data-transformations/eventTriggerConfigurationItemTransformationBeforeSave';
import { fetchPushNotificationConfigurations } from './pushNotificationConfigurationActions';

export const fetchEventTriggerConfigurations = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`EventTriggerConfiguration/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.EVENTTRIGGERCONFIGURATION_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};

export const createEventTriggerConfiguration = (data: EventTriggerConfiguration) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = EventTriggerConfigurationItemTransformationBeforeSave(data);

    axios
        .post(`EventTriggerConfiguration`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.EVENTTRIGGERCONFIGURATION_ADD,
                });

                dispatch(navigateToEventTriggerConfigurations());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updateEventTriggerConfiguration = (data: EventTriggerConfiguration) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = EventTriggerConfigurationItemTransformationBeforeSave(data);

    axios
        .put(`EventTriggerConfiguration`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.EVENTTRIGGERCONFIGURATION_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToEventTriggerConfigurations());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const fetchEventTriggerConfigurationDependencies = () => async (dispatch: Dispatch<any>, getState: any) => {
    try {
        dispatch(LOADING_DEPENDENCIES_BEGIN);

        await fetchPushNotificationConfigurations()(dispatch, getState);
    } catch (exception) {
        throw exception; //FUJ
    } finally {
        dispatch(LOADING_DEPENDENCIES_END);
    }
};

const LOADING_DEPENDENCIES_BEGIN = {
    type: types.NEWS_LOADING_DEPENDENCIES_BEGIN,
};

const LOADING_DEPENDENCIES_END = {
    type: types.NEWS_LOADING_DEPENDENCIES_END,
};
