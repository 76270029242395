import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import UserDetail from './userDetail';
import { createUser } from '../../actions/userActions';
import { navigateToUsers } from '../../actions/navigationActions';
import User from '../../entities/user';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';

const initialValues = new User({});

interface UserDetailCreateProps extends BaseDetailProps<User> {}

const UserDetailCreate = ({ submitAction, cancelAction, t }: UserDetailCreateProps) => {
    return (
        <UserDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('CreateUser')}
            cancelAction={cancelAction}
            submittButtonLabel={t('SendUserInvitation')}
            emailIsReadOnly={false}
            t={t}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<UserDetailCreateProps>): BaseDetailDispatchProps<User> => {
    return {
        submitAction: bindActionCreators(createUser, dispatch),
        cancelAction: bindActionCreators(navigateToUsers, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(UserDetailCreate);
