import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import { edit, filterChange, filterClear } from '../../actions/tableActions';
import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import StringCell from '../common/formatters/StringCell';
import EnumCell from '../common/formatters/EnumCelll';
import DateCell from '../common/formatters/DateCell';
import NavigableCell from '../common/formatters/navigableCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { SIM_CARD_TYPE } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import * as enums from '../../common/enums';
import { NAVIGATION_PATHS, TEXT_HORIZONTAL_ALIGN } from '../../common/constants';
import SimCardTypeActionsBar from './simCardTypeActionsBar';
import { nameofFactory } from '../../helpers/nameOfFactory';
import SimCardType from '../../entities/simCardType';
import BoolCell from '../common/formatters/BoolCell';

const nameof = nameofFactory<SimCardType>();

const defaultSimCardTypeTabelSort = [
    {
        dir: 'asc',
        field: 'id',
    },
];

const actionUrl = 'sim-card-types';

interface SimCardTypesProp extends SimCardTypesDispatchProps {}

interface SimCardTypesDispatchProps {
    t: any;
    navigateTo: typeof navigateTo;
}

const SimCardTypes = ({ t, navigateTo }: SimCardTypesProp) => {
    let table: any;

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.SIM_CARD_TYPE_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    const customRowRender = (trElement: any) => {
        return trElement;
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="SimCardTypes" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <SimCardTypeActionsBar onEdit={onEdit} onNew={onNew} onRemove={onRemove} />
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'simcardtype'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={SIM_CARD_TYPE}
                            name="SimCardTypesTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultSimCardTypeTabelSort}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('name')}
                                width="300"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Name')}
                            />
                            <Column
                                field={nameof('isManagerType')}
                                width="200"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={BoolCell} />}
                                title={t('IsManagerType')}
                            />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<SimCardTypesDispatchProps>) => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        clearFilter: bindActionCreators(filterClear, dispatch),
        filter: bindActionCreators(filterChange, dispatch),
        edit: bindActionCreators(edit, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(SimCardTypes);
