import {
    getEnumItemByValue,
    getEnumItemsByFlaggedValue,
    NEWS_SECTION_TYPES,
    NEWS_TYPES,
    PLATFORMA_TYPES,
    TARGETED_CAMPAIGN_CONDITION_TYPE,
    TARGETED_GROUP_SUFFIX_TYPE,
    TARGETED_CAMPAIGN_DATA_SOURCE_TYPE,
    TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM,
    NEWS_BANNER_VIEW_TYPES,
} from '../common/enums';

export default class News {
    id;
    name;
    description;
    shortDescription;
    pageId;
    serviceId;
    url;
    validFrom;
    validTo;
    type;
    actionButtonText;
    imageUrl;
    imageUrlV2;
    detailImageUrl;
    published;
    platform;
    urlOpenExternal;
    smsRecipient;
    smsBody;
    order;
    navigateDirectToPage;
    isCampaign;
    targetedCampaignCode;
    targetedCampaignConditionType;
    targetedCampaignUseDateSuffix;
    targetedCampaignDateSuffixType;
    campaignTitle;
    campaignMessage;
    dateOfCampaignStart;
    campaignForAll;
    campaignSent;
    section;
    dataSourceType;
    bannerViewType;

    constructor(properties) {
        Object.assign(this, properties);

        this.validFrom = properties.validFrom ? new Date(properties.validFrom) : properties.validFrom;
        this.validTo = properties.validTo ? new Date(properties.validTo) : properties.validTo;
        this.type = getEnumItemByValue(properties.type, NEWS_TYPES);
        this.targetedCampaignConditionType = getEnumItemByValue(properties.targetedCampaignConditionType, TARGETED_CAMPAIGN_CONDITION_TYPE);
        this.platform = getEnumItemByValue(properties.platform, PLATFORMA_TYPES);
        this.section = getEnumItemByValue(properties.section, NEWS_SECTION_TYPES);
        this.dataSourceType = getEnumItemsByFlaggedValue(properties.dataSourceType, TARGETED_CAMPAIGN_DATA_SOURCE_TYPE);
        this.bannerViewType = getEnumItemByValue(properties.bannerViewType, NEWS_BANNER_VIEW_TYPES);
        this.targetedCampaignDateSuffixType = properties.targetedCampaignUseDateSuffix
            ? getEnumItemByValue(properties.targetedCampaignDateSuffixType, TARGETED_GROUP_SUFFIX_TYPE)
            : undefined;
        this.dateOfCampaignStart = properties.dateOfCampaignStart ? new Date(properties.dateOfCampaignStart) : properties.dateOfCampaignStart;
    }
}
