import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToLinks } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/roleReducer';
import Link from '../entities/link';
import linkItemTransformationBeforeSave from '../data-transformations/linkItemTransformationBeforeSave';

export const fetchLinks = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`link/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.LINK_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};

export const createLink = (data: Link) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = linkItemTransformationBeforeSave(data);

    axios
        .post(`link`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.LINK_ADD,
                });

                dispatch(navigateToLinks());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updateLink = (data: Link) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = linkItemTransformationBeforeSave(data);

    axios
        .put(`link`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.LINK_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToLinks());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};
