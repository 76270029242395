import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import StringCell from '../common/formatters/StringCell';
import EnumCell from '../common/formatters/EnumCelll';
import NavigableCell from '../common/formatters/navigableCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { REWARD } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import * as enums from '../../common/enums';
import { NAVIGATION_PATHS, TEXT_HORIZONTAL_ALIGN } from '../../common/constants';
import RewardsActionsBar from './rewardsActionsBar';
import { nameofFactory } from '../../helpers/nameOfFactory';
import Reward from '../../entities/reward';
import DateTimeCell from '../common/formatters/DateTimeCell';
import { BaseListProps, BaseListDispatchProps } from '../common/types/baseDetail';
import * as types from '../../actions/actionTypes';

const nameof = nameofFactory<Reward>();

const defaultRewardTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

const actionUrl = 'rewards';

interface RewardsProp extends BaseListProps {}

const Rewards = ({ t, navigateTo }: RewardsProp) => {
    let table: any;

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.REWARD_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    const onClone = () => {
        table.getWrappedInstance().cloneSelected();
    };

    const customRowRender = (trElement: any) => {
        return trElement;
    };

    const onShowActive = () => {
        const filters = [];
        filters[0] = {
            field: nameof('availableTo'),
            operator: 'gte',
            value: new Date().toISOString(),
        };
        filters[1] = { field: nameof('availableTo'), operator: 'eq', logicalOperator: 1 };

        table.getWrappedInstance().customFilter(filters);
    };

    const onShowAll = () => {
        table.getWrappedInstance().onFilterClear();
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="Rewards" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <RewardsActionsBar
                            onEdit={onEdit}
                            onNew={onNew}
                            onClone={onClone}
                            onRemove={onRemove}
                            onShowActive={onShowActive}
                            onShowAll={onShowAll}
                        />
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'reward'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={REWARD}
                            name="RewardTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultRewardTabelSort}
                            afterLoadDataActionType={types.REWARD_FETCH}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('name')}
                                width="260"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Name')}
                            />
                            <Column
                                field={nameof('code')}
                                width="160"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Code')}
                            />
                            <Column
                                field={nameof('extId')}
                                width="130"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('ExternalId')}
                            />
                            <Column
                                field={nameof('productCode')}
                                width="120"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('ProductCode')}
                            />
                            <Column
                                field={nameof('dateOfCreate')}
                                width="150"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={DateTimeCell} />}
                                title={t('DateOfCreate')}
                            />
                            <Column
                                field={nameof('availableFrom')}
                                width="150"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={DateTimeCell} />}
                                title={t('AvailableFrom')}
                            />
                            <Column
                                field={nameof('availableTo')}
                                width="150"
                                cell={(props) => <NavigableCell {...props} actionUrl={actionUrl} navigateTo={navigateTo} component={DateTimeCell} />}
                                title={t('AvailableTo')}
                            />
                            <Column
                                field={nameof('sourceOfSubscribersEnumDescription')}
                                width="150"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={EnumCell(enums.SOURCE_OF_SUBSCRIBERS_TYPES)}
                                    />
                                )}
                                title={t('SourceOfSubscribers')}
                            />
                            <Column
                                field={nameof('campaignCode')}
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('CampaignCode')}
                            />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<any>): BaseListDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(Rewards);
