import React from 'react';

import ActionButton from '../common/ActionButton';

interface SimCardTypeActionsBarProps {
    onNew: () => void;
    onEdit: () => void;
    onRemove: () => void;
}

export default class SimCardTypeActionsBar extends React.Component<SimCardTypeActionsBarProps> {
    render() {
        return (
            <div className="actions">
                <ActionButton text="Add" onClick={this.props.onNew} icon="plus" />
                <ActionButton text="Edit" onClick={this.props.onEdit} icon="pencil" selection="one" />
                <ActionButton text="Remove" onClick={this.props.onRemove} icon="delete" selection="multi" />                
            </div>
        );
    }
}
