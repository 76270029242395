import { AffiliateProgramRewardModel } from '../api/models';

export default class AffiliateProgramReward {
    id: number;
    name: string;
    partnerId: number;
    rewardId: number;
    validFrom: Date;
    validTo: Date;
    partnerName: string;
    claimValidTo?: Date;
    claimValidForDays: number;

    constructor(properties: Partial<AffiliateProgramRewardModel>) {
        this.id = 0;
        this.name = '';
        this.partnerName = '';
        this.partnerId = -1;
        this.rewardId = -1;
        this.claimValidForDays = 0;

        Object.assign(this, properties);

        this.validFrom = properties.validFromUtc ? new Date(properties.validFromUtc) : new Date();
        this.validTo = properties.validToUtc ? new Date(properties.validToUtc) : new Date();
        this.claimValidTo = properties.claimValidToUtc ? new Date(properties.claimValidToUtc) : undefined;
    }
}
