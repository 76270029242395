import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import ReportDetail from './reportDetail';
import {createReport} from "../../actions/reportActions";
import {navigateToReports} from "../../actions/navigationActions";

const initialValues= {
  code: '',
  name: ''
};

const ReportDetailCreate =({saveAction, cancelAction, t})=>{
  return <ReportDetail initialValues={initialValues} onSubmit={saveAction} headerText={t('ReportLabel')} onCancel={cancelAction}/>;
};

ReportDetailCreate.propTypes = {
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAction: bindActionCreators(createReport, dispatch),
    cancelAction: bindActionCreators(navigateToReports, dispatch)
  };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(ReportDetailCreate);