import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import { betatesterDetailPropTypes } from "../../common/propTypes";
import BetatesterDetail from './betatesterDetail';
import {getRowById} from "../../reducers/tableReducer";
import {updateBetatester} from "../../actions/betatesterAction";
import {navigateToBetatester} from "../../actions/navigationActions";

const BetatestersDetailUpdate = ({initialValues, saveAction, cancelAction, t}) => {
  return <BetatesterDetail initialValues={initialValues} onSubmit={saveAction} headerText={t('BetaTesterLabel')} onCancel={cancelAction}/>;
};

BetatestersDetailUpdate.propTypes = {
  initialValues: betatesterDetailPropTypes.isRequired,
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;

  return {
    lang: state.app.lang,
    initialValues: getRowById(state, id)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAction: bindActionCreators(updateBetatester, dispatch),
    cancelAction: bindActionCreators(navigateToBetatester, dispatch)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(BetatestersDetailUpdate);

