import * as types from '../actions/actionTypes';

const rewardInitialState = {
    userReadableProperty: 'name',
    loaded: false,
    data: [],
};

const marketingActionsReducer = (state = rewardInitialState, action) => {
    switch (action.type) {
        case types.MARKETING_ACTION_FETCH:
            return {
                ...state,
                data: action.data.map((marketingAction) => {
                    return { ...marketingAction };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default marketingActionsReducer;

export const isLoaded = (state) => {
    return state.marketingActions.loaded === true;
};

export const getData = (state) => {
    return state.marketingActions.data;
};

export const getUserReadableProperty = (state) => {
    return state.marketingActions.userReadableProperty;
};
