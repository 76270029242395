import React from 'react';
import PropTypes from "prop-types";
import {Field} from 'react-final-form';
import {OnChange} from 'react-final-form-listeners';

const WhenFieldChangesListener = ({field, becomes, set, to}) => (
  <Field name={set} subscription={{}}>
    {(
      {input: {onChange}}
    ) => (
      <OnChange name={field}>
        {value => {
          if (value === becomes) {
            onChange(to);
          }
        }}
      </OnChange>
    )}
  </Field>
);

WhenFieldChangesListener.propTypes = {
  field: PropTypes.string.isRequired,
  becomes: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  set: PropTypes.string.isRequired,
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
};

export default WhenFieldChangesListener;
