import React from 'react';
import { connect, Dispatch } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { bindActionCreators } from 'redux';

import * as enums from '../../common/enums';

import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import SubmitButton from '../common/SubmitButton';
import { nameofFactory } from '../../helpers/nameOfFactory';
import DateTimePicker from '../common/DateTimePicker';
import AffiliateProgramReward from '../../entities/affiliateProgramReward';
import DropDownWithValueField from '../common/DropDownWithValueField';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';
import {
    getData as getAffiliateProgramPartners,
    getUserReadableProperty as getAffiliateProgramPartnerUserReadableProperty,
} from '../../reducers/affiliateProgramPartnersReducer';
import { getDataByDataSource as getRewards, getUserReadableProperty as getRewardUserReadableProperty } from '../../reducers/rewardReducer';
import LoadingIndicator from '../common/LoadingIndicator';
import { isLoadingDependencies } from '../../reducers/affiliateProgramRewardsReducer';
import { fetchAffiliateProgramRewardDependencies } from '../../actions/affiliateProgramRewardsActions';
import Reward from '../../entities/extra';
import { BaseDetailProps } from '../common/types/baseDetail';

const nameof = nameofFactory<AffiliateProgramReward>();

interface AffiliateProgramRewardDetailProps
    extends BaseDetailProps<AffiliateProgramReward>,
        AffiliateProgramRewardDetailStateProps,
        AffiliateProgramRewardDetailDispatchProps {
    headerText: string;
}

interface AffiliateProgramRewardDetailStateProps {
    lang: string;
    affiliateProgramPartnerUserReadableProperty: string;
    affiliateProgramPartners: AffiliateProgramPartner[];
    rewardUserReadableProperty: string;
    rewards: Reward[];
    loadingDependencies: boolean;
}

interface AffiliateProgramRewardDetailDispatchProps {
    fetchAffiliateProgramRewardDependencies: typeof fetchAffiliateProgramRewardDependencies;
}

class AffiliateProgramRewardDetail extends React.Component<AffiliateProgramRewardDetailProps> {
    componentDidMount() {
        this.props.fetchAffiliateProgramRewardDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as AffiliateProgramReward);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;
        const typedValues = values as AffiliateProgramReward;
        const errors: Partial<Record<keyof AffiliateProgramReward, string>> = {};

        /*  if (!typedValues.name) {
            errors[nameof('name')] = t('NameIsRequired');
        } */

        if (!typedValues.partnerId || typedValues.partnerId <= 0) {
            errors[nameof('partnerId')] = 'Partner je povinný';
        }

        if (!typedValues.rewardId || typedValues.rewardId <= 0) {
            errors[nameof('rewardId')] = 'Reward je povinný';
        }

        if (!typedValues.validFrom) {
            errors[nameof('validFrom')] = 'Datum Platnný od je povinné';
        }

        if (!typedValues.validTo) {
            errors[nameof('validTo')] = 'Datum Platný do je povinné';
        }

        if (typedValues.validTo && typedValues.validFrom && typedValues.validTo < typedValues.validFrom) {
            errors[nameof('validTo')] = 'Datum Platný do, musí být větší než datum Platný od';
        }

        if (typedValues.claimValidTo && typedValues.validFrom && typedValues.claimValidTo < typedValues.validFrom) {
            errors[nameof('claimValidTo')] = 'Datum platnosti nároku musí být větší než datum Platný od';
        }

        if (!typedValues.claimValidTo && typedValues.claimValidForDays < 1) {
            errors[nameof('claimValidTo')] = 'Musí být nastaveno datum platnosti nároku nebo počet dnů po které nárok platí';
            errors[nameof('claimValidForDays')] = 'Musí být nastaveno datum platnosti nároku nebo počet dnů po které nárok platí';
        }

        if (typedValues.claimValidTo && typedValues.claimValidForDays > 0) {
            errors[nameof('claimValidTo')] = 'Musí být nastaveno jenom datum platnosti nároku nebo jenom počet dnů po které nárok platí';
            errors[nameof('claimValidForDays')] = 'Musí být nastaveno jenom datum platnosti nároku nebo jenom počet dnů po které nárok platí';
        }

        return errors;
    };

    render() {
        console.debug('AffiliateProgramRewardDetail.render');

        const { initialValues, headerText } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    {this.props.loadingDependencies && <LoadingIndicator />}
                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('validFrom')}>
                                            <span>
                                                <Translation tKey="ValidFrom" />
                                            </span>
                                            <Field name={nameof('validFrom')} component={DateTimePicker} />
                                        </label>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('validTo')}>
                                            <span>
                                                <Translation tKey="ValidTo" />
                                            </span>
                                            <Field name={nameof('validTo')} component={DateTimePicker} />
                                        </label>
                                    </div>

                                    <label className="k-form-field" htmlFor="partnerId">
                                        <span>
                                            <Translation tKey="Partner" />
                                        </span>
                                        <Field
                                            name="partnerId"
                                            component={DropDownWithValueField}
                                            // placeholder={t("Page")}
                                            textField={this.props.affiliateProgramPartnerUserReadableProperty}
                                            valueField="id"
                                            dataSource={this.props.affiliateProgramPartners}
                                        />
                                    </label>

                                    <label className="k-form-field" htmlFor="rewardId">
                                        <span>
                                            <Translation tKey="Reward" />
                                        </span>
                                        <Field
                                            name="rewardId"
                                            component={DropDownWithValueField}
                                            // placeholder={t("Page")}
                                            textField={this.props.rewardUserReadableProperty}
                                            valueField="id"
                                            dataSource={this.props.rewards}
                                        />
                                    </label>

                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('claimValidTo')}>
                                            <span>
                                                <Translation tKey="ClaimValidTo" />
                                            </span>
                                            <Field name={nameof('claimValidTo')} component={DateTimePicker} />
                                        </label>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('claimValidForDays')}>
                                            <span>
                                                <Translation tKey="ClaimValidForDays" />
                                            </span>
                                            <Field name={nameof('claimValidForDays')} component={Input} type="number" min={0} />
                                        </label>
                                    </div>

                                    <label className="k-form-field" htmlFor={nameof('name')}>
                                        <span>
                                            <Translation tKey="Name" />
                                        </span>
                                        <Field name={nameof('name')} component={Input} />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): AffiliateProgramRewardDetailStateProps => {
    return {
        lang: state.app.lang,
        loadingDependencies: isLoadingDependencies(state),
        affiliateProgramPartners: getAffiliateProgramPartners(state),
        affiliateProgramPartnerUserReadableProperty: getAffiliateProgramPartnerUserReadableProperty(state),
        rewards: getRewards(state, enums.SOURCE_OF_SUBSCRIBERS_TYPES_ENUM.AFFILIATE_PROGRAM),
        rewardUserReadableProperty: getRewardUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): AffiliateProgramRewardDetailDispatchProps => {
    return {
        fetchAffiliateProgramRewardDependencies: bindActionCreators(fetchAffiliateProgramRewardDependencies, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateProgramRewardDetail);
