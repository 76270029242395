import { getFlaggeValueFormEnumItems } from '../common/enums';
import Role from '../entities/role';

const roleItemTransformationBeforeSave = (roleItem: Role) => {
    const transformedData = {
        ...roleItem,
        permittedModules: getFlaggeValueFormEnumItems(roleItem.permittedModules),
    } as { [key: string]: any };

    /* const body = new FormData();

    for (const key in transformedData) {
        if (transformedData[key] !== null && transformedData[key] !== undefined) {
            body.append(key, transformedData[key]);
        }
    }

    return body; */

    return transformedData;
};

export default roleItemTransformationBeforeSave;
