import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import DataSourceQueryContextDetail from './dataSourceQueryContextDetail';
import { createDataSourceQueryContext } from '../../actions/dataSourceQueryContextActions';
import { navigateToDataSourceQueryContexts } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import DataSourceQueryContext from '../../entities/dataSourceQueryContext';

const initialValues = new DataSourceQueryContext({});

interface DataSourceQueryContextDetailCreateProps extends BaseDetailProps<DataSourceQueryContext> {}

const DataSourceQueryContextDetailCreate = ({ submitAction, cancelAction, t }: DataSourceQueryContextDetailCreateProps) => {
    return <DataSourceQueryContextDetail initialValues={initialValues} submitAction={submitAction} headerText={t('DataSourceQueryContextCreateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapDispatchToProps = (dispatch: Dispatch<DataSourceQueryContextDetailCreateProps>): BaseDetailDispatchProps<DataSourceQueryContext> => {
    return {
        submitAction: bindActionCreators(createDataSourceQueryContext, dispatch),
        cancelAction: bindActionCreators(navigateToDataSourceQueryContexts, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(DataSourceQueryContextDetailCreate);
