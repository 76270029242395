import * as types from "./actionTypes";
import uuidV4 from "uuid/v4";

// style: "info", "success", "warning", "error"
export const alertAdd = (tKey, style) => {
  return {
    type: types.ALERTS_ADD,
    message: {
      id: uuidV4(),
      type: style,
      tKey: tKey
    }
  };
};

export const alertWarningAdd = tKey => {
  return {
    type: types.ALERTS_ADD,
    message: {
      id: uuidV4(),
      type: "warning",
      tKey: tKey
    }
  };
};

export const alertSuccessAdd = tKey => {
  return {
    type: types.ALERTS_ADD,
    message: {
      id: uuidV4(),
      type: "success",
      tKey: tKey
    }
  };
};

export const alertErrorAdd = tKey => {
  return {
    type: types.ALERTS_ADD,
    message: {
      id: uuidV4(),
      type: "error",
      tKey: tKey
    }
  };
};

export const alertWarningMessageAdd = (message, disabledAutoClose) => {
  return {
    type: types.ALERTS_ADD,
    message: {
      id: uuidV4(),
      type: "warning",
      message: message,
      disabledAutoClose: disabledAutoClose
    },
   
  };
};

export const alertSuccessMessageAdd = (message, disabledAutoClose) => {
  return {
    type: types.ALERTS_ADD,
    message: {
      id: uuidV4(),
      type: "success",
      message: message,
      disabledAutoClose: disabledAutoClose
    },
    
  };
};

export const alertErrorMessageAdd = (message, disabledAutoClose) => {
  return {
    type: types.ALERTS_ADD,
    message: {
      id: uuidV4(),
      type: "error",
      message: message,
      disabledAutoClose: disabledAutoClose
    },
  };
};

export const alertRemove = id => ({
  type: types.ALERTS_REMOVE,
  id
});

export const alertClear = () => ({
  type: types.ALERTS_CLEAR
});
