import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import LinkDetail from './linkDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updateLink } from '../../actions/linkActions';
import { navigateToLinks } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import Link from '../../entities/link';

interface LinkDetailUpdateProps extends BaseDetailProps<Link> {}

const LinkDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: LinkDetailUpdateProps) => {
    return <LinkDetail initialValues={initialValues} submitAction={submitAction} headerText={t('LinkUpdateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<LinkDetailUpdateProps>): BaseDetailDispatchProps<Link> => {
    return {
        submitAction: bindActionCreators(updateLink, dispatch),
        cancelAction: bindActionCreators(navigateToLinks, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(LinkDetailUpdate);
