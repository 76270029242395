import * as types from '../actions/actionTypes';
import Report from '../entities/report';
import { SOURCE_OF_SUBSCRIBERS_TYPES_ENUM } from '../common/enums';

const reportInitialState = {
    loaded: false,
    data: [],
};

const reportReducer = (state = reportInitialState, action: any) => {
    switch (action.type) {
        case types.REPORT_FETCH:
            return {
                ...state,
                data: action.data,
                loaded: true,
            };
        default:
            return state;
    }
};

export default reportReducer;

/* export const isLoaded = (state: any) => {
    return state.rewards.loaded === true;
};

export const getData = (state: any) => {
    return state.rewards.data;
};

export const getDataByDataSource = (state: any, sourceOfSubscribers: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM) => {
    return state.rewards.data.filter((reward: Reward) => reward.sourceOfSubscribers === sourceOfSubscribers);
};

export const getUserReadableProperty = (state: any): keyof Reward => {
    return state.rewards.userReadableProperty;
}; */

export const getReport = (state: any, reportId: number): Report => {
    return state.reports.data.find((report: Report) => report.id == reportId);
};
