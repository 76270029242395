import PropTypes from "prop-types";

export const autoCloseConfigPropTypes =  PropTypes.shape({
    timeout: PropTypes.number,
    
    autoCloseInfo: PropTypes.bool,
    autoCloseSuccess: PropTypes.bool,
    autoCloseWarning: PropTypes.bool,
    autoCloseError: PropTypes.bool,    
});

export const alertPropTypes = PropTypes.shape({
    id: PropTypes.string,
    type: PropTypes.string,
    tKey: PropTypes.string,
    disabledAutoClose: PropTypes.bool,
});

export const betatesterDetailPropTypes = PropTypes.shape({
    msisdn: PropTypes.string.isRequired,
    description: PropTypes.string    
});

export const enumProtoType = PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.number.isRequired,
});

export const finalFormInput= PropTypes.object;

export const  finalFormMeta = PropTypes.shape({
    touched:PropTypes.bool.isRequired,
    error:PropTypes.string,
    warning:PropTypes.string,
});

export const location= PropTypes.shape({
    search: PropTypes.string.isRequired,
});

export const newsPropTypes= PropTypes.shape({  
    name: PropTypes.string.isRequired,
    validFrom: PropTypes.instanceOf(Date).isRequired,
    validTo: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    type: enumProtoType.isRequired,
    platform: enumProtoType.isRequired,
    urlOpenExternal: PropTypes.bool,
    smsRecipient: PropTypes.string,
    smsBody: PropTypes.string,
    published : PropTypes.bool,  
    order: PropTypes.number,
    navigateDirectToPage: PropTypes.bool,  
    isCampaign : PropTypes.bool, 
    targetedCampaignCode: PropTypes.string,   
    campaignTitle: PropTypes.string,   
    campaignMessage: PropTypes.string,   
});

export const competitionPropTypes= PropTypes.shape({  
    name: PropTypes.string.isRequired,
    validFrom: PropTypes.instanceOf(Date).isRequired,
    validTo: PropTypes.instanceOf(Date),
    description: PropTypes.string,
    noVipText: PropTypes.string,
    targetCustomer: enumProtoType.isRequired,
    platform: enumProtoType.isRequired,
    urlOpenExternal: PropTypes.bool,  
    published : PropTypes.bool,          
});

export const chargingCodeDetailPropTypes = PropTypes.shape({
    code: PropTypes.string.isRequired,
    name: PropTypes.string    
});

export const reportDetailPropTypes = PropTypes.shape({
    viewName: PropTypes.string.isRequired,
    name: PropTypes.string .isRequired,  
});

export const tableSortPropertyPropTypes = PropTypes.shape({
    dir: PropTypes.string.isRequired,
    field: PropTypes.string.isRequired,
});

export const ratingPropTypes= PropTypes.shape({  
    msisdn: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired,
    description: PropTypes.string,
    value: PropTypes.number.isRequired,  
    trigger: enumProtoType.isRequired,
    dateOfCreate:PropTypes.instanceOf(Date).isRequired,
    platform: enumProtoType.isRequired, 
});