import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as userActions from '../../actions/userActions';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';
import Translation from '../common/Translation';

class ActivateAccount extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentWillMount() {
    let { query } = this.props;

    if (query.code !== undefined) {
      this.props.actions.verifyAccount(query.code);
    }
  }

  renderLoading = () => {
    return (<p><span className="k-icon k-i-loading">&nbsp;</span>&nbsp;<Translation tKey="WaitAccountIsNotReady" /></p>);
  }

  renderSignIn = () => {
    return (
      <div>
        <p>
        <Translation tKey="AccountWasActivated" />
        </p>
        <div>
          <p>
          <Link to="/signin"><Translation tKey="ReturnToSignIn" /></Link>
          </p>
        </div>
      </div>);
  }

  render() {
    let { loading } = this.props;
    return (
      <div className="signIn-wrapper">
        <div className="signIn">
          <div className="box">
            <div className="header">&nbsp;</div>
            <div className="body">
              {loading && this.renderLoading()}
              {!loading && this.renderSignIn()}
            </div>
            <div className="footer">&nbsp;</div>
          </div>
        </div>
      </div>
    );
  }
}

ActivateAccount.propTypes = {
  path: PropTypes.string,
  query: PropTypes.object,
  actions: PropTypes.object,
  loading: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    path: ownProps.location.pathname,
    query: ownProps.location.query,
    loading: state.ajaxStatus > 0,
    isVerified: state.user.isVerified || null
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
};


export default connect(mapStateToProps, mapDispatchToProps)(ActivateAccount);
