import React from 'react';

import ActionButton from '../common/ActionButton';
import ToggableActionButton from '../common/ToggableActionButton';

interface RewardsActionsBarProps {
    onNew: () => void;
    onEdit: () => void;
    onRemove: () => void;
    onClone: () => void;
    onShowActive: () => void;
    onShowAll: () => void;
}

export default class RewardsActionsBar extends React.Component<RewardsActionsBarProps> {
    render() {
        return (
            <div className="actions">
                <ActionButton text="Add" onClick={this.props.onNew} icon="plus" />
                <ActionButton text="Edit" onClick={this.props.onEdit} icon="pencil" selection="one" />
                <ActionButton text="Remove" onClick={this.props.onRemove} icon="delete" selection="multi" />
                <ActionButton text="Clone" onClick={this.props.onClone} icon="copy" selection="one" />
                <ToggableActionButton text="ShowActive" onSelected={this.props.onShowActive} onNotSelected={this.props.onShowAll} icon="filter" />
            </div>
        );
    }
}
