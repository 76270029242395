import * as types from './actionTypes';
import axios from 'axios';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';

export const toggleAccountMenu = (event) => (dispatch) => {
  event.preventDefault();

  return dispatch({ type: types.TOGGLE_ACCOUNT_MENU });
};

export const refreshCache = (event) => (dispatch) => {
  event.preventDefault();

  dispatch(beginAjaxCall());

  axios.get(`cache/refresh`)
  .then((response) => {
  })
  .catch((error) => { 
    dispatch(ajaxCallError());
    throw error; 
  });
};
