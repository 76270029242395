import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToPushNotificationTasks } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/roleReducer';
import PushNotificationTask from '../entities/pushNotificationTask';
import PushNotificationTaskItemTransformationBeforeSave from '../data-transformations/pushNotificationTaskItemTransformationBeforeSave';
import { fetchPushNotificationConfigurations } from './pushNotificationConfigurationActions';
import { fetchDataSources } from './dataSourceActions';

export const fetchPushNotificationTasks = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`PushNotificationTask/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.PUSHNOTIFICATIONTASK_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};

export const createPushNotificationTask = (data: PushNotificationTask) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = PushNotificationTaskItemTransformationBeforeSave(data);

    axios
        .post(`PushNotificationTask`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.PUSHNOTIFICATIONTASK_ADD,
                });

                dispatch(navigateToPushNotificationTasks());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updatePushNotificationTask = (data: PushNotificationTask) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = PushNotificationTaskItemTransformationBeforeSave(data);

    axios
        .put(`PushNotificationTask`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.PUSHNOTIFICATIONTASK_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToPushNotificationTasks());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const fetchPushNotificationTaskDependencies = () => async (dispatch: Dispatch<any>, getState: any) => {
    try {
        dispatch(LOADING_DEPENDENCIES_BEGIN);

        await fetchPushNotificationConfigurations()(dispatch, getState);
        await fetchDataSources()(dispatch, getState);
    } catch (exception) {
        throw exception; //FUJ
    } finally {
        dispatch(LOADING_DEPENDENCIES_END);
    }
};

const LOADING_DEPENDENCIES_BEGIN = {
    type: types.NEWS_LOADING_DEPENDENCIES_BEGIN,
};

const LOADING_DEPENDENCIES_END = {
    type: types.NEWS_LOADING_DEPENDENCIES_END,
};
