import axios from 'axios';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import * as alertActions from './alertActions';
import { navigateToAffiliateProgramSubscriberRewardClaims } from './navigationActions';
import { fetchAffiliateProgramPartners } from './affiliateProgramPartnerActions';

import ResponseStatus from '../utils/responseStatus';
import affiliateProgramSubscriberRewardClaimItemTransformationBeforeSave from '../data-transformations/affiliateProgramSubscriberRewardClaimItemTransformationBeforeSave';
import { fetchAffiliateProgramRewards } from './affiliateProgramRewardsActions';

export const createAffiliateProgramSubscriberRewardClaim = (data: any) => (dispatch: any) => {
    dispatch(beginAjaxCall());

    const transformedData = affiliateProgramSubscriberRewardClaimItemTransformationBeforeSave(data);

    axios
        .post(`AffiliateProgramSubscriberRewardClaim`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_ADD,
                });

                dispatch(navigateToAffiliateProgramSubscriberRewardClaims());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const fetchAffiliateProgramSubscriberRewardClaimDependencies = () => async (dispatch: any, getState: any) => {
    try {
        dispatch(LOADING_DEPENDENCIES_BEGIN);

        await fetchAffiliateProgramPartners()(dispatch, getState);
        await fetchAffiliateProgramRewards()(dispatch, getState);
    } catch (exception) {
        throw exception; //FUJ
    } finally {
        dispatch(LOADING_DEPENDENCIES_END);
    }
};

const LOADING_DEPENDENCIES_BEGIN = {
    type: types.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_LOADING_DEPENDENCIES_BEGIN,
};

const LOADING_DEPENDENCIES_END = {
    type: types.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_LOADING_DEPENDENCIES_END,
};
