import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import LinkDetail from './linkDetail';
import { createLink } from '../../actions/linkActions';
import { navigateToTexts } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import { LANGUAGE_ENUM, LINK_TYPE_ENUM } from '../../common/enums';
import Link from '../../entities/link';

const initialValues = new Link({ type: LINK_TYPE_ENUM.YOUTUBE_VIDEO, language: LANGUAGE_ENUM.CZ });

interface LinkDetailCreateProps extends BaseDetailProps<Link> {}

const LinkDetailCreate = ({ submitAction, cancelAction, t }: LinkDetailCreateProps) => {
    return (
        <LinkDetail initialValues={initialValues} submitAction={submitAction} headerText={t('LinkCreateLabel')} cancelAction={cancelAction} t={t} />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<LinkDetailCreateProps>): BaseDetailDispatchProps<Link> => {
    return {
        submitAction: bindActionCreators(createLink, dispatch),
        cancelAction: bindActionCreators(navigateToTexts, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(LinkDetailCreate);
