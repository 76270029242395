// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import News from "../entities/news";

const newsDataTransformationAfterFetch = (data) => {
  return data.map((row) => {
    return new News(row);
  });
};

export default newsDataTransformationAfterFetch;
