import React from "react";
import { connect } from 'react-redux';
import LoadingSpinner from "./loadingSpinner";
import { isLoadingSpinnerVisible } from "../../../reducers/loadingSpinnerReducer";

type LoadingSpinnerContainerStateProps = {
  isVisible: boolean;
};

type LoadingSpinnerContainerProps = {} & LoadingSpinnerContainerStateProps;

class LoadingSpinnerContainer extends React.Component<LoadingSpinnerContainerProps> {

  render(): React.ReactElement | null {
    const { isVisible } = this.props;
    if (isVisible) {
      return (<LoadingSpinner />);
    }

    return (null);
  }
}

const mapStateToProps = (state: any): LoadingSpinnerContainerStateProps => {
  return {
    isVisible: isLoadingSpinnerVisible(state),
  };
};

export default connect(mapStateToProps)(LoadingSpinnerContainer);