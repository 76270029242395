import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { Form, Field } from 'react-final-form';
import arrayMutators from 'final-form-arrays';
import { FieldArray } from 'react-final-form-arrays';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import LoadingIndicator from '../common/LoadingIndicator';
import { getReport } from '../../reducers/reportReducer';
import Report, { ReportFilter } from '../../entities/report';
import DatePicker from '../common/DatePicker';

import { reportDetailPropTypes } from '../../common/propTypes';
import { navigateToReports } from '../../actions/navigationActions';
import { filterReport } from '../../actions/reportActions';
import { BaseDetailProps } from '../common/types/baseDetail';
import { REPORT_PARAMETER_TYPES_ENUM } from '../../api/enums/reportParameterType';
import DateTimePicker from '../common/DateTimePicker';

export interface ReportFiltersProps extends BaseDetailProps<Report> {
    /*  rewards: Reward[];
    followingRewardUserReadableProperty: keyof Reward;

    showIsDigiCondition: (value: any) => boolean;
    showIsO2VopeCondition: (value: any) => boolean;
    showIsGameOrAffiliateProgramCondition: (value: any) => boolean;
    nameof: (name: keyof Reward) => keyof Reward; */

    headerText: string;
}

class ReportFilters extends Component<ReportFiltersProps> {
    constructor(props: ReportFiltersProps) {
        super(props);

        this.state = { loading: false };
        this.translatedFormValidation = this.translatedFormValidation.bind(this);
    }

    translatedFormValidation(values: Report) {
        /* const { t } = this.props; */
        const errors = {};

        /*  if (!values.displayName) {
            errors.displayName = t('DisplayNameIsRequired');
        }

        if (!values.name) {
            errors.name = t('NameIsRequired');
        }

        if (!values.viewName) {
            errors.viewName = t('ViewNameIsRequired');
        } */

        return errors;
    }

    handleOnSubmit = (values: Report) => {
        this.props.submitAction(values);
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            mutators={{
                                ...arrayMutators,
                            }}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <FieldArray<ReportFilter> name="filters">
                                        {({ fields }) =>
                                            fields.map((name: string, index: number) => (
                                                <div className="k-form-field" key={name}>
                                                    <span>
                                                        <Translation tKey={`${fields.value[index].displayName}`} />
                                                    </span>
                                                    {fields.value[index].type === REPORT_PARAMETER_TYPES_ENUM.DATE && (
                                                        <Field
                                                            name={`${name}.value`}
                                                            component={DatePicker}
                                                            placeholder={`${fields.value[index].displayName}`}
                                                        />
                                                    )}

                                                    {fields.value[index].type === REPORT_PARAMETER_TYPES_ENUM.DATE_TIME && (
                                                        <Field
                                                            name={`${name}.value`}
                                                            component={DateTimePicker}
                                                            placeholder={`${fields.value[index].displayName}`}
                                                        />
                                                    )}
                                                </div>
                                            ))
                                        }
                                    </FieldArray>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Download" />
                                        </SubmitButton>
                                        <CancelButton onClick={cancelAction}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any, ownProps: any) => {
    const reportId = parseInt(ownProps.match.params.id);
    const report = getReport(state, reportId);
    const initialValues = {
        ...report,
        filters: [...report.parameters],
    };

    return {
        lang: state.app.lang,
        initialValues,
        headerText: `${report.displayName} - konfigurace filtrů`,
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>) => {
    return {
        submitAction: bindActionCreators(filterReport, dispatch),
        cancelAction: bindActionCreators(navigateToReports, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(ReportFilters);
