import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToRewards } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import rewardItemTransformationBeforeSave from '../data-transformations/rewardItemTransformationBeforeSave';
import Reward from '../entities/reward';
import { isLoaded } from '../reducers/rewardReducer';
import { fetchDataSources } from './dataSourceActions';

export const fetchRewards = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`reward/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.REWARD_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};

export const createReward = (data: Reward) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = rewardItemTransformationBeforeSave(data);

    axios
        .post(`reward`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.REWARD_ADD,
                });

                dispatch(navigateToRewards());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updateReward = (data: Reward) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = rewardItemTransformationBeforeSave(data);

    axios
        .put(`reward`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.REWARD_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToRewards());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const fetchRewardDependencies = () => async (dispatch: Dispatch<any>, getState: any) => {
    try {
        dispatch(LOADING_DEPENDENCIES_BEGIN);

        await fetchDataSources()(dispatch, getState);
    } catch (exception) {
        throw exception; //FUJ
    } finally {
        dispatch(LOADING_DEPENDENCIES_END);
    }
};

const LOADING_DEPENDENCIES_BEGIN = {
    type: types.NEWS_LOADING_DEPENDENCIES_BEGIN,
};

const LOADING_DEPENDENCIES_END = {
    type: types.NEWS_LOADING_DEPENDENCIES_END,
};
