import React from 'react';
import { Field } from 'react-final-form';
import PropTypes from 'prop-types';

const Condition = ({ when, is, children }) => (
    <Field name={when} subscription={{ value: true }}>
        {({ input: { value } }) => {
            if (typeof is === 'function') {
                if (is(value)) {
                    return children;
                } else {
                    return null;
                }
            } else if (value === is) {
                return children;
            } else {
                return null;
            }
        }}
    </Field>
);

Condition.propTypes = {
    when: PropTypes.string.isRequired,
    is: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool, PropTypes.func]).isRequired,
    children: PropTypes.node.isRequired,
};

export default Condition;
