import axios from 'axios';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import {navigateToCompetitions} from "./navigationActions";
import ResponseStatus from "../utils/responseStatus";
import competitionItemTransformationBeforeSave from "../data-transformations/competitionItemTransformationBeforeSave";

export const createCompetition = (data) => (dispatch) => {
  dispatch(beginAjaxCall());

  const transformedData = competitionItemTransformationBeforeSave(data);

  axios.post(`competition`, transformedData)
    .then((response) => {
      if (ResponseStatus.IsCreated(response.status)) {
        dispatch({
          type: types.NEWS_ADD
        });

        dispatch(navigateToCompetitions());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch((error) => {
      dispatch(ajaxCallError());
      throw error;
    });
};

export const updateCompetition = (data) => (dispatch) => {
  dispatch(beginAjaxCall());

  const transformedData = competitionItemTransformationBeforeSave(data);

   axios.put(`competition`, transformedData)
    .then((response) => {
      if (ResponseStatus.IsOK(response.status)) {
        dispatch({
          type: types.NEWS_UPDATE,
          data: response.data
        });

        dispatch(navigateToCompetitions());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch((error) => {
      dispatch(ajaxCallError());
      throw error;
    });
};

