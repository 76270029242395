export default class SimCardType {
    id: number;
    name: string;
    isManagerType: boolean;

    constructor(properties: Partial<SimCardType>) {
        this.id = 0;        
        this.name = '';
        this.isManagerType = false;
        
        Object.assign(this, properties);
    }
}
