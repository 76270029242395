import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { alertRemove, alertClear } from "../../actions/alertActions";
import { bindActionCreators } from "redux";
import Alert from "./Alert";
import { TransitionGroup } from 'react-transition-group';
import {autoCloseConfigPropTypes, alertPropTypes} from "../../common/propTypes";

class Alerts extends React.Component {
  constructor(props) {
    super(props);

    this.removeAlert = this.removeAlert.bind(this);
    this.clearAlerts = this.clearAlerts.bind(this);
  }

  componentWillUnmount() {
    this.clearAlerts();
  }

  removeAlert(alertId) {
    this.props.removeAlert(alertId);
  }

  clearAlerts() {
    this.props.clearAlerts();
  }

  render() {    
    const alerts = this.props.alerts.map(alert => (
      <Alert key={alert.id} alert={alert} onClose={this.removeAlert} autoCloseConfig={this.autoCloseConfig} />
    ));

    return (      
      <TransitionGroup>
        <div className="notification-container">
          {alerts}
        </div>       
      </TransitionGroup>
    );
  }
}

Alerts.propTypes = {
  removeAlert: PropTypes.func.isRequired,
  clearAlerts: PropTypes.func.isRequired,
  alerts: PropTypes.arrayOf(alertPropTypes).isRequired,
  autoCloseConfig: autoCloseConfigPropTypes,  
};

Alerts.defaultProps = {
  alerts: []
};

const mapStateToProps = state => {
  return {
    alerts: state.alerts
  };
};

const mapDispatchToProps = dispatch => {
  return {
    removeAlert: bindActionCreators(alertRemove, dispatch),
    clearAlerts: bindActionCreators(alertClear, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Alerts);
