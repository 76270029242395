import EventTriggerConfiguration from '../entities/eventTriggerConfiguration';

const EventTriggerConfigurationItemTransformationBeforeSave = (item: EventTriggerConfiguration) => {
    const validFromUtc = item.validFromUtc;
    const validToUtc = item.validToUtc;

    const transformedData = {
        ...item,
        source: item.sourceEnumDescription?.value,
        validFromUtc: validFromUtc ? validFromUtc.toUTCString() : null,
        validToUtc: validToUtc ? validToUtc.toUTCString() : null,
    } as { [key: string]: any };

    return transformedData;
};

export default EventTriggerConfigurationItemTransformationBeforeSave;
