import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import PushNotificationConfiguration from '../../entities/pushNotificationConfiguration';
import { nameofFactory } from '../../helpers/nameOfFactory';
import * as enums from '../../common/enums';
import { BaseDetailProps } from '../common/types/baseDetail';
import DropDown from '../common/DropDown';
import DropDownWithValueField from '../common/DropDownWithValueField';
import { Dispatch, bindActionCreators } from 'redux';
import { fetchPushNotificationConfigurationDependencies } from '../../actions/pushNotificationConfigurationActions';
import { getData as getPages, getUserReadableProperty as getPageUserReadableProperty } from '../../reducers/newsPagesReducer';
import { getData as getNewses, getUserReadableProperty as getNewsUserReadableProperty } from '../../reducers/newsReducer';
import NewsPage from '../../entities/newsPage';
import News from '../../entities/news';

const nameof = nameofFactory<PushNotificationConfiguration>();

export interface PushNotificationConfigurationDetailProps
    extends BaseDetailProps<PushNotificationConfiguration>,
        PushNotificationConfigurationDetailStateProps,
        PushNotificationConfigurationDetailDispatchProps {
    headerText: string;
}

export interface PushNotificationConfigurationDetailStateProps {
    lang: string;
    pages: NewsPage[];
    pageUserReadableProperty: string;
    newses: News[];
    newsUserReadableProperty: string;
}

export interface PushNotificationConfigurationDetailDispatchProps {
    fetchPushNotificationConfigurationDependencies: () => void;
}

class PushNotificationConfigurationDetail extends Component<PushNotificationConfigurationDetailProps> {
    componentDidMount() {
        this.props.fetchPushNotificationConfigurationDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as PushNotificationConfiguration);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;

        const typedValues = values as PushNotificationConfiguration;
        const errors: Partial<Record<keyof PushNotificationConfiguration, string>> = {};

        if (!typedValues.name) {
            errors.name = t('NameIsRequired');
        }

        if (!typedValues.message) {
            errors.message = t('MessageIsRequired');
        }

        if (!typedValues.title) {
            errors.title = t('TitleIsRequired');
        }

        if (typedValues.targetPageId <= 0 && typedValues.newsId <= 0) {
            errors.targetPageId = t('TargetPageIdOrNewsIdIsRequired');
            errors.newsId = t('TargetPageIdOrNewsIdIsRequired');
        }
    
        return errors;
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <label className="k-form-field" htmlFor={nameof('name')}>
                                        <span>
                                            <Translation tKey="Name" />
                                        </span>
                                        <Field name={nameof('name')} component={Input} />
                                    </label>
                                    <label className="k-form-field" htmlFor={nameof('code')}>
                                        <span>
                                            <Translation tKey="Code" />
                                        </span>
                                        <Field name={nameof('code')} component={Input} />
                                    </label>
                                    <label className="k-form-field" htmlFor={nameof('message')}>
                                        <span>
                                            <Translation tKey="Message" />
                                        </span>
                                        <Field
                                            name={nameof('message')}
                                            component="textarea"
                                            className="k-textarea"
                                            rows="4"
                                            style={{ width: '100%' }}
                                            // placeholder={t("Description")}
                                        />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('title')}>
                                        <span>
                                            <Translation tKey="Title" />
                                        </span>
                                        <Field name={nameof('title')} component={Input} />
                                    </label>

                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('targetPageId')}>
                                            <span>
                                                <Translation tKey="Page" />
                                            </span>
                                            <Field
                                                name={nameof('targetPageId')}
                                                component={DropDownWithValueField}
                                                // placeholder={t("Page")}
                                                textField={this.props.pageUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.pages}
                                            />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('newsId')}>
                                            <span>
                                                <Translation tKey="News" />
                                            </span>
                                            <Field
                                                name={nameof('newsId')}
                                                component={DropDownWithValueField}
                                                // placeholder={t("Page")}
                                                textField={this.props.newsUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.newses}
                                            />
                                        </label>
                                    </div>

                                    <label className="k-form-field" htmlFor={nameof('channelTypeEnumDescription')}>
                                        <span>
                                            <Translation tKey="Channel" />
                                        </span>
                                        <Field
                                            name={nameof('channelTypeEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.PUSH_NOTIFICATION_CHANNEL_TYPE_TYPES}
                                        />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): PushNotificationConfigurationDetailStateProps => {
    return {
        lang: state.app.lang,
        pages: getPages(state),
        pageUserReadableProperty: getPageUserReadableProperty(state),
        newses: getNewses(state),
        newsUserReadableProperty: getNewsUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<PushNotificationConfigurationDetailProps>): PushNotificationConfigurationDetailDispatchProps => {
    return {
        fetchPushNotificationConfigurationDependencies: bindActionCreators(fetchPushNotificationConfigurationDependencies, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(PushNotificationConfigurationDetail);
