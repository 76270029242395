import React from "react";
import { GridCell } from "@progress/kendo-react-grid";
import { getEnumItemByValue } from "../../../common/enums";

const EnumCellWrapper = enums => {
  class EnumCell extends GridCell {
    render() {
      const { onClick, style } = this.props;
      const enumField = this.props.dataItem[this.props.field];     
      const textValue = getEnumItemByValue(enumField.value, enums).text;

      return (
        <td onClick={onClick} style={style}>
          {textValue}
        </td>
      );
    }
  }

  return EnumCell;
};

export default EnumCellWrapper;
