import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { loadState, saveState } from '../components/common/LocalStorage';
import { alertErrorAdd } from './alertActions';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateTo, navigateToSingIn } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import { fetchRoles } from './roleActions';

export const setNewPassword = (password, token) => (dispatch) => {
    dispatch(beginAjaxCall());
    document.cookie = `Token=${token}`;

    axios
        .post(`auth/newpassword`, { password })
        .then((response) => {
            dispatch({
                type: types.USER_PASSWORD_SET,
            });

            let { accessToken } = response.data;
            if (accessToken) {
                dispatch(verifyAccess(accessToken));
                dispatch(navigateTo('/'));
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const resetPassword = (email) => (dispatch) => {
    dispatch(beginAjaxCall());

    axios
        .get(`auth/forgottenpassword`, { params: { email: email } })
        .then((response) => {
            let { data } = response;
            dispatch({
                type: types.USER_PASSWORD_RESET,
                sent: data,
            });

            if (data != undefined && !data) {
                dispatch(alertErrorAdd('UserDoesntExist', 'error'));
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const verifyAccount = (token) => (dispatch) => {
    dispatch(beginAjaxCall());

    axios
        .get(`auth/verify`, { params: { token: token } })
        .then(() => {
            dispatch({
                type: types.USER_ACTIVATE_ACCOUNT,
            });
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const clear = () => (dispatch) => {
    dispatch({
        type: types.USER_CLEAR,
    });
};

export const signUp = (account) => (dispatch) => {
    dispatch(beginAjaxCall());

    axios
        .post(`auth/signup`, { ...account })
        .then((response) => {
            if (response) {
                let { data } = response;
                if (data) {
                    dispatch({
                        type: types.USER_SING_UP,
                        ...data,
                    });
                } else {
                    dispatch(ajaxCallError());
                }
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const signIn = (email, password) => (dispatch) => {
    dispatch(beginAjaxCall());

    axios
        .post(`auth/token`, {
            email,
            password,
        })
        .then((response) => {
            dispatch(signInCallback(response, '/'));
        })
        .catch((error) => {
            dispatch(signOut());
        });
};

export const verifyAccess = (token) => (dispatch) => {
    if (!token) dispatch(signOut());

    let { exp } = jwtDecode(token);

    if (!exp) dispatch(signOut());

    let now = new Date();
    let expireIn = new Date(exp * 1000);

    if (now > expireIn) {
        dispatch(signOut());
    } else {
        // Mapping token to storage and set cookie
        saveState({ user: { isAuth: true, tokenExpireIn: expireIn } });
        document.cookie = `Token=${token}`;

        // Renew token - checking validity
        axios
            .get(`auth/renew`)
            .then((response) => {
                dispatch(signInCallback(response));
            })
            .catch(() => {
                dispatch(signOut());
            });
    }
};

export const hasAccess = () => (dispatch) => {
    let state = loadState();
    if (!state) {
        dispatch(signOut());
    } else {
        let { user } = state;
        if (user.tokenExpireIn == '') {
            dispatch(signOut());
        } else {
            let now = new Date();
            let expireIn = new Date(user.tokenExpireIn * 1000);
            console.warn('hasAccess after expireIn');

            if (now > expireIn) {
                dispatch(signOut());
            } else {
                // Try to authorize
                dispatch(beginAjaxCall());
                axios
                    .get(`auth/renew`)
                    .then((response) => {
                        if (response.data != '') dispatch(signInCallback(response));
                        else dispatch(signOut());
                    })
                    .catch(() => {
                        dispatch(ajaxCallError());
                        dispatch(signOut());
                    });
            }
        }
    }
};

export const signUpCallback =
    (response, navigateTo = undefined) =>
    (dispatch) => {
        let { data } = response;
        document.cookie = `Token=${data.accessToken}`;

        dispatch(beginAjaxCall());
        // Renew token - checking validity
        axios
            .get(`auth/renew`)
            .then((response) => {
                dispatch(signInCallback(response, navigateTo));
            })
            .catch(() => {
                dispatch(signOut());
            });
    };

export const signInCallback =
    (response, pathToNavigate = undefined) =>
    (dispatch) => {
        let { data } = response;
        if (data && data.isAuthorized) {
            // Get data
            let { data } = response;
            // Set user logged in
            dispatch({
                type: types.USER_SIGN_IN,
                tokenExpireIn: data.expirationIn,
                isAdmin: data.isAdmin,
                permittedModules: data.permittedModules,
            });
            // Save to local storage
            saveState({ user: { isAuth: true, tokenExpireIn: data.expirationIn, isAdmin: data.isAdmin } });
            // Navigate to homepage
            if (pathToNavigate) {
                dispatch(navigateTo(pathToNavigate));
            }
        } else {
            dispatch(alertErrorAdd('UserDoesntExist'));
            dispatch(ajaxCallError());
        }
    };

export const signOut = () => (dispatch, getState) => {
    // Clear local storage
    console.warn('signOut');
    saveState({ user: { isAuth: false, token: '', tokenExpireIn: '' } });

    dispatch(beginAjaxCall());
    axios
        .get(`auth/clear`)
        .then(() => {
            // Nothing to read
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });

    // Clearing cookies
    dispatch({
        type: types.USER_SIGN_OUT,
    });

    dispatch({
        type: types.APP_RESET,
    });

    // Navigate to signIn page
    dispatch(navigateToSingIn());
};

export const isAuth = (lastModified) => (dispatch) => {
    let state = loadState();
    if (!state) {
        dispatch(signOut());
    } else {
        // Inactive user will be signed out
        let now = new Date();
        let maxInactiveTime = new Date(lastModified.getTime() + 20 * 60 * 1000);
        if (now > maxInactiveTime) {
            dispatch(signOut());
        } else {
            let { user } = state;
            if (user.tokenExpireIn == '') dispatch(signOut());

            let expireIn = new Date(user.tokenExpireIn * 1000);
            let willExpire = new Date(user.tokenExpireIn * 1000);
            willExpire.setMinutes(willExpire.getMinutes() - 2);

            if (now < expireIn) {
                // Token is still valid
                if (now > willExpire) {
                    // Renew early
                    axios
                        .get(`auth/renew`)
                        .then((response) => {
                            dispatch(signInCallback(response));
                        })
                        .catch(() => {
                            dispatch(signOut());
                        });
                }
            } else {
                // Token is expired
                dispatch(signOut());
            }
        }
    }
};

export const userAgendas = () => (dispatch) => {
    dispatch(beginAjaxCall());

    axios.get(`auth/useragendas`).then((response) => {
        let { data } = response;
        dispatch({
            type: types.USER_FETCH_AGENDAS,
            data,
        });
    });
};

export const setAgenda = (agendaId) => (dispatch) => {
    dispatch(beginAjaxCall());

    axios
        .post(`auth/setagenda`, {
            agendaId,
        })
        .then(() => {
            dispatch({
                type: types.APP_AGENDA_SET,
            });
            dispatch(navigateTo('/'));
        })
        .catch(() => {
            dispatch(ajaxCallError());
            dispatch(signOut());
        });
};

export const createUser = (data) => (dispatch) => {
    dispatch(beginAjaxCall());

    axios
        .post(`auth/invite`, data)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                if (response.data && response.data.emailIsInUsed) {
                    dispatch(alertErrorAdd('UserWithEmailIsInUsed'));

                    return;
                }

                dispatch({
                    type: types.USER_ADD,
                });

                dispatch(navigateTo('/users'));
            } else {
                dispatch(ajaxCallError());
                dispatch(alertErrorAdd('UserCreateError'));
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            dispatch(alertErrorAdd('UserCreateError'));
        });
};

export const updateUser = (data) => (dispatch) => {
    dispatch(beginAjaxCall());

    axios
        .put(`users`, data)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.USER_UPDATE,
                    data: response.data,
                });

                dispatch(navigateTo('/users'));
            } else {
                dispatch(ajaxCallError());
                dispatch(alertErrorAdd('UserUpdateError'));
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            dispatch(alertErrorAdd('UserUpdateError'));
        });
};

export const setPassword = (password, token) => (dispatch) => {
    dispatch(beginAjaxCall());

    document.cookie = `Token=${token}`;

    axios
        .post(`auth/setpassword`, { password })
        .then((response) => {
            if (ResponseStatus.IsOK(response.status) && response.data === true) {
                dispatch({
                    type: types.USER_PASSWORD_SET,
                });

                dispatch(navigateToSingIn());
            } else {
                dispatch(ajaxCallError());
                dispatch(alertErrorAdd('SetPasswordError'));
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            dispatch(alertErrorAdd('SetPasswordError'));
        });
};

export const fetchUserDependencies = () => async (dispatch, getState) => {
    try {
        dispatch(LOADING_DEPENDENCIES_BEGIN);

        await fetchRoles()(dispatch, getState);
    } catch (exception) {
        throw exception; //FUJ
    } finally {
        dispatch(LOADING_DEPENDENCIES_END);
    }
};

const LOADING_DEPENDENCIES_BEGIN = {
    type: types.USER_LOADING_DEPENDENCIES_BEGIN,
};

const LOADING_DEPENDENCIES_END = {
    type: types.USER_LOADING_DEPENDENCIES_END,
};
