import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const Translation = ({t, tKey}) => {
  return t(tKey);
};

Translation.propTypes = {
  tKey: PropTypes.string.isRequired,
  t: PropTypes.func.isRequired
};

export default translate('common')(Translation);

