import axios from "axios";

import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import ResponseStatus from "../utils/responseStatus";
import { showLoadingSpinner, hideLoadingSpinner } from "./loadingSpinnerActions";
import { addHrefWithFileToDownload } from "../utils/commonFunctions";

const downloadReport = (reportId, dispatch, getState) => {
  dispatch(beginAjaxCall());
  
  if (reportId) {
    dispatch(showLoadingSpinner());
    
    const { table } = getState();
    const { actionUrl } = table;

    axios
      .get(`${actionUrl}/xlsx/${reportId}`, {
        responseType: "blob",
      })
      .then((response) => {                
        if (ResponseStatus.IsOK(response.status)) {
          
          const dateString = new Date().toLocaleDateString();          
          addHrefWithFileToDownload(response.data, `${reportId}_${dateString}.xlsx`);

          dispatch(hideLoadingSpinner());
        } else {
          dispatch(hideLoadingSpinner());
          dispatch(ajaxCallError());          
        }
      })
      .catch((error) => {
        dispatch(hideLoadingSpinner());
        dispatch(ajaxCallError());
        throw error;
      });
  }
};

export const downloadReportById = (id) => (dispatch, getState) => {
  downloadReport(id, dispatch, getState);
};