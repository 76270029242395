class ResponseStatus {
  static IsCreated(status) {
    return status === 201;
  }

  static IsOK(status) {
    return status === 200;
  }
}

export default ResponseStatus;
