import axios from 'axios';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import ResponseStatus from "../utils/responseStatus";
import {alertAdd} from "./alertActions";
import { ALERT_TYPE } from '../common/constants';
import ratingSettingsTransformationBeforeSave from "../data-transformations/ratingSettingsTransformationBeforeSave";
import ratingSettingsDataTransformationAfterFetch from "../data-transformations/ratingSettingsDataTransformationAfterFetch";

export const updateRatingSettings = (data) => (dispatch) => {
    dispatch(beginAjaxCall());
    
    const transformedData = ratingSettingsTransformationBeforeSave(data);

    axios.put(`ratingSettings`, transformedData)
      .then((response) => {
        if (ResponseStatus.IsOK(response.status)) {
          dispatch({
            type: types.RATING_SETTINGS_UPDATE,
            data: response.data
          });
  
          dispatch(alertAdd("RatingSettingsSaveOk", ALERT_TYPE.SUCCESS));
            
          fetchRatingSettings()(dispatch);
        } else {
          dispatch(ajaxCallError());
        }
      })
      .catch((error) => {
        dispatch(ajaxCallError());
        throw error;
      });
  };

  export const fetchRatingSettings = () => (dispatch) => {
    dispatch(beginAjaxCall());
    dispatch(LOADING_BEGIN);

    axios.get(`ratingSettings`)
      .then((response) => {
        if (ResponseStatus.IsOK(response.status)) {
          const transformedData = ratingSettingsDataTransformationAfterFetch(response.data);

          dispatch({
            type: types.RATING_SETTINGS_FETCH,
            data: transformedData
          });
  
        } else {
          dispatch(ajaxCallError());
        }

        dispatch(LOADING_END);
      })
      .catch((error) => {
        dispatch(ajaxCallError());
        dispatch(LOADING_END);

        throw error;
      });
  };

  const LOADING_BEGIN = {
    type: types.RATING_SETTINGS_LOADING_BEGIN,
  };

  const LOADING_END = {
    type: types.RATING_SETTINGS_LOADING_END,
  }