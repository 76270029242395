import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToDataSourceQueryContexts, navigateToLinks } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/roleReducer';
import DataSourceQueryContext from '../entities/dataSourceQueryContext';
import DataSourceQueryContextItemTransformationBeforeSave from '../data-transformations/dataSourceQueryContextItemTransformationBeforeSave';

export const fetchDataSourceQueryContexts = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`DataSourceQueryContext/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.DATASOURCEQUERYCONTEXT_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};

export const createDataSourceQueryContext = (data: DataSourceQueryContext) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = DataSourceQueryContextItemTransformationBeforeSave(data);

    axios
        .post(`DataSourceQueryContext`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.DATASOURCEQUERYCONTEXT_ADD,
                });

                dispatch(navigateToDataSourceQueryContexts());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updateDataSourceQueryContext = (data: DataSourceQueryContext) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    const transformedData = DataSourceQueryContextItemTransformationBeforeSave(data);

    axios
        .put(`DataSourceQueryContext`, transformedData)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.DATASOURCEQUERYCONTEXT_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToDataSourceQueryContexts());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};
