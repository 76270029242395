import React from "react";

const LoadingSpinner : React.FunctionComponent = () => {
  return (
    <div className="loading-spinner-overlay">
    </div>
  );
};

export default LoadingSpinner;
