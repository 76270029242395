import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import AffiliateProgramSubscriberRewardClaimDetail from './affiliateProgramSubscriberRewardClaimDetail';
import { createAffiliateProgramSubscriberRewardClaim } from '../../actions/affiliateProgramSubscriberRewardClaimsActions';
import { navigateToAffiliateProgramSubscriberRewardClaims } from '../../actions/navigationActions';
import AffiliateProgramSubscriberRewardClaim from '../../entities/affiliateProgramSubscriberRewardClaim';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';

const initialValues = new AffiliateProgramSubscriberRewardClaim({});

interface AffiliateProgramSubscriberRewardClaimDetailCreateProps extends BaseDetailProps<AffiliateProgramSubscriberRewardClaim> {}

const RewardDetailCreate = ({ submitAction, cancelAction, t }: AffiliateProgramSubscriberRewardClaimDetailCreateProps) => {
    return (
        <AffiliateProgramSubscriberRewardClaimDetail
            t={t}
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('CreateAffiliateProgramSubscriberRewardClaim')}
            cancelAction={cancelAction}
        />
    );
};

const mapDispatchToProps = (
    dispatch: Dispatch<AffiliateProgramSubscriberRewardClaimDetailCreateProps>
): BaseDetailDispatchProps<AffiliateProgramSubscriberRewardClaim> => {
    return {
        submitAction: bindActionCreators(createAffiliateProgramSubscriberRewardClaim, dispatch),
        cancelAction: bindActionCreators(navigateToAffiliateProgramSubscriberRewardClaims, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(RewardDetailCreate);
