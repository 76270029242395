import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import { edit, filterChange, filterClear } from '../../actions/tableActions';
import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import StringCellEx from '../common/formatters/stringCellEx';
import NavigableCell from '../common/formatters/navigableCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import { NAVIGATION_PATHS, TEXT_HORIZONTAL_ALIGN } from '../../common/constants';
import AffiliateProgramSubscriberRewardClaimsActionsBar from './affiliateProgramSubscriberRewardClaimsActionsBar';
import { nameofFactory } from '../../helpers/nameOfFactory';
import RelationCell from '../common/formatters/RelationCell';
import AffiliateProgramSubscriberRewardClaim from '../../entities/affiliateProgramSubscriberRewardClaim';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';
import AffiliateProgramReward from '../../entities/affiliateProgramReward';
import { fetchAffiliateProgramSubscriberRewardClaimDependencies } from '../../actions/affiliateProgramSubscriberRewardClaimsActions';
import {
    getData as getAffiliateProgramPartners,
    getUserReadableProperty as getAffiliateProgramPartnerUserReadableProperty,
} from '../../reducers/affiliateProgramPartnersReducer';
import {
    getData as getAffiliateProgramRewards,
    getUserReadableProperty as getAffiliateProgramRewardUserReadableProperty,
} from '../../reducers/affiliateProgramRewardsReducer';
import LoadingIndicator from '../common/LoadingIndicator';
import { isLoadingDependencies } from '../../reducers/affiliateProgramSubscriberRewardClaimsReducer';
import DateCell from '../common/formatters/DateCell';
import { BaseListDispatchProps, BaseListProps } from '../common/types/baseDetail';

const nameof = nameofFactory<AffiliateProgramSubscriberRewardClaim>();

const defaultAffiliateProgramSubscriberRewardClaimTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

const actionUrl = 'affiliateProgramSubscriberRewardClaim';

interface AffiliateProgramSubscriberRewardClaimsProp
    extends BaseListProps,
        AffiliateProgramSubscriberRewardClaimsStateProps,
        AffiliateProgramSubscriberRewardClaimsDispatchProps {}

interface AffiliateProgramSubscriberRewardClaimsStateProps {
    affiliateProgramPartnerUserReadableProperty: keyof AffiliateProgramPartner;
    affiliateProgramPartners: AffiliateProgramPartner[];
    affiliateProgramRewardUserReadableProperty: keyof AffiliateProgramReward;
    affiliateProgramRewards: AffiliateProgramReward[];
    loadingDependencies: boolean;
}

interface AffiliateProgramSubscriberRewardClaimsDispatchProps extends BaseListDispatchProps {
    fetchAffiliateProgramSubscriberRewardClaimDependencies: typeof fetchAffiliateProgramSubscriberRewardClaimDependencies;
}

const AffiliateProgramSubscriberRewardClaims = (props: AffiliateProgramSubscriberRewardClaimsProp) => {
    // Similar to componentDidMount and componentDidUpdate:
    useEffect(() => {
        fetchAffiliateProgramSubscriberRewardClaimDependencies();
    }, []);

    const {
        t,
        navigateTo,
        fetchAffiliateProgramSubscriberRewardClaimDependencies,
        affiliateProgramRewards,
        affiliateProgramRewardUserReadableProperty,
        affiliateProgramPartners,
        affiliateProgramPartnerUserReadableProperty,
    } = props;

    let table: any;

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    const onClone = () => {
        table.getWrappedInstance().cloneSelected();
    };

    const customRowRender = (trElement: any) => {
        return trElement;
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="AffiliateProgramClaims" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <AffiliateProgramSubscriberRewardClaimsActionsBar
                            /* onEdit={onEdit} */ onNew={onNew}
                            /* onClone={onClone}  */ onRemove={onRemove}
                        />
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        {props.loadingDependencies && <LoadingIndicator />}
                        <Table
                            path={'affiliateProgramSubscriberRewardClaim'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS}
                            name="AffiliateProgramSubscriberRewardClaimsTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultAffiliateProgramSubscriberRewardClaimTabelSort}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('msisdn')}
                                width="160"
                                cell={(props) => <StringCellEx {...props} textAlign={TEXT_HORIZONTAL_ALIGN.LEFT} />}
                                title={t('Msisdn')}
                            />
                            <Column
                                field={nameof('affiliateProgramPartnerId')}
                                width="160"
                                cell={(props) => (
                                    <RelationCell<AffiliateProgramPartner>
                                        {...props}
                                        relationUserReadableProperty={affiliateProgramPartnerUserReadableProperty}
                                        relations={affiliateProgramPartners}
                                    />
                                )}
                                title={t('Partner')}
                            />
                            <Column
                                field={nameof('affiliateProgramRewardId')}
                                width="360"
                                cell={(props) => (
                                    <RelationCell<AffiliateProgramReward>
                                        {...props}
                                        relationUserReadableProperty={affiliateProgramRewardUserReadableProperty}
                                        relations={affiliateProgramRewards}
                                    />
                                )}
                                title={t('Reward')}
                            />
                            <Column field={nameof('dateOfCreate')} width="160" cell={(props) => <DateCell {...props} />} title={t('DateOfCreate')} />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): AffiliateProgramSubscriberRewardClaimsStateProps => {
    return {
        loadingDependencies: isLoadingDependencies(state),
        affiliateProgramPartners: getAffiliateProgramPartners(state),
        affiliateProgramPartnerUserReadableProperty: getAffiliateProgramPartnerUserReadableProperty(state),
        affiliateProgramRewards: getAffiliateProgramRewards(state),
        affiliateProgramRewardUserReadableProperty: getAffiliateProgramRewardUserReadableProperty(state),
    };
};

const mapDispatchToProps = (
    dispatch: Dispatch<AffiliateProgramSubscriberRewardClaimsDispatchProps>
): AffiliateProgramSubscriberRewardClaimsDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        fetchAffiliateProgramSubscriberRewardClaimDependencies: bindActionCreators(fetchAffiliateProgramSubscriberRewardClaimDependencies, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(AffiliateProgramSubscriberRewardClaims);
