import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

// import * as propTypes from '../../common/propTypes';
import UserDetail from './userDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updateUser } from '../../actions/userActions';
import { navigateToUsers } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import User from '../../entities/user';

interface UserDetailUpdateProps extends BaseDetailProps<User> {}

const UserDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: UserDetailUpdateProps) => {
    return (
        <UserDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('UpdateUser') + ' - ' + initialValues.email}
            cancelAction={cancelAction}
            submittButtonLabel={t('Save')}
            emailIsReadOnly={true}
            t={t}
        />
    );
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<UserDetailUpdateProps>): BaseDetailDispatchProps<User> => {
    return {
        submitAction: bindActionCreators(updateUser, dispatch),
        cancelAction: bindActionCreators(navigateToUsers, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(UserDetailUpdate);
