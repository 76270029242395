import React, {Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translate} from "react-i18next";
import {bindActionCreators, compose} from "redux";
import {Form, Field} from 'react-final-form';
import createDecorator from 'final-form-calculate';

import SubmitButton from '../common/SubmitButton';
import * as enums from '../../common/enums';
import * as propTypes from '../../common/propTypes';

import Alerts from "../common/Alerts";
import Translation from "../common/Translation";
import CancelButton from "../common/CancelButton";
import {fetchNewsDependencies} from '../../actions/newsActions';
import Dropzone from "../common/DropZone";
import Input from "../common/Input";
import DatePicker from "../common/DatePicker";
import DropDown from "../common/DropDown";
import {isEmpty} from "../../utils/isEmpty";
import {MAX_IMAGE_SIZE_IN_BYTES, MAX_IMAGE_SIZE_IN_MEGA_BYTES} from "../../common/configs";
import Switch from './../common/Switch';

class CompetitionDetail extends Component {
  constructor(props) {
    super(props);

    this.state = {files: []};
    this.translatedFormValidation = this.translatedFormValidation.bind(this);
    this.formDecorator = createDecorator(
      {
        field: 'image',
        updates: {
          imageUrl: (imageValue, allValues) => {
            const oldImageUrl = allValues.imageUrl;
    
            if (oldImageUrl) {
              URL.revokeObjectURL(oldImageUrl);
            }
    
            let newImageUrl = null;
            if (!isEmpty(imageValue)) {
              newImageUrl = URL.createObjectURL(imageValue);
            }
    
            return newImageUrl;
          },
        }
      },
    );
  }
  
  componentDidMount() {  
    
  }

  translatedFormValidation(values) {
    const { t } = this.props;
    const errors = {};

    if (!values.name) {
      errors.name = t('NameIsRequired');
    }

    if (!values.url) {
      errors.url = t('UrlIsRequired');
    }

    if (!values.validFrom) {
      errors.validFrom = 'Datum Platný od je povinné';
    }

    if (values.validTo && values.validFrom && values.validTo < values.validFrom) {
      errors.validTo = 'Datum Platný do, musí být větší než datum Platný od';
    }

    if (!values.targetCustomer) {
      errors.targetCustomer = 'Typ zákazníka je povinný';
    }

    if(values.image && values.image.size > MAX_IMAGE_SIZE_IN_BYTES){
      errors.image = `Obrázek je příliš velký. Maximální veliskot je ${MAX_IMAGE_SIZE_IN_MEGA_BYTES}MB`;
    }

    return errors;
  }

  render() {
    console.debug("CompetitionDetail.render");

    const {initialValues, headerText, onCancel} = this.props;

    return (
      <div>
        <div className="content-header">
          <h1>{headerText}</h1>
        </div>
        <div className="content-wrapper">
          <Alerts/>
          <div className="panel card col-xs-12 col-sm-6">
            <Form
              validate={this.translatedFormValidation}
              onSubmit={this.props.onSubmit}
              initialValues={initialValues}
              decorators={[this.formDecorator]}
              render={({handleSubmit, reset, submitting, pristine, values, valid, form}) => (

                <form className="k-form" onSubmit={handleSubmit}>
                  
                  <label className="k-form-field" htmlFor="name">
                    <span><Translation tKey="Name"/></span>
                    <Field name="name"
                           component={Input}
                      // placeholder={t("Name")}
                    />
                  </label>

                  <div className={'row'}>
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor="validFrom">
                      <span><Translation tKey="ValidFrom"/></span>
                      <Field name="validFrom"
                             component={DatePicker}
                        // placeholder={t("validFrom")}
                      />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor="validTo">
                      <span><Translation tKey="ValidTo"/></span>
                      <Field name="validTo"
                             component={DatePicker}
                        // placeholder={t("ValidTo")}
                      />
                    </label>
                  </div>                 

                  <label className="k-form-field">
                    <span><Translation tKey="Platform"/></span>
                    <Field name="platform"
                           component={DropDown}
                           textField="text"
                           dataSource={enums.PLATFORMA_TYPES}
                    />
                  </label>

                  <label className="k-form-field">
                    <span><Translation tKey="CustomerType"/></span>
                    <Field name="targetCustomer"
                           component={DropDown}
                           textField="text"
                           dataSource={enums.COMPETITION_TARGET_CUSTOMER_TYPES}
                    />
                  </label>

                  
                  <label className="k-form-field" htmlFor="urlOpenExternal">
                      <span><Translation tKey="UrlOpenExternal"/></span>
                      <Field name="urlOpenExternal"
                            component={Switch}
                        // placeholder={t("Name")}
                      />
                  </label>

                  <label className="k-form-field" htmlFor="url">
                      <span><Translation tKey="URL"/></span>
                      <Field name="url"
                             component={Input}
                        // placeholder={t("URL")}
                      />
                  </label>
                  
                  <label className="k-form-field" htmlFor="actionButtonText">
                    <span><Translation tKey="ActionButtonText"/></span>
                    <Field name="actionButtonText"
                           component={Input}
                      // placeholder={t("Name")}
                    />
                  </label>

                  <label className="k-form-field">
                    <span><Translation tKey="NoVipText"/></span>
                    <Field name="noVipText"
                           component="textarea"
                           className="k-textarea"
                           rows="2"
                           style={{width: '100%'}}
                      // placeholder={t("ShortDescription")}
                    />
                  </label>

                  <label className="k-form-field">
                    <span><Translation tKey="ImageOverlayText"/></span>
                    <Field name="imageOverlayText"
                           component="textarea"
                           className="k-textarea"
                           rows="2"
                           style={{width: '100%'}}
                      // placeholder={t("ShortDescription")}
                    />
                  </label>

                  <label className="k-form-field">
                    <span><Translation tKey="Description"/></span>
                    <Field name="description"
                           component="textarea"
                           className="k-textarea"
                           rows="4"
                           style={{width: '100%'}}
                      // placeholder={t("Description")}
                    />
                  </label>

                  <div className="k-form-field">
                    <span><Translation tKey="Picture"/></span>
                    <Field name="image"
                           component={Dropzone}
                           recommendedResolution="3240x2580"
                           imageUrl="imageUrl"
                      // placeholder={t("Name")}
                    />
                  </div>

                  <div className="k-form-field">
                    <span><Translation tKey="Published"/></span>
                    <Field name="published"
                           component={Switch}
                      // placeholder={t("Name")}
                    />
                  </div>
                 
                  <div className="footer">
                    <SubmitButton loading={submitting}><Translation tKey="Save"/></SubmitButton>
                    <CancelButton onClick={onCancel}><Translation tKey="Cancel"/></CancelButton>
                  </div>
                </form>
              )}/>
          </div>
        </div>
      </div>
    );
  }
}

CompetitionDetail.propTypes = {
  t: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  initialValues: propTypes.competitionPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
  return {
    lang: state.app.lang,  
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    fetchNewsDependencies: bindActionCreators(fetchNewsDependencies, dispatch),   
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(CompetitionDetail);
