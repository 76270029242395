// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import Competition from "../entities/competition";

const competitionDataTransformationAfterFetch = (data) => {
  return data.map((row) => {
    return new Competition(row);
  });
};

export default competitionDataTransformationAfterFetch;
