import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import DataSourceQueryContext from '../../entities/dataSourceQueryContext';
import { nameofFactory } from '../../helpers/nameOfFactory';
import * as enums from '../../common/enums';
import { BaseDetailProps } from '../common/types/baseDetail';
import DropDown from '../common/DropDown';

const nameof = nameofFactory<DataSourceQueryContext>();

export interface DataSourceQueryContextDetailProps extends BaseDetailProps<DataSourceQueryContext>, DataSourceQueryContextDetailStateProps {
    headerText: string;
}

export interface DataSourceQueryContextDetailStateProps {
    lang: string;
}

class DataSourceQueryContextDetail extends Component<DataSourceQueryContextDetailProps> {
    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as DataSourceQueryContext);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;

        const typedValues = values as DataSourceQueryContext;
        const errors: Partial<Record<keyof DataSourceQueryContext, string>> = {};

        if (!typedValues.name) {
            errors.name = t('NameIsRequired');
        }

        if (!typedValues.connectionString) {
            errors.connectionString = t('ConnectionStringIsRequired');
        }

        return errors;
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <label className="k-form-field" htmlFor={nameof('name')}>
                                        <span>
                                            <Translation tKey="Name" />
                                        </span>
                                        <Field name={nameof('name')} component={Input} />
                                    </label>
                                    <label className="k-form-field" htmlFor={nameof('connectionString')}>
                                        <span>
                                            <Translation tKey="ConnectionString" />
                                        </span>
                                        <Field
                                            name={nameof('connectionString')}
                                            component="textarea"
                                            className="k-textarea"
                                            rows="4"
                                            style={{ width: '100%' }}
                                            // placeholder={t("Description")}
                                        />
                                    </label>

                                   {/*  <label className="k-form-field" htmlFor={nameof('typeEnumDescription')}>
                                        <span>
                                            <Translation tKey="Type" />
                                        </span>
                                        <Field
                                            name={nameof('typeEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.LINK_TYPE_TYPES}
                                        />
                                    </label> */}

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): DataSourceQueryContextDetailStateProps => {
    return {
        lang: state.app.lang,
    };
};

export default connect(mapStateToProps)(DataSourceQueryContextDetail);
