import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import Text from '../../entities/text';
import { nameofFactory } from '../../helpers/nameOfFactory';
import * as enums from '../../common/enums';
import { BaseDetailProps } from '../common/types/baseDetail';
import DropDown from '../common/DropDown';

const nameof = nameofFactory<Text>();

export interface TextDetailProps extends BaseDetailProps<Text>, TextDetailStateProps {
    headerText: string;
}

export interface TextDetailStateProps {
    lang: string;
}

class TextDetail extends Component<TextDetailProps> {
    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as Text);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;

        const typedValues = values as Text;
        const errors: Partial<Record<keyof Text, string>> = {};

        if (!typedValues.key) {
            errors.key = t('KeyIsRequired');
        }

        if (!typedValues.value) {
            errors.value = t('ValueIsRequired');
        }

        return errors;
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <label className="k-form-field" htmlFor={nameof('key')}>
                                        <span>
                                            <Translation tKey="Key" />
                                        </span>
                                        <Field name={nameof('key')} component={Input} />
                                    </label>
                                    <label className="k-form-field" htmlFor={nameof('value')}>
                                        <span>
                                            <Translation tKey="Value" />
                                        </span>
                                        <Field
                                            name={nameof('value')} 
                                            component="textarea"
                                            className="k-textarea"
                                            rows="4"
                                            style={{ width: '100%' }}
                                            // placeholder={t("Description")}
                                        />
                                    </label>

                                    <label className="k-form-field" htmlFor={nameof('languageEnumDescription')}>
                                        <span>
                                            <Translation tKey="Language" />
                                        </span>
                                        <Field
                                            name={nameof('languageEnumDescription')}
                                            component={DropDown}
                                            textField="text"
                                            dataSource={enums.LANGUAGE_TYPES}
                                        />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): TextDetailStateProps => {
    return {
        lang: state.app.lang,
    };
};

export default connect(mapStateToProps)(TextDetail);
