import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import PushNotificationConfigurationDetail from './pushNotificationConfigurationDetail';
import { createPushNotificationConfiguration } from '../../actions/pushNotificationConfigurationActions';
import { navigateToPushNotificationConfigurations } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import PushNotificationConfiguration from '../../entities/pushNotificationConfiguration';
import { PUSH_NOTIFICATION_CHANNEL_TYPE_ENUM } from '../../common/enums';

const initialValues = new PushNotificationConfiguration({ channelType: PUSH_NOTIFICATION_CHANNEL_TYPE_ENUM.Default });

interface PushNotificationConfigurationDetailCreateProps extends BaseDetailProps<PushNotificationConfiguration> {}

const PushNotificationConfigurationDetailCreate = ({ submitAction, cancelAction, t }: PushNotificationConfigurationDetailCreateProps) => {
    return (
        <PushNotificationConfigurationDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('PushNotificationConfigurationCreateLabel')}
            cancelAction={cancelAction}
            t={t}
        />
    );
};

const mapDispatchToProps = (
    dispatch: Dispatch<PushNotificationConfigurationDetailCreateProps>
): BaseDetailDispatchProps<PushNotificationConfiguration> => {
    return {
        submitAction: bindActionCreators(createPushNotificationConfiguration, dispatch),
        cancelAction: bindActionCreators(navigateToPushNotificationConfigurations, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(PushNotificationConfigurationDetailCreate);
