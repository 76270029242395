// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import AffiliateProgramPartner from '../entities/affiliateProgramPartner';

const affiliateProgramPartnerDataTransformationAfterFetch = (data: any) => {
    return data.map((row: any) => {
        return new AffiliateProgramPartner(row);
    });
};

export default affiliateProgramPartnerDataTransformationAfterFetch;
