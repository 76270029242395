// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import Reward from "../entities/reward";

const rewardDataTransformationAfterFetch = (data) => {
  return data.map((row) => {
    return new Reward(row);
  });
};

export default rewardDataTransformationAfterFetch;
