import React from "react";

const LoadingIndicator = () => {
  return (
    <div className="loading">
      <div className="indicator" />
    </div>
  );
};

export default LoadingIndicator;
