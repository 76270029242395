import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import EventTriggerConfigurationDetail from './eventTriggerConfigurationDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updateEventTriggerConfiguration } from '../../actions/eventTriggerConfigurationActions';
import { navigateToEventTriggerConfigurations } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import EventTriggerConfiguration from '../../entities/eventTriggerConfiguration';

interface EventTriggerConfigurationDetailUpdateProps extends BaseDetailProps<EventTriggerConfiguration> {}

const EventTriggerConfigurationDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: EventTriggerConfigurationDetailUpdateProps) => {
    return <EventTriggerConfigurationDetail initialValues={initialValues} submitAction={submitAction} headerText={t('EventTriggerConfigurationUpdateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<EventTriggerConfigurationDetailUpdateProps>): BaseDetailDispatchProps<EventTriggerConfiguration> => {
    return {
        submitAction: bindActionCreators(updateEventTriggerConfiguration, dispatch),
        cancelAction: bindActionCreators(navigateToEventTriggerConfigurations, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(EventTriggerConfigurationDetailUpdate);
