import React from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import StringCell from '../common/formatters/StringCell';
import NavigableCell from '../common/formatters/navigableCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { AFFILIATE_PROGRAM_PARTNER } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import { NAVIGATION_PATHS, TEXT_HORIZONTAL_ALIGN } from '../../common/constants';
import AffiliateProgramPartnersActionsBar from './affiliateProgramPartnersActionsBar';
import { nameofFactory } from '../../helpers/nameOfFactory';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';
import { BaseListDispatchProps, BaseListProps } from '../common/types/baseDetail';

const nameof = nameofFactory<AffiliateProgramPartner>();

const defaultRewardTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

const actionUrl = 'affiliateProgramPartner';

interface AffiliateProgramPartnersProp extends BaseListProps {}

const AffiliateProgramPartners = ({ t, navigateTo }: AffiliateProgramPartnersProp) => {
    let table: any;

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.AFFILIATE_PROGRAM_PARTNERS_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    const onClone = () => {
        table.getWrappedInstance().cloneSelected();
    };

    const customRowRender = (trElement: any) => {
        return trElement;
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="AffiliateProgramPartners" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <AffiliateProgramPartnersActionsBar onEdit={onEdit} onNew={onNew} onClone={onClone} onRemove={onRemove} />
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'affiliateProgramPartner'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={AFFILIATE_PROGRAM_PARTNER}
                            name="AffiliateProgramPartnersTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultRewardTabelSort}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('name')}
                                width="260"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Name')}
                            />
                            <Column
                                field={nameof('code')}
                                width="160"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Code')}
                            />
                            <Column
                                field={nameof('description')}
                                width="260"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Code')}
                            />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<any>): BaseListDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(AffiliateProgramPartners);
