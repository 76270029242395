import * as types from '../actions/actionTypes';
import Reward from '../entities/reward';
import { SOURCE_OF_SUBSCRIBERS_TYPES_ENUM } from '../common/enums';

const rewardInitialState = {
    userReadableProperty: 'code',
    loaded: false,
    data: [],
};

const rewardsReducer = (state = rewardInitialState, action: any) => {
    switch (action.type) {
        case types.REWARD_FETCH:
            return {
                ...state,
                data: action.data.map((reward: Reward) => {
                    return { ...reward, code: reward.code ?? '' };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default rewardsReducer;

export const isLoaded = (state: any) => {
    return state.rewards.loaded === true;
};

export const getData = (state: any) => {
    return state.rewards.data;
};

export const getDataByDataSource = (state: any, sourceOfSubscribers: SOURCE_OF_SUBSCRIBERS_TYPES_ENUM) => {
    return state.rewards.data.filter((reward: Reward) => reward.sourceOfSubscribers === sourceOfSubscribers);
};

export const getUserReadableProperty = (state: any): keyof Reward => {
    return state.rewards.userReadableProperty;
};
