import React from 'react';
import { connect, Dispatch } from 'react-redux';
import { Form, Field, FormSpy } from 'react-final-form';
import { bindActionCreators } from 'redux';

import * as enums from '../../common/enums';

import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import SubmitButton from '../common/SubmitButton';
import { nameofFactory } from '../../helpers/nameOfFactory';
import AffiliateProgramReward from '../../entities/affiliateProgramReward';
import DropDownWithValueField from '../common/DropDownWithValueField';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';
import {
    getData as getAffiliateProgramPartners,
    getUserReadableProperty as getAffiliateProgramPartnerUserReadableProperty,
} from '../../reducers/affiliateProgramPartnersReducer';
import {
    getData as getAffiliateProgramRewards,
    getUserReadableProperty as getAffiliateProgramRewardUserReadableProperty,
} from '../../reducers/affiliateProgramRewardsReducer';
import LoadingIndicator from '../common/LoadingIndicator';
import { isLoadingDependencies } from '../../reducers/affiliateProgramRewardsReducer';
import { fetchAffiliateProgramSubscriberRewardClaimDependencies } from '../../actions/affiliateProgramSubscriberRewardClaimsActions';
import AffiliateProgramSubscriberRewardClaim from '../../entities/affiliateProgramSubscriberRewardClaim';
import { BaseDetailProps } from '../common/types/baseDetail';

const nameof = nameofFactory<AffiliateProgramSubscriberRewardClaim>();

interface AffiliateProgramSubscriberRewardClaimDetailProps
    extends BaseDetailProps<AffiliateProgramSubscriberRewardClaim>,
        AffiliateProgramSubscriberRewardClaimDetailStateProps,
        AffiliateProgramSubscriberRewardClaimDetailDispatchProps {
    headerText: string;
}

interface AffiliateProgramSubscriberRewardClaimDetailStateProps {
    lang: string;
    affiliateProgramPartnerUserReadableProperty: string;
    affiliateProgramPartners: AffiliateProgramPartner[];
    affiliateProgramRewardUserReadableProperty: string;
    affiliateProgramRewards: AffiliateProgramReward[];
    loadingDependencies: boolean;
}

interface AffiliateProgramSubscriberRewardClaimDetailDispatchProps {
    fetchAffiliateProgramSubscriberRewardClaimDependencies: typeof fetchAffiliateProgramSubscriberRewardClaimDependencies;
}

class AffiliateProgramSubscriberRewardClaimDetail extends React.Component<AffiliateProgramSubscriberRewardClaimDetailProps> {
    componentDidMount() {
        this.props.fetchAffiliateProgramSubscriberRewardClaimDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as AffiliateProgramSubscriberRewardClaim);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;
        const typedValues = values as AffiliateProgramSubscriberRewardClaim;
        const errors: Partial<Record<keyof AffiliateProgramSubscriberRewardClaim, string>> = {};

        if (!typedValues.msisdn) {
            errors[nameof('msisdn')] = t('MsisdnIsRequired');
        }

        if (typedValues.msisdn && typedValues.msisdn.length != 12) {
            errors[nameof('msisdn')] = t('MsisdnInvalidLength');
        }

        if (typedValues.msisdn && !typedValues.msisdn.startsWith('420')) {
            errors[nameof('msisdn')] = t('MsisdnInvalidFormat');
        }

        if (!typedValues.affiliateProgramPartnerId || typedValues.affiliateProgramPartnerId <= 0) {
            errors[nameof('affiliateProgramPartnerId')] = 'Partner je povinný';
        }

        if (!typedValues.affiliateProgramRewardId || typedValues.affiliateProgramRewardId <= 0) {
            errors[nameof('affiliateProgramRewardId')] = 'Reward je povinný';
        }

        return errors;
    };

    render() {
        console.debug('AffiliateProgramSubscriberRewardClaimDetail.render');

        const { initialValues, headerText } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    {this.props.loadingDependencies && <LoadingIndicator />}
                                    <label className="k-form-field" htmlFor="rewardId">
                                        <span>
                                            <Translation tKey="Name" />
                                        </span>
                                        <Field name={nameof('msisdn')} component={Input} />
                                    </label>

                                    <label className="k-form-field" htmlFor="partnerId">
                                        <span>
                                            <Translation tKey="Partner" />
                                        </span>
                                        <Field
                                            name={nameof('affiliateProgramPartnerId')}
                                            component={DropDownWithValueField}
                                            // placeholder={t("Page")}
                                            textField={this.props.affiliateProgramPartnerUserReadableProperty}
                                            valueField="id"
                                            dataSource={this.props.affiliateProgramPartners}
                                        />
                                    </label>

                                    <label className="k-form-field" htmlFor="rewardId">
                                        <span>
                                            <Translation tKey="Reward" />
                                        </span>
                                        <FormSpy subscription={{ pristine: true }}>
                                            {(props) => (
                                                <Field
                                                    name={nameof('affiliateProgramRewardId')}
                                                    component={DropDownWithValueField}
                                                    // placeholder={t("Page")}
                                                    textField={this.props.affiliateProgramRewardUserReadableProperty}
                                                    valueField="id"
                                                    dataSource={this.props.affiliateProgramRewards.filter(
                                                        (item) => item.partnerId === props.form.getFieldState(nameof('affiliateProgramPartnerId')).value
                                                    )}
                                                />
                                            )}
                                        </FormSpy>
                                    </label>

                                    <div className="footer">
                                        <SubmitButton>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): AffiliateProgramSubscriberRewardClaimDetailStateProps => {
    return {
        lang: state.app.lang,
        loadingDependencies: isLoadingDependencies(state),
        affiliateProgramPartners: getAffiliateProgramPartners(state),
        affiliateProgramPartnerUserReadableProperty: getAffiliateProgramPartnerUserReadableProperty(state),
        affiliateProgramRewards: getAffiliateProgramRewards(state),
        affiliateProgramRewardUserReadableProperty: getAffiliateProgramRewardUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<any>): AffiliateProgramSubscriberRewardClaimDetailDispatchProps => {
    return {
        fetchAffiliateProgramSubscriberRewardClaimDependencies: bindActionCreators(fetchAffiliateProgramSubscriberRewardClaimDependencies, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(AffiliateProgramSubscriberRewardClaimDetail);
