import React, { PropsWithChildren } from 'react';
import PropTypes from 'prop-types';

import { AuthConsumer } from './authContext';
import { CAMPRO_MODULES, CAMPRO_MODULE_ENUM, getEnumItemsByFlaggedValue, isSelectedEnumFlagg } from '../../common/enums';

interface HassAccessProps {
    requestedPermittedModule: CAMPRO_MODULE_ENUM;
}

const hasAccess: React.FunctionComponent<PropsWithChildren<HassAccessProps>> = ({
    children,
    requestedPermittedModule,
}: PropsWithChildren<HassAccessProps>) => {
    return (
        <AuthConsumer>
            {({ isAdmin, permittedModules }) => {
                const permittedModulesFlags = getEnumItemsByFlaggedValue(permittedModules, CAMPRO_MODULES);
                const hasPermission = isSelectedEnumFlagg(permittedModulesFlags, requestedPermittedModule);

                // (isAdmin ? children : null)
                return hasPermission ? children : null;
            }}
        </AuthConsumer>
    );
};

export default hasAccess;
