import PushNotificationConfiguration from '../entities/pushNotificationConfiguration';

const PushNotificationConfigurationItemTransformationBeforeSave = (item: PushNotificationConfiguration) => {
    const transformedData = {
        ...item,
        channelType: item.channelTypeEnumDescription?.value,
    } as { [key: string]: any };

    return transformedData;
};

export default PushNotificationConfigurationItemTransformationBeforeSave;
