export const BEGIN_AJAX_CALL = 'BEGIN_AJAX_CALL';
export const AJAX_CALL_ERROR = 'AJAX_CALL_ERROR';
export const TOGGLE_SIDEBAR = 'TOGGLE_SIDEBAR';
export const TOGGLE_ACCOUNT_MENU = 'TOGGLE_ACCOUNT_MENU';

export const USER_CLEAR = 'user/CLEAR';
export const USER_SIGN_OUT = 'user/SIGN_OUT';
export const USER_SIGN_IN = 'user/SIGN_IN_SUCCESS';
export const USER_SING_UP = 'user/SIGN_UP_SUCCESS';
export const USER_ACTIVATE_ACCOUNT = 'user/USER_ACTIVATE_ACCOUNT_SUCCESS';
export const USER_PASSWORD_RESET = 'user/PASSWORD_RESET_SUCCESS';
export const USER_PASSWORD_SET = 'user/PASSWORD_SET_SUCCESS';
export const USER_FETCH_AGENDAS = 'user/FETCH_AGENDAS_SUCCESS';
export const USER_ADD = 'user/ADD_SUCCESS';
export const USER_FETCH = 'user/FETCH_SUCCESS';
export const USER_UPDATE = 'user/UPDATE_SUCCESS';
export const USER_LOADING_DEPENDENCIES_BEGIN = 'user/LOADING_DEPENDENCIES_BEGIN';
export const USER_LOADING_DEPENDENCIES_END = 'user/LOADING_DEPENDENCIES_END';

export const APP_RESET = 'app/RESET';
export const APP_CHANGE_LANG = 'app/CHANGE_LANG';
export const APP_AGENDA_SET = 'app/AGENDA_SET_SUCCESS';

export const ALERTS_ADD = 'alerts/ADD';
export const ALERTS_REMOVE = 'alerts/REMOVE';
export const ALERTS_CLEAR = 'alerts/CLEAR';

export const TABLE_RESET = 'table/RESET';
export const TABLE_FETCH_DATA = 'table/FETCH_DATA_SUCCESS';
export const TABLE_PAGE_CHANGE = 'table/PAGE_CHANGE';
export const TABLE_SORT_CHANGE = 'table/SORT_CHANGE';
export const TABLE_FILTER_CHANGE = 'table/FILTER_CHANGE';
export const TABLE_FILTER_CLEAR = 'table/FILTER_CLEAR';
export const TABLE_CACHE_DATA = 'table/CACHE_DATA';
export const TABLE_SELECT_ROW = 'table/SELECT_ROW';
export const TABLE_DESELECT_ALL_ROWS = 'table/DESELECT_ALL_ROWS';
export const TABLE_SELECT_ROWS = 'table/SELECT_ROWS';
export const TABLE_REMOVE_ROWS = 'table/REMOVE_ROWS';
export const TABLE_SELECT_ALL_ROWS = 'table/SELECT_ALL_ROWS';
export const TABLE_DATA_TRANSFORMATION_CHANGE = 'table/TABLE_DATA_TRANSFORMATION_CHANGE';
export const TABLE_ADD_ROW = 'table/ADD_ROW';
export const TABLE_AFTER_LOAD_DATA_ACTION = 'table/AFTER_LOAD_DATA_ACTION';

export const NEWS_ADD = 'news/ADD_SUCCESS';
export const NEWS_FETCH = 'news/FETCH_SUCCESS';
export const NEWS_UPDATE = 'news/UPDATE_SUCCESS';
export const NEWS_FOUND = 'news/FOUND_SUCCESS';
export const NEWS_LOADING_DEPENDENCIES_BEGIN = 'news/NEWS_LOADING_DEPENDENCIES_BEGIN';
export const NEWS_LOADING_DEPENDENCIES_END = 'news/NEWS_LOADING_DEPENDENCIES_END';

export const NEWS_PAGES_FETCH = 'newsPages/FETCH_SUCCESS';

export const SERVICES_FETCH = 'services/FETCH_SUCCESS';

export const NAVIGATE_TO = 'navigation/NAVIGATE_TO';

export const EXTRA_FETCH = 'extra/FETCH_SUCCESS';
export const EXTRA_UPDATE = 'extra/UPDATE_SUCCESS';
export const EXTRA_LOADING_BEGIN = 'extra/LOADING_BEGIN';
export const EXTRA_LOADING_END = 'extra/LOADING_END';

export const BETATESTER_FETCH = 'betatester/FETCH_SUCCESS';
export const BETATESTER_ADD = 'betatester/ADD_SUCCESS';
export const BETATESTER_UPDATE = 'betatester/UPDATE_SUCCESS';

export const CHARGINGCODE_FETCH = 'chargingcode/FETCH_SUCCESS';
export const CHARGINGCODE_ADD = 'chargingcode/ADD_SUCCESS';
export const CHARGINGCODE_UPDATE = 'chargingcode/UPDATE_SUCCESS';

export const REPORT_FETCH = 'report/FETCH_SUCCESS';
export const REPORT_ADD = 'report/ADD_SUCCESS';
export const REPORT_UPDATE = 'report/UPDATE_SUCCESS';

export const RATING_FETCH = 'rating/FETCH_SUCCESS';
export const RATING_UPDATE = 'rating/UPDATE_SUCCESS';

export const RATING_SETTINGS_FETCH = 'ratingSettings/FETCH_SUCCESS';
export const RATING_SETTINGS_UPDATE = 'ratingSettings/UPDATE_SUCCESS';
export const RATING_SETTINGS_LOADING_BEGIN = 'ratingSettings/LOADING_BEGIN';
export const RATING_SETTINGS_LOADING_END = 'ratingSettings/LOADING_END';

export const ECOMAIL_LOADING_BEGIN = 'ecomail/LOADING_BEGIN';
export const ECOMAIL_LOADING_END = 'ecomail/LOADING_END';

export const OUTAGE_NOTIFICATION_FETCH = 'outagenotification/FETCH_SUCCESS';
export const OUTAGE_NOTIFICATION_ADD = 'outagenotification/ADD_SUCCESS';
export const OUTAGE_NOTIFICATION_UPDATE = 'outagenotification/UPDATE_SUCCESS';

export const LOADING_SPINNER_SHOW = 'loadingSpinner/SHOW';
export const LOADING_SPINNER_HIDE = 'loadingSpinner/HIDE';

export const REWARD_FETCH = 'reward/FETCH_SUCCESS';
export const REWARD_ADD = 'reward/ADD_SUCCESS';
export const REWARD_UPDATE = 'reward/UPDATE_SUCCESS';

export const SIM_CARD_TYPE_FETCH = 'simCardType/FETCH_SUCCESS';
export const SIM_CARD_TYPE_ADD = 'simCardType/ADD_SUCCESS';
export const SIM_CARD_TYPE_UPDATE = 'simCardType/UPDATE_SUCCESS';

export const MARKETING_ACTION_FETCH = 'marketingAction/FETCH_SUCCESS';

export const AFFILIATE_PROGRAM_REWARDS_FETCH = 'affiliateprogramrewards/FETCH_SUCCESS';
export const AFFILIATE_PROGRAM_REWARDS_ADD = 'affiliateprogramrewards/ADD_SUCCESS';
export const AFFILIATE_PROGRAM_REWARDS_UPDATE = 'affiliateprogramrewards/UPDATE_SUCCESS';
export const AFFILIATE_PROGRAM_REWARDS_LOADING_DEPENDENCIES_BEGIN = 'affiliateprogramrewards/LOADING_DEPENDENCIES_BEGIN';
export const AFFILIATE_PROGRAM_REWARDS_LOADING_DEPENDENCIES_END = 'affiliateprogramrewards/LOADING_DEPENDENCIES_END';

export const AFFILIATE_PROGRAM_PARTNERS_FETCH = 'affiliateprogrampartners/FETCH_SUCCESS';
export const AFFILIATE_PROGRAM_PARTNERS_ADD = 'affiliateprogrampartners/ADD_SUCCESS';
export const AFFILIATE_PROGRAM_PARTNERS_UPDATE = 'affiliateprogrampartners/UPDATE_SUCCESS';

export const AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_FETCH = 'affiliateProgramSubscriberRewardClaims/FETCH_SUCCESS';
export const AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_ADD = 'affiliateProgramSubscriberRewardClaims/ADD_SUCCESS';
export const AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_UPDATE = 'affiliateProgramSubscriberRewardClaims/UPDATE_SUCCESS';
export const AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_LOADING_DEPENDENCIES_BEGIN =
    'affiliateProgramSubscriberRewardClaims/LOADING_DEPENDENCIES_BEGIN';
export const AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_LOADING_DEPENDENCIES_END = 'affiliateProgramSubscriberRewardClaims/LOADING_DEPENDENCIES_END';

export const ROLE_FETCH = 'role/FETCH_SUCCESS';
export const ROLE_ADD = 'role/ADD_SUCCESS';
export const ROLE_UPDATE = 'role/UPDATE_SUCCESS';

export const GAME_CLAN_FETCH = 'gameClan/FETCH_SUCCESS';
export const GAME_CLAN_UPDATE = 'gameClan/UPDATE_SUCCESS';

export const TEXT_FETCH = 'text/FETCH_SUCCESS';
export const TEXT_ADD = 'text/ADD_SUCCESS';
export const TEXT_UPDATE = 'text/UPDATE_SUCCESS';

export const LINK_FETCH = 'link/FETCH_SUCCESS';
export const LINK_ADD = 'link/ADD_SUCCESS';
export const LINK_UPDATE = 'link/UPDATE_SUCCESS';

export const DATASOURCE_FETCH = 'datasource/FETCH_SUCCESS';
export const DATASOURCE_ADD = 'datasource/ADD_SUCCESS';
export const DATASOURCE_UPDATE = 'datasource/UPDATE_SUCCESS';

export const DATASOURCEQUERYCONTEXT_FETCH = 'datasourcequerycontext/FETCH_SUCCESS';
export const DATASOURCEQUERYCONTEXT_ADD = 'datasourcequerycontext/ADD_SUCCESS';
export const DATASOURCEQUERYCONTEXT_UPDATE = 'datasourcequerycontext/UPDATE_SUCCESS';


export const PUSHNOTIFICATIONCONFIGURATION_FETCH = 'pushnotificationconfiguration/FETCH_SUCCESS';
export const PUSHNOTIFICATIONCONFIGURATION_ADD = 'pushnotificationconfiguration/ADD_SUCCESS';
export const PUSHNOTIFICATIONCONFIGURATION_UPDATE = 'pushnotificationconfiguration/UPDATE_SUCCESS';


export const PUSHNOTIFICATIONTASK_FETCH = 'pushnotificationtask/FETCH_SUCCESS';
export const PUSHNOTIFICATIONTASK_ADD = 'pushnotificationtask/ADD_SUCCESS';
export const PUSHNOTIFICATIONTASK_UPDATE = 'pushnotificationtask/UPDATE_SUCCESS';


export const EVENTTRIGGERCONFIGURATION_FETCH = 'eventtriggerconfiguration/FETCH_SUCCESS';
export const EVENTTRIGGERCONFIGURATION_ADD = 'eventtriggerconfiguration/ADD_SUCCESS';
export const EVENTTRIGGERCONFIGURATION_UPDATE = 'eventtriggerconfiguration/UPDATE_SUCCESS';


/*entityActionsType*/
