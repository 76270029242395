import React from 'react';

import WhenFieldChangesListener from "./WhenFieldChangesListener";
import PropTypes from "prop-types";

const WhenFieldChanges = ({field, becomes, set, to}) => {
  if (set && Array.isArray(set)) {
    return set.map((setItem) => <WhenFieldChangesListener field={field} becomes={becomes} set={setItem} to={to}/>);
  }

  return <WhenFieldChangesListener field={field} becomes={becomes} set={set} to={to}/>;
};

WhenFieldChanges.propTypes = {
  field: PropTypes.string.isRequired,
  becomes: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
  set: PropTypes.arrayOf(PropTypes.string).isRequired,
  to: PropTypes.oneOfType([PropTypes.number, PropTypes.string, PropTypes.bool]).isRequired,
};

export default  WhenFieldChanges;
