import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';
import { Dispatch, bindActionCreators } from 'redux';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import EventTriggerConfiguration from '../../entities/eventTriggerConfiguration';
import { nameofFactory } from '../../helpers/nameOfFactory';
import * as enums from '../../common/enums';
import { BaseDetailProps } from '../common/types/baseDetail';
import DropDown from '../common/DropDown';

import {
    getData as getPushNotificationConfigurations,
    getUserReadableProperty as getPushNotificationConfigurationUserReadableProperty,
} from '../../reducers/pushNotificationConfigurationReducer';
import PushNotificationConfiguration from '../../entities/pushNotificationConfiguration';
import DatePicker from '../common/DatePicker';
import DropDownWithValueField from '../common/DropDownWithValueField';
import { fetchEventTriggerConfigurationDependencies } from '../../actions/eventTriggerConfigurationActions';
import Switch from '../common/Switch';

const nameof = nameofFactory<EventTriggerConfiguration>();

export interface EventTriggerConfigurationDetailProps
    extends BaseDetailProps<EventTriggerConfiguration>,
        EventTriggerConfigurationDetailStateProps,
        EventTriggerConfigurationDetailDispatchProps {
    headerText: string;
}

export interface EventTriggerConfigurationDetailStateProps {
    lang: string;
    pushNotificationConfigurations: PushNotificationConfiguration[];
    pushNotificationConfigurationUserReadableProperty: string;
}

export interface EventTriggerConfigurationDetailDispatchProps {
    fetchEventTriggerConfigurationDependencies: () => void;
}

class EventTriggerConfigurationDetail extends Component<EventTriggerConfigurationDetailProps> {
    componentDidMount() {
        this.props.fetchEventTriggerConfigurationDependencies();
    }

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as EventTriggerConfiguration);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;

        const typedValues = values as EventTriggerConfiguration;
        const errors: Partial<Record<keyof EventTriggerConfiguration, string>> = {};

        if (!typedValues.name) {
            errors.name = t('NameIsRequired');
        }

        /*  if (!typedValues.value) {
            errors.value = t('ValueIsRequired');
        } */

        return errors;
    };

    render() {
        const { initialValues, headerText, cancelAction } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <label className="k-form-field" htmlFor={nameof('name')}>
                                        <span>
                                            <Translation tKey="Key" />
                                        </span>
                                        <Field name={nameof('name')} component={Input} />
                                    </label>

                                    <div className={'row'}>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('validFromUtc')}>
                                            <span>
                                                <Translation tKey="ValidFrom" />
                                            </span>
                                            <Field
                                                name={nameof('validFromUtc')}
                                                component={DatePicker}
                                                // placeholder={t("validFrom")}
                                            />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('validToUtc')}>
                                            <span>
                                                <Translation tKey="ValidTo" />
                                            </span>
                                            <Field
                                                name={nameof('validToUtc')}
                                                component={DatePicker}
                                                // placeholder={t("ValidTo")}
                                            />
                                        </label>
                                    </div>

                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('pushNotificationConfigurationId')}>
                                            <span>
                                                <Translation tKey="PushNotificationConfiguration" />
                                            </span>
                                            <Field
                                                name={nameof('pushNotificationConfigurationId')}
                                                component={DropDownWithValueField}
                                                textField={this.props.pushNotificationConfigurationUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.pushNotificationConfigurations}
                                            />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('sourceEnumDescription')}>
                                            <span>
                                                <Translation tKey="Source" />
                                            </span>
                                            <Field
                                                name={nameof('sourceEnumDescription')}
                                                component={DropDown}
                                                textField="text"
                                                dataSource={enums.EVENT_TRIGGER_CONFIGURATION_SOURCE_TYPES}
                                            />
                                        </label>
                                    </div>

                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('onlyForBetaTesters')}>
                                            <span>
                                                <Translation tKey="OnlyForBetaTesters" />
                                            </span>
                                            <Field name={nameof('onlyForBetaTesters')} component={Switch} />
                                        </label>
                                    </div>

                                    <div className="footer">
                                        <SubmitButton loading={form.getState().submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): EventTriggerConfigurationDetailStateProps => {
    return {
        lang: state.app.lang,
        pushNotificationConfigurations: getPushNotificationConfigurations(state),
        pushNotificationConfigurationUserReadableProperty: getPushNotificationConfigurationUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<EventTriggerConfigurationDetailProps>): EventTriggerConfigurationDetailDispatchProps => {
    return {
        fetchEventTriggerConfigurationDependencies: bindActionCreators(fetchEventTriggerConfigurationDependencies, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(EventTriggerConfigurationDetail);
