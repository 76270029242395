import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import { reportDetailPropTypes } from "../../common/propTypes";
import OutageNotificationDetail from './outageNotificationDetail';
import {getRowById} from "../../reducers/tableReducer";
import {navigateToOutageNotifications} from "../../actions/navigationActions";
import { updateOutageNotification } from '../../actions/outageNotificationActions';

const OutageNotificationDetailUpdate = ({initialValues, saveAction, cancelAction, t}) => {
  return <OutageNotificationDetail initialValues={initialValues} onSubmit={saveAction} headerText={t('OutageNotificationLabel')} onCancel={cancelAction}/>;
};

OutageNotificationDetailUpdate.propTypes = {
  initialValues: reportDetailPropTypes.isRequired,
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;

  return {
    lang: state.app.lang,
    initialValues: getRowById(state, id)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAction: bindActionCreators(updateOutageNotification, dispatch),
    cancelAction: bindActionCreators(navigateToOutageNotifications, dispatch)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(OutageNotificationDetailUpdate);

