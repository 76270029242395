import { EnumDescription, LANGUAGE_TYPES, LANGUAGE_ENUM, LINK_TYPE_TYPES, LINK_TYPE_ENUM, getEnumItemByValue } from '../common/enums';

export default class DataSourceQueryContext {
    id: number;
    name: string;
    connectionString: string;

    constructor(properties: Partial<DataSourceQueryContext>) {
        this.id = 0;
        this.name = '';
        this.connectionString = '';

        Object.assign(this, properties);
    }
}
