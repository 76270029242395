import * as types from '../actions/actionTypes';

const newsPagesInitialState = {
  userReadableProperty: "description",
  loaded: false,
  data: [],
};

const newsPages = (state = newsPagesInitialState, action) => {
  switch (action.type) {
    case types.NEWS_PAGES_FETCH:
      return {
        ...state,
        data: action.data,
        loaded: true,
      };
    default:
      return state;
  }
};

export default newsPages;

export const isLoaded = (state) => {
  return state.newsPages.loaded === true;
};

export const getData = (state) => {
  return state.newsPages.data;
};

export const getUserReadableProperty = (state) => {
  return state.newsPages.userReadableProperty;
};