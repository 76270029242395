import * as types from '../actions/actionTypes';

const ecomailInitialState= {
  loaded: false,
  data: null,
};

const ecomail = (state = ecomailInitialState, action) => {
  switch (action.type) {    
    case types.ECOMAIL_LOADING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case types.ECOMAIL_LOADING_END:
      return {
        ...state,       
        loading: false,
      };
    default:
      return state;
  }
};

export default ecomail;

export const isLoading = (state) => {
  return state.ecomail.loading === true;
};
