import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { translate } from "react-i18next";

import NewsDetail from "./NewsDetail";
import { createNews } from "../../actions/newsActions";
import * as enums from "../../common/enums";
import { navigateToNews } from "../../actions/navigationActions";

const initialValues = {
  name: "",
  validFrom: new Date(),
  validTo: null,
  description: "",
  type: enums.NEWS_TYPES[0],
  published: false,
  platform: enums.PLATFORMA_TYPES[0],
  urlOpenExternal: false,
  smsRecipient: "",
  smsBody: "",
  order: 0,
  navigateDirectToPage: false,
  isCampaign: false,
  dateOfCampaignStart: new Date(),
  isTargetedCampaign: false,
};

const NewsDetailCreate = ({ createNews, cancelAction, t }) => {
  return (
    <NewsDetail
      initialValues={initialValues}
      onSubmit={createNews}
      headerText={t("CreateNews")}
      onCancel={cancelAction}
    />
  );
};

NewsDetailCreate.propTypes = {
  createNews: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    createNews: bindActionCreators(createNews, dispatch),
    cancelAction: bindActionCreators(navigateToNews, dispatch)
  };
};

export default compose(
  connect(
    undefined,
    mapDispatchToProps
  ),
  translate("common")
)(NewsDetailCreate);
