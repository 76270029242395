import React from "react";

import ToggableActionButton from "../common/ToggableActionButton";
import { Rating as RatingComponent } from "../common/Rating";
import ActionButton from "../common/ActionButton";
import {
  RATING_VARIANT_DEFAULT_ENUM_VALUE,
  RATING_VARIANT_WITHOUT_STARS_ENUM_VALUE,
} from "../../common/enums";

export default class RatingActionsBar extends React.Component {
  constructor() {
    super();

    this.state = {
      filters: {},
    };
  }

  onShow3AndLess = () => {
    const filters = [];
    filters[0] = {
      field: "value",
      operator: "lte",
      value: 3,
      name: "show3AndLess",
      logicalOperator: 0,
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, show3AndLess: true },
    }));
  };

  onRemove3AndLess = () => {
    const filters = [];
    filters[0] = {
      remove: true,
      name: "show3AndLess",
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, show3AndLess: false },
    }));
  };

  onShow4AndMore = () => {
    const filters = [];
    filters[0] = {
      field: "value",
      operator: "gte",
      value: 4,
      logicalOperator: 0,
      name: "show4AndMore",
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, show4AndMore: true },
    }));
  };

  onRemove4AndMore = () => {
    const filters = [];
    filters[0] = {
      remove: true,
      name: "show4AndMore",
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, show4AndMore: false },
    }));
  };

  onShowWithDescription = () => {
    const filters = [];
    filters[0] = {
      field: "description",
      operator: "neq",
      value: null,
      logicalOperator: 0,
      name: "showWithDescriptionWhenNotNull",
    };
    filters[1] = {
      field: "description",
      operator: "neq",
      value: "",
      logicalOperator: 0,
      name: "showWithDescriptionWhenNotStringEmpty",
    };

    this.props.filter(filters);
  };

  onShowVariantWithoutStars = () => {
    const filters = [];
    filters[0] = {
      field: "variant",
      operator: "eq",
      value: RATING_VARIANT_WITHOUT_STARS_ENUM_VALUE,
      logicalOperator: 0,
      name: "showVariantWithoutStars",
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, showVariantWithoutStars: true },
    }));
  };

  onRemoveVariantWithoutStars = () => {
    const filters = [];
    filters[0] = {
      remove: true,
      name: "showVariantWithoutStars",
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, showVariantWithoutStars: false },
    }));
  };

  onShowDefaultVariant = () => {
    const filters = [];
    filters[0] = {
      field: "variant",
      operator: "eq",
      value: RATING_VARIANT_DEFAULT_ENUM_VALUE,
      logicalOperator: 0,
      name: "showDefaultVariant",
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, showDefaultVariant: true },
    }));
  };

  onRemoveDefaultVariant = () => {
    const filters = [];
    filters[0] = {
      remove: true,
      name: "showDefaultVariant",
    };

    this.props.filter(filters);
    this.setState((state, props) => ({
      filters: { ...state.filters, showDefaultVariant: false },
    }));
  };

  onRemoveWithDescription = () => {
    const filters = [];
    filters[0] = {
      remove: true,
      name: "showWithDescriptionWhenNotNull",
    };
    filters[1] = {
      remove: true,
      name: "showWithDescriptionWhenNotStringEmpty",
    };

    this.props.filter(filters);
  };

  isFilterActive = (filterName) => {
    return !!this.state.filters[filterName];
  };

  render() {
    return (
      <div className="actions">
        <ActionButton
          text="Edit"
          onClick={this.props.edit}
          icon="pencil"
          selection="one"
        />
        <ToggableActionButton
          disabled={
            this.isFilterActive("show4AndMore") ||
            this.isFilterActive("showVariantWithoutStars")
          }
          text="RatingShow3AndLess"
          onSelected={this.onShow3AndLess}
          onNotSelected={this.onRemove3AndLess}
        >
          <RatingComponent value={3} readonly />
        </ToggableActionButton>
        <ToggableActionButton
          disabled={
            this.isFilterActive("show3AndLess") ||
            this.isFilterActive("showVariantWithoutStars")
          }
          text="RatingShow4AndMore"
          onSelected={this.onShow4AndMore}
          onNotSelected={this.onRemove4AndMore}
        >
          <RatingComponent value={5} readonly />
        </ToggableActionButton>
        <ToggableActionButton
          disabled={this.isFilterActive("showVariantWithoutStars")}
          text="DefaultVariant"
          onSelected={this.onShowDefaultVariant}
          onNotSelected={this.onRemoveDefaultVariant}
          icon="filter"
        />
        <ToggableActionButton
          disabled={
            this.isFilterActive("show4AndMore") ||
            this.isFilterActive("show3AndLess") ||
            this.isFilterActive("showDefaultVariant")
          }
          text="VariantWithoutStars"
          onSelected={this.onShowVariantWithoutStars}
          onNotSelected={this.onRemoveVariantWithoutStars}
          icon="filter"
        />
      </div>
    );
  }
}
