import { CAMPRO_MODULES, getEnumItemsByFlaggedValue } from '../common/enums';

export default class User {
    id: number;
    firstname: string;
    lastname: string;
    email: string;
    isAdmin: boolean;
    roleId: number;

    constructor(properties: Partial<User>) {
        this.id = 0;
        this.firstname = '';
        this.lastname = '';
        this.email = '';
        this.isAdmin = false;
        this.roleId = 0;

        Object.assign(this, properties);
    }
}
