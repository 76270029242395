import React from 'react';
import { Field } from 'react-final-form';

import * as enums from '../../common/enums';
import Translation from '../common/Translation';
import Input from '../common/Input';
import DropDown from '../common/DropDown';
import Reward from '../../entities/reward';
import Textarea from '../common/Textarea';
import DateTimePicker from '../common/DateTimePicker';
import Dropzone from '../common/DropZone';
import Switch from '../common/Switch';
import Condition from '../common/utils/Condition';
import { RewardDetailPanelProps } from './type';
import DropDownWithValueField from '../common/DropDownWithValueField';

export default class RewardDetailStandardPanel extends React.Component<RewardDetailPanelProps> {
    componentDidMount() {}

    render() {
        console.debug('RewardDetailStandardPanel.render');
        const nameof = this.props.nameof;

        return (
            <React.Fragment>
                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('name')}>
                        <span>
                            <Translation tKey="Name" />
                        </span>
                        <Field name={nameof('name')} component={Input} />
                    </label>
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('campaignCode')}>
                        <span>
                            <Translation tKey="CampaignCode" />
                        </span>
                        <Field name={nameof('campaignCode')} component={Input} />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('productCode')}>
                        <span>
                            <Translation tKey="ProductCode" />
                        </span>
                        <Field name={nameof('productCode')} component={Input} />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('extId')}>
                        <span>
                            <Translation tKey="ExternalId" />
                        </span>
                        <Field name={nameof('extId')} component={Input} type="number" />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('platform')}>
                        <span>
                            <Translation tKey="Platform" />
                        </span>
                        <Field name={nameof('platform')} component={DropDown} textField="text" dataSource={enums.PLATFORMA_TYPES} />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('sourceOfSubscribersEnumDescription')}>
                        <span>
                            <Translation tKey="SourceOfSubscribers" />
                        </span>
                        <Field
                            name={nameof('sourceOfSubscribersEnumDescription')}
                            component={DropDown}
                            textField="text"
                            dataSource={enums.SOURCE_OF_SUBSCRIBERS_TYPES}
                        />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('type')}>
                        <span>
                            <Translation tKey="Type" />
                        </span>
                        <Field name={nameof('type')} component={DropDown} textField="text" dataSource={enums.REWARD_TYPES} />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('numberOfPeriods')}>
                        <span>
                            <Translation tKey="NumberOfPeriods" />
                        </span>
                        <Field name={nameof('numberOfPeriods')} component={Input} type="number" />
                    </label>
                </div>

                <Condition when={nameof('sourceOfSubscribers')} is={this.props.showIsDigiCondition}>
                    <div className="row">
                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('isDigi')}>
                            <span>
                                <Translation tKey="PayByCreditCard" />
                            </span>
                            <Field
                                name={nameof('isDigi')}
                                component={Switch}
                                // placeholder={t("Name")}
                            />
                        </label>
                        <Condition when={nameof('isDigi')} is={true}>
                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('priceByCreditCard')}>
                                <span>
                                    <Translation tKey="PriceByCreditCard" />
                                </span>
                                <Field name={nameof('priceByCreditCard')} component={Input} type="number" />
                            </label>
                        </Condition>
                    </div>
                </Condition>

                <Condition when={nameof('sourceOfSubscribers')} is={this.props.showIsO2VopeCondition}>
                    <div className="row">
                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('usePriceWhenO2PriceIsNull')}>
                            <span>
                                <Translation tKey="UsePriceWhenO2PriceIsNull" />
                            </span>
                            <Field name={nameof('usePriceWhenO2PriceIsNull')} component={Switch} />
                        </label>
                        <Condition when={nameof('usePriceWhenO2PriceIsNull')} is={true}>
                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('price')}>
                                <span>
                                    <Translation tKey="Price" />
                                </span>
                                <Field name={nameof('price')} component={Input} type="number" />
                            </label>
                        </Condition>
                    </div>
                </Condition>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('availableFrom')}>
                        <span>
                            <Translation tKey="AvailableFrom" />
                        </span>
                        <Field name={nameof('availableFrom')} component={DateTimePicker} />
                    </label>
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('availableTo')}>
                        <span>
                            <Translation tKey="AvailableTo" />
                        </span>
                        <Field name={nameof('availableTo')} component={DateTimePicker} />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('takeValidityFromClaim')}>
                        <Condition when="sourceOfSubscribers" is={this.props.showIsGameOrAffiliateProgramCondition}>
                            <span>
                                <Translation tKey="TakeValidityFromClaim" />
                            </span>
                            <Field name={nameof('takeValidityFromClaim')} component={Switch} />
                        </Condition>
                    </label>
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('isAllowedMultipleUse')}>
                        <span>
                            <Translation tKey="IsAllowedMultipleUse" />
                        </span>
                        <Field name={nameof('isAllowedMultipleUse')} component={Switch} />
                    </label>
                </div>

                <Condition when={nameof('sourceOfSubscribersEnumDescription')} is={this.props.showIsDataSourceCondition}>
                    <label className="k-form-field" htmlFor="dataSourcedId">
                        <span>
                            <Translation tKey="DataSource" />
                        </span>
                        <Field
                            name={nameof('dataSourceId')}
                            component={DropDownWithValueField}
                            textField={this.props.dataSourceUserReadableProperty}
                            valueField="id"
                            dataSource={this.props.dataSources}
                        />
                    </label>
                </Condition>

                <label className="k-form-field" htmlFor="followingRewardId">
                    <span>
                        <Translation tKey="FollowingReward" />
                    </span>
                    <Field
                        name={nameof('followingRewardId')}
                        component={DropDownWithValueField}
                        textField={this.props.followingRewardUserReadableProperty}
                        valueField="id"
                        dataSource={this.props.rewards}
                    />
                </label>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('useInServicePriceCalculator')}>
                        <span>
                            <Translation tKey="UseInServicePriceCalculator" />
                        </span>
                        <Field name={nameof('useInServicePriceCalculator')} component={Switch} />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('takeOverOnlyPriceSummary')}>
                        <span>
                            <Translation tKey="TakeOverOnlyPriceSummary" />
                        </span>
                        <Field name={nameof('takeOverOnlyPriceSummary')} component={Switch} />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('useWhenProductIsActivated')}>
                        <span>
                            <Translation tKey="UseWhenProductIsActivated" />
                        </span>
                        <Field name={nameof('useWhenProductIsActivated')} component={Switch} />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('textLine1')}>
                        <span>
                            <Translation tKey="TextLine1" />
                        </span>
                        <Field name={nameof('textLine1')} component={Input} />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('textLine2')}>
                        <span>
                            <Translation tKey="TextLine2" />
                        </span>
                        <Field name={nameof('textLine2')} component={Input} />
                    </label>
                </div>

                <div className="k-form-field">
                    <span>
                        <Translation tKey="DetailName" />
                    </span>
                    <Field name={nameof('detailName')} component={Input} />
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('detailShortDescription')}>
                        <span>
                            <Translation tKey="DetailShortDescription" />
                        </span>
                        <Field name={nameof('detailShortDescription')} component={Input} />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('buyFromCreditButtonText')}>
                        <span>
                            <Translation tKey="BuyFromCreditButtonText" />
                        </span>
                        <Field name={nameof('buyFromCreditButtonText')} component={Input} />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('buyFromCreditCardButtonText')}>
                        <span>
                            <Translation tKey="BuyFromCreditCardButtonText" />
                        </span>
                        <Field name={nameof('buyFromCreditCardButtonText')} component={Input} />
                    </label>
                </div>

                <div className="k-form-field">
                    <span>
                        <Translation tKey="DetailPicture" />
                    </span>
                    <Field name={nameof('detailImage')} component={Dropzone} recommendedResolution="3240x2580" imageUrl={nameof('detailImageUrl')} />
                </div>

                <label className="k-form-field" htmlFor={nameof('description')}>
                    <span>
                        <Translation tKey="Description" />
                    </span>
                    <Field name={nameof('description')} component={Textarea} className="k-textarea" rows="8" style={{ width: '100%' }} />
                </label>

                <label className="k-form-field" htmlFor={nameof('code')}>
                    <span>
                        <Translation tKey="Code" />
                    </span>
                    <Field name={nameof('code')} component={Input} />
                </label>
            </React.Fragment>
        );
    }
}
