import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import TextDetail from './textDetail';
import { createText } from '../../actions/textActions';
import { navigateToTexts } from '../../actions/navigationActions';
import Text from '../../entities/text';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import { LANGUAGE_ENUM } from '../../common/enums';

const initialValues = new Text({ language: LANGUAGE_ENUM.CZ });

interface TextDetailCreateProps extends BaseDetailProps<Text> {}

const TextDetailCreate = ({ submitAction, cancelAction, t }: TextDetailCreateProps) => {
    return (
        <TextDetail initialValues={initialValues} submitAction={submitAction} headerText={t('TextCreateLabel')} cancelAction={cancelAction} t={t} />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<TextDetailCreateProps>): BaseDetailDispatchProps<Text> => {
    return {
        submitAction: bindActionCreators(createText, dispatch),
        cancelAction: bindActionCreators(navigateToTexts, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(TextDetailCreate);
