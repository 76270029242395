import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as sideBarActions from '../../actions/sideBarActions';
import * as accountMenuActions from '../../actions/accountMenuActions';
import AccountMenu from './AccountMenu';

const Header = ({ open, actions }) => {
  let headerMenu = "header-menu";
  if (open)
    headerMenu += " sidebar-open";

  return (
    <div className="header">
      <div className={headerMenu}>
        <div className="header-tile sidebar-btn">
          <a href="/" onClick={(event) => actions.toggleSidebar(event)} className="icon icon-menu">&nbsp;</a>
        </div>
      </div>
      <div className="header-right-controls">
        <AccountMenu />
      </div>
    </div>
  );
};

Header.propTypes = {
  actions: PropTypes.shape({
    toggleSidebar: PropTypes.func.isRequired,
  }),
  open: PropTypes.bool,
  //lang: PropTypes.string
};

const mapStateToProps = (state) => {
  return {
    open: state.sideBar.open,
    //lang: state.app.lang
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({ ...sideBarActions, ...accountMenuActions }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Header);
