import {
    EnumDescription,
    LANGUAGE_TYPES,
    LANGUAGE_ENUM,
    LINK_TYPE_TYPES,
    LINK_TYPE_ENUM,
    getEnumItemByValue,
    EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM,
    EVENT_TRIGGER_CONFIGURATION_SOURCE_TYPES,
} from '../common/enums';

export default class EventTriggerConfiguration {
    id: number;
    name: string;
    pushNotificationConfigurationId: number;
    validFromUtc?: Date;
    validToUtc?: Date;
    source: number;
    sourceEnumDescription: EnumDescription | undefined;
    onlyForBetaTesters: boolean;

    constructor(properties: Partial<EventTriggerConfiguration> & Pick<EventTriggerConfiguration, 'source'>) {
        this.id = 0;
        this.name = '';
        this.pushNotificationConfigurationId = 0;
        this.source = EVENT_TRIGGER_CONFIGURATION_SOURCE_ENUM.NewVoucherClaim;
        this.onlyForBetaTesters = true;
        
        /*  this.query = '';
        this.queryContextId = 0; 
        this.type = DATASOURCE_TYPE_ENUM.Query;
        this.queryType = DATASOURCE_QUERY_TYPE_ENUM.OneRecord;
        this.platform = PLATFORMA_TYPES_ENUM.ALL; */

        Object.assign(this, properties);

        this.sourceEnumDescription = getEnumItemByValue(properties.source, EVENT_TRIGGER_CONFIGURATION_SOURCE_TYPES);
        this.validFromUtc = properties.validFromUtc ? new Date(properties.validFromUtc) : properties.validFromUtc;
        this.validToUtc = properties.validToUtc ? new Date(properties.validToUtc) : properties.validToUtc;
    }
}
