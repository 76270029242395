import {
  getEnumItemByValue,
  COMPETITION_TARGET_CUSTOMER_TYPES,
  PLATFORMA_TYPES
} from "../common/enums";

export default class Competition {
  id;
  name;
  description;
  noVipText;
  url;
  validFrom;
  validTo;
  targetCustomer;
  actionButtonText;
  image;
  published;
  platform;
  urlOpenExternal;
  imageOverlayText;

  constructor({
    id,
    name,
    description,
    noVipText,
    url,
    validFrom,
    validTo,
    targetCustomer,
    actionButtonText,
    imageUrl,
    published,
    platform,
    urlOpenExternal,
    imageOverlayText
  }) {
    this.id = id;
    this.name = name;
    this.description = description;
    this.noVipText = noVipText;
    this.url = url;
    this.urlOpenExternal = urlOpenExternal;
    this.actionButtonText = actionButtonText;
    this.validFrom = validFrom ? new Date(validFrom) : validFrom;
    this.validTo = validTo ? new Date(validTo) : validTo;
    this.targetCustomer = getEnumItemByValue(
      targetCustomer,
      COMPETITION_TARGET_CUSTOMER_TYPES
    );
    this.imageUrl = imageUrl;
    this.published = published;
    this.platform = getEnumItemByValue(platform, PLATFORMA_TYPES);
    this.imageOverlayText = imageOverlayText;
  }
}
