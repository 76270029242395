import * as types from '../actions/actionTypes';

const initialState = {
  lang: 'cs',
};


const app = (state = initialState, action) => {
  switch (action.type) {
    case types.APP_CHANGE_LANG:
      return {
        ...state,
        lang: action.lang
      };
  }

  return state;
};

export default app;
