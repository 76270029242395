import React from 'react';

import ToggableActionButton from '../common/ToggableActionButton';
import ActionButton from '../common/ActionButton';

export default class GameClansActionsBar extends React.Component {
    constructor() {
        super();

        this.state = {
            filters: {},
        };
    }

    onShowOnlyBlocked = () => {
        const filters = [];
        filters[0] = {
            field: 'isBlocked',
            operator: 'eq',
            value: true,
            logicalOperator: 0,
            name: 'showOnlyBlocked',
        };

        this.props.filter(filters);
    };

    onRemoveShowOnlyBlocked = () => {
      const filters = [];
      filters[0] = {
        remove: true,
        name: "showOnlyBlocked",
      };
  
      this.props.filter(filters);
    };

    isFilterActive = (filterName) => {
        return !!this.state.filters[filterName];
    };

    renderActionButtonText = (selectedRow) => {
        return selectedRow.isBlocked ? 'GameClanUnblock' : 'GameClanBlock';
    };

    render() {
        return (
            <div className="actions">
                {/* <ActionButton
                    text="Edit"
                    onClick={this.props.changeBlockedState}
                    icon="pencil"
                    selection="one"
                    customTextRender={this.renderActionButtonText}
                /> */}
                <ToggableActionButton
                    disabled={this.isFilterActive('showOnlyBlocked')}
                    text="OnlyBlockedGameClans"
                    onSelected={this.onShowOnlyBlocked}
                    onNotSelected={this.onRemoveShowOnlyBlocked}
                    icon="filter"
                />
                {/* <ToggableActionButton
          disabled={
            this.isFilterActive("show4AndMore") ||
            this.isFilterActive("showVariantWithoutStars")
          }
          text="RatingShow3AndLess"
          onSelected={this.onShow3AndLess}
          onNotSelected={this.onRemove3AndLess}
        >
          <RatingComponent value={3} readonly />
        </ToggableActionButton>
        <ToggableActionButton
          disabled={
            this.isFilterActive("show3AndLess") ||
            this.isFilterActive("showVariantWithoutStars")
          }
          text="RatingShow4AndMore"
          onSelected={this.onShow4AndMore}
          onNotSelected={this.onRemove4AndMore}
        >
          <RatingComponent value={5} readonly />
        </ToggableActionButton>
        <ToggableActionButton
          disabled={this.isFilterActive("showVariantWithoutStars")}
          text="DefaultVariant"
          onSelected={this.onShowDefaultVariant}
          onNotSelected={this.onRemoveDefaultVariant}
          icon="filter"
        />
        <ToggableActionButton
          disabled={
            this.isFilterActive("show4AndMore") ||
            this.isFilterActive("show3AndLess") ||
            this.isFilterActive("showDefaultVariant")
          }
          text="VariantWithoutStars"
          onSelected={this.onShowVariantWithoutStars}
          onNotSelected={this.onRemoveVariantWithoutStars}
          icon="filter"
        /> */}
            </div>
        );
    }
}
