import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'redux';

const ActionButton = ({ className, onClick, icon, t, text, oneRowSelected, selection = '', multiRowSelected, title, customTextRender, selectedRow }) => {
    let enabled = true;
    if (selection == 'multi') {
        enabled = multiRowSelected;
    }

    if (selection == 'one') {
        enabled = oneRowSelected;
    }

    const handleClick = (e) => {
        e.preventDefault();

        if (enabled) {
            onClick(e);
        }
    };

    let wrapperClass = 'd-action-button';
    if (!enabled) {
        wrapperClass = `${wrapperClass} d-action-button-disabled`;
    }

    let textToTransalte = text;
    if (customTextRender && enabled && selectedRow) {
        textToTransalte = customTextRender(selectedRow);
    }

    return (
        <button className={wrapperClass} onClick={handleClick} title={t(title)}>
            <div>
                <span className={`k-icon k-i-${icon}`}>&nbsp;</span>
            </div>
            <div>{t(textToTransalte)}</div>
        </button>
    );
};

ActionButton.propTypes = {
    text: PropTypes.string,
    onClick: PropTypes.func,
    className: PropTypes.string,
    icon: PropTypes.string,
    t: PropTypes.func,
    oneRowSelected: PropTypes.bool,
    multiRowSelected: PropTypes.bool,
    selection: PropTypes.string,
    title: PropTypes.string,
    customTextRender: PropTypes.func,
    selectedRow: PropTypes.object,
};

const mapStateToProps = (state) => {
    return {
        oneRowSelected: state.table.selected == 1,
        multiRowSelected: state.table.selected > 0,
        selectedRow: state.table.selectedRow,
    };
};

export default compose(translate('common'), connect(mapStateToProps, null))(ActionButton);
