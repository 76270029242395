import React from 'react';
import PropTypes from 'prop-types';

const CancelButton = ({children, className = "", onClick}) => {
  className = "k-button k-secondary "+ className;

  return (
      <button className={className} onClick={onClick}>
        &nbsp;
        {children}
      </button>
  );
};

CancelButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.string
};

export default CancelButton;
