import React from 'react';
import { Switch as KendoSwitch, SwitchChangeEvent } from '@progress/kendo-react-inputs';
import { GridCell, GridCellProps } from '@progress/kendo-react-grid';

interface BoolCellProps extends GridCellProps {
    onBoolChange?: (newValue: boolean, id: number) => void;
    idField?: string;
}

class BoolCell extends React.Component<BoolCellProps, {}> {
    onChange = (event: SwitchChangeEvent) => {
        if (this.props.onBoolChange && this.props.idField) {
            this.props.onBoolChange(event.value, this.props.dataItem[this.props.idField]);
        }
    };

    render() {
        const boolFieldValue = this.props.field ? this.props.dataItem[this.props.field] : false;

        return (
            <td style={{ textAlign: 'center' }}>
                <KendoSwitch checked={boolFieldValue} onChange={this.onChange} />
            </td>
        );
    }
}

const BoolCellCellWrapper = (onChange?: (newValue: boolean, id: number) => void, idField?: string) => (props: BoolCellProps) => {
    return <BoolCell {...props} onBoolChange={onChange} idField={idField} />;
};

BoolCellCellWrapper.displayName = 'BoolCellCellWrapper';

export default BoolCellCellWrapper;
