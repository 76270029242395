import * as types from '../actions/actionTypes';
import Role from '../entities/role';

const roleInitialState = {
    userReadableProperty: 'name',
    loadingDependencies: false,
    loaded: false,
    data: [],
};

const roles = (state = roleInitialState, action: any) => {
    switch (action.type) {
        case types.ROLE_FETCH:
            return {
                ...state,
                data: action.data.map((role: Role) => {
                    return { ...role };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default roles;

export const isLoaded = (state: any): boolean => {
    return state.roles.loaded === true;
};

export const getData = (state: any): Role[] => {
    return state.roles.data;
};

export const getUserReadableProperty = (state: any): keyof Role => {
    return state.roles.userReadableProperty;
};
