import React from 'react';
import { GridCellProps } from '@progress/kendo-react-grid';
import { TEXT_HORIZONTAL_ALIGN } from '../../../common/constants';

export interface StringCellProps extends Partial<GridCellProps> {
    textAlign?: any;
    onClick?: () => void;
}

class StringCellEx extends React.Component<StringCellProps> {
    render() {
        const value = this.props.field ? this.props.dataItem[this.props.field] : '';
        let { textAlign, onClick, style } = this.props;

        if (textAlign === null) {
            textAlign = TEXT_HORIZONTAL_ALIGN.CENTER;
        }

        if (style) {
            style.textAlign = textAlign;
        }

        return (
            <td style={style} onClick={onClick}>
                {value}
            </td>
        );
    }
}

export default StringCellEx;
