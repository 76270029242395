import React from 'react';
import {GridCell} from "@progress/kendo-react-grid";
import { Tooltip } from '@progress/kendo-react-tooltip';

import {TEXT_HORIZONTAL_ALIGN} from "../../../common/constants";

const defaultTextAlign = TEXT_HORIZONTAL_ALIGN.CENTER;

// TODO
class StringCellWithIcon extends GridCell {
  render() {
    const value = this.props.dataItem[this.props.field];
    const {textAlign, icon, condtionToShowIcon} = this.props;
    const showIcon = condtionToShowIcon(this.props.dataItem);

    let renderedValue = value;

    if(showIcon) {
      renderedValue = (
        <Tooltip anchorElement="target" position="right">
          <span className={`icon__warning icon-${icon}`} title="Existuje další novinka, která má stejné pořadí">{value}</span>
        </Tooltip>       
      );
    }  

    return (
      <td style={{textAlign: textAlign? textAlign : defaultTextAlign}}>
        {renderedValue}
      </td>
    );
  }
}

/*
<Tooltip anchorElement="target" position="right" style={backRoundColor:""}>
          <span className={`icon__warning icon-${icon}`} title="Existuje další novinka, která má stejné pořadí">{value}</span>
        </Tooltip>
*/
/*const StringCellWithIconWrapper = (horizontalTextAlign) => (props) => {
  return <StringCellWithIcon {...props} textAlign={horizontalTextAlign} />;
};

StringCellWithIconWrapper.displayName = 'StringCellWithIconWrapper';

export default StringCellWithIconWrapper;*/

export default StringCellWithIcon;
