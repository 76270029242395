import React from "react";
import * as PropTypes from "prop-types";

const ErrorValidationMessage = ({errorMessage})=> {
  return (
    <span className="validation-message validation-message-error">{errorMessage}</span>
  );
};

ErrorValidationMessage.propTypes={
  errorMessage:PropTypes.string.isRequired,
};

export default ErrorValidationMessage;
