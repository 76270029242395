/* eslint-disable no-restricted-globals */
export const alertAutoCloseConfig = {
    timeout: 5000,
    
    autoCloseInfo: true,
    autoCloseSuccess: true,
    autoCloseWarning: true,
    autoCloseError: false
};

const url = process.env.NODE_ENV === 'production' ? location.host : location.hostname + ':5000';
//const url = process.env.NODE_ENV === 'production' ? location.host : "dev.tescomobile.app";
//const protocol =process.env.NODE_ENV === 'production' ?location.protocol : "https:";

export const apiBaseUrl = `${location.protocol}//${url}/api`;
export const imageBaseUrl = `${location.protocol}//${url}`;

//export const apiBaseUrl = `${protocol}//${url}/api`;
//export const imageBaseUrl = `${protocol}//${url}`;

export const MAX_IMAGE_SIZE_IN_MEGA_BYTES=1;
export const MAX_IMAGE_SIZE_IN_BYTES = MAX_IMAGE_SIZE_IN_MEGA_BYTES*1024*1024;
