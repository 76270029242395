import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import AffiliateProgramRewardDetail from './affiliateProgramRewardDetail';
import { getRowById } from '../../reducers/tableReducer';
import { navigateToAffiliateProgramRewards } from '../../actions/navigationActions';
import { updateAffiliateProgramReward } from '../../actions/affiliateProgramRewardsActions';
import AffiliateProgramReward from '../../entities/affiliateProgramReward';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';

interface RewardDetailUpdateProps extends BaseDetailProps<AffiliateProgramReward> {}

const RewardDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: RewardDetailUpdateProps) => {
    return (
        <AffiliateProgramRewardDetail
            initialValues={initialValues}
            t={t}
            submitAction={submitAction}
            headerText={t('UpdateAffiliateProgramReward') + ' - ' + initialValues.name}
            cancelAction={cancelAction}
        />
    );
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RewardDetailUpdateProps>): BaseDetailDispatchProps<AffiliateProgramReward> => {
    return {
        submitAction: bindActionCreators(updateAffiliateProgramReward, dispatch),
        cancelAction: bindActionCreators(navigateToAffiliateProgramRewards, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(RewardDetailUpdate);
