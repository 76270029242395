import * as types from '../actions/actionTypes';

const extraInitialState= {
  loaded: false,
  data: null,
};

const ratingSettings = (state = extraInitialState, action) => {
  switch (action.type) {
    case types.RATING_SETTINGS_FETCH:
      return {
        ...state,
        data: action.data,
      };
    case types.RATING_SETTINGS_LOADING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case types.RATING_SETTINGS_LOADING_END:
      return {
        ...state,       
        loading: false,
      };
    default:
      return state;
  }
};

export default ratingSettings;

export const isLoading = (state) => {
  return state.ratingSettings.loading === true;
};

export const getData = (state) => {
  return state.ratingSettings.data;
};
