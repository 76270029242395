import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import sideBar from './sideBarReducer';
import app from './appReducer';
import user from './userReducer';
import ajaxStatus from './ajaxStatusReducer';
import alerts from './alertReducer';
import accountMenu from './accountMenuReducer';
import table from './tableReducer';
import news from './newsReducer';
import newsPages from './newsPagesReducer';
import services from './servicesReducer';
import extra from './extraReducer';
import ratingSettings from './ratingSettingsReducer';
import ecomail from './ecomailReducer';
import loadingSpinner from './loadingSpinnerReducer';
import rewards from './rewardReducer';
import simCardType from './simCardTypeReducer';
import marketingActions from './marketingActionReducer';
import affiliateProgramPartners from './affiliateProgramPartnersReducer';
import affiliateProgramRewards from './affiliateProgramRewardsReducer';
import affiliateProgramSubscriberRewardClaims from './affiliateProgramSubscriberRewardClaimsReducer';
import roles from './roleReducer';
import reports from './reportReducer';
import dataSources from './dataSourceReducer';
import dataSourceQueryContexts from './dataSourceQueryContextReducer';
import pushNotificationConfigurations from './pushNotificationConfigurationReducer';

import * as types from '../actions/actionTypes';

const crossReducer = (state = {}, action) => {
    switch (action.type) {
        case types.APP_RESET:
            state = undefined;
    }

    return state;
};

const cmbReducer = combineReducers({
    app,
    user,
    sideBar,
    ajaxStatus,
    alerts,
    accountMenu,
    table,
    form: formReducer,
    news,
    newsPages,
    services,
    extra,
    ratingSettings,
    ecomail,
    loadingSpinner,
    rewards,
    simCardType,
    marketingActions,
    affiliateProgramPartners,
    affiliateProgramRewards,
    affiliateProgramSubscriberRewardClaims,
    roles,
    reports,
    dataSources,
    dataSourceQueryContexts,
    pushNotificationConfigurations,
});

const rootReducer = (state, action) => {
    state = crossReducer(state, action);
    return cmbReducer(state, action);
};

export default rootReducer;
