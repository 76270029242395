import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import AffiliateProgramPartnerDetail from './affiliateProgramPartnerDetail';
import { getRowById } from '../../reducers/tableReducer';
import { navigateToAffiliateProgramPartners } from '../../actions/navigationActions';
import { updateAffiliateProgramPartner } from '../../actions/affiliateProgramPartnerActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';

interface RewardDetailUpdateProps extends BaseDetailProps<AffiliateProgramPartner> {}

const RewardDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: RewardDetailUpdateProps) => {
    return (
        <AffiliateProgramPartnerDetail
            initialValues={initialValues}
            t={t}
            submitAction={submitAction}
            headerText={t('UpdateAffiliateProgramPartner') + ' - ' + initialValues.name}
            cancelAction={cancelAction}
        />
    );
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RewardDetailUpdateProps>): BaseDetailDispatchProps<AffiliateProgramPartner> => {
    return {
        submitAction: bindActionCreators(updateAffiliateProgramPartner, dispatch),
        cancelAction: bindActionCreators(navigateToAffiliateProgramPartners, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(RewardDetailUpdate);
