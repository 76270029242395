import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Provider } from 'react-redux';
import { Router, Route } from 'react-router-dom';
import axios from 'axios';
import { I18nextProvider } from 'react-i18next';

import i18n from '../i18n';
import Layout from "./common/Layout";
import { AuthProvider } from './security/authContext';
import {apiBaseUrl} from "../common/configs";
import {registerInterceptors} from "../utils/axiosInterceptors";


// Set default AXIOS url by type of enviroment
axios.defaults.baseURL = apiBaseUrl;

// init axios interceptors
registerInterceptors();

export default class Root extends Component {
  render() {
    const { store, history } = this.props;
    return (
      <I18nextProvider i18n={i18n}>
        <Provider store={store}>
          <AuthProvider>           
            <Router history={history}>
              <Route path="/" component={Layout} />
            </Router>
          </AuthProvider>
        </Provider>
      </I18nextProvider>
    );
  }
}

Root.propTypes = {
  store: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired
};
