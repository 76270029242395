import * as types from '../actions/actionTypes';

const servicesInitialState = {
  userReadableProperty: "name",
  loaded: false,
  data: [],
};

const services = (state = servicesInitialState, action) => {
  switch (action.type) {
    case types.SERVICES_FETCH:
      return {
        ...state,
        data: action.data,
        loaded: true,
      };
    default:
      return state;
  }
};

export default services;

export const isLoaded = (state) => {
  return state.services.loaded === true;
};

export const getData = (state) => {
  return state.services.data;
};

export const getUserReadableProperty = (state) => {
  return state.services.userReadableProperty;
};