import React from 'react';
import PropTypes from 'prop-types';
import { translate } from 'react-i18next';

const TranslatedTextBox = ({ placeholder, value, name, type, className, onChange, icon, error, t }) => {
  let wrapperClass = `k-textbox`;
  
  if (className) {
    wrapperClass += ` ${className}`;
  }

  if (error) {
    wrapperClass += ` notification-input-error`;
  }

  let wrapperType = `text`;
  if (type) {
    wrapperType = type;
  }

  return (
    <div>
      {icon && <span className={`icon icon-${icon}`}>&nbsp;</span>}
      <input className={wrapperClass} type={wrapperType} name={name} value={value} placeholder={t(placeholder)} onChange={onChange} />
      {error && <div className={`notification-error`}>{error}</div>}
    </div>
  );
};

TranslatedTextBox.propTypes = {
  name: PropTypes.string.isRequired,
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  t: PropTypes.func
};

export default translate('common')(TranslatedTextBox);
