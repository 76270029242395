import React from "react";
import { connect } from "react-redux";
import { compose, bindActionCreators, Dispatch } from "redux";
import { translate } from "react-i18next";

import Table from "../common/Table";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import StringCell from "../common/formatters/stringCellEx";
import DateCell from "../common/formatters/DateCell";
import ButtonCell from "../common/ButtonCell";
import Translation from "../common/Translation";
import Alerts from "../common/Alerts";
import { TARGET_CAMPAIGN } from "../../data-transformations/dataTransformationTypes";
import { navigateTo } from "../../actions/navigationActions";
import { downloadReportById } from "../../actions/targetCampaignActions";
import { TEXT_HORIZONTAL_ALIGN, } from "../../common/constants";
import TargetCampaignGroup from "../../entities/targetCampaignGroup";
import { nameofFactory } from '../../helpers/nameOfFactory';

const nameof = nameofFactory<TargetCampaignGroup>();

const defaultRatingTabelSort = [
  {
    dir: "desc",
    field: "dateOfLastImport",
  },
];

const actionUrl = "targetcampaign";

interface TargetCampaignProp extends TargetCampaignDispatchProps {}

interface TargetCampaignDispatchProps {
    t: any;
    navigateTo: typeof navigateTo;
    downloadReportById: (id: string) => (dispatch: Dispatch<TargetCampaignDispatchProps>, getState: any) => void;
}

const TargetCampaign = (props: TargetCampaignProp) => {
  const { t, downloadReportById } = props;

  let table;

  const customRowRender = (trElement: any) => {
    return trElement;
  };

  return (
    <div>
      <div className="content-header">
        <h1>
          <Translation tKey="TargetCampaigns" />
        </h1>
      </div>
      <div className="content-wrapper">
        <Alerts />
        <div className="panel">
          {/* <div className="panel-header">
            <TargetCampaignActionsBar
              edit={edit}              
            />
            <div className="filter" />
          </div> */}
          <div className="panel-content">
            <Table
              path={"targetcampaign"}
              actionPath={actionUrl}
              selectedField="selected"
              ref={(el) => {
                table = el;
              }}
              dataTransformation={TARGET_CAMPAIGN}
              name="TargetCampaignTable"
              customRowRender={customRowRender}
              defaultSort={defaultRatingTabelSort}
            >
              <Column field="selected" width="50" />
              <Column
                field={nameof("campaignCode")}
                width="300"
                cell={(props) => (
                  <StringCell
                    {...props}                
                    textAlign={TEXT_HORIZONTAL_ALIGN.LEFT}
                  />
                )}
                title={t("CampaignCode")}
              />
              <Column
                field={nameof("msisdnCount")}
                width="150"
                cell={(props) => (
                  <StringCell
                    {...props}
                    textAlign={TEXT_HORIZONTAL_ALIGN.RIGHT}
                  />
                )}
                title={t("ItemCount")}
              />
              <Column
                field={nameof("dateOfLastImport")}
                width="300"
                cell={(props) => (
                  <DateCell
                    {...props}                    
                  />
                )}
                title={t("DateOfLastImport")}
              />
              <Column
                field={nameof("dateOfLastNotificationSent")}
                width="300"
                cell={(props) => (
                  <DateCell {...props} />
                )}
                title={t("DateOfLastNotificationSent")}
              />
              <Column
                cell={ButtonCell(
                  "campaignCode",
                  t("Download"),
                  "download",
                  downloadReportById
                )}
                title={t("Download")}
              />              
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch: Dispatch<TargetCampaignDispatchProps>) => {
  return {
    navigateTo: bindActionCreators(navigateTo, dispatch),
    downloadReportById: (id: string) => dispatch(downloadReportById(id))
  };
};

export default compose(connect(null, mapDispatchToProps), translate("common"))(TargetCampaign);
