import * as types from '../actions/actionTypes';

const alert = (state, action) => {
  switch (action.type) {
    case types.ALERTS_ADD:
      return {...action.message};
    default:
      return state;
  }
};

const alerts = (state = [], action) => {
  switch (action.type) {
    case types.ALERTS_ADD:
      return [
        ...state,
        alert(state, action)
      ];
    case types.ALERTS_REMOVE: {
      let i = state.map(a => { return a.id; }).indexOf(action.id);
      return [
        ...state.slice(0, i),
        ...state.slice(i + 1)
      ]; }
    case types.ALERTS_CLEAR:
      return [];
    default:
      return state;
  }
};

export default alerts;
