import React from 'react';
import { Field } from 'react-final-form';

import * as enums from '../../common/enums';

import Translation from '../common/Translation';
import Input from '../common/Input';
import DropDown from '../common/DropDown';
import Reward from '../../entities/reward';
import Textarea from '../common/Textarea';
import DateTimePicker from '../common/DateTimePicker';
import Dropzone from '../common/DropZone';
import Switch from '../common/Switch';
import Condition from '../common/utils/Condition';
import { RewardDetailPanelProps } from './type';
import DropDownWithValueField from '../common/DropDownWithValueField';

export default class RewardDetailFollowingPanel extends React.Component<RewardDetailPanelProps> {
    componentDidMount() {}

    render() {
        console.debug('RewardDetailFollowingPanel.render');
        const nameof = this.props.nameof;

        return (
            <React.Fragment>
                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('name')}>
                        <span>
                            <Translation tKey="Name" />
                        </span>
                        <Field name={nameof('name')} component={Input} />
                    </label>
                    {/* <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('campaignCode')}>
                        <span>
                            <Translation tKey="CampaignCode" />
                        </span>
                        <Field name={nameof('campaignCode')} component={Input} />
                    </label> */}
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('productCode')}>
                        <span>
                            <Translation tKey="ProductCode" />
                        </span>
                        <Field name={nameof('productCode')} component={Input} />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('type')}>
                        <span>
                            <Translation tKey="Type" />
                        </span>
                        <Field name={nameof('type')} component={DropDown} textField="text" dataSource={enums.REWARD_TYPES} />
                    </label>
                </div>

                <div className="row">
                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('numberOfPeriods')}>
                        <span>
                            <Translation tKey="NumberOfPeriods" />
                        </span>
                        <Field name={nameof('numberOfPeriods')} component={Input} type="number" />
                    </label>
                </div>

                <Condition when={nameof('sourceOfSubscribers')} is={this.props.showIsDigiCondition}>
                    <div className="row">
                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('isDigi')}>
                            <span>
                                <Translation tKey="PayByCreditCard" />
                            </span>
                            <Field
                                name={nameof('isDigi')}
                                component={Switch}
                                // placeholder={t("Name")}
                            />
                        </label>
                        <Condition when={nameof('isDigi')} is={true}>
                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('priceByCreditCard')}>
                                <span>
                                    <Translation tKey="PriceByCreditCard" />
                                </span>
                                <Field name={nameof('priceByCreditCard')} component={Input} type="number" />
                            </label>
                        </Condition>
                    </div>
                </Condition>
                
                <label className="k-form-field" htmlFor="followingRewardId">
                    <span>
                        <Translation tKey="FollowingReward" />
                    </span>
                    <Field
                        name={nameof('followingRewardId')}
                        component={DropDownWithValueField}
                        textField={this.props.followingRewardUserReadableProperty}
                        valueField="id"
                        dataSource={this.props.rewards}
                    />
                </label>              

                <label className="k-form-field" htmlFor={nameof('code')}>
                    <span>
                        <Translation tKey="Code" />
                    </span>
                    <Field name={nameof('code')} component={Input} />
                </label>
            </React.Fragment>
        );
    }
}
