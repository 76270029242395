import DataSourceQueryContext from '../entities/dataSourceQueryContext';

const DataSourceQueryContextItemTransformationBeforeSave = (item: DataSourceQueryContext) => {
    const transformedData = {
        ...item      
    } as { [key: string]: any };

    return transformedData;
};

export default DataSourceQueryContextItemTransformationBeforeSave;