import { EnumDescription, LANGUAGE_TYPES, LANGUAGE_ENUM, LINK_TYPE_TYPES, LINK_TYPE_ENUM, getEnumItemByValue } from '../common/enums';

export default class Link {
    id: number;
    value: string;
    key: string;
    typeEnumDescription: EnumDescription | undefined;
    type: number;
    languageEnumDescription: EnumDescription | undefined;
    language: number;
    deleted: boolean;

    constructor(properties: Partial<Link> & Pick<Link, 'type' | 'language'>) {
        this.id = 0;
        this.value = '';
        this.key = '';
        this.type = LINK_TYPE_ENUM.YOUTUBE_VIDEO;
        this.language = LANGUAGE_ENUM.CZ;
        this.deleted = false;

        Object.assign(this, properties);

        this.typeEnumDescription = getEnumItemByValue(properties.type, LINK_TYPE_TYPES);
        this.languageEnumDescription = getEnumItemByValue(properties.language, LANGUAGE_TYPES);
    }
}
