import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators, compose } from "redux";
import { translate } from "react-i18next";

import Translation from "../common/Translation";
import Alerts from "../common/Alerts";
import { Form, Field } from "react-final-form";
import SubmitButton from "../common/SubmitButton";
import NumericTextBox from "../common/NumericTextBox";
import Switch from "../common/Switch";
import LoadingIndicator from "../common/LoadingIndicator";
import DropDown from "../common/DropDown";

import * as enums from "../../common/enums";
import {
  updateRatingSettings,
  fetchRatingSettings,
} from "../../actions/ratingSettingsActions";
import { getData, isLoading } from "../../reducers/ratingSettingsReducer";

class RatingSettings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {};
    this.translatedFormValidation = this.translatedFormValidation.bind(this);
  }

  componentDidMount() {
    this.props.fetchData();
  }

  translatedFormValidation(values) {
    //const { t } = this.props;
    const errors = {};

    // if (!values.name) {
    //   errors.name = t('NameIsRequired');
    // }

    // if (!values.validFrom) {
    //   errors.validFrom = 'Datum Platný od je povinné';
    // }

    // if (values.validTo && values.validFrom && values.validTo < values.validFrom) {
    //   errors.validTo = 'Datum Platný do, musí být větší než datum Platný od';
    // }

    // if (!values.type) {
    //   errors.type = 'Typ je povinný';
    // }

    return errors;
  }

  render() {
    const { initialValues } = this.props;

    return (
      <div>
        <div className="content-header">
          <h1>
            <Translation tKey="RatingSettings" />
          </h1>
        </div>
        <div className="content-wrapper">
          <Alerts />

          <div className="panel card col-xs-12 col-sm-6">
            <Form
              validate={this.translatedFormValidation}
              onSubmit={this.props.onSubmit}
              initialValues={initialValues}
              render={({
                handleSubmit,
                reset,
                submitting,
                pristine,
                values,
                valid,
              }) => (
                <form className="k-form" onSubmit={handleSubmit}>
                  {this.props.loading && <LoadingIndicator />}

                  <div className={"row"}>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="isAllowedTriggerAfterOrder"
                    >
                      <span>
                        <Translation tKey="AllowedRatingAfterOrder" />
                      </span>
                      <Field
                        name="isAllowedTriggerAfterOrder"
                        component={Switch}
                      />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6">
                      <span>
                        <Translation tKey="AllowedVariantAfterOrder" />
                      </span>
                      <Field
                        name="allowedVariantAfterOrder"
                        component={DropDown}
                        textField="text"
                        dataSource={enums.RATING_VARIANT_TYPES}
                      />
                    </label>
                  </div>
                  <div className={"row"}>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="isAllowedTriggerAfterRecharge"
                    >
                      <span>
                        <Translation tKey="AllowedRatingAfterRecharge" />
                      </span>
                      <Field
                        name="isAllowedTriggerAfterRecharge"
                        component={Switch}
                      />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6">
                      <span>
                        <Translation tKey="AllowedVariantAfterRecharge" />
                      </span>
                      <Field
                        name="allowedVariantAfterRecharge"
                        component={DropDown}
                        textField="text"
                        dataSource={enums.RATING_VARIANT_TYPES}
                      />
                    </label>
                  </div>
                  <div className={"row"}>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="isAllowedTriggerAfterCompetition"
                    >
                      <span>
                        <Translation tKey="AllowedRatingAfterCompetition" />
                      </span>
                      <Field
                        name="isAllowedTriggerAfterCompetition"
                        component={Switch}
                      />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6">
                      <span>
                        <Translation tKey="AllowedVariantAfterCompetition" />
                      </span>
                      <Field
                        name="allowedVariantAfterCompetition"
                        component={DropDown}
                        textField="text"
                        dataSource={enums.RATING_VARIANT_TYPES}
                      />
                    </label>
                  </div>
                  <div className={"row"}>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="isAllowedTriggerAfterLeavingPageExtra"
                    >
                      <span>
                        <Translation tKey="AllowedTriggerAfterLeavingPageExtra" />
                      </span>
                      <Field
                        name="isAllowedTriggerAfterLeavingPageExtra"
                        component={Switch}
                      />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6">
                      <span>
                        <Translation tKey="AllowedVariantAfterLeavingPageExtra" />
                      </span>
                      <Field
                        name="allowedVariantAfterLeavingPageExtra"
                        component={DropDown}
                        textField="text"
                        dataSource={enums.RATING_VARIANT_TYPES}
                      />
                    </label>
                  </div>
                  <div className={"row"}>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="isAllowedTriggerAfterLeavingPageSuriGame"
                    >
                      <span>
                        <Translation tKey="AllowedTriggerAfterLeavingPageSuriGame" />
                      </span>
                      <Field
                        name="isAllowedTriggerAfterLeavingPageSuriGame"
                        component={Switch}
                      />
                    </label>

                    <label className="k-form-field col-xs-12 col-sm-6">
                      <span>
                        <Translation tKey="AllowedVariantAfterLeavingPageSuriGame" />
                      </span>
                      <Field
                        name="allowedVariantAfterLeavingPageSuriGame"
                        component={DropDown}
                        textField="text"
                        dataSource={enums.RATING_VARIANT_TYPES}
                      />
                    </label>
                  </div>
                  <label
                    className="k-form-field"
                    htmlFor="numberOfDaysToDeferRating"
                  >
                    <span>
                      <Translation tKey="NumberOfDaysToDeferRating" />
                    </span>
                    <Field
                      name="numberOfDaysToDeferRating"
                      component={NumericTextBox}
                    />
                  </label>

                  <div className="footer">
                    <SubmitButton loading={submitting}>
                      <Translation tKey="Save" />
                    </SubmitButton>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

RatingSettings.defaultProps = {
  initialValues: {
    isAllowedTriggerAfterOrder: true,
    isAllowedTriggerAfterRecharge: true,
    isAllowedTriggerAfterCompetition: true,
    isAllowedTriggerAfterLeavingPageExtra: true,
    numberOfDaysToDeferRating: 14,
    allowedVariantAfterOrder: 0,
    allowedVariantAfterRecharge: 0,
    allowedVariantAfterCompetition: 0,
    allowedVariantAfterLeavingPageExtra: 0,
  },
};

RatingSettings.propTypes = {
  t: PropTypes.func.isRequired,
  initialValues: PropTypes.shape({
    isAllowedTriggerAfterOrder: PropTypes.bool,
    isAllowedTriggerAfterRecharge: PropTypes.bool,
    isAllowedTriggerAfterCompetition: PropTypes.bool,
    isAllowedTriggerAfterLeavingPageExtra: PropTypes.bool,
    numberOfDaysToDeferRating: PropTypes.number,
    allowedVariantAfterOrder: PropTypes.number,
    allowedVariantAfterRecharge: PropTypes.number,
    allowedVariantAfterCompetition: PropTypes.number,
    allowedVariantAfterLeavingPageExtra: PropTypes.number,
  }).isRequired,
  onSubmit: PropTypes.func.isRequired,
  fetchData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
  return {
    initialValues: getData(state),
    loading: isLoading(state),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onSubmit: bindActionCreators(updateRatingSettings, dispatch),
    fetchData: bindActionCreators(fetchRatingSettings, dispatch),
  };
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  translate("common")
)(RatingSettings);
