import {
  getEnumItemByValue,
  RATING_VARIANT_TYPES,
} from "../common/enums";

export default class RatingSettings {
  isAllowedTriggerAfterOrder;
  isAllowedTriggerAfterRecharge;
  isAllowedTriggerAfterCompetition;
  isAllowedTriggerAfterLeavingPageExtra;
  isAllowedTriggerAfterLeavingPageSuriGame;
  numberOfDaysToDeferRating;
  allowedVariantAfterOrder;
  allowedVariantAfterRecharge;
  allowedVariantAfterCompetition;
  allowedVariantAfterLeavingPageExtra;
  allowedVariantAfterLeavingPageSuriGame;

  constructor(properties) {

    Object.assign(this, properties);
  
    this.allowedVariantAfterOrder = getEnumItemByValue(properties.allowedVariantAfterOrder, RATING_VARIANT_TYPES);   
    this.allowedVariantAfterRecharge = getEnumItemByValue(properties.allowedVariantAfterRecharge, RATING_VARIANT_TYPES);    
    this.allowedVariantAfterCompetition = getEnumItemByValue(properties.allowedVariantAfterCompetition, RATING_VARIANT_TYPES);    
    this.allowedVariantAfterLeavingPageExtra = getEnumItemByValue(properties.allowedVariantAfterLeavingPageExtra, RATING_VARIANT_TYPES);    
    this.allowedVariantAfterLeavingPageSuriGame = getEnumItemByValue(properties.allowedVariantAfterLeavingPageSuriGame, RATING_VARIANT_TYPES);    
  }
}
