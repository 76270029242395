import React from 'react';
import {Field} from 'react-final-form';
import PropTypes from "prop-types";
import {isEmpty} from "../../../utils/isEmpty";

const WhenFieldIsEmpty = ({when, children}) => (
  <Field name={when} subscription={{value: true}}>
    {
      ({input: {value}}) => (
        isEmpty(value) ? children : null)
    }
  </Field>
);

WhenFieldIsEmpty.propTypes = {
  when: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default WhenFieldIsEmpty;
