import React, { Dispatch } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose } from 'redux';
import { translate } from 'react-i18next';
import { GridColumn as Column } from '@progress/kendo-react-grid';

import Table from '../common/Table';
import StringCell from '../common/formatters/StringCell';
import Translation from '../common/Translation';
import EnumCell from '../common/formatters/EnumCelll';
import Alerts from '../common/Alerts';
import ActionButton from '../common/ActionButton';
import { navigateTo } from '../../actions/navigationActions';
import { TEXT_HORIZONTAL_ALIGN, NAVIGATION_PATHS } from '../../common/constants';
import { nameofFactory } from '../../helpers/nameOfFactory';
import PushNotificationConfiguration from '../../entities/pushNotificationConfiguration';
import NavigableCell from '../common/formatters/navigableCell';
import { PUSHNOTIFICATIONCONFIGURATION } from '../../data-transformations/dataTransformationTypes';
import { BaseListDispatchProps, BaseListProps } from '../common/types/baseDetail';
import { sendPushNotificationsToBetaTesters as sendPushNotificationsToBetaTestersAction } from '../../actions/pushNotificationConfigurationActions';

import * as enums from '../../common/enums';

const nameof = nameofFactory<PushNotificationConfiguration>();

const actionUrl = 'pushnotificationconfigurations';

interface PushNotificationConfigurationProps extends BaseListProps, PushNotificationConfigurationDispatchProps {}
interface PushNotificationConfigurationDispatchProps extends BaseListDispatchProps {
    sendPushNotificationsToBetaTesters: typeof sendPushNotificationsToBetaTestersAction;
}

const PushNotificationConfigurations = ({ t, navigateTo, sendPushNotificationsToBetaTesters }: PushNotificationConfigurationProps) => {
    let table: any;

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.PUSHNOTIFICATIONCONFIGURATION_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    const onSendPushNotificationsToBetaTesters = () => {
        sendPushNotificationsToBetaTesters();
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="PushNotificationConfigurations" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <div className="actions">
                            <ActionButton text="Add" onClick={onNew} icon="plus" />
                            <ActionButton text="Edit" onClick={onEdit} icon="pencil" selection="one" />
                            <ActionButton text="Remove" onClick={onRemove} icon="delete" selection="multi" />
                            <ActionButton
                                text="TrySendPushNotificationsToBetaTesters"
                                onClick={onSendPushNotificationsToBetaTesters}
                                icon="bell"
                                selection="one"
                                title="TrySendPushNotificationsToBetaTesters"
                            />
                        </div>
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'pushnotificationconfiguration'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            name="LinkTable"
                            dataTransformation={PUSHNOTIFICATIONCONFIGURATION}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('id')}
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Key')}
                            />
                            <Column
                                field={nameof('name')}
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Name')}
                            />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapDispatchToProps = (dispatch: Dispatch<any>): PushNotificationConfigurationDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        sendPushNotificationsToBetaTesters: bindActionCreators(sendPushNotificationsToBetaTestersAction, dispatch),
    };
};

export default compose(connect(null, mapDispatchToProps), translate('common'))(PushNotificationConfigurations);
