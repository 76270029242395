import { EnumDescription, LANGUAGE_TYPES, LANGUAGE_ENUM, getEnumItemByValue } from '../common/enums';

export default class Text {
    id: number;
    value: string;
    key: string;
    languageEnumDescription: EnumDescription | undefined;
    language: number;

    constructor(properties: Partial<Text> & Pick<Text, 'language'>) {
        this.id = 0;
        this.value = '';
        this.key = '';
        this.language = LANGUAGE_ENUM.CZ;

        Object.assign(this, properties);

        this.languageEnumDescription = getEnumItemByValue(properties.language, LANGUAGE_TYPES);
    }
}
