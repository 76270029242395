import { type } from 'os';
import * as types from '../actions/actionTypes';
import AffiliateProgramReward from '../entities/affiliateProgramReward';

const affiliateProgramRewardsInitialState = {
    userReadableProperty: 'name',
    loadingDependencies: false,
    loaded: false,
    data: [],
};

const affiliateProgramRewards = (state = affiliateProgramRewardsInitialState, action: any) => {
    switch (action.type) {
        case types.AFFILIATE_PROGRAM_REWARDS_LOADING_DEPENDENCIES_BEGIN:
            return {
                ...state,
                loadingDependencies: true,
            };
        case types.AFFILIATE_PROGRAM_REWARDS_LOADING_DEPENDENCIES_END:
            return {
                ...state,
                loadingDependencies: false,
            };
        case types.AFFILIATE_PROGRAM_REWARDS_FETCH:
            return {
                ...state,
                data: action.data.map((affiliateProgramReward: AffiliateProgramReward) => {
                    return { ...affiliateProgramReward };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default affiliateProgramRewards;

export const isLoaded = (state: any): boolean => {
    return state.affiliateProgramRewards.loaded === true;
};

export const isLoadingDependencies = (state: any): boolean => {
    return state.affiliateProgramRewards.loadingDependencies === true;
};

export const getData = (state: any): AffiliateProgramReward[] => {
    return state.affiliateProgramRewards.data;
};

export const getUserReadableProperty = (state: any): keyof AffiliateProgramReward => {
    return state.affiliateProgramRewards.userReadableProperty;
};
