import React from "react";
import PropTypes from "prop-types";
import { translate } from "react-i18next";

const Tile = ({ onClick, icon, t, text, title, loading }) => {
  const handleClick = e => {
    e.preventDefault();
    onClick(e);
  };

  let wrapperClass = "tile";

  return (
    <button className={wrapperClass} onClick={handleClick} title={t(title)}>
      <div>
        {loading && <span className="tile__icon k-icon k-i-loading">&nbsp;</span>}
        {!loading && <span className={`tile__icon icon-${icon}`}>&nbsp;</span>}
      </div>
      <div>{t(text)}</div>
    </button>
  );
};

Tile.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  icon: PropTypes.string,
  t: PropTypes.func,
  title: PropTypes.string,
  loading: PropTypes.bool
};

export default translate("common")(Tile);
