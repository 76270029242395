import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { compose, bindActionCreators, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import StringCell from '../common/formatters/StringCell';
import DateCell from '../common/formatters/DateCell';
import NavigableCell from '../common/formatters/navigableCell';
import Translation from '../common/Translation';
import Alerts from '../common/Alerts';
import { AFFILIATE_PROGRAM_REWARD } from '../../data-transformations/dataTransformationTypes';
import { navigateTo } from '../../actions/navigationActions';
import { NAVIGATION_PATHS, TEXT_HORIZONTAL_ALIGN } from '../../common/constants';
import AffiliateProgramRewardsActionsBar from './affiliateProgramRewardsActionsBar';
import { nameofFactory } from '../../helpers/nameOfFactory';
import AffiliateProgramReward from '../../entities/affiliateProgramReward';
import RelationCell from '../common/formatters/RelationCell';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';
import { fetchAffiliateProgramRewardDependencies } from '../../actions/affiliateProgramRewardsActions';
import {
    getData as getAffiliateProgramPartners,
    getUserReadableProperty as getAffiliateProgramPartnerUserReadableProperty,
} from '../../reducers/affiliateProgramPartnersReducer';
import { isLoadingDependencies } from '../../reducers/affiliateProgramRewardsReducer';
import { BaseListDispatchProps, BaseListProps } from '../common/types/baseDetail';
import LoadingIndicator from '../common/LoadingIndicator';
import StringCellEx from '../common/formatters/stringCellEx';
const nameof = nameofFactory<AffiliateProgramReward>();

const defaultRewardTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

const actionUrl = 'affiliateProgramRewards';

interface AffiliateProgramRewardsProp extends BaseListProps, AffiliateProgramRewardsDispatchProps, AffiliateProgramRewardsStateProps {}

interface AffiliateProgramRewardsStateProps {
    affiliateProgramPartnerUserReadableProperty: keyof AffiliateProgramPartner;
    affiliateProgramPartners: AffiliateProgramPartner[];
    loadingDependencies: boolean;
}

interface AffiliateProgramRewardsDispatchProps extends BaseListDispatchProps {
    fetchAffiliateProgramRewardDependencies: typeof fetchAffiliateProgramRewardDependencies;
}

const AffiliateProgramRewards = ({
    t,
    navigateTo,
    fetchAffiliateProgramRewardDependencies,
    loadingDependencies,
    affiliateProgramPartnerUserReadableProperty,
    affiliateProgramPartners,
}: AffiliateProgramRewardsProp) => {
    let table: any;

    useEffect(() => {
        fetchAffiliateProgramRewardDependencies();
    }, []);

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.AFFILIATE_PROGRAM_REWARDS_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    const onClone = () => {
        table.getWrappedInstance().cloneSelected();
    };

    const customRowRender = (trElement: any) => {
        return trElement;
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="AffiliateProgramRewards" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        <AffiliateProgramRewardsActionsBar onEdit={onEdit} onNew={onNew} /* onClone={onClone} */ onRemove={onRemove} />
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        {loadingDependencies && <LoadingIndicator />}
                        <Table
                            path={'affiliateProgramReward'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            dataTransformation={AFFILIATE_PROGRAM_REWARD}
                            name="AffiliateProgramPartnersTable"
                            customRowRender={customRowRender}
                            defaultSort={defaultRewardTabelSort}
                        >
                            <Column field="selected" width="50" />
                            <Column
                                field={nameof('name')}
                                width="360"
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Name')}
                            />
                            <Column
                                field={nameof('partnerId')}
                                width="160"
                                cell={(props) => (
                                    <RelationCell<AffiliateProgramPartner>
                                        {...props}
                                        relationUserReadableProperty={affiliateProgramPartnerUserReadableProperty}
                                        relations={affiliateProgramPartners}
                                    />
                                )}
                                title={t('Partner')}
                            />
                            <Column field={nameof('claimValidTo')} cell={DateCell} title={t('ClaimValidTo')} />
                            <Column
                                field={nameof('claimValidForDays')}
                                cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                title={t('ClaimValidForDays')}
                            />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): AffiliateProgramRewardsStateProps => {
    return {
        loadingDependencies: isLoadingDependencies(state),
        affiliateProgramPartners: getAffiliateProgramPartners(state),
        affiliateProgramPartnerUserReadableProperty: getAffiliateProgramPartnerUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<AffiliateProgramRewardsDispatchProps>): AffiliateProgramRewardsDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        fetchAffiliateProgramRewardDependencies: bindActionCreators(fetchAffiliateProgramRewardDependencies, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(AffiliateProgramRewards);
