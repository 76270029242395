import * as types from '../actions/actionTypes';

const newsInitialState = {
    userReadableProperty: 'name',
    loaded: false,
    data: [],
    loadingDependencies: false,
};

const newsPages = (state = newsInitialState, action) => {
    switch (action.type) {
        case types.NEWS_FETCH:
            return {
                ...state,
                data: action.data,
                loaded: true,
            };
        case types.NEWS_LOADING_DEPENDENCIES_BEGIN:
            return {
                ...state,
                loadingDependencies: true,
            };
        case types.NEWS_LOADING_DEPENDENCIES_END:
            return {
                ...state,
                loadingDependencies: false,
            };
        default:
            return state;
    }
};

export default newsPages;

export const isLoadingDependencies = (state) => {
    return state.news.loadingDependencies === true;
};

export const getData = (state) => {
    return state.news.data;
};

export const getUserReadableProperty = (state) => {
    return state.news.userReadableProperty;
};
