import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import * as propTypes from '../../common/propTypes';
import CompetitionDetail from './competitionDetail';
import {getRowById} from "../../reducers/tableReducer";
import {updateCompetition} from "../../actions/competitionActions";
import {navigateToCompetitions} from "../../actions/navigationActions";

const CompetitionDetailUpdate = ({initialValues, updateCompetition, cancelAction, t}) => {
  return <CompetitionDetail initialValues={initialValues} onSubmit={updateCompetition} headerText={t('UpdateCompetition') + ' - ' + initialValues.name} onCancel={cancelAction}/>;
};

CompetitionDetailUpdate.propTypes = {
  initialValues: propTypes.newsPropTypes.isRequired,
  updateCompetition: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;

  return {
    lang: state.app.lang,
    initialValues: getRowById(state, id)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateCompetition: bindActionCreators(updateCompetition, dispatch),
    cancelAction: bindActionCreators(navigateToCompetitions, dispatch)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(CompetitionDetailUpdate);

