import {
    getEnumItemByValue,
    RATING_TRIGGER_TYPES,   
    PLATFORMA_TYPES, 
    RATING_VARIANT_TYPES
  } from "../common/enums";
  
  export default class Rating {
    id;  
    msisdn;  
    description;  
    value;  
    trigger;   
    dateOfCreate;
    platform;
    variant;

    constructor(properties) {  
      Object.assign(this, properties);      
     
      this.trigger = getEnumItemByValue(properties.trigger, RATING_TRIGGER_TYPES);    
      this.platform = getEnumItemByValue(properties.platform, PLATFORMA_TYPES);
      this.variant = getEnumItemByValue(properties.variant, RATING_VARIANT_TYPES);    
      this.dateOfCreate = properties.dateOfCreate
      ? new Date(properties.dateOfCreate)
      : properties.dateOfCreate;  
    }
  }
  