import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "redux";

const CellActionButton = ({
  onClick,
  icon,
  t,
  text
}) => {
  const handleClick = e => {
    e.preventDefault();
    if(onClick) {
      onClick(e);
    }
  };

  let wrapperClass = "d-action-button";

  return (
    <button className={wrapperClass} onClick={handleClick}>
      <div>
        <span className={`k-icon k-i-${icon}`}>&nbsp;</span>
      </div>
      <div>{t(text)}</div>
    </button>
  );
};

CellActionButton.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  t: PropTypes.func
  //oneRowSelected: PropTypes.bool,
  //multiRowSelected: PropTypes.bool,
  //selection: PropTypes.string,
};

const mapStateToProps = state => {
  return {
    //oneRowSelected: state.table.selected == 1,
    //multiRowSelected: state.table.selected > 0
  };
};

export default compose(
  translate("common"),
  connect(
    mapStateToProps,
    null
  )
)(CellActionButton);
