import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import SimCardTypeDetail, { BaseSimCardTypeDetailProps } from './simCardTypeDetail';
import { createSimCardType } from '../../actions/simCardTypeActions';
import { navigateToSimCardTypes } from '../../actions/navigationActions';
import SimCardType from '../../entities/simCardType';

const initialValues = new SimCardType({
    name: '',
    isManagerType: false,
});

interface SimCardTypeDetailCreateProps extends BaseSimCardTypeDetailProps {
    t: (key: string) => string;
}

const SimCardTypeDetailCreate = ({ submitAction, cancelAction, t }: SimCardTypeDetailCreateProps) => {
    return (
        <SimCardTypeDetail t={t} initialValues={initialValues} submitAction={submitAction} headerText={t('CreateSimCardType')} cancelAction={cancelAction} />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<SimCardTypeDetailCreateProps>) => {
    return {
        submitAction: bindActionCreators(createSimCardType, dispatch),
        cancelAction: bindActionCreators(navigateToSimCardTypes, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(SimCardTypeDetailCreate);
