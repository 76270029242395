import AffiliateProgramPartner from '../entities/affiliateProgramPartner';

const affiliateProgramPartnerItemTransformationBeforeSave = (affiliateProgramPartnerItem: AffiliateProgramPartner) => {
    const transformedData: Record<string | number | symbol, unknown> = {
        ...affiliateProgramPartnerItem,
    };

    const body = new FormData();

    Object.keys(transformedData).forEach((key) => {
        if (transformedData[key] !== null && transformedData[key] !== undefined) {
            body.append(key, transformedData[key] as string | Blob);
        }
    });

    return body;
};

export default affiliateProgramPartnerItemTransformationBeforeSave;
