import * as types from '../actions/actionTypes';
import { CAMPRO_MODULE_ENUM } from '../common/enums';

const initialState = {
    isAuth: false,
    isAdmin: false,
    tokenExpireIn: null,
    agendas: null,
    loadingDependencies: false,
    permittedModules: CAMPRO_MODULE_ENUM.NONE,
};

const user = (state = initialState, action: any) => {
    switch (action.type) {
        case types.USER_SIGN_OUT:
            return {
                ...initialState,
            };
        case types.USER_SIGN_IN:
            return {
                ...state,
                isAuth: true,
                tokenExpireIn: action.tokenExpireIn,
                isAdmin: action.isAdmin,
                permittedModules: action.permittedModules,
            };
        case types.USER_SING_UP:
            return {
                ...initialState,
                registration: {
                    emailIsInUsed: action.emailIsInUsed,
                    activateEmailWasSent: action.activateEmailWasSent,
                    isCaptchaVerified: action.isCaptchaVerified,
                },
            };
        case types.USER_CLEAR:
            return {
                ...initialState,
            };
        case types.USER_PASSWORD_RESET:
            return {
                ...state,
                forgottenPasswordSent: action.sent,
            };
        case types.USER_FETCH_AGENDAS:
            return {
                ...state,
                agendas: action.data,
            };
        case types.USER_LOADING_DEPENDENCIES_BEGIN:
            return {
                ...state,
                loadingDependencies: true,
            };
        case types.USER_LOADING_DEPENDENCIES_END:
            return {
                ...state,
                loadingDependencies: false,
            };
        default:
            return state;
    }
};

export default user;

export const isLoadingDependencies = (state: any): boolean => {
    return state.user.loadingDependencies === true;
};
