import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import DataSourceQueryContextDetail from './dataSourceQueryContextDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updateDataSourceQueryContext } from '../../actions/dataSourceQueryContextActions';
import { navigateToDataSourceQueryContexts } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import DataSourceQueryContext from '../../entities/dataSourceQueryContext';

interface DataSourceQueryContextDetailUpdateProps extends BaseDetailProps<DataSourceQueryContext> {}

const DataSourceQueryContextDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: DataSourceQueryContextDetailUpdateProps) => {
    return <DataSourceQueryContextDetail initialValues={initialValues} submitAction={submitAction} headerText={t('DataSourceQueryContextUpdateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<DataSourceQueryContextDetailUpdateProps>): BaseDetailDispatchProps<DataSourceQueryContext> => {
    return {
        submitAction: bindActionCreators(updateDataSourceQueryContext, dispatch),
        cancelAction: bindActionCreators(navigateToDataSourceQueryContexts, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(DataSourceQueryContextDetailUpdate);
