import * as types from '../actions/actionTypes';
import DataSourceQueryContext from '../entities/dataSourceQueryContext';
import PushNotificationConfiguration from '../entities/pushNotificationConfiguration';

const pushNotificationConfigurationsInitialState = {
    userReadableProperty: 'name',
    loadingDependencies: false,
    loaded: false,
    data: [],
};

const pushNotificationConfigurations = (state = pushNotificationConfigurationsInitialState, action: any) => {
    switch (action.type) {
        case types.PUSHNOTIFICATIONCONFIGURATION_FETCH:
            return {
                ...state,
                data: action.data.map((dataSourceQueryContext: PushNotificationConfiguration) => {
                    return { ...dataSourceQueryContext };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default pushNotificationConfigurations;

export const getData = (state: any): PushNotificationConfiguration[] => {
    return state.pushNotificationConfigurations.data;
};

export const getUserReadableProperty = (state: any): keyof PushNotificationConfiguration => {
    return state.pushNotificationConfigurations.userReadableProperty;
};
