import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { navigateTo, navigateToDetail, navigateToReports } from './navigationActions';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import ResponseStatus from '../utils/responseStatus';
import { showLoadingSpinner, hideLoadingSpinner } from './loadingSpinnerActions';
import { addHrefWithFileToDownload } from '../utils/commonFunctions';
import { NAVIGATION_PATHS } from '../common/constants';
import Report from '../entities/report';
import { REPORT_PARAMETER_TYPES_ENUM } from '../api/enums/reportParameterType';

const downloadReport = (reportId: number, dispatch: Dispatch<any>, getState: any) => {
    dispatch(beginAjaxCall());

    if (reportId) {
        dispatch(showLoadingSpinner());
        const { table } = getState();
        const { actionUrl, data } = table;
        const { name } = data.find((i: any) => i.id === reportId);

        axios
            .get(`${actionUrl}/xlsx/${reportId}`, {
                responseType: 'blob',
            })
            .then((response) => {
                if (ResponseStatus.IsOK(response.status)) {
                    const dateString = new Date().toLocaleDateString();
                    addHrefWithFileToDownload(response.data, `${name}_${dateString}.xlsx`);

                    dispatch(hideLoadingSpinner());
                } else {
                    dispatch(hideLoadingSpinner());
                    dispatch(ajaxCallError());
                }
            })
            .catch((error) => {
                dispatch(hideLoadingSpinner());
                dispatch(ajaxCallError());
                throw error;
            });
    }
};

const downloadReportIfNoParametersOtherwisePromtParametrsDialog = (reportId: number, dispatch: Dispatch<any>, getState: any) => {
    const { table } = getState();
    const { data } = table;
    const { parameters } = data.find((i: any) => i.id == reportId);

    if (!parameters || parameters.length === 0) {
        downloadReport(reportId, dispatch, getState);
    } else {
        dispatch(navigateToDetail('REPORT_FILTER', reportId));
    }
};

export const downloadReportBySelectedRow = (dispatch: Dispatch<any>, getState: any) => {
    const { table } = getState();
    const { data } = table;
    const { id } = data.find((i: any) => i.selected);

    downloadReportIfNoParametersOtherwisePromtParametrsDialog(id, dispatch, getState);
};

export const downloadReportById = (id: number) => (dispatch: Dispatch<any>, getState: any) => {
    downloadReportIfNoParametersOtherwisePromtParametrsDialog(id, dispatch, getState);
};

export const createReport = (data: Report) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    axios
        .post(`report`, data)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.REPORT_ADD,
                });

                dispatch(navigateToReports());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updateReport = (data: Report) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    axios
        .put(`report`, data)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.REPORT_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToReports());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const filterReport = (data: Report) => (dispatch: Dispatch<any>, getState: any) => {
    dispatch(beginAjaxCall());

    dispatch(showLoadingSpinner());
    const { table } = getState();
    const { actionUrl } = table;
    const filters = data.filters.map((filter) => {
        return { ...filter };
    });

    filters.forEach((filter) => {
        if (filter.type === REPORT_PARAMETER_TYPES_ENUM.DATE || filter.type === REPORT_PARAMETER_TYPES_ENUM.DATE_TIME) {
            filter.value = filter.value ? filter.value.toUTCString() : null;
        }
    });

    axios
        .post(`${actionUrl}/xlsx/${data.id}`, filters, {
            responseType: 'blob',
        })
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                const dateString = new Date().toLocaleDateString();
                addHrefWithFileToDownload(response.data, `${data.name}_${dateString}.xlsx`);
                dispatch(hideLoadingSpinner());
                dispatch(navigateToReports());
            } else {
                dispatch(hideLoadingSpinner());
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(hideLoadingSpinner());
            dispatch(ajaxCallError());
            throw error;
        });
};
