import classNames from 'classnames';
import React from 'react';
import ErrorValidationMessage from '../ErrorValidationMessage';

const KendoHOC = (type, { icon, input, meta, placeholder, dataSource, ...rest }) => {
    const Type = type;
    const { style } = rest;
    const isInvalid = meta.error && meta.touched;

    const wrapperClass = classNames({
        'validable-control': true,
        'validable-control__error': isInvalid,
    });

    return (
        <div className={wrapperClass}>
            {icon && <span className={`icon icon-${icon}`}>&nbsp;</span>}
            <Type {...input} {...rest} width="100%" style={{ ...style, width: '100%' }} data={dataSource} />
            {isInvalid && <ErrorValidationMessage errorMessage={meta.error} />}
        </div>
    );
};

export default KendoHOC;
