export const TEXT_HORIZONTAL_ALIGN = {
    LEFT: 'left',
    CENTER: 'center',
    RIGHT: 'right',
};

export const ALERT_TYPE = {
    INFO: 'info',
    SUCCESS: 'success',
    WARNING: 'warning',
    ERROR: 'error',
};

export const NAVIGATION_PATHS = {
    NEWS: '/news',
    NEWS_CREATE: '/news/create',
    BETA_TESTER: '/betatester',
    USERS: '/users',
    USERS_CREATE: '/users/create',
    ROLES: '/roles',
    ROLE_CREATE: '/roles/create',
    ROLE_DETAIL: '/roles/:id',
    CHARGING_CODE: '/chargingcode',
    COMPETITION: '/competition',
    COMPETITION_CREATE: '/competition/create',
    REPORTS: '/report',
    REPORT_CREATE: '/report/create',
    REPORT_DETAIL: '/report/:id',
    REPORT_FILTER: '/report/:id/filter',
    REWARDS: '/rewards',
    REWARD_CREATE: '/reward/create',
    REWARD_DETAIL: '/rewards/:id',
    RATING: '/rating',
    OUTAGE_NOTIFICATION: '/outagenotification',
    OUTAGE_NOTIFICATION_CREATE: '/outagenotification/create',
    TARGET_CAMPAIGNS: '/targetcampaigns',
    SIM_CARD_TYPES: '/sim-card-types',
    SIM_CARD_TYPE_CREATE: '/sim-card-types/create',
    SIM_CARD_TYPE_DETAIL: '/sim-card-types/:id',
    AFFILIATE_PROGRAM_PARTNERS: '/affiliateprogrampartners',
    AFFILIATE_PROGRAM_PARTNERS_CREATE: '/affiliateprogrampartner/create',
    AFFILIATE_PROGRAM_PARTNERS_DETAIL: '/affiliateprogrampartner/:id',
    AFFILIATE_PROGRAM_REWARDS: '/affiliateprogramrewards',
    AFFILIATE_PROGRAM_REWARDS_DETAIL: '/affiliateprogramrewards/:id',
    AFFILIATE_PROGRAM_REWARDS_CREATE: '/affiliateprogramrewards/create',
    AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS: '/affiliateprogramsubsciberrewardclaims',
    AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_CREATE: '/affiliateprogramsubsciberrewardclaims/create',
    GAME_CLANS: '/gameclans',
    TEXTS: '/texts',
    TEXT_CREATE: '/texts/create',
    TEXT_DETAIL: '/texts/:id',
    LINKS: '/links',
    LINK_CREATE: '/links/create',
    LINK_DETAIL: '/links/:id',

    DATASOURCES: '/datasources',
    DATASOURCE_CREATE: '/datasource/screate',
    DATASOURCE_DETAIL: '/datasources/:id',

    DATASOURCEQUERYCONTEXTS: '/datasourcequerycontexts',
    DATASOURCEQUERYCONTEXT_CREATE: '/datasourcequerycontexts/create',
    DATASOURCEQUERYCONTEXT_DETAIL: '/datasourcequerycontexts/:id',

    PUSHNOTIFICATIONCONFIGURATIONS: '/pushnotificationconfigurations',
    PUSHNOTIFICATIONCONFIGURATION_CREATE: '/pushnotificationconfigurations/create',
    PUSHNOTIFICATIONCONFIGURATION_DETAIL: '/pushnotificationconfigurations/:id',

    PUSHNOTIFICATIONTASKS: '/pushnotificationtasks',
    PUSHNOTIFICATIONTASK_CREATE: '/pushnotificationtasks/create',
    PUSHNOTIFICATIONTASK_DETAIL: '/pushnotificationtasks/:id',

    EVENTTRIGGERCONFIGURATIONS: '/eventtriggerconfigurations',
    EVENTTRIGGERCONFIGURATION_CREATE: '/eventtriggerconfigurations/create',
    EVENTTRIGGERCONFIGURATION_DETAIL: '/eventtriggerconfigurations/:id',

    /*entityLinks*/

    SIGN_IN: '/signin',
};

export type NAVIGATION_PATHS_TYPE = keyof typeof NAVIGATION_PATHS;
