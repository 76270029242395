import { EnumDescription } from "../../common/enums";

export enum REPORT_PARAMETER_TYPES_ENUM {
    DATE_TIME = 1,
    DATE = 2,
}

export const REPORT_PARAMETER_TYPES: EnumDescription[] = [
    { text: 'Datum a čas', value: REPORT_PARAMETER_TYPES_ENUM.DATE_TIME },
    { text: 'Datum', value: REPORT_PARAMETER_TYPES_ENUM.DATE },
];
