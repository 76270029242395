import React from "react";
import PropTypes from "prop-types";
import CSSTransition from "react-transition-group/CSSTransition";
import Translation from "../common/Translation";
import { ALERT_TYPE } from "../../common/constants";
import { alertAutoCloseConfig } from "./../../common/configs";
import {
  alertPropTypes,
  autoCloseConfigPropTypes
} from "../../common/propTypes";

const alertClasses = {
  error: "alert-error",
  warning: "alert-warning",
  info: "alert-info",
  success: "alert-success"
};

class Alert extends React.Component {
  constructor(props) {
    super(props);

    this.onClose = this.onClose.bind(this);
    this.onHide = this.onHide.bind(this);
    this.state = { showAlert: true };
  }

  componentDidMount() {
    if (
      !this.props.alert.disabledAutoClose &&
      ((this.props.alert.type === ALERT_TYPE.ERROR &&
        this.props.autoCloseConfig.autoCloseError) ||
        (this.props.alert.type === ALERT_TYPE.SUCCESS &&
          this.props.autoCloseConfig.autoCloseSuccess) ||
        (this.props.alert.type === ALERT_TYPE.INFO &&
          this.props.autoCloseConfig.autoCloseInfo) ||
        (this.props.alert.type === ALERT_TYPE.WARNING &&
          this.props.autoCloseConfig.autoCloseWarning))
    ) {
      this.timer = setTimeout(this.onHide, this.props.autoCloseConfig.timeout);
    }
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  alertClass(type) {
    return alertClasses[type] || alertClasses.success;
  }

  onHide() {
    this.setState({ showAlert: false });
  }

  onClose() {
    this.props.onClose(this.props.alert.id);
  }

  render() {
    const alert = this.props.alert;
    const alertClassName = `alert ${this.alertClass(alert.type)}`;
    const message = alert.message ? (
      alert.message
    ) : (
      <Translation tKey={alert.tKey} />
    );

    return (
      <CSSTransition
        in={this.state.showAlert}
        key={alert.id}
        timeout={500}
        classNames="alert"
        unmountOnExit
        onExited={this.onClose}
      >
        <div className={alertClassName}>
          <span onClick={this.onHide} className="close">
            ×
          </span>
          {message}
        </div>
      </CSSTransition>
    );
  }
}

Alert.propTypes = {
  autoCloseConfig: autoCloseConfigPropTypes,
  alert: alertPropTypes.isRequired,
  onClose: PropTypes.func
};

Alert.defaultProps = {
  autoCloseConfig: alertAutoCloseConfig
};

export default Alert;
