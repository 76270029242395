import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as accountMenuActions from '../../actions/accountMenuActions';
import * as userActions from '../../actions/userActions';
import Translation from './Translation';

const AccountMenu = ({ open, actions }) => {
  let accountMenu = "account-menu";
  if (open)
    accountMenu += " account-menu-opened";

  const signOut = (event) => {
    event.preventDefault();
    actions.signOut();
  };

  const refreshCache = (event) => {
    actions.refreshCache(event);
    actions.toggleAccountMenu(event);
  };

  return (
    <div className="header-tile">
      <a href="/" onClick={(event) => actions.toggleAccountMenu(event)}>
        <span className="icon icon-user"/>
      </a>
      <div className={accountMenu}>
        <ul>
          <li>
            <a href="/" onClick={refreshCache}>
              <span className="icon icon-cog">&nbsp;</span>
              <Translation tKey="RefreshCache"/>
            </a>
          </li>
          <li>
            <a href="/" onClick={(event) => signOut(event)}>
              <span className="icon icon-off">&nbsp;</span>
              <Translation tKey="SignOut"/>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

AccountMenu.propTypes = {
  actions: PropTypes.shape({
    toggleAccountMenu: PropTypes.func.isRequired,
    refreshCache: PropTypes.func.isRequired,
    signOut: PropTypes.func.isRequired,
  }),
  open: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    open: state.accountMenu.open
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators({...accountMenuActions, ...userActions}, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AccountMenu);
