import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';

import Table from '../common/Table';
import { GridColumn as Column } from '@progress/kendo-react-grid';
import StringCell from '../common/formatters/StringCell';
import BoolCell from '../common/formatters/BoolCell';

import Translation from '../common/Translation';
import { translate } from 'react-i18next';
import Alerts from '../common/Alerts';
import ActionButton from '../common/ActionButton';
import { navigateTo } from '../../actions/navigationActions';
// import * as enums from "../../common/enums";
import { TEXT_HORIZONTAL_ALIGN, NAVIGATION_PATHS } from '../../common/constants';
import Role from '../../entities/role';
import { fetchUserDependencies } from '../../actions/userActions';
import { nameofFactory } from '../../helpers/nameOfFactory';
import User from '../../entities/user';
import NavigableCell from '../common/formatters/navigableCell';
import { BaseListDispatchProps, BaseListProps } from '../common/types/baseDetail';
import RelationCell from '../common/formatters/RelationCell';
import { getData as getRoles, getUserReadableProperty as getRoleUserReadableProperty } from '../../reducers/roleReducer';
import { isLoadingDependencies } from '../../reducers/userReducer';

const nameof = nameofFactory<User>();

const actionUrl = 'users';

const defaultRewardTabelSort = [
    {
        dir: 'desc',
        field: 'dateOfCreate',
    },
];

interface UsersProp extends UsersStateProps, UsersDispatchProps, BaseListProps {}

interface UsersStateProps {
    roleUserReadableProperty: keyof Role;
    roles: Role[];

    loadingDependencies: boolean;
}

interface UsersDispatchProps extends BaseListDispatchProps {
    fetchUserDependencies: typeof fetchUserDependencies;
}

const Users = ({ t, navigateTo, roleUserReadableProperty, roles, fetchUserDependencies }: UsersProp) => {
    useEffect(() => {
        fetchUserDependencies();
    }, []);

    let table: any;

    const onNew = () => {
        navigateTo(NAVIGATION_PATHS.USERS_CREATE);
    };

    const onEdit = () => {
        table.getWrappedInstance().editSelected();
    };

    const onRemove = () => {
        table.getWrappedInstance().removeSelected();
    };

    return (
        <div>
            <div className="content-header">
                <h1>
                    <Translation tKey="Users" />
                </h1>
            </div>
            <div className="content-wrapper">
                <Alerts />
                <div className="panel">
                    <div className="panel-header">
                        {/*<UsersSearchBox/>*/}
                        <div className="actions">
                            <ActionButton text="Add" onClick={onNew} icon="plus" />
                            <ActionButton text="Edit" onClick={onEdit} icon="pencil" selection="one" />
                            <ActionButton text="Remove" onClick={onRemove} icon="delete" selection="multi" />
                        </div>
                        <div className="filter" />
                    </div>
                    <div className="panel-content">
                        <Table
                            path={'users'}
                            actionPath={actionUrl}
                            selectedField="selected"
                            ref={(el) => {
                                table = el;
                            }}
                            name="UsersTable"
                        >
                            <Column field="selected" width="50px" />
                            <Column
                                field={nameof('email')}
                                cell={(props) => (
                                    <NavigableCell
                                        {...props}
                                        actionUrl={actionUrl}
                                        navigateTo={navigateTo}
                                        component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                                    />
                                )}
                                title={t('Email')}
                            />
                            <Column field={nameof('firstname')} cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)} title={t('Firstname')} />
                            <Column field={nameof('lastname')} cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)} title={t('Lastname')} />
                            <Column
                                field={nameof('roleId')}
                                cell={(props) => (
                                    <RelationCell<Role> {...props} relationUserReadableProperty={roleUserReadableProperty} relations={roles} />
                                )}
                                title={t('Role')}
                            />
                            <Column field={nameof('isAdmin')} cell={BoolCell()} title={t('Admin')} width="110px" />
                        </Table>
                    </div>
                </div>
            </div>
        </div>
    );
};

const mapStateToProps = (state: any): UsersStateProps => {
    return {
        loadingDependencies: isLoadingDependencies(state),
        roleUserReadableProperty: getRoleUserReadableProperty(state),
        roles: getRoles(state),
    };
};
const mapDispatchToProps = (dispatch: Dispatch<UsersDispatchProps>): UsersDispatchProps => {
    return {
        navigateTo: bindActionCreators(navigateTo, dispatch),
        fetchUserDependencies: bindActionCreators(fetchUserDependencies, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(Users);
