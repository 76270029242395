import { getFlaggeValueFormEnumItems, isSelectedEnumFlagg, TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM } from '../common/enums';

const newsItemTransformationBeforeSave = (newsItem) => {
    const isSelectedCMTDataSource = isSelectedEnumFlagg(newsItem.dataSourceType, TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.CMT);
    const validFromOnlyDate = new Date(newsItem.validFrom.getFullYear(), newsItem.validFrom.getMonth(), newsItem.validFrom.getDate(), 0, 0, 0, 0);
    const validToOnlyDate = newsItem.validTo
        ? new Date(newsItem.validTo.getFullYear(), newsItem.validTo.getMonth(), newsItem.validTo.getDate(), 23, 59, 59, 0)
        : undefined;

    const transformedData = {
        ...newsItem,
        type: newsItem.type.value,
        platform: newsItem.platform.value,
        section: newsItem.section.value,
        dataSourceType: newsItem.isTargetedCampaign ? getFlaggeValueFormEnumItems(newsItem.dataSourceType) : null,
        validFrom: validFromOnlyDate.toUTCString(),
        validTo: validToOnlyDate ? validToOnlyDate.toUTCString() : null,
        isTargetedCampaign: newsItem.isTargetedCampaign,
        targetedCampaignCode: newsItem.isTargetedCampaign && isSelectedCMTDataSource ? newsItem.targetedCampaignCode : null,
        targetedCampaignConditionType: newsItem.isTargetedCampaign ? newsItem.targetedCampaignConditionType.value : 0,
        targetedCampaignDateSuffixType:
            newsItem.isTargetedCampaign && isSelectedCMTDataSource && newsItem.targetedCampaignUseDateSuffix
                ? newsItem.targetedCampaignDateSuffixType.value
                : null,
        campaignTitle: newsItem.isCampaign ? newsItem.campaignTitle : null,
        campaignMessage: newsItem.isCampaign ? newsItem.campaignMessage : null,
        dateOfCampaignStart: null,
        countOfCampaignSent: newsItem.isCampaign ? newsItem.countOfCampaignSent : 0,
        dateOfLastCampaignSent: newsItem.isCampaign ? newsItem.dateOfLastCampaignSent : null,
        bannerViewType: newsItem.bannerViewType.value,
    };

    const body = new FormData();

    Object.keys(transformedData).forEach((key) => {
        if (transformedData[key] !== null && transformedData[key] !== undefined) {
            body.append(key, transformedData[key]);
        }
    });

    return body;
};

export default newsItemTransformationBeforeSave;
