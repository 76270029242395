import React from 'react';
import PropTypes from 'prop-types';

const SubmitButton = ({ children, loading = false, className = '', onClick }) => {
    className = 'k-button k-primary ' + className;
    if (onClick) {
        return (
            <button type="button" className={className} disabled={loading} onClick={onClick}>
                {loading && <span className="k-icon k-i-loading">&nbsp;</span>}&nbsp;
                {children}
            </button>
        );
    }

    return (
        <button type="submit" className={className} disabled={loading}>
            {loading && <span className="k-icon k-i-loading">&nbsp;</span>}&nbsp;
            {children}
        </button>
    );
};

SubmitButton.propTypes = {
    children: PropTypes.node,
    loading: PropTypes.bool,
    className: PropTypes.string,
    onClick: PropTypes.func,
};

export default SubmitButton;
