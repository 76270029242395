import {
    EnumDescription,
    DATASOURCE_QUERY_TYPE_ENUM,
    DATASOURCE_TYPE_ENUM,
    DATASOURCE_TYPE_TYPES,
    getEnumItemByValue,
    DATASOURCE_QUERY_TYPE_TYPES,
    PLATFORMA_TYPES_ENUM,
    PLATFORMA_TYPES,
} from '../common/enums';

export default class PushNotificationTask {
    id: number;
    name: string;
    cronExpression: string;
    pushNotificationConfigurationId: number;
    dataSourceId: number;
    lastRunTime?: Date;
    nextRunTime?: Date;
    isOneTime: boolean;
    active: boolean;
    /*  query: string;
    queryContextId: number;
    queryType: number;
    queryTypeEnumDescription: EnumDescription | undefined;
    type: number;
    typeEnumDescription: EnumDescription | undefined;
    platform: number;
    platformEnumDescription: EnumDescription | undefined; */

    constructor(properties: Partial<PushNotificationTask> /* & Pick<PushNotificationConfiguration, 'type' | 'queryType' | 'platform'> */) {
        this.id = 0;
        this.name = '';
        this.cronExpression = '';
        this.pushNotificationConfigurationId = 0;
        this.dataSourceId = 0;
        this.isOneTime = false;
        this.active = false;
        
        /*  this.query = '';
        this.queryContextId = 0; 
        this.type = DATASOURCE_TYPE_ENUM.Query;
        this.queryType = DATASOURCE_QUERY_TYPE_ENUM.OneRecord;
        this.platform = PLATFORMA_TYPES_ENUM.ALL; */

        Object.assign(this, properties);
        this.lastRunTime = properties.lastRunTime ? new Date(properties.lastRunTime) : properties.lastRunTime;
        this.nextRunTime = properties.nextRunTime ? new Date(properties.nextRunTime) : properties.nextRunTime;

        /* this.typeEnumDescription = getEnumItemByValue(properties.type, DATASOURCE_TYPE_TYPES);
        this.queryTypeEnumDescription = getEnumItemByValue(properties.queryType, DATASOURCE_QUERY_TYPE_TYPES);
        this.platformEnumDescription = getEnumItemByValue(properties.platform, PLATFORMA_TYPES); */
    }
}
