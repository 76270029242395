import * as types from '../actions/actionTypes';

const affiliateProgramSubscriberRewardClaimsInitialState = {
    loadingDependencies: false,
};

const affiliateProgramSubscriberRewardClaimsReducer = (state = affiliateProgramSubscriberRewardClaimsInitialState, action: any) => {
    switch (action.type) {
        case types.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_LOADING_DEPENDENCIES_BEGIN:
            return {
                ...state,
                loadingDependencies: true,
            };
        case types.AFFILIATE_PROGRAM_SUBSCRIBER_REWARD_CLAIMS_LOADING_DEPENDENCIES_END:
            return {
                ...state,
                loadingDependencies: false,
            };
        default:
            return state;
    }
};

export default affiliateProgramSubscriberRewardClaimsReducer;

export const isLoadingDependencies = (state: any): boolean => {
    return state.affiliateProgramSubscriberRewardClaims.loadingDependencies === true;
};
