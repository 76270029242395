export default class AffiliateProgramSubscriberRewardClaim {
    id: number;
    msisdn: string;
    affiliateProgramPartnerId: number;
    affiliateProgramRewardId: number;
    dateOfCreate?: Date;

    constructor(properties: Partial<AffiliateProgramSubscriberRewardClaim>) {
        this.id = 0;
        this.msisdn = '420';
        this.affiliateProgramPartnerId = -1;
        this.affiliateProgramRewardId = -1;

        Object.assign(this, properties);

        this.dateOfCreate = properties.dateOfCreate ? new Date(properties.dateOfCreate) : properties.dateOfCreate;
    }
}
