import { getEnumItemByValue, PLATFORMA_TYPES, OUTAGE_NOTIFICATION_CONDITION_TYPE, OUTAGE_NOTIFICATION_FILTER_TYPE } from '../common/enums';

export default class OutageNotification {
    id;
    name;
    text;
    platform;
    notificationTo;
    notificationFrom;
    outageTo;
    outageFrom;
    order;
    filter;
    condition;
    version;
    groupName;

    constructor(properties) {
        Object.assign(this, properties);

        this.platform = getEnumItemByValue(properties.platform, PLATFORMA_TYPES);
        this.filter = getEnumItemByValue(properties.filter, OUTAGE_NOTIFICATION_FILTER_TYPE);
        this.condition = getEnumItemByValue(properties.condition, OUTAGE_NOTIFICATION_CONDITION_TYPE);

        this.notificationTo = properties.notificationTo ? new Date(properties.notificationTo) : properties.notificationTo;
        this.notificationFrom = properties.notificationFrom ? new Date(properties.notificationFrom) : properties.notificationFrom;

        this.outageTo = properties.outageTo ? new Date(properties.outageTo) : properties.outageTo;
        this.outageFrom = properties.outageFrom ? new Date(properties.outageFrom) : properties.outageFrom;
    }
}
