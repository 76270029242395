import axios from "axios";

import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as alertActions from "./alertActions";
import ResponseStatus from "../utils/responseStatus";

export const exportSubscribersToEcomail = () => async (dispatch, getState) => {
  dispatch(beginAjaxCall());
  dispatch(LOADING_BEGIN);
  dispatch(alertActions.alertClear());

  axios
    .post(`ecomail`)
    .then(response => {
      const data = response.data;
      const resultCode = data.resultCode;
      const resultReason = data.resultReason;
      const failedResponses = data.failedResponses;
      // const successfulResponses = data.successfulResponses;
      const subscribersWithEmptyEmailCount =
        data.subscribersWithEmptyEmailCount;
      const subscribersWithInvalidEmailCount =
        data.subscribersWithInvalidEmailCount;
      const subscribersWithValidEmailCount =
        data.subscribersWithValidEmailCount;
      const subscribersWithDistinctEmailCount =
        data.subscribersWithDistinctEmailCount;

      const subscribersSummaryCountMessage = `Databáze obsahuje ${subscribersWithEmptyEmailCount + subscribersWithInvalidEmailCount + subscribersWithValidEmailCount} kontaktů.`;
        dispatch(
          alertActions.alertWarningMessageAdd(
            subscribersSummaryCountMessage,
            true
          )
        );

      const subscribersWithEmptyEmailMessage = `Počet kontaktů s nevyplněným emailem: ${subscribersWithEmptyEmailCount}.`;
      dispatch(
        alertActions.alertWarningMessageAdd(
          subscribersWithEmptyEmailMessage,
          true
        )
      );

      const subscribersWithInvalidEmailMessage = `Počet kontaktů s nevalidním emailem: ${subscribersWithInvalidEmailCount}.`;
      dispatch(
        alertActions.alertWarningMessageAdd(
          subscribersWithInvalidEmailMessage,
          true
        )
      );

      const subscribersWithDuplicateEmailMessage = `Počet kontaktů s jedním emailem pro dvě a více čísel: ${subscribersWithValidEmailCount - subscribersWithDistinctEmailCount}.`;
      dispatch(
        alertActions.alertWarningMessageAdd(
          subscribersWithDuplicateEmailMessage,
          true
        )
      );
    
      if (
        !ResponseStatus.IsOK(response.status) ||
        resultCode != 0 ||
        failedResponses.some(failedResponse => failedResponse)
      ) {
        const failedMessage = `Při exportu došlo k chybě: ${resultReason}`;
        dispatch(alertActions.alertErrorMessageAdd(failedMessage, true));

        const partialExportMessage = `Byla exportována jen část kontaktů!`;
        dispatch(alertActions.alertErrorMessageAdd(partialExportMessage, true));
      } else {       
        const successfulMessage = `Úspšně exportováno ${subscribersWithDistinctEmailCount} kontaktů.`;
        dispatch(alertActions.alertSuccessMessageAdd(successfulMessage, true));
      }

      dispatch(LOADING_END);
    })
    .catch(error => {
      ajaxCallError();
      dispatch(LOADING_END);
      dispatch(
        alertActions.alertErrorAdd(
          "Chyba, data se nepodařilo exportovat do Ecomailu."
        )
      );
      throw error;
    });
};

const LOADING_BEGIN = {
  type: types.ECOMAIL_LOADING_BEGIN
};

const LOADING_END = {
  type: types.ECOMAIL_LOADING_END
};
