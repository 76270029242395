import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import RewardDetail from './rewardDetail';
import { getRowById } from '../../reducers/tableReducer';
import { navigateToRewards } from '../../actions/navigationActions';
import { updateReward } from '../../actions/rewardActions';
import { BaseDetailProps } from '../common/types/baseDetail';
import Reward from '../../entities/reward';

interface RewardDetailUpdateProps extends BaseDetailProps<Reward> {}

const RewardDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: BaseDetailProps<Reward>) => {
    return (
        <RewardDetail
            initialValues={initialValues}
            t={t}
            submitAction={submitAction}
            headerText={t('UpdateReward') + ' - ' + initialValues.name}
            cancelAction={cancelAction}
        />
    );
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RewardDetailUpdateProps>) => {
    return {
        submitAction: bindActionCreators(updateReward, dispatch),
        cancelAction: bindActionCreators(navigateToRewards, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(RewardDetailUpdate);
//export default connect(mapStateToProps, mapDispatchToProps)(RewardDetailUpdate);
