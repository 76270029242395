import React from 'react';
import PropTypes from "prop-types";
import {connect} from 'react-redux';
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import { chargingCodeDetailPropTypes } from "../../common/propTypes";
import ChargingCodeDetail from './chargingCodeDetail';
import {getRowById} from "../../reducers/tableReducer";
import {updateChargingCode} from "../../actions/chargingCodeActions";
import {navigateToChargingCode} from "../../actions/navigationActions";

const ChargingCodeDetailUpdate = ({initialValues, saveAction, cancelAction, t}) => {
  return <ChargingCodeDetail initialValues={initialValues} onSubmit={saveAction} headerText={t('ChargingCodeLabel')} onCancel={cancelAction}/>;
};

ChargingCodeDetailUpdate.propTypes = {
  initialValues: chargingCodeDetailPropTypes.isRequired,
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapStateToProps = (state, {match}) => {
  const id = match.params.id;

  return {
    lang: state.app.lang,
    initialValues: getRowById(state, id)
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAction: bindActionCreators(updateChargingCode, dispatch),
    cancelAction: bindActionCreators(navigateToChargingCode, dispatch)
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(ChargingCodeDetailUpdate);

