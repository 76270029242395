import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { navigateToSimCardTypes } from './navigationActions';
import ResponseStatus from '../utils/responseStatus';
import SimCardType from '../entities/simCardType';

export const createSimCardType = (data: SimCardType) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    axios
        .post(`simcardtype`, data)
        .then((response) => {
            if (ResponseStatus.IsCreated(response.status)) {
                dispatch({
                    type: types.SIM_CARD_TYPE_ADD,
                });

                dispatch(navigateToSimCardTypes());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};

export const updateSimCardType = (data: SimCardType) => (dispatch: Dispatch<any>) => {
    dispatch(beginAjaxCall());

    axios
        .put(`simcardtype`, data)
        .then((response) => {
            if (ResponseStatus.IsOK(response.status)) {
                dispatch({
                    type: types.SIM_CARD_TYPE_UPDATE,
                    data: response.data,
                });

                dispatch(navigateToSimCardTypes());
            } else {
                dispatch(ajaxCallError());
            }
        })
        .catch((error) => {
            dispatch(ajaxCallError());
            throw error;
        });
};
