import { OUTAGE_NOTIFICATION_FILTER_TYPE } from '../common/enums';

const outageNotificationItemTransformationBeforeSave = (outageNotificationItem) => {
    const isVersionFilter = outageNotificationItem.filter == OUTAGE_NOTIFICATION_FILTER_TYPE[1];
    const isGroupNameFilter = outageNotificationItem.filter == OUTAGE_NOTIFICATION_FILTER_TYPE[2];

    const notificationFrom = outageNotificationItem.notificationFrom;
    const notificationTo = outageNotificationItem.notificationTo;
    const outageFrom = outageNotificationItem.outageFrom;
    const outageTo = outageNotificationItem.outageTo;

    const transformedData = {
        ...outageNotificationItem,
        platform: outageNotificationItem.platform.value,
        filter: outageNotificationItem.filter.value,
        condition: isVersionFilter ? outageNotificationItem.condition.value : null,
        version: isVersionFilter ? outageNotificationItem.version : null,
        groupName: isGroupNameFilter ? outageNotificationItem.groupName : null,       
        
        notificationFrom: notificationFrom.toUTCString(),
        notificationTo: notificationTo.toUTCString(),
        outageFrom: outageFrom ? outageFrom.toUTCString() : null,
        outageTo: outageTo ? outageTo.toUTCString() : null,
    };

    return transformedData;
};

export default outageNotificationItemTransformationBeforeSave;
