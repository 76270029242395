import axios from "axios";
import * as types from "./actionTypes";
import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import * as alertActions from "./alertActions";
import { navigateToNews } from "./navigationActions";
import { fetchNewsPages } from "./newsPageActions";
import { fetchServices } from "./serviceActions";
import { fetchRewards } from "./rewardActions";
import { fetchMarketingActions } from "./marketingActions";

import ResponseStatus from "../utils/responseStatus";
import newsItemTransformationBeforeSave from "../data-transformations/newsItemTransformationBeforeSave";

export const createNews = data => dispatch => {
  dispatch(beginAjaxCall());

  const transformedData = newsItemTransformationBeforeSave(data);

  axios
    .post(`news`, transformedData)
    .then(response => {
      if (ResponseStatus.IsCreated(response.status)) {
        dispatch({
          type: types.NEWS_ADD
        });

        dispatch(navigateToNews());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch(error => {
      dispatch(ajaxCallError());
      throw error;
    });
};

export const updateNews = data => dispatch => {
  dispatch(beginAjaxCall());

  const transformedData = newsItemTransformationBeforeSave(data);

  axios
    .put(`news`, transformedData)
    .then(response => {
      if (ResponseStatus.IsOK(response.status)) {
        dispatch({
          type: types.NEWS_UPDATE,
          data: response.data
        });

        dispatch(navigateToNews());
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch(error => {
      dispatch(ajaxCallError());
      throw error;
    });
};

export const fetchNewsDependencies = () => async (dispatch, getState) => {
  try {
    dispatch(LOADING_DEPENDENCIES_BEGIN);

    await fetchNewsPages()(dispatch, getState);
    await fetchServices()(dispatch, getState);
    await fetchRewards()(dispatch, getState);
    await fetchMarketingActions()(dispatch, getState);
  } catch (exception) {
    throw exception; //FUJ
  } finally {
    dispatch(LOADING_DEPENDENCIES_END);
  }
};

export const isLoaded = (state) => {
  return state.news.loaded === true;
};

export const fetchNews = () => async (dispatch, getState) => {
  if (isLoaded(getState())) {
    return;
  }

  try {
    dispatch(beginAjaxCall());

    const response = await axios.get(`news/`);

    if (ResponseStatus.IsOK(response.status)) {
      dispatch({
        type: types.NEWS_FETCH,
        data: response.data
      });
    } else {
      dispatch(ajaxCallError());
    }
  }
  catch (error) {
    dispatch(ajaxCallError());
    throw error;
  }
};

const LOADING_DEPENDENCIES_BEGIN = {
  type: types.NEWS_LOADING_DEPENDENCIES_BEGIN
};

const LOADING_DEPENDENCIES_END = {
  type: types.NEWS_LOADING_DEPENDENCIES_END
};
