import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import Table from "../common/Table";
import { GridColumn as Column } from "@progress/kendo-react-grid";

import Translation from "../common/Translation";
import { translate } from "react-i18next";
import Alerts from "../common/Alerts";
import ActionButton from "../common/ActionButton";
import { navigateTo } from "../../actions/navigationActions";
import { TEXT_HORIZONTAL_ALIGN } from "../../common/constants";
import NavigableCell from "../common/formatters/navigableCell";
import StringCell from "../common/formatters/StringCell";
const actionUrl = "chargingcode";

const ChargingCode = ({ t, navigateTo }) => {
  let table;

  const onNew = () => {
    navigateTo("/chargingcode/create");
  };

  const onEdit = () => {
    table.getWrappedInstance().editSelected();
  };

  const onRemove = () => {
    table.getWrappedInstance().removeSelected();
  };

  return (
    <div>
      <div className="content-header">
        <h1>
          <Translation tKey="ChargingCodesLabel" />
        </h1>
      </div>
      <div className="content-wrapper">
        <Alerts />
        <div className="panel">
          <div className="panel-header">
            <div className="actions">
              <ActionButton text="Add" onClick={onNew} icon="plus" />
              <ActionButton
                text="Edit"
                onClick={onEdit}
                icon="pencil"
                selection="one"
              />
              <ActionButton
                text="Remove"
                onClick={onRemove}
                icon="delete"
                selection="multi"
              />
            </div>
            <div className="filter" />
          </div>
          <div className="panel-content">
            <Table
              path={"chargingcode"}
              actionPath={actionUrl}
              selectedField="selected"
              ref={el => {
                table = el;
              }}
              name="ChargingCodeTable"
            >
              <Column field="selected" width="50px" />
              <Column
                field="code"
                cell={props => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                  />
                )}
                title={t("Code")}
              />
              <Column
                field="name"
                cell={props => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                  />
                )}
                title={t("Name")}
              />
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

ChargingCode.propTypes = {
  t: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: path => {
      dispatch(navigateTo(path));
    }
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  translate("common")
)(ChargingCode);
