import Link from '../entities/link';

const linkItemTransformationBeforeSave = (item: Link) => {
    const transformedData = {
        ...item,
        type: item.typeEnumDescription?.value,
    } as { [key: string]: any };

    return transformedData;
};

export default linkItemTransformationBeforeSave;
