export default class AffiliateProgramPartner {
    id: number;
    name: string;
    code: string;
    description: string;

    constructor(properties: Partial<AffiliateProgramPartner>) {
        this.id = 0;
        this.name = '';
        this.code = '';
        this.description = '';

        Object.assign(this, properties);
    }
}
