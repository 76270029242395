import React, { PureComponent } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "redux";

class ToggableActionButton extends PureComponent {
  constructor(props) {
    super(props);

    let enabled = true;
    if (props.selection == "multi") {
      enabled = props.multiRowSelected;
    }

    if (props.selection == "one") {
      enabled = props.oneRowSelected;
    }
    this.state = { enabled: enabled, toggled: false };
  }

  handleClick = e => {
    e.preventDefault();

    if (this.state.enabled) {
      if (this.state.toggled) {
        this.props.onNotSelected(e);
      } else {
        this.props.onSelected(e);
      }
    }

    this.setState((state, props) => ({
      toggled: !state.toggled
    }));
  };

  getContent() {
    if (this.props.children) {
      return (
        <React.Fragment>
          <div>{this.props.children}</div>
          <div>{this.props.t(this.props.text)}</div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <div>
          <span className={`k-icon k-i-${this.props.icon}`}>&nbsp;</span>
        </div>
        <div>{this.props.t(this.props.text)}</div>
      </React.Fragment>
    );
  }

  render() {
    const contentComponent = this.getContent();
    let wrapperClass = "d-action-button";
    if (!this.state.enabled || this.props.disabled) {
      wrapperClass = `${wrapperClass} d-action-button-disabled`;
    }

    if (this.state.toggled) {
      wrapperClass = `${wrapperClass} d-action-button__toggled`;
    }

    return (
      <button
        className={wrapperClass}
        onClick={this.handleClick}
        disabled={this.props.disabled}
      >
        {contentComponent}
      </button>
    );
  }
}

ToggableActionButton.propTypes = {
  text: PropTypes.string,
  onSelected: PropTypes.func,
  onNotSelected: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  t: PropTypes.func,
  oneRowSelected: PropTypes.bool,
  multiRowSelected: PropTypes.bool,
  selection: PropTypes.string,
  children: PropTypes.element,
  disabled: PropTypes.bool
};

const mapStateToProps = state => {
  return {
    oneRowSelected: state.table.selected == 1,
    multiRowSelected: state.table.selected > 0
  };
};

export default compose(
  translate("common"),
  connect(mapStateToProps, null)
)(ToggableActionButton);
