import React from 'react';
import PropTypes from 'prop-types';
import { bindActionCreators, compose } from 'redux';
import { connect } from 'react-redux';
import queryString from 'query-string';
import {Form, Field} from 'react-final-form';
import { Link } from 'react-router-dom';

import * as userActions from '../../actions/userActions';
import SubmitButton from '../common/SubmitButton';
import Translation from '../common/Translation';
import { translate } from 'react-i18next';
import * as propTypes from '../../common/propTypes';
import Input from '../common/Input';
import LoadingIndicator from "../common/LoadingIndicator";

class ResetPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      password: '',
      confirmPassword: '',
      errors: {},
      token: ''
    };
  }

  componentDidMount() {
   
  }

  translatedFormValidation = (values) =>{
    const { t } = this.props;
    const errors = {};

    if (!values.password) {
      errors.password = t('PasswordIsRequired');
    }

    if (values.password && (values.password !== values.confirmPassword)) {
        errors.confirmPassword = t('PasswordsDontMatchRetype');
    }
    
    return errors;
  }
  
  onSubmit = (values) => {
    const search = this.props.location.search;
    const parsed = queryString.parse(search);

    if(parsed.key) {
      this.props.actions.setNewPassword(values.password, parsed.key);   
    }
  }

  renderForm = () => {   
    let { loading } = this.props;

    return (
      <div className="body">
        <div>
          <h2><Translation tKey="PasswordReset" /></h2>
          <p>
            <Translation tKey="TypePasswordTwice" />
         </p>
        </div>
        <div>
        <Form
                    validate={this.translatedFormValidation}
                    onSubmit={this.onSubmit}
                    render={({handleSubmit, reset, submitting, pristine, values, valid, form}) => (
                    <form className="k-form" onSubmit={handleSubmit}>
                    {this.props.loading && <LoadingIndicator/>}                
                    <label className="k-form-field-white" htmlFor="password">
                        <span><Translation tKey="Password"/></span>
                        <Field name="password"
                            component={Input}
                            type="password"
                        // placeholder={t("Name")}
                        />
                    </label>

                    <label className="k-form-field-white" htmlFor="confirmPassword">
                        <span><Translation tKey="ConfirmPassword"/></span>
                        <Field name="confirmPassword"
                            component={Input}
                            type="password"
                        // placeholder={t("Name")}
                        />
                    </label>

                        <div className="actions">
                        <SubmitButton loading={loading || submitting}><Translation tKey="SetPassword" /></SubmitButton>
                        </div> 
                        <div className="links">
                        <div>
                            <Link to="/signin"><Translation tKey="SignIn" /></Link>
                        </div> 
                        </div>                 
                    </form>
                )}/>    
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="signIn-wrapper">
        <div className="signIn">
          <div className="box">
            <div className="header">&nbsp;</div>
            {this.renderForm()}
            <div className="footer">&nbsp;</div>
          </div>
        </div>
      </div>);
  }
}

ResetPassword.propTypes = {
  actions: PropTypes.object,
  loading: PropTypes.bool,
  query: PropTypes.object,
  t: PropTypes.func,
  location: propTypes.location.isRequired,
};

const mapStateToProps = (state, ownProps) => {
  return {
    loading: state.ajaxStatus > 0,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    actions: bindActionCreators(userActions, dispatch)
  };
};

export default compose(translate('common'), connect(mapStateToProps, mapDispatchToProps))(ResetPassword);
