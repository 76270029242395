import EventTriggerConfiguration from '../entities/eventTriggerConfiguration';

const EventTriggerConfigurationDataTransformationAfterFetch = (data: any[]) => {
    return data.map((row) => {
        return new EventTriggerConfiguration(row);
    });
};

export default EventTriggerConfigurationDataTransformationAfterFetch;

