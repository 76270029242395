import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import AffiliateProgramRewardDetail from './affiliateProgramRewardDetail';
import { createAffiliateProgramReward } from '../../actions/affiliateProgramRewardsActions';
import { navigateToAffiliateProgramRewards } from '../../actions/navigationActions';
import AffiliateProgramReward from '../../entities/affiliateProgramReward';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';

const initialValues = new AffiliateProgramReward({});

interface AffiliateProgramRewardDetailCreateProps extends BaseDetailProps<AffiliateProgramReward> {
    t: (key: string) => string;
}

const RewardDetailCreate = ({ submitAction, cancelAction, t }: AffiliateProgramRewardDetailCreateProps) => {
    return (
        <AffiliateProgramRewardDetail
            t={t}
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('CreateAffiliateProgramReward')}
            cancelAction={cancelAction}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch<AffiliateProgramRewardDetailCreateProps>): BaseDetailDispatchProps<AffiliateProgramReward> => {
    return {
        submitAction: bindActionCreators(createAffiliateProgramReward, dispatch),
        cancelAction: bindActionCreators(navigateToAffiliateProgramRewards, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(RewardDetailCreate);
