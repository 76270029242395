import React from "react";

import ToggableActionButton from "../common/ToggableActionButton";
import ActionButton from "../common/ActionButton";

export default class OutageNotificationActionsBar extends React.Component {
  constructor() {
    super();

    this.state = {
      filters: {},
    };
  }

  onShowActive = () => {
    const filters = [];
    filters[0] = {
      field: "notificationTo",
      operator: "gte",
      value: new Date().toISOString()
    };   
   
    this.props.filter(filters);
  };

  onShowAll = () => {
    this.props.clearFilter();
    this.setState({ filters: {} });
  };

  isFilterActive = (filterName) => {
    return !!this.state.filters[filterName];
  };

  render() {
    return (
      <div className="actions">
        <ActionButton text="Add" onClick={this.props.new} icon="plus" />
        <ActionButton
          text="Edit"
          onClick={this.props.edit}
          icon="pencil"
          selection="one"
        />
        <ActionButton
          text="Remove"
          onClick={this.props.remove}
          icon="delete"
          selection="multi"
        />       
        <ToggableActionButton
          text="ShowActive"
          onSelected={this.onShowActive}
          onNotSelected={this.onShowAll}
          icon="filter"
        />
      </div>
    );
  }
}
