const competitionItemTransformationBeforeSave = (competitionItem) => {
  const validFromOnlyDate = new Date(
    competitionItem.validFrom.getFullYear(),
    competitionItem.validFrom.getMonth(),
    competitionItem.validFrom.getDate(),
    0,0,0,0
  );  

  const validToOnlyDate = competitionItem.validTo
    ? new Date(
      competitionItem.validTo.getFullYear(),
      competitionItem.validTo.getMonth(),
      competitionItem.validTo.getDate(),
        23,59,59,0
      )
    : undefined;   

  const transformedData ={
    ...competitionItem,
    targetCustomer: competitionItem.targetCustomer.value,
    platform: competitionItem.platform.value,
    validFrom: validFromOnlyDate.toUTCString(),
    validTo: validToOnlyDate ? validToOnlyDate.toUTCString() : null,
  };

  const body = new FormData();

  Object.keys(transformedData).forEach((key) => {
    if (transformedData[key]) {
      body.append(key, transformedData[key]);
    }
  });

  return body;
};

export default competitionItemTransformationBeforeSave;
