import axios from "axios";
import {imageBaseUrl} from "../common/configs";


const convertStringToDate = (response) => {
    // Identify data fields returning from server as ISO_8601 formatted dates
    const ISO_8601 = /(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})Z/;

    // TODO: podpora pro pole (grid)
    let data = response.data;
    Object.keys(data).forEach(function (k) {
      if (ISO_8601.test(data[k])) {
        data[k] = new Date(Date.parse(data[k]));
      }
    });

    return response;
};

const fixImageUrl = (response) => {
  const data = response.data;

  if (data.data && Array.isArray(data.data)) {
    data.data.forEach((item) => {
      fixImageUrlOnItem(item);
    });
  }
  else {
    fixImageUrlOnItem(data);
  }

  return response;
};

const propertyNames = ['imageUrl', 'imageUrlV2', 'detailImageUrl'];
const fixImageUrlOnItem = (item) => {
  Object.keys(item).forEach((property) => {
    if (propertyNames.includes(property) && item[property]) {
      item[property] = imageBaseUrl + item[property];
    }
  });
};

//TODO - updelat pipeline
export const registerInterceptors = () => {
  axios.interceptors.response.use((response) =>
      fixImageUrl(convertStringToDate(response)),
    function (error) {
      // Do something with response error
      return Promise.reject(error);
    }
  );

  // Default config for AXIOS HTTP client
  axios.interceptors.request.use(function (config) {
    config.withCredentials = true;
    return config;
  }, function (error) {
    return Promise.reject(error);
  });
};
