import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { translate } from "react-i18next";
import { compose } from "redux";
import { Form, Field } from "react-final-form";
import createDecorator from "final-form-calculate";

import * as enums from "../../common/enums";
import * as propTypes from "../../common/propTypes";

import Alerts from "../common/Alerts";
import Translation from "../common/Translation";
import CancelButton from "../common/CancelButton";
import { isLoadingDependencies } from "../../reducers/newsReducer";
import Input from "../common/Input";
import DatePicker from "../common/DatePicker";
import DropDown from "../common/DropDown";
import Rating from "../common/Rating";
import { isEmpty } from "../../utils/isEmpty";
import LoadingIndicator from "../common/LoadingIndicator";

const getImageUrl = (imageValue, oldImageUrl) => {
  if (oldImageUrl) {
    URL.revokeObjectURL(oldImageUrl);
  }

  let newImageUrl = null;
  if (!isEmpty(imageValue)) {
    newImageUrl = URL.createObjectURL(imageValue);
  }

  return newImageUrl;
};

const formDecorator = createDecorator(
  {
    field: "image",
    updates: {
      imageUrl: (imageValue, allValues) => {
        const oldImageUrl = allValues.imageUrl;

        return getImageUrl(imageValue, oldImageUrl);
      }
    }
  },
  {
    field: "detailImage",
    updates: {
      detailImageUrl: (imageValue, allValues) => {
        const oldImageUrl = allValues.detailImageUrl;

        return getImageUrl(imageValue, oldImageUrl);
      }
    }
  }
);

class RatingDetail extends Component {
  constructor(props) {
    super(props);

    //this.state = {newsPages: [], newsServices: [], files: []};
    this.translatedFormValidation = this.translatedFormValidation.bind(this);
  }

  componentDidMount() {}

  translatedFormValidation() {
    const errors = {};

    return errors;
  }

  render() {
    console.debug("RatingDetail.render");

    const { initialValues, headerText, onCancel } = this.props;

    return (
      <div>
        <div className="content-header">
          <h1>{headerText}</h1>
        </div>
        <div className="content-wrapper">
          <Alerts />
          <div className="panel card col-xs-12 col-sm-6">
            <Form
              validate={this.translatedFormValidation}
              onSubmit={this.props.onSubmit}
              initialValues={initialValues}
              decorators={[formDecorator]}
              render={({
                handleSubmit              }) => (
                <form className="k-form" onSubmit={handleSubmit}>
                  {this.props.loadingDependencies && <LoadingIndicator />}

                  <div className={"row"}>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="msisdn"
                    >
                      <span>
                        <Translation tKey="MSISDN" />
                      </span>
                      <Field name="msisdn" component={Input} readOnly={true} />
                    </label>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="value"
                    >
                      <span>
                        <Translation tKey="RatingValue" />
                      </span>
                      <Field
                        name="value"
                        component={Rating}
                        readOnly={true}
                        // placeholder={t("validFrom")}
                      />
                    </label>
                  </div>

                  <div className={"row"}>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="dateOfCreate"
                    >
                      <span>
                        <Translation tKey="DateOfCreate" />
                      </span>
                      <Field name="dateOfCreate" component={DatePicker} readOnly={true} />
                    </label>
                    <label
                      className="k-form-field col-xs-12 col-sm-6"
                      htmlFor="platform"
                    >
                      <span>
                        <Translation tKey="RatingValue" />
                      </span>
                      <Field
                      name="platform"
                      component={DropDown}
                      readOnly={true}
                      textField="text"
                      dataSource={enums.PLATFORMA_TYPES}
                    />
                    </label>
                  </div>

                  <label className="k-form-field">
                    <span>
                      <Translation tKey="Event" />
                    </span>
                    <Field
                      name="trigger"
                      component={DropDown}
                      textField="text"
                      dataSource={enums.RATING_TRIGGER_TYPES}
                      readOnly={true}
                    />
                  </label>

                  <label className="k-form-field">
                    <span>
                      <Translation tKey="RatingText" />
                    </span>
                    <Field
                      name="description"
                      component="textarea"
                      className="k-textarea"
                      rows="8"
                      style={{ width: "100%" }}
                      readOnly={true}
                      // placeholder={t("ShortDescription")}
                    />
                  </label>

                  <div className="footer">
                    {/* <SubmitButton loading={submitting}>
                      <Translation tKey="Save" />
                    </SubmitButton> */}
                    <CancelButton onClick={onCancel}>
                      <Translation tKey="Cancel" />
                    </CancelButton>
                  </div>
                </form>
              )}
            />
          </div>
        </div>
      </div>
    );
  }
}

RatingDetail.propTypes = {
  t: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  initialValues: propTypes.newsPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  loadingDependencies: PropTypes.bool.isRequired
};

const mapStateToProps = state => {
  return {
    lang: state.app.lang,
    loadingDependencies: isLoadingDependencies(state)
  };
};

const mapDispatchToProps = () => {
  return {};
};

export default compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  ),
  translate("common")
)(RatingDetail);
