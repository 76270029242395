import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose, bindActionCreators } from "redux";

import Table from "../common/Table";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import DateCell from "../common/formatters/DateCell";
import EnumCell from "../common/formatters/EnumCelll";
import StringCellWithIcon from "../common/formatters/StringCellWithIcon";
import BoolCell from "../common/formatters/BoolCell";
import NavigableCell from "../common/formatters/navigableCell";
import StringCell from "../common/formatters/StringCell";
import Translation from "../common/Translation";
import { translate } from "react-i18next";
import Alerts from "../common/Alerts";
import ActionButton from "../common/ActionButton";
import ToggableActionButton from "../common/ToggableActionButton";
import { NEWS } from "../../data-transformations/dataTransformationTypes";
import { navigateTo } from "../../actions/navigationActions";
import * as enums from "../../common/enums";
import {
  TEXT_HORIZONTAL_ALIGN,
  NAVIGATION_PATHS
} from "../../common/constants";

const defaultNewsTabelSort = [
  {
    dir: "desc",
    field: "validFrom"
  }
];

const actionUrl = "news";

const News = ({ t, navigateTo, sendPushNotificationsToBetaTesters }) => {
  let table;

  const onNew = () => {
    navigateTo(NAVIGATION_PATHS.NEWS_CREATE);
  };

  const onEdit = () => {
    table.getWrappedInstance().editSelected();
  };

  const onRemove = () => {
    table.getWrappedInstance().removeSelected();
  };

  const onClone = () => {
    table.getWrappedInstance().cloneSelected();
  };

  const onShowActive = () => {
    const filters = [];
    filters[0] = {
      field: "validTo",
      operator: "gte",
      value: new Date().toISOString()
    };
    filters[1] = { field: "validTo", operator: "eq", logicalOperator: 1 };

    table.getWrappedInstance().customFilter(filters);
  };

  const onShowAll = () => {
    table.getWrappedInstance().onFilterClear();
  };

  const customRowRender = (trElement, rowProps, data) => {
    console.warn("News.rowRender");

    /*const isRowWithSameOrder = data.some((item)=>{
      return item.order === rowProps.dataItem.order && item.id !== rowProps.dataItem.id;
    });
    const orange = { backgroundColor: "rgba(255,190, 12,1)" };
    const trProps = { style: isRowWithSameOrder ? orange : null };
    */
    //return React.cloneElement(trElement, { ...trProps }, trElement.props.children);

    return trElement;
  };

  const condtionToShowIcon = (dataItem, data) => {
    /*  const isRowWithSameOrder = data.some((item)=>{
      return item.order === dataItem.order && item.id !== dataItem.id;
    });

    return isRowWithSameOrder; */

    return false;
  };

  return (
    <div>
      <div className="content-header">
        <h1>
          <Translation tKey="News" />
        </h1>
      </div>
      <div className="content-wrapper">
        <Alerts />
        <div className="panel">
          <div className="panel-header">
            {/*<NewsSearchBox/>*/}
            <div className="actions">
              <ActionButton text="Add" onClick={onNew} icon="plus" />
              <ActionButton
                text="Edit"
                onClick={onEdit}
                icon="pencil"
                selection="one"
              />
              <ActionButton
                text="Remove"
                onClick={onRemove}
                icon="delete"
                selection="multi"
              />
              <ActionButton
                text="Clone"
                onClick={onClone}
                icon="copy"
                selection="one"
              />
              <ToggableActionButton
                text="ShowActive"
                onSelected={onShowActive}
                onNotSelected={onShowAll}
                icon="filter"
              />            
            </div>
            <div className="filter" />
          </div>
          <div className="panel-content">
            <Table
              path={"news"}
              actionPath={actionUrl}
              selectedField="selected"
              ref={el => {
                table = el;
              }}
              dataTransformation={NEWS}
              name="NewsTable"
              customRowRender={customRowRender}
              defaultSort={defaultNewsTabelSort}
            >
              <Column field="selected" width="50" />
              <Column
                field="order"
                cell={props => (
                  <StringCellWithIcon
                    {...props}
                    textAlign={TEXT_HORIZONTAL_ALIGN.LEFT}
                    icon="attention"
                    condtionToShowIcon={condtionToShowIcon}
                  />
                )}
                title={t("Order")}
                width="75px"
              />
              <Column
                field="name"
                cell={props => (
                  <NavigableCell
                    {...props}
                    actionUrl={actionUrl}
                    navigateTo={navigateTo}
                    component={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                  />
                )}
                title={t("Name")}
              />
              <Column
                field="validFrom"
                cell={DateCell}
                title={t("ValidFrom")}
              />
              <Column field="validTo" cell={DateCell} title={t("ValidTo")} />
              <Column
                field="type"
                cell={EnumCell(enums.NEWS_TYPES)}
                title={t("Type")}
              />
              <Column
                field="isCampaign"
                cell={BoolCell}
                title={t("Campaign")}
                width="100px"
              />
              <Column
                field="published"
                cell={BoolCell}
                title={t("Published")}
                width="110px"
              />
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

News.propTypes = {
  t: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  sendPushNotificationsToBetaTesters: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: bindActionCreators(navigateTo, dispatch),
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  translate("common")
)(News);
