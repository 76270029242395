import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import TextDetail from './textDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updateText } from '../../actions/textActions';
import { navigateToTexts } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import Text from '../../entities/text';

interface TextDetailUpdateProps extends BaseDetailProps<Text> {}

const TextDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: TextDetailUpdateProps) => {
    return <TextDetail initialValues={initialValues} submitAction={submitAction} headerText={t('TextUpdateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<TextDetailUpdateProps>): BaseDetailDispatchProps<Text> => {
    return {
        submitAction: bindActionCreators(updateText, dispatch),
        cancelAction: bindActionCreators(navigateToTexts, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(TextDetailUpdate);
