import React, {Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {translate} from "react-i18next";
import {compose} from "redux";
import {Form, Field} from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from "../common/Alerts";
import Translation from "../common/Translation";
import CancelButton from "../common/CancelButton";
import Input from "../common/Input";
import LoadingIndicator from "../common/LoadingIndicator";

import { betatesterDetailPropTypes } from "../../common/propTypes";

class BetatesterDetail extends Component {
  constructor(props) {
    super(props);
    
    this.state = { loading: false };
    this.translatedFormValidation = this.translatedFormValidation.bind(this);
  }

  translatedFormValidation(values) {
    const { t } = this.props;
    const errors = {};

    if (!values.msisdn) {
      errors.msisdn = t('MsisdnIsRequired');
    }

    return errors;
  }

  render() {
    const {initialValues, headerText, onCancel} = this.props;

    return (
      <div>
        <div className="content-header">
          <h1>{headerText}</h1>
        </div>
        <div className="content-wrapper">
          <Alerts/>
          <div className="panel card col-xs-12 col-sm-6">
            <Form
              validate={this.translatedFormValidation}
              onSubmit={this.props.onSubmit}
              initialValues={initialValues}
              render={({handleSubmit, reset, submitting, pristine, values, valid, form}) => (

                <form className="k-form" onSubmit={handleSubmit}>
                  { this.state.loading && <LoadingIndicator/> }

                  <label className="k-form-field" htmlFor="msisdn">
                    <span><Translation tKey="MSISDN"/></span>
                    <Field name="msisdn"
                           component={Input}
                    />
                  </label>
                  <label className="k-form-field" htmlFor="description">
                    <span><Translation tKey="Description"/></span>
                    <Field name="description"
                           component={Input}
                    />
                  </label>

                  <div className="footer">
                    <SubmitButton loading={submitting}><Translation tKey="Save"/></SubmitButton>
                    <CancelButton onClick={onCancel}><Translation tKey="Cancel"/></CancelButton>
                  </div>
                </form>
              )}/>
          </div>
        </div>
      </div>
    );
  }
}

BetatesterDetail.propTypes = {
  t: PropTypes.func.isRequired,
  headerText: PropTypes.string.isRequired,
  initialValues: betatesterDetailPropTypes.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,  
};

const mapStateToProps = (state) => {
  return {
    lang: state.app.lang,    
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    //fetchNewsPages: bindActionCreators(fetchNewsPages, dispatch),
    //fetchServices: bindActionCreators(fetchServices, dispatch),
  };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(BetatesterDetail);
