import axios from 'axios';
import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import { alertAdd } from './alertActions';
import { navigateTo } from './navigationActions';
import { showLoadingSpinner, hideLoadingSpinner } from './loadingSpinnerActions';

export const reset = (url, actionUrl, columns) => (dispatch) => {
    dispatch({
        type: types.TABLE_RESET,
        url,
        actionUrl,
        columns: columns.map((column, index) => {
            return { index, ...column };
        }),
    });
};

export const sortChange = (sort) => (dispatch) => {
    dispatch({
        type: types.TABLE_SORT_CHANGE,
        sort,
    });

    dispatch(loadData(true));
};

export const pageChange = (skip, take) => (dispatch) => {
    dispatch({
        type: types.TABLE_PAGE_CHANGE,
        skip,
        take,
    });

    dispatch(loadData());
};

export const filterClear = () => (dispatch) => {
    dispatch({
        type: types.TABLE_FILTER_CLEAR,
    });

    dispatch(loadData(true));
};

export const filterChange = (filter) => (dispatch) => {
    dispatch({
        type: types.TABLE_FILTER_CHANGE,
        filter,
    });

    dispatch(loadData(true));
};

export const dataTransformationChange = (dataTransformationName) => (dispatch) => {
    dispatch({
        type: types.TABLE_DATA_TRANSFORMATION_CHANGE,
        dataTransformation: dataTransformationName,
    });
};

export const setAfterLoadDataActionType = (afterLoadDataActionType) => (dispatch) => {
    dispatch({
        type: types.TABLE_AFTER_LOAD_DATA_ACTION,
        afterLoadDataActionType: afterLoadDataActionType,
    });
};

export const loadData =
    (hardRerfresh = false) =>
    (dispatch, getState) => {
        let readFromServer = false;
        if (!hardRerfresh) {
            readFromServer = cacheData(dispatch, getState);
        }

        if (readFromServer || hardRerfresh) {
            dispatch(beginAjaxCall());
            dispatch(showLoadingSpinner());
            let result = composeUrl(getState);
            const afterLoadDataActionType = getAfterLoadDataActionType(getState);

            axios
                .get(result.url)
                .then((response) => {
                    dispatch(hideLoadingSpinner());
                    dispatch({
                        type: types.TABLE_FETCH_DATA,
                        ...response.data,
                        take: result.take,
                        skip: result.skip,
                    });

                    if (afterLoadDataActionType) {
                        dispatch({
                            type: afterLoadDataActionType,
                            data: response.data.data,
                        });
                    }
                })
                .catch((error) => {
                    dispatch(hideLoadingSpinner());
                    ajaxCallError();
                    throw error;
                });
        }
    };

const cacheData = (dispatch, getState) => {
    let { table } = getState();
    let { data, cache, skip, take } = table;

    if (data && data.length > 0 && cache.data && cache.data.length > 0) {
        if (skip >= cache.skip && skip + take <= cache.skip + cache.take) {
            dispatch({
                type: types.TABLE_CACHE_DATA,
            });

            return false;
        }
    }

    return true;
};

const composeUrl = (getState) => {
    let { table } = getState();
    let { skip, take, sort, filter, columns, cache } = table;

    let tSkip = Math.floor(skip / cache.take) * cache.take;
    let tTake = Math.floor((skip - tSkip + take) / cache.take) * cache.take;
    tTake = tTake <= 0 ? cache.take : tTake;
    let url = `${table.url}/query?skip=${tSkip}&take=${tTake}`;

    columns = columns.filter((c) => {
        return c.field !== 'selected';
    });
    columns.forEach((column, index) => {
        url += `&columns[${index}][data]=${column.field}`;
    });

    sort.forEach((s, i) => {
        let column = columns.find((c) => {
            return c.field == s.field;
        });
        if (column) {
            url += `&order[${i}][column]=${column.index - 1}&order[${i}][dir]=${s.dir}`;
        }
    });

    if (filter && Array.isArray(filter)) {
        filter.forEach((f, i) => {
            url += `&filters[${i}][member]=${f.field}&filters[${i}][operator]=${f.operator}&filters[${i}][value]=${f.value}`;

            if (f.logicalOperator) {
                url += `&filters[${i}][logicalOperator]=${f.logicalOperator}`;
            }
        });
    }

    return { url, skip: tSkip, take: tTake };
};

export const selectRow = (id, selected) => (dispatch) => {
    dispatch({
        type: types.TABLE_SELECT_ROW,
        id,
        selected,
    });
};

export const desellectAllRows = () => (dispatch) => {
    dispatch({
        type: types.TABLE_DESELECT_ALL_ROWS,
    });
};

export const selectRows = (min, max, selected) => (dispatch) => {
    dispatch({
        type: types.TABLE_SELECT_ROWS,
        min,
        max,
        selected,
    });
};

export const selectAllRows = (checked) => (dispatch) => {
    dispatch({
        type: types.TABLE_SELECT_ALL_ROWS,
        checked,
    });
};

export const remove = () => (dispatch, getState) => {
    let { table } = getState();
    let { url, data } = table;
    let ids = data
        .filter((i) => {
            return i.selected;
        })
        .map((i) => {
            return i.id;
        });
    axios
        .delete(`${url}/`, { data: ids })
        .then((response) => {
            let { success, failed } = response.data;
            if (success && Array.isArray(success)) {
                let ids = success.map((i) => {
                    return i.id;
                });

                if (ids.length > 0) {
                    dispatch({
                        type: types.TABLE_REMOVE_ROWS,
                        ids,
                    });
                    dispatch(alertAdd('RecordWasRemoved', 'info'));
                }
            }
            if (failed && Array.isArray(failed)) {
                let ids = failed.map((i) => {
                    return i.id;
                });
                if (ids.length > 0) {
                    dispatch(alertAdd('RecordWasntRemoved', 'error'));
                }
            }
        })
        .catch((error) => {
            ajaxCallError();
            throw error;
        });
};

export const edit = () => (dispatch, getState) => {
    let { table } = getState();
    let { actionUrl, data } = table;
    let { id } = data.find((i) => i.selected);
    if (id) {
        dispatch(navigateTo(`/${actionUrl}/${id}`));
    }
};

export const clone = () => (dispatch, getState) => {
    let { table } = getState();
    let { url, actionUrl, data } = table;
    let { id } = data.find((i) => i.selected);
    if (id) {
        axios
            .get(`${url}/clone/${id}`)
            .then((response) => {
                const cloned = response.data;

                dispatch({
                    type: types.TABLE_ADD_ROW,
                    row: cloned,
                });

                dispatch(navigateTo(`/${actionUrl}/${cloned.id}`));
            })
            .catch((error) => {
                ajaxCallError();
                throw error;
            });
    }
};

export const getAfterLoadDataActionType = (getState) => {
    let { table } = getState();

    return table.afterLoadDataActionType;
};
