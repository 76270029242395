export function isNotNullDate(value: any): boolean {
    if (value instanceof Date) {
        return !isNullDate(value as Date);
    }

    return false;
}

export function isNullDate(dateValue: Date): boolean {
    if (dateValue) {
        return (
            dateValue.getDate() === new Date(1, 1, 1).getDate() &&
            dateValue.getMonth() === new Date(1, 1, 1).getMonth() &&
            dateValue.getFullYear() === new Date(1, 1, 1).getFullYear()
        );
    }

    return true;
}

export function addHrefWithFileToDownload(data: any, fileName: string): void {
    const url = window.URL.createObjectURL(data);
    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    document.body.appendChild(a); // we need to append the element to the dom -> otherwise it will not work in firefox
    a.click();
    a.remove(); //afterwards we remove the element again
}
