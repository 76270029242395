import React from 'react';
import ReactDOM from 'react-dom';
import Root from './components/Root';
import '@progress/kendo-ui';
import { load } from '@progress/kendo-react-intl';

import createBrowserHistory from 'history/createBrowserHistory';
import likelySubtags from 'cldr-data/supplemental/likelySubtags';
import currencyData from 'cldr-data/supplemental/currencyData';
import weekData from 'cldr-data/supplemental/weekData';
import numbers from 'cldr-data/main/cs/numbers';
import currencies from 'cldr-data/main/cs/currencies';
import caGregorian from 'cldr-data/main/cs/ca-gregorian';
import dateFields from 'cldr-data/main/cs/dateFields';
import timeZoneNames from 'cldr-data/main/cs/timeZoneNames';

import loadKendoMessagesCS from './locales/cs/kendo';
import configureStore from './store/configureStore'; // Tell webpack to load favicon.ico
import './styles/styles.scss';

const history = createBrowserHistory();
const store = configureStore(undefined, history);

load(likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields, timeZoneNames);

loadKendoMessagesCS();

ReactDOM.render(
    <React.StrictMode>
        <Root store={store} history={history} />
    </React.StrictMode>,
    document.getElementById('root')
);
