import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import RoleDetail from './roleDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updateRole } from '../../actions/roleActions';
import { navigateToRoles } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import Role from '../../entities/role';

interface RoleDetailUpdateProps extends BaseDetailProps<Role> {}

const ReportDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: RoleDetailUpdateProps) => {
    return <RoleDetail initialValues={initialValues} submitAction={submitAction} headerText={t('RoleLabel')} cancelAction={cancelAction} t={t} />;
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<RoleDetailUpdateProps>): BaseDetailDispatchProps<Role> => {
    return {
        submitAction: bindActionCreators(updateRole, dispatch),
        cancelAction: bindActionCreators(navigateToRoles, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(ReportDetailUpdate);
