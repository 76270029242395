import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import DataSourceDetail, { DataSourceDetailDispatchProps, DataSourceDetailProps } from './dataSourceDetail';
import { getRowById } from '../../reducers/tableReducer';
import { fetchDataSourcesDependencies, updateDataSource } from '../../actions/dataSourceActions';
import { navigateToDataSources } from '../../actions/navigationActions';

interface DataSourceDetailUpdateProps extends DataSourceDetailProps {}

const DataSourceDetailUpdate = ({ initialValues, submitAction, cancelAction, t, fetchDataSourcesDependencies }: DataSourceDetailUpdateProps) => {
    return (
        <DataSourceDetail
            initialValues={initialValues}
            submitAction={submitAction}
            headerText={t('DataSourceUpdateLabel')}
            cancelAction={cancelAction}
            t={t}
            fetchDataSourcesDependencies={fetchDataSourcesDependencies}
        />
    );
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<DataSourceDetailUpdateProps>): DataSourceDetailDispatchProps => {
    return {
        submitAction: bindActionCreators(updateDataSource, dispatch),
        cancelAction: bindActionCreators(navigateToDataSources, dispatch),
        fetchDataSourcesDependencies: bindActionCreators(fetchDataSourcesDependencies, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(DataSourceDetailUpdate);
