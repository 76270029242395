import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import CompetitionDetail from './competitionDetail';
import {createCompetition} from "../../actions/competitionActions";
import * as enums from "../../common/enums";
import {navigateToNews} from "../../actions/navigationActions";

const initialValues= {
  name:'',
  validFrom: new Date(),
  validTo:null,
  description: '',
  noVipText: '',
  targetCustomer: enums.COMPETITION_TARGET_CUSTOMER_TYPES[0],
  published : false,
  platform: enums.PLATFORMA_TYPES[0],
  urlOpenExternal: false,
};

const CompetitionDetailCreate =({createCompetition, cancelAction, t})=>{
  return <CompetitionDetail initialValues={initialValues} onSubmit={createCompetition} headerText={t('CreateCompetition')} onCancel={cancelAction}/>;
};

CompetitionDetailCreate.propTypes = {
  createCompetition: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    createCompetition: bindActionCreators(createCompetition, dispatch),
    cancelAction: bindActionCreators(navigateToNews, dispatch)
  };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(CompetitionDetailCreate);

