import React from "react";
import PropTypes from "prop-types";

import { finalFormInput, finalFormMeta } from "../../common/propTypes";

const TextBox = ({
  input,
  placeholder,
  type,
  className,
  icon,
  meta: { touched, error, warning },
  autoComplete,
  ...rest
}) => {
  const isInvalid = touched && error;
  let wrapperClass = `k-textbox`;

  if (className) {
    wrapperClass += ` ${className}`;
  }
  if (isInvalid) {
    wrapperClass += ` notification-input-error`;
  }

  let wrapperType = type;
  if (!type) {
    wrapperType = "text";
  }
  return (
    <div>
      {icon && <span className={`icon icon-${icon}`}>&nbsp;</span>}
      <input
        autoComplete={autoComplete}
        className={wrapperClass}
        type={wrapperType}
        {...input}
        placeholder={placeholder}
      />
      {isInvalid && <div className={`notification-error`}>{error}</div>}
    </div>
  );
};

TextBox.propTypes = {
  value: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  className: PropTypes.string,
  icon: PropTypes.string,
  error: PropTypes.string,
  input: finalFormInput.isRequired,
  meta: finalFormMeta.isRequired,
  autoComplete: PropTypes.string,
};

export default TextBox;
