import axios from 'axios';
import ResponseStatus from "../utils/responseStatus";
import {ajaxCallError, beginAjaxCall} from "./ajaxStatusActions";
import * as types from './actionTypes';
import {isLoaded} from "../reducers/servicesReducer";

export const fetchServices = () => async (dispatch, getState) => {
  if (isLoaded(getState())) {
    return;
  }

  try {
    dispatch(beginAjaxCall());

    const response = await axios.get(`services/`);

    if (ResponseStatus.IsOK(response.status)) {
      dispatch({
        type: types.SERVICES_FETCH,
        data: response.data
      });
    } else {
      dispatch(ajaxCallError());
    }
  }
  catch (error) {
    dispatch(ajaxCallError());
    throw error;
  }
};

