import axios from "axios";

import * as types from "./actionTypes";
import { navigateToGameClans } from "./navigationActions";
import { loadData } from "./tableActions";

import { beginAjaxCall, ajaxCallError } from "./ajaxStatusActions";
import ResponseStatus from "../utils/responseStatus";

export const changeBlockedState = data => dispatch => {
  dispatch(beginAjaxCall());

  axios
    .put(`gameClan/${data.id}/changeBlockedState`, data)
    .then(response => {
      if (ResponseStatus.IsOK(response.status)) {
        dispatch({
          type: types.GAME_CLAN_UPDATE,
          data: response.data
        });

        dispatch(loadData(true));

       /*  dispatch(navigateToGameClans()); */
      } else {
        dispatch(ajaxCallError());
      }
    })
    .catch(error => {
      dispatch(ajaxCallError());
      throw error;
    });
};
