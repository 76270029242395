import axios from 'axios';
import { Dispatch } from 'react-redux';

import * as types from './actionTypes';
import { beginAjaxCall, ajaxCallError } from './ajaxStatusActions';
import ResponseStatus from '../utils/responseStatus';
import { isLoaded } from '../reducers/marketingActionReducer';

export const fetchMarketingActions = () => async (dispatch: Dispatch<any>, getState: any) => {
    if (isLoaded(getState())) {
        return;
    }

    try {
        dispatch(beginAjaxCall());

        const response = await axios.get(`marketingActions/`);

        if (ResponseStatus.IsOK(response.status)) {
            dispatch({
                type: types.MARKETING_ACTION_FETCH,
                data: response.data,
            });
        } else {
            dispatch(ajaxCallError());
        }
    } catch (error) {
        dispatch(ajaxCallError());
        throw error;
    }
};
