import { getEnumItemByValue, TARGETED_GROUP_SUFFIX_TYPE, SOURCE_OF_SUBSCRIBERS_TYPES, REWARD_TYPES, EnumDescription } from '../common/enums';

export default class Reward {
    id: number;
    hvcForAll: boolean;
    hvcForAllValidFrom: Date | null | undefined;
    hvcForAllValidTo: Date | null | undefined;
    hvcForGroup: boolean;
    hvcForGroupDateSuffixType: any;
    hvcForGroupName: string | undefined;
    hvcForGroupUseDateSuffix: boolean;
    hvcLimit: number;
    hvcDaysForSpentCalculation: number;

    constructor(properties: Partial<Reward>) {
        this.id = 0;
        this.hvcForAll = false;
        this.hvcForAllValidFrom = null;
        this.hvcForAllValidTo = null;
        this.hvcForGroup = false;
        this.hvcForGroupName = '';
        this.hvcForGroupUseDateSuffix = false;
        this.hvcLimit = 0;
        this.hvcDaysForSpentCalculation = 0;

        Object.assign(this, properties);

        this.hvcForGroupDateSuffixType = properties.hvcForGroupUseDateSuffix
            ? getEnumItemByValue(properties.hvcForGroupDateSuffixType, TARGETED_GROUP_SUFFIX_TYPE)
            : undefined;

        this.hvcForAllValidFrom = properties.hvcForAllValidFrom ? new Date(properties.hvcForAllValidFrom) : properties.hvcForAllValidFrom;
        this.hvcForAllValidTo = properties.hvcForAllValidTo ? new Date(properties.hvcForAllValidTo) : properties.hvcForAllValidTo;
    }
}
