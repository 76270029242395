import React from 'react';
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {bindActionCreators, compose} from "redux";
import {translate} from "react-i18next";

import ChargingCodeDetail from './chargingCodeDetail';
import {createChargingCode} from "../../actions/chargingCodeActions";
import {navigateToChargingCode} from "../../actions/navigationActions";


const initialValues= {
  code: '',
  name: ''
};

const ChargingCodeDetailCreate =({saveAction, cancelAction, t})=>{
  return <ChargingCodeDetail initialValues={initialValues} onSubmit={saveAction} headerText={t('ChargingCodeLabel')} onCancel={cancelAction}/>;
};

ChargingCodeDetailCreate.propTypes = {
  saveAction: PropTypes.func.isRequired,
  cancelAction: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveAction: bindActionCreators(createChargingCode, dispatch),
    cancelAction: bindActionCreators(navigateToChargingCode, dispatch)
  };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(ChargingCodeDetailCreate);