import React from 'react';
import { connect } from 'react-redux';
import { Form, Field } from 'react-final-form';

import * as enums from '../../common/enums';

import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import AffiliateProgramPartner from '../../entities/affiliateProgramPartner';
import SubmitButton from '../common/SubmitButton';
import { nameofFactory } from '../../helpers/nameOfFactory';
import Textarea from '../common/Textarea';
import { BaseDetailProps } from '../common/types/baseDetail';

const nameof = nameofFactory<AffiliateProgramPartner>();

interface AffiliateProgramPartnerDetailProps extends BaseDetailProps<AffiliateProgramPartner>, AffiliateProgramPartnerDetailStateProps {
    headerText: string;
}

interface AffiliateProgramPartnerDetailStateProps {
    lang: string;
}

class AffiliateProgramPartnerDetail extends React.Component<AffiliateProgramPartnerDetailProps> {
    componentDidMount() {}

    handleOnSubmit = (values: object) => {
        this.props.submitAction(values as AffiliateProgramPartner);
    };

    handleOnCancel = (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        event.preventDefault();

        this.props.cancelAction();
    };

    translatedFormValidation = (values: object) => {
        const { t } = this.props;
        const typedValues = values as AffiliateProgramPartner;
        const errors: Partial<Record<keyof AffiliateProgramPartner, string>> = {};

        if (!typedValues.name) {
            errors[nameof('name')] = t('NameIsRequired');
        }

        if (!typedValues.code) {
            errors[nameof('code')] = 'Kód je povinný';
        }

        return errors;
    };

    render() {
        console.debug('AffiliateProgramPartnerDetail.render');

        const { initialValues, headerText } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.handleOnSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    <div className="row">
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('name')}>
                                            <span>
                                                <Translation tKey="Name" />
                                            </span>
                                            <Field name={nameof('name')} component={Input} />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor={nameof('code')}>
                                            <span>
                                                <Translation tKey="Code" />
                                            </span>
                                            <Field name={nameof('code')} component={Input} />
                                        </label>
                                    </div>

                                    <label className="k-form-field" htmlFor={nameof('description')}>
                                        <span>
                                            <Translation tKey="Description" />
                                        </span>
                                        <Field
                                            name={nameof('description')}
                                            component={Textarea}
                                            className="k-textarea"
                                            rows="8"
                                            style={{ width: '100%' }}
                                        />
                                    </label>

                                    <div className="footer">
                                        <SubmitButton>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={this.handleOnCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: any): AffiliateProgramPartnerDetailStateProps => {
    return {
        lang: state.app.lang,
    };
};

export default connect(mapStateToProps)(AffiliateProgramPartnerDetail);
