import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { bindActionCreators, compose } from 'redux';
import { Form, Field } from 'react-final-form';
import createDecorator from 'final-form-calculate';

import SubmitButton from '../common/SubmitButton';
import * as enums from '../../common/enums';
import * as propTypes from '../../common/propTypes';

import WhenFieldChanges from '../common/utils/WhenFieldChanges';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import Condition from '../common/utils/Condition';
import CancelButton from '../common/CancelButton';
import { getData as getNewsPages, getUserReadableProperty as getNewsPageUserReadableProperty } from '../../reducers/newsPagesReducer';
import { getData as getServices, getUserReadableProperty as getServiceUserReadableProperty } from '../../reducers/servicesReducer';
import { getData as getRewards, getUserReadableProperty as getRewardUserReadableProperty } from '../../reducers/rewardReducer';
import {
    getData as getMarketingActions,
    getUserReadableProperty as getMarketingActionUserReadableProperty,
} from '../../reducers/marketingActionReducer';
import { isLoadingDependencies } from '../../reducers/newsReducer';
import { fetchNewsDependencies } from '../../actions/newsActions';
import Dropzone from '../common/DropZone';
import Input from '../common/Input';
import Textarea from '../common/Textarea';
import DatePicker from '../common/DatePicker';
import DropDown from '../common/DropDown';
import DropDownWithValueField from '../common/DropDownWithValueField';
import DropDownMultiSelect from '../common/DropDownMultiSelect';
import { isEmpty } from '../../utils/isEmpty';
import { MAX_IMAGE_SIZE_IN_BYTES, MAX_IMAGE_SIZE_IN_MEGA_BYTES } from '../../common/configs';
import Switch from './../common/Switch';
import NumericTextBox from './../common/NumericTextBox';
import LoadingIndicator from '../common/LoadingIndicator';

const isSelectedCMTDataSource = (values) => {
    return enums.isSelectedEnumFlagg(values, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.CMT);
};

const isSelectedRewardDataSource = (values) => {
    return enums.isSelectedEnumFlagg(values, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.REWARD_USAGES);
};

const isSelectedMarketingActionDataSource = (values) => {
    return enums.isSelectedEnumFlagg(values, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.MARKETING_ACTION_USAGES);
};

const isSelectedPruductStateChangeSource = (values) => {
    return enums.isSelectedEnumFlagg(values, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.PRODUCT_STATE_CHANGE);
};


const getImageUrl = (imageValue, oldImageUrl) => {
    if (oldImageUrl) {
        URL.revokeObjectURL(oldImageUrl);
    }

    let newImageUrl = null;
    if (!isEmpty(imageValue)) {
        newImageUrl = URL.createObjectURL(imageValue);
    }

    return newImageUrl;
};

const formDecorator = createDecorator(
    {
        field: 'image',
        updates: {
            imageUrl: (imageValue, allValues) => {
                const oldImageUrl = allValues.imageUrl;

                return getImageUrl(imageValue, oldImageUrl);
            },
        },
    },
    {
        field: 'imageV2',
        updates: {
            imageUrlV2: (imageValue, allValues) => {
                const oldImageUrl = allValues.imageUrlV2;

                return getImageUrl(imageValue, oldImageUrl);
            },
        },
    },
    {
        field: 'detailImage',
        updates: {
            detailImageUrl: (imageValue, allValues) => {
                const oldImageUrl = allValues.detailImageUrl;

                return getImageUrl(imageValue, oldImageUrl);
            },
        },
    }
);

class NewsDetail extends Component {
    constructor(props) {
        super(props);

        //this.state = {newsPages: [], newsServices: [], files: []};
        this.translatedFormValidation = this.translatedFormValidation.bind(this);
    }

    componentDidMount() {
        this.props.fetchNewsDependencies();
    }

    translatedFormValidation(values) {
        const { t } = this.props;
        const errors = {};

        if (!values.name) {
            errors.name = t('NameIsRequired');
        }

        if (!values.validFrom) {
            errors.validFrom = 'Datum Platný od je povinné';
        }

        if (values.validTo && values.validFrom && values.validTo < values.validFrom) {
            errors.validTo = 'Datum Platný do, musí být větší než datum Platný od';
        }

        if (!values.type) {
            errors.type = 'Typ je povinný';
        }

        if (!values.section) {
            errors.section = 'Sekce je povinná';
        }

        if (values.image && values.image.size > MAX_IMAGE_SIZE_IN_BYTES) {
            errors.image = `Obrázek je příliš velký. Maximální veliskot je ${MAX_IMAGE_SIZE_IN_MEGA_BYTES}MB`;
        }

        if (values.detailImage && values.detailImage.size > MAX_IMAGE_SIZE_IN_BYTES) {
            errors.detailImage = `Obrázek je příliš velký. Maximální veliskot je ${MAX_IMAGE_SIZE_IN_MEGA_BYTES}MB`;
        }

        if (values.isCampaign) {
            if (!values.campaignMessage) {
                errors.campaignMessage = 'Zpáva kampaně je povinná';
            }

            if (!values.campaignTitle) {
                errors.campaignTitle = 'Titulek kampaně je povinný';
            }
        }

        if (values.isTargetedCampaign) {
            if (!values.dataSourceType || values.dataSourceType.length === 0) {
                errors.dataSourceType = 'Typ zdroje dat je povinný';
            }

            if (!values.targetedCampaignConditionType) {
                errors.targetedCampaignConditionType = 'Podmínka je povinná';
            }

            if (
                values.dataSourceType &&
                enums.isSelectedEnumFlagg(values.dataSourceType, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.MARKETING_ACTION_USAGES) &&
                values.dataSourceType.length > 1
            ) {
                errors.dataSourceType = 'Marketingová akce není kombinovatelná s jiným datovým zdrojem';
            }

            if (
                values.dataSourceType &&
                enums.isSelectedEnumFlagg(values.dataSourceType, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.REWARD_USAGES) &&
                values.dataSourceType.length > 1
            ) {
                errors.dataSourceType = 'Odměna ze sekce Jen pro mě není kombinovatelná s jiným datovým zdrojem';
            }

            if (values.dataSourceType && enums.isSelectedEnumFlagg(values.dataSourceType, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.CMT)) {
                if (!values.targetedCampaignCode) {
                    errors.targetedCampaignCode = 'Kód kampaně je povinný';
                }

                if (!values.targetedCampaignConditionType) {
                    errors.targetedCampaignConditionType = 'Typ podmínky je povinný';
                }

                if (values.targetedCampaignUseDateSuffix && !values.targetedCampaignDateSuffixType) {
                    errors.targetedCampaignDateSuffixType = 'Typ sufixu je povunný';
                }
            }

            if (
                values.dataSourceType &&
                enums.isSelectedEnumFlagg(values.dataSourceType, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.MARKETING_ACTION_USAGES)
            ) {
                if (!values.marketingActionId) {
                    errors.marketingActionId = 'Marketingová akce je povinná';
                }
            }

            if (
                values.dataSourceType &&
                enums.isSelectedEnumFlagg(values.dataSourceType, enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE_ENUM.REWARD_USAGES)
            ) {
                if (!values.rewardId) {
                    errors.rewardId = 'Odměna ze sekce Jen pro mě je povinná';
                }
            }
        }

        return errors;
    }

    render() {
        console.debug('NewsDetail.render');

        const { initialValues, headerText, onCancel } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.props.onSubmit}
                            initialValues={initialValues}
                            decorators={[formDecorator]}
                            render={({ handleSubmit, reset, submitting, pristine, values, valid, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    {this.props.loadingDependencies && <LoadingIndicator />}

                                    <WhenFieldChanges field="type" becomes={enums.NEWS_TYPES[0]} set={['serviceId', 'pageId']} to={undefined} />

                                    <WhenFieldChanges field="type" becomes={enums.NEWS_TYPES[1]} set={['serviceId', 'url']} to={undefined} />

                                    <WhenFieldChanges field="type" becomes={enums.NEWS_TYPES[2]} set={['serviceId', 'pageId']} to={undefined} />

                                    <WhenFieldChanges field="isTargetedCampaign" becomes={false} set={['targetedCampaignCode']} to={undefined} />

                                    <label className="k-form-field" htmlFor="name">
                                        <span>
                                            <Translation tKey="Name" />
                                        </span>
                                        <Field
                                            name="name"
                                            component={Input}
                                            // placeholder={t("Name")}
                                        />
                                    </label>

                                    <div className={'row'}>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="validFrom">
                                            <span>
                                                <Translation tKey="ValidFrom" />
                                            </span>
                                            <Field
                                                name="validFrom"
                                                component={DatePicker}
                                                // placeholder={t("validFrom")}
                                            />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="validTo">
                                            <span>
                                                <Translation tKey="ValidTo" />
                                            </span>
                                            <Field
                                                name="validTo"
                                                component={DatePicker}
                                                // placeholder={t("ValidTo")}
                                            />
                                        </label>
                                    </div>

                                    <label className="k-form-field" htmlFor="order">
                                        <span>
                                            <Translation tKey="Order" />
                                        </span>
                                        <Field name="order" component={NumericTextBox} />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="Platform" />
                                        </span>
                                        <Field name="platform" component={DropDown} textField="text" dataSource={enums.PLATFORMA_TYPES} />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="Section" />
                                        </span>
                                        <Field name="section" component={DropDown} textField="text" dataSource={enums.NEWS_SECTION_TYPES} />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="Type" />
                                        </span>
                                        <Field name="type" component={DropDown} textField="text" dataSource={enums.NEWS_TYPES} />
                                    </label>

                                    <Condition when="type" is={enums.NEWS_TYPES[0]}>
                                        <div className="k-form-field">
                                            <span>
                                                <Translation tKey="UrlOpenExternal" />
                                            </span>
                                            <Field
                                                name="urlOpenExternal"
                                                component={Switch}
                                                // placeholder={t("Name")}
                                            />
                                        </div>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[0]}>
                                        <label className="k-form-field" htmlFor="url">
                                            <span>
                                                <Translation tKey="URL" />
                                            </span>
                                            <Field
                                                name="url"
                                                component={Input}
                                                // placeholder={t("URL")}
                                            />
                                        </label>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[1]}>
                                        <label className="k-form-field" htmlFor="pageId">
                                            <span>
                                                <Translation tKey="Page" />
                                            </span>
                                            <Field
                                                name="pageId"
                                                component={DropDownWithValueField}
                                                // placeholder={t("Page")}
                                                textField={this.props.newsPageUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.newsPages}
                                            />
                                        </label>

                                        <div className="k-form-field">
                                            <span>
                                                <Translation tKey="NavigateDirectToPage" />
                                            </span>
                                            <Field
                                                name="navigateDirectToPage"
                                                component={Switch}
                                                // placeholder={t("Name")}
                                            />
                                        </div>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[2]}>
                                        <label className="k-form-field" htmlFor="serviceId">
                                            <span>
                                                <Translation tKey="Service" />
                                            </span>
                                            <Field
                                                name="serviceId"
                                                component={DropDownWithValueField}
                                                // placeholder={t("Service")}
                                                textField={this.props.serviceUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.services}
                                            />
                                        </label>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[4]}>
                                        <label className="k-form-field" htmlFor="serviceId">
                                            <span>
                                                <Translation tKey="Service" />
                                            </span>
                                            <Field
                                                name="serviceId"
                                                component={DropDownWithValueField}
                                                // placeholder={t("Service")}
                                                textField={this.props.serviceUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.services}
                                            />
                                        </label>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[5]}>
                                        <label className="k-form-field" htmlFor="serviceId">
                                            <span>
                                                <Translation tKey="Service" />
                                            </span>
                                            <Field
                                                name="serviceId"
                                                component={DropDownWithValueField}
                                                // placeholder={t("Service")}
                                                textField={this.props.serviceUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.services}
                                            />
                                        </label>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[3]}>
                                        <label className="k-form-field" htmlFor="smsRecipient">
                                            <span>
                                                <Translation tKey="SmsRecipient" />
                                            </span>
                                            <Field name="smsRecipient" component={Input} />
                                        </label>

                                        <label className="k-form-field">
                                            <span>
                                                <Translation tKey="SmsBody" />
                                            </span>
                                            <Field name="smsBody" component="textarea" className="k-textarea" rows="2" style={{ width: '100%' }} />
                                        </label>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[6]}>
                                        <label className="k-form-field" htmlFor="rewardId">
                                            <span>
                                                <Translation tKey="ForMe" />
                                            </span>
                                            <Field
                                                name="rewardId"
                                                component={DropDownWithValueField}
                                                // placeholder={t("Service")}
                                                textField={this.props.rewardUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.rewards}
                                            />
                                        </label>
                                    </Condition>

                                    <Condition when="type" is={enums.NEWS_TYPES[7]}>
                                        <label className="k-form-field" htmlFor="rewardId">
                                            <span>
                                                <Translation tKey="ForMe" />
                                            </span>
                                            <Field
                                                name="rewardId"
                                                component={DropDownWithValueField}
                                                // placeholder={t("Service")}
                                                textField={this.props.rewardUserReadableProperty}
                                                valueField="id"
                                                dataSource={this.props.rewards}
                                            />
                                        </label>
                                    </Condition>

                                    <label className="k-form-field" htmlFor="actionButtonText">
                                        <span>
                                            <Translation tKey="ActionButtonText" />
                                        </span>
                                        <Field
                                            name="actionButtonText"
                                            component={Input}
                                            // placeholder={t("Name")}
                                        />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="ShortDescription" />
                                        </span>
                                        <Field
                                            name="shortDescription"
                                            component="textarea"
                                            className="k-textarea"
                                            rows="2"
                                            style={{ width: '100%' }}
                                            // placeholder={t("ShortDescription")}
                                        />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="Description" />
                                        </span>
                                        <Field
                                            name="description"
                                            component="textarea"
                                            className="k-textarea"
                                            rows="4"
                                            style={{ width: '100%' }}
                                            // placeholder={t("Description")}
                                        />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="BannerViewType" />
                                        </span>
                                        <Field name="bannerViewType" component={DropDown} textField="text" dataSource={enums.NEWS_BANNER_VIEW_TYPES} />
                                    </label>

                                    <div className="k-form-field">
                                        <span>
                                            <Translation tKey="BannerPicture" />
                                        </span>
                                        <Field name="image" component={Dropzone} recommendedResolution="3240x690" imageUrl="imageUrl" />
                                    </div>

                                    <div className="k-form-field">
                                        <span>
                                            <Translation tKey="BannerPictureV2" />
                                        </span>
                                        <Field name="imageV2" component={Dropzone} recommendedResolution="3240x1200" imageUrl="imageUrlV2" />
                                    </div>

                                    <div className="k-form-field">
                                        <span>
                                            <Translation tKey="DetailPicture" />
                                        </span>
                                        <Field name="detailImage" component={Dropzone} recommendedResolution="3240x2580" imageUrl="detailImageUrl" />
                                    </div>

                                    <div className="k-form-field">
                                        <span>
                                            <Translation tKey="IsTargetedCampaign" />
                                        </span>
                                        <Field name="isTargetedCampaign" component={Switch} />
                                    </div>

                                    <Condition when="isTargetedCampaign" is={true}>
                                        <div className="k-form-field">
                                            <span>
                                                <Translation tKey="DataSourceType" />
                                            </span>
                                            <Field
                                                name="dataSourceType"
                                                component={DropDownMultiSelect}
                                                textField="text"
                                                dataSource={enums.TARGETED_CAMPAIGN_DATA_SOURCE_TYPE}
                                            />
                                        </div>

                                        <Condition when="dataSourceType" is={isSelectedCMTDataSource}>
                                            <div className={'row'}>
                                                <label className="k-form-field col-xs-12 col-sm-6" htmlFor="targetedCampaignCode" disabled>
                                                    <span>
                                                        <Translation tKey="TargetedCampaignCode" />
                                                    </span>
                                                    <Field name="targetedCampaignCode" component={Input} />
                                                </label>
                                            </div>

                                            <div className="row">
                                                <label className="k-form-field col-xs-12 col-sm-6" htmlFor="targetedCampaignUseDateSuffix">
                                                    <span>
                                                        <Translation tKey="TargetedCampaignUseDateSuffix" />
                                                        <Field name="targetedCampaignUseDateSuffix" component={Switch} />
                                                    </span>
                                                </label>

                                                <Condition when="targetedCampaignUseDateSuffix" is={true}>
                                                    <label className="k-form-field col-xs-12 col-sm-6" htmlFor="targetedCampaignDateSuffixType">
                                                        <span>
                                                            <Translation tKey="TargetedCampaignDateSuffixType" />
                                                        </span>
                                                        <Field
                                                            name="targetedCampaignDateSuffixType"
                                                            component={DropDown}
                                                            textField="text"
                                                            dataSource={enums.TARGETED_GROUP_SUFFIX_TYPE}
                                                        />
                                                    </label>
                                                </Condition>
                                            </div>
                                        </Condition>

                                        <Condition when="dataSourceType" is={isSelectedMarketingActionDataSource}>
                                            <label className="k-form-field" htmlFor="marketingActionId">
                                                <span>
                                                    <Translation tKey="MarketingAction" />
                                                </span>
                                                <Field
                                                    name="marketingActionId"
                                                    component={DropDownWithValueField}
                                                    textField={this.props.marketingActionUserReadableProperty}
                                                    valueField="id"
                                                    dataSource={this.props.marketingActions}
                                                />
                                            </label>
                                        </Condition>

                                        <Condition when="dataSourceType" is={isSelectedRewardDataSource}>
                                            <label className="k-form-field" htmlFor="rewardId">
                                                <span>
                                                    <Translation tKey="ForMe" />
                                                </span>
                                                <Field
                                                    name="rewardId"
                                                    component={DropDownWithValueField}
                                                    // placeholder={t("Service")}
                                                    textField={this.props.rewardUserReadableProperty}
                                                    valueField="id"
                                                    dataSource={this.props.rewards}
                                                />
                                            </label>
                                        </Condition>

                                        <Condition when="dataSourceType" is={isSelectedPruductStateChangeSource}>
                                            <label className="k-form-field" htmlFor="serviceId">
                                                <span>
                                                    <Translation tKey="Service" />
                                                </span>
                                                <Field
                                                    name="serviceId"
                                                    component={DropDownWithValueField}
                                                    // placeholder={t("Service")}
                                                    textField={this.props.serviceUserReadableProperty}
                                                    valueField="id"
                                                    dataSource={this.props.services}
                                                />
                                            </label>
                                        </Condition>

                                        <div className="k-form-field">
                                            <span>
                                                <Translation tKey="TargetedCampaignConditionType" />
                                            </span>
                                            <Field
                                                name="targetedCampaignConditionType"
                                                component={DropDown}
                                                textField="text"
                                                dataSource={enums.TARGETED_CAMPAIGN_CONDITION_TYPE}
                                            />
                                        </div>
                                    </Condition>

                                    <div className="k-form-field">
                                        <span>
                                            <Translation tKey="PushNotification" />
                                        </span>
                                        <Field
                                            name="isCampaign"
                                            component={Switch}
                                            // placeholder={t("Name")}
                                        />
                                    </div>

                                    <Condition when="isCampaign" is={true}>
                                        <div className="row">
                                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor="campaignMessage">
                                                <span>
                                                    <Translation tKey="MessageText" />
                                                </span>
                                                <Field
                                                    name="campaignMessage"
                                                    component={Textarea}
                                                    className="k-textarea"
                                                    rows="2"
                                                    style={{ width: '100%' }}
                                                />
                                            </label>

                                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor="campaignTitle">
                                                <span>
                                                    <Translation tKey="MessageTitle" />
                                                </span>
                                                <Field name="campaignTitle" component={Input} />
                                            </label>

                                            {/*  <label className="k-form-field col-xs-12 col-sm-6" htmlFor="dateOfCampaignStart">
                                                <span>
                                                    <Translation tKey="DateOfCampaignStart" />
                                                </span>
                                                <Field name="dateOfCampaignStart" component={DateTimePicker} />
                                            </label> */}
                                        </div>
                                    </Condition>

                                    <div className="k-form-field">
                                        <span>
                                            <Translation tKey="Published" />
                                        </span>
                                        <Field
                                            name="published"
                                            component={Switch}
                                            // placeholder={t("Name")}
                                        />
                                    </div>

                                    <div className="footer">
                                        <SubmitButton loading={submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={onCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

NewsDetail.propTypes = {
    t: PropTypes.func.isRequired,
    headerText: PropTypes.string.isRequired,
    initialValues: propTypes.newsPropTypes.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
    fetchNewsDependencies: PropTypes.func.isRequired,
    newsPages: PropTypes.arrayOf({
        id: PropTypes.string.isRequired,
    }).isRequired,
    newsPageUserReadableProperty: PropTypes.string.isRequired,
    services: PropTypes.arrayOf({
        id: PropTypes.string.isRequired,
    }).isRequired,
    serviceUserReadableProperty: PropTypes.string.isRequired,
    loadingDependencies: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => {
    return {
        lang: state.app.lang,
        loadingDependencies: isLoadingDependencies(state),
        newsPages: getNewsPages(state),
        newsPageUserReadableProperty: getNewsPageUserReadableProperty(state),
        services: getServices(state),
        serviceUserReadableProperty: getServiceUserReadableProperty(state),
        rewards: getRewards(state),
        marketingActions: getMarketingActions(state),
        rewardUserReadableProperty: getRewardUserReadableProperty(state),
        marketingActionUserReadableProperty: getMarketingActionUserReadableProperty(state),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        fetchNewsDependencies: bindActionCreators(fetchNewsDependencies, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(NewsDetail);
