import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import PushNotificationTaskDetail from './pushNotificationTaskDetail';
import { getRowById } from '../../reducers/tableReducer';
import { updatePushNotificationTask } from '../../actions/pushNotificationTaskActions';
import { navigateToPushNotificationTasks } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import PushNotificationTask from '../../entities/pushNotificationTask';

interface PushNotificationTaskDetailUpdateProps extends BaseDetailProps<PushNotificationTask> {}

const PushNotificationTaskDetailUpdate = ({ initialValues, submitAction, cancelAction, t }: PushNotificationTaskDetailUpdateProps) => {
    return <PushNotificationTaskDetail initialValues={initialValues} submitAction={submitAction} headerText={t('PushNotificationTaskUpdateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapStateToProps = (state: any, { match }: any) => {
    const id = match.params.id;

    return {
        lang: state.app.lang,
        initialValues: getRowById(state, id),
    };
};

const mapDispatchToProps = (dispatch: Dispatch<PushNotificationTaskDetailUpdateProps>): BaseDetailDispatchProps<PushNotificationTask> => {
    return {
        submitAction: bindActionCreators(updatePushNotificationTask, dispatch),
        cancelAction: bindActionCreators(navigateToPushNotificationTasks, dispatch),
    };
};

export default compose(connect(mapStateToProps, mapDispatchToProps), translate('common'))(PushNotificationTaskDetailUpdate);
