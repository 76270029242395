import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { translate } from 'react-i18next';
import { compose } from 'redux';
import { Form, Field } from 'react-final-form';

import SubmitButton from '../common/SubmitButton';
import Alerts from '../common/Alerts';
import Translation from '../common/Translation';
import CancelButton from '../common/CancelButton';
import Input from '../common/Input';
import LoadingIndicator from '../common/LoadingIndicator';
import DateTimePicker from '../common/DateTimePicker';
import DropDown from '../common/DropDown';
import Condition from '../common/utils/Condition';
import NumericTextBox from './../common/NumericTextBox';

import * as enums from '../../common/enums';
import { reportDetailPropTypes } from '../../common/propTypes';

class OutageNotificationDetail extends Component {
    constructor(props) {
        super(props);

        this.state = { loading: false };
        this.translatedFormValidation = this.translatedFormValidation.bind(this);
    }

    translatedFormValidation(values) {
        const { t } = this.props;
        const errors = {};

        if (!values.name) {
            errors.name = t('NameIsRequired');
        }

        if (!values.text) {
            errors.text = t('TextIsRequired');
        }

        if (!values.platform) {
            errors.platform = t('PlatformIsRequired');
        }

        if (!values.notificationFrom) {
            errors.notificationFrom = t('NotificationFromIsRequired');
        }

        if (!values.notificationTo) {
            errors.notificationTo = t('NotificationToIsRequired');
        }

        const outageFrom = values.outageFrom;
        const outageTo = values.outageTo;
        const notificationFrom = values.notificationFrom;
        const notificationTo = values.notificationTo;

        if (notificationTo < notificationFrom) {
            errors.notificationTo = t('NotificationToIsOutOfRange');
        }

        if (outageFrom && (outageFrom < notificationFrom || outageFrom > notificationTo)) {
            errors.outageFrom = t('OutageFromIsOutOfRange');
        }

        if (outageFrom && !outageTo) {
            errors.outageTo = t('OutageToIsRequired');
        }

        if (outageTo && (outageTo < notificationFrom || outageTo > notificationTo || outageTo < outageFrom)) {
            errors.outageTo = t('OutageToIsOutOfRange');
        }

        if (outageTo && !outageFrom) {
            errors.outageFrom = t('OutageFromIsRequired');
        }

        return errors;
    }

    render() {
        const { initialValues, headerText, onCancel } = this.props;

        return (
            <div>
                <div className="content-header">
                    <h1>{headerText}</h1>
                </div>
                <div className="content-wrapper">
                    <Alerts />
                    <div className="panel card col-xs-12 col-sm-6">
                        <Form
                            validate={this.translatedFormValidation}
                            onSubmit={this.props.onSubmit}
                            initialValues={initialValues}
                            render={({ handleSubmit, reset, submitting, pristine, values, valid, form }) => (
                                <form className="k-form" onSubmit={handleSubmit}>
                                    {this.state.loading && <LoadingIndicator />}
                                    <label className="k-form-field" htmlFor="name">
                                        <span>
                                            <Translation tKey="Name" />
                                        </span>
                                        <Field name="name" component={Input} />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="Platform" />
                                        </span>
                                        <Field name="platform" component={DropDown} textField="text" dataSource={enums.PLATFORMA_TYPES} />
                                    </label>

                                    <div className={'row'}>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="notificationFrom">
                                            <span>
                                                <Translation tKey="NotificationFrom" />
                                            </span>
                                            <Field name="notificationFrom" component={DateTimePicker} />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="notificationTo">
                                            <span>
                                                <Translation tKey="NotificationTo" />
                                            </span>
                                            <Field name="notificationTo" component={DateTimePicker} />
                                        </label>
                                    </div>

                                    <div className={'row'}>
                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="outageFrom">
                                            <span>
                                                <Translation tKey="OutageFrom" />
                                            </span>
                                            <Field name="outageFrom" component={DateTimePicker} />
                                        </label>

                                        <label className="k-form-field col-xs-12 col-sm-6" htmlFor="outageTo">
                                            <span>
                                                <Translation tKey="OutageTo" />
                                            </span>
                                            <Field name="outageTo" component={DateTimePicker} />
                                        </label>
                                    </div>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="OutageNotificationText" />
                                        </span>
                                        <Field name="text" component="textarea" className="k-textarea" rows="2" style={{ width: '100%' }} />
                                    </label>

                                    <label className="k-form-field" htmlFor="order">
                                        <span>
                                            <Translation tKey="Order" />
                                        </span>
                                        <Field name="order" component={NumericTextBox} />
                                    </label>

                                    <label className="k-form-field">
                                        <span>
                                            <Translation tKey="Filter" />
                                        </span>
                                        <Field name="filter" component={DropDown} textField="text" dataSource={enums.OUTAGE_NOTIFICATION_FILTER_TYPE} />
                                    </label>

                                    <Condition when="filter" is={enums.OUTAGE_NOTIFICATION_FILTER_TYPE[1]}>
                                        <div className={'row'}>
                                            <label className="k-form-field col-xs-12 col-sm-6">
                                                <span>
                                                    <Translation tKey="Condition" />
                                                </span>
                                                <Field
                                                    name="condition"
                                                    component={DropDown}
                                                    textField="text"
                                                    dataSource={enums.OUTAGE_NOTIFICATION_CONDITION_TYPE}
                                                />
                                            </label>
                                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor="version">
                                                <span>
                                                    <Translation tKey="Version" />
                                                </span>
                                                <Field name="version" component={Input} />
                                            </label>
                                        </div>
                                    </Condition>

                                    <Condition when="filter" is={enums.OUTAGE_NOTIFICATION_FILTER_TYPE[2]}>
                                        <div className={'row'}>
                                            <label className="k-form-field col-xs-12 col-sm-6" htmlFor="groupName">
                                                <span>
                                                    <Translation tKey="UserGroup" />
                                                </span>
                                                <Field name="groupName" component={Input} />
                                            </label>
                                        </div>
                                    </Condition>
                                    
                                    <div className="footer">
                                        <SubmitButton loading={submitting}>
                                            <Translation tKey="Save" />
                                        </SubmitButton>
                                        <CancelButton onClick={onCancel}>
                                            <Translation tKey="Cancel" />
                                        </CancelButton>
                                    </div>
                                </form>
                            )}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

OutageNotificationDetail.propTypes = {
    t: PropTypes.func.isRequired,
    headerText: PropTypes.string.isRequired,
    initialValues: reportDetailPropTypes.isRequired,
    onSubmit: PropTypes.func.isRequired,
    onCancel: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => {
    return {
        lang: state.app.lang,
    };
};

export default compose(connect(mapStateToProps, undefined), translate('common'))(OutageNotificationDetail);
