export default class TargetCampaignGroup {
  id: number;
  campaignCode: string;
  msisdnCount: number;
  dateOfLastImport: Date | null | undefined;
  dateOfLastNotificationSent: Date | null | undefined;

  constructor(properties: Partial<TargetCampaignGroup>) {
    this.id = 0;
    this.campaignCode = "";
    this.msisdnCount = 0;
    this.dateOfLastImport = null;
    this.dateOfLastNotificationSent = null;

    Object.assign(this, properties);

    this.dateOfLastImport = properties.dateOfLastImport ? new Date(properties.dateOfLastImport) : properties.dateOfLastImport;
    this.dateOfLastNotificationSent = properties.dateOfLastNotificationSent ? new Date(properties.dateOfLastNotificationSent) : properties.dateOfLastNotificationSent;
  }
}
