import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators, compose, Dispatch } from 'redux';
import { translate } from 'react-i18next';

import PushNotificationTaskDetail from './pushNotificationTaskDetail';
import { createPushNotificationTask } from '../../actions/pushNotificationTaskActions';
import { navigateToPushNotificationTasks } from '../../actions/navigationActions';
import { BaseDetailDispatchProps, BaseDetailProps } from '../common/types/baseDetail';
import PushNotificationTask from '../../entities/pushNotificationTask';

const initialValues = new PushNotificationTask({});

interface PushNotificationTaskDetailCreateProps extends BaseDetailProps<PushNotificationTask> {}

const PushNotificationTaskDetailCreate = ({ submitAction, cancelAction, t }: PushNotificationTaskDetailCreateProps) => {
    return <PushNotificationTaskDetail initialValues={initialValues} submitAction={submitAction} headerText={t('PushNotificationTaskCreateLabel')} cancelAction={cancelAction} t={t} />;
};

const mapDispatchToProps = (dispatch: Dispatch<PushNotificationTaskDetailCreateProps>): BaseDetailDispatchProps<PushNotificationTask> => {
    return {
        submitAction: bindActionCreators(createPushNotificationTask, dispatch),
        cancelAction: bindActionCreators(navigateToPushNotificationTasks, dispatch),
    };
};

export default compose(connect(undefined, mapDispatchToProps), translate('common'))(PushNotificationTaskDetailCreate);
