import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { compose } from "redux";

import Table from "../common/Table";
import { GridColumn as Column } from "@progress/kendo-react-grid";
import StringCell from "../common/formatters/StringCell";
import Translation from "../common/Translation";
import { translate } from "react-i18next";
import Alerts from "../common/Alerts";
import ActionButton from "../common/ActionButton";
import { navigateTo } from "../../actions/navigationActions";
import {
  downloadReportBySelectedRow,
  downloadReportById
} from "../../actions/reportActions";
import {
  TEXT_HORIZONTAL_ALIGN,
  NAVIGATION_PATHS
} from "../../common/constants";
import ButtonCell from "../common/ButtonCell";
import * as types from '../../actions/actionTypes';

const actionUrl = "report";

const Reports = ({
  t,
  navigateTo,
  downloadReportById
}) => {
  let table;

  const onNew = () => {
    navigateTo(NAVIGATION_PATHS.REPORT_CREATE);
  };

  const onEdit = () => {
    table.getWrappedInstance().editSelected();
  };

  const onRemove = () => {
    table.getWrappedInstance().removeSelected();
  };

  const onActionButtonDownloadReport = () => {
    downloadReportBySelectedRow();
  };

  const onDownloadReport = id => {
    downloadReportById(id);
  };

  return (
    <div>
      <div className="content-header">
        <h1>
          <Translation tKey="Reports" />
        </h1>
      </div>
      <div className="content-wrapper">
        <Alerts />
        <div className="panel">
          <div className="panel-header">
            <div className="actions">
              <ActionButton text="Add" onClick={onNew} icon="plus" />
              <ActionButton
                text="Edit"
                onClick={onEdit}
                icon="pencil"
                selection="one"
              />
              <ActionButton
                text="Remove"
                onClick={onRemove}
                icon="delete"
                selection="multi"
              />
              <ActionButton
                text="Download"
                onClick={onActionButtonDownloadReport}
                icon="download"
                selection="one"
              />
            </div>
            <div className="filter" />
          </div>
          <div className="panel-content">
            <Table
              path={"report"}
              actionPath={actionUrl}
              selectedField="selected"
              ref={el => {
                table = el;
              }}
              name="ReportTable"
              afterLoadDataActionType={types.REPORT_FETCH}
            >
              <Column field="selected" width="50" />
              <Column
                field="displayName"
                cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                title={t("DisplayName")}
              />
              <Column
                field="name"
                cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                title={t("InternalName")}
              />
              <Column
                field="viewName"
                cell={StringCell(TEXT_HORIZONTAL_ALIGN.LEFT)}
                title={t("View")}
              />
              <Column
                cell={ButtonCell(
                  "id",
                  t("Download"),
                  "download",
                  onDownloadReport
                )}
                title={t("Download")}
              />
            </Table>
          </div>
        </div>
      </div>
    </div>
  );
};

Reports.propTypes = {
  t: PropTypes.func.isRequired,
  navigateTo: PropTypes.func.isRequired,
  downloadReport: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => {
  return {
    navigateTo: path => {
      dispatch(navigateTo(path));
    },
    downloadReportBySelectedRow: () => dispatch(downloadReportBySelectedRow),
    downloadReportById: id => dispatch(downloadReportById(id))
  };
};

export default compose(
  connect(
    null,
    mapDispatchToProps
  ),
  translate("common")
)(Reports);
