export const isNull = (value) => {
  return value === null;
};

export const isUndefined = (value) => {
  return value === undefined;
};

export const isNullOrUndefined = (value) => {
  return isNull(value) || isUndefined(value);
};
