export default class GameClan {
    id: number;
    name: string;
    permittedModules: any;

    constructor(properties: Partial<GameClan>) {
        this.id = 0;
        this.name = '';

        Object.assign(this, properties);
    }
}
