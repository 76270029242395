import * as types from '../actions/actionTypes';

const loadingSpinnerInitialState= {
    isVisible: false,
    indicatorStack: 0,
};

const loadingSpinner = (state = loadingSpinnerInitialState, action) => {
  switch (action.type) {    
    case types.LOADING_SPINNER_SHOW:
      const newStackShow = state.indicatorStack + 1;
      return {
        ...state,
        isVisible: newStackShow > 0,
        indicatorStack: newStackShow
      };
    case types.LOADING_SPINNER_HIDE:
      const newStackHide = state.indicatorStack - 1;
      return {
        ...state,       
        isVisible: newStackHide > 0,
        indicatorStack: newStackHide
      };
    default:
      return state;
  }
};

export default loadingSpinner;

export const isLoadingSpinnerVisible = (state) => {
  return state.loadingSpinner.isVisible;
};
