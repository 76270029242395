import {init} from 'i18next';

import * as commonCs from './locales/cs/common.json';
import * as commonEn from './locales/en/common.json';

const i18n = init({
    fallbackLng: 'cs',
    debug: true,
    lng: 'cs',
    resources: {
      en: {
        common: commonEn.default
      },
      cs: {
        common: commonCs.default
      }
    },
    initImmediate: false,
    react: {
      wait: true,
      bindI18n: 'languageChanged loaded',
      bindStore: 'added removed',
      nsMode: 'default'
    }
  });

export default i18n;