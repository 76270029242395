// import {getEnumItemByValue, NEWS_TYPES} from "../common/enums";
import AffiliateProgramSubscriberRewardClaim from '../entities/affiliateProgramSubscriberRewardClaim';

const affiliateProgramSubscriberRewardClaimDataTransformationAfterFetch = (data: any) => {
    return data.map((row: any) => {
        return new AffiliateProgramSubscriberRewardClaim(row);
    });
};

export default affiliateProgramSubscriberRewardClaimDataTransformationAfterFetch;
