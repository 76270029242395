import React from 'react';

import { TEXT_HORIZONTAL_ALIGN } from '../../../common/constants';
import { StringCellProps } from './stringCellEx';

interface RelationItem {
    id: number;
}

interface RelationCellProps<T extends RelationItem> extends StringCellProps {
    textAlign?: any;
    relations: T[];
    relationUserReadableProperty: keyof T;
    onClick?: () => void;
}

class RelationCell<T extends RelationItem> extends React.Component<RelationCellProps<T>> {
    render() {
        const relationId = this.props.field ? this.props.dataItem[this.props.field] : 0;
        const relationItem = this.props.relations.find((item) => item.id === relationId);
        const value = relationItem ? relationItem[this.props.relationUserReadableProperty] : 'nenastaveno';

        let { textAlign, onClick, style } = this.props;

        if (textAlign === null) {
            textAlign = TEXT_HORIZONTAL_ALIGN.CENTER;
        }

        if (style) {
            style.textAlign = textAlign;
        }

        return (
            <td style={style} onClick={onClick}>
                {value}
            </td>
        );
    }
}

export default RelationCell;
