import AffiliateProgramReward from '../entities/affiliateProgramReward';

const affiliateProgramRewardItemTransformationBeforeSave = (affiliateProgramRewardItem: AffiliateProgramReward) => {
    const validFrom = affiliateProgramRewardItem.validFrom;
    const validTo = affiliateProgramRewardItem.validTo;
    const claimValidTo = affiliateProgramRewardItem.claimValidTo;
    /* const validFromOnlyDate = new Date(
        affiliateProgramRewardItem.validFrom.getFullYear(),
        affiliateProgramRewardItem.validFrom.getMonth(),
        affiliateProgramRewardItem.validFrom.getDate(),
        0,
        0,
        0,
        0
    );

    const validToOnlyDate = affiliateProgramRewardItem.validTo
        ? new Date(
              affiliateProgramRewardItem.validTo.getFullYear(),
              affiliateProgramRewardItem.validTo.getMonth(),
              affiliateProgramRewardItem.validTo.getDate(),
              23,
              59,
              59,
              0
          )
        : undefined; */

    const transformedData: Record<string | number | symbol, unknown> = {
        ...affiliateProgramRewardItem,
        validFromUtc: validFrom ? validFrom.toUTCString() : null,
        validToUtc: validTo ? validTo.toUTCString() : null,
        claimValidToUtc: claimValidTo ? claimValidTo.toUTCString() : null,
    };

    const body = new FormData();

    Object.keys(transformedData).forEach((key) => {
        if (transformedData[key] !== null && transformedData[key] !== undefined) {
            body.append(key, transformedData[key] as string | Blob);
        }
    });

    return body;
};

export default affiliateProgramRewardItemTransformationBeforeSave;
