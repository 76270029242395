import * as types from '../actions/actionTypes';
import DataSourceQueryContext from '../entities/dataSourceQueryContext';

const dataSourceQueryContextsInitialState = {
    userReadableProperty: 'name',
    loadingDependencies: false,
    loaded: false,
    data: [],
};

const dataSourceQueryContexts = (state = dataSourceQueryContextsInitialState, action: any) => {
    switch (action.type) {
        case types.DATASOURCEQUERYCONTEXT_FETCH:
            return {
                ...state,
                data: action.data.map((dataSourceQueryContext: DataSourceQueryContext) => {
                    return { ...dataSourceQueryContext };
                }),
                loaded: true,
            };
        default:
            return state;
    }
};

export default dataSourceQueryContexts;

export const getData = (state: any): DataSourceQueryContext[] => {
    return state.dataSourceQueryContexts.data;
};

export const getUserReadableProperty = (state: any): keyof DataSourceQueryContext => {
    return state.dataSourceQueryContexts.userReadableProperty;
};
