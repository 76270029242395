import { platform } from 'os';
import DataSource from '../entities/dataSource';

const DataSourceItemTransformationBeforeSave = (item: DataSource) => {
    const transformedData = {
        ...item,
        queryType: item.queryTypeEnumDescription?.value,
        type: item.typeEnumDescription?.value,
        platform: item.platformEnumDescription?.value,
        executionPolicy: item.executionPolicyEnumDescription?.value,
    } as { [key: string]: any };

    return transformedData;
};

export default DataSourceItemTransformationBeforeSave;
