import Text from '../entities/text';

const textItemTransformationBeforeSave = (textItem: Text) => {
    const transformedData = {
        ...textItem,
        language: textItem.languageEnumDescription?.value,
    } as { [key: string]: any };

    return transformedData;
};

export default textItemTransformationBeforeSave;
