import React from 'react';
import { MultiSelect as KendoMultiSelect } from '@progress/kendo-react-dropdowns';
import KendoHOC from './utils/KendoHOC';

const withMultiSelect = (MultiSelect) => {
    return class extends React.Component {
        component;
        events = {
            onBlur: (event) => this.triggerEvent('onBlur', event),
            onFocus: (event) => this.triggerEvent('onFocus', event),
            onChange: (event) => this.triggerEvent('onChange', event),
            onPageChange: (event) => this.triggerEvent('onPageChange', event),
            onFilterChange: (event) => this.triggerEvent('onFilterChange', event),
        };

        get value() {
            if (this.component) {
                const value = this.component.value.reduce((total, item, i) => total + item.value, 0);

                return value;
            }
        }

        render() {
            return (
                <MultiSelect
                    {...this.props}
                    value={this.itemFromValue(this.props.value)}
                    defaultValue={this.itemFromValue(this.props.defaultValue)}
                    ref={(component) => (this.component = component)}
                    {...this.events}
                />
            );
        }

        triggerEvent(eventType, event) {
            if (this.props[eventType]) {
                this.props[eventType].call(undefined, {
                    ...event,
                    target: this,
                });
            }
        }

        itemFromValue(value) {
            return this.props.data.filter((item) => {
                return (item.value & this.props.value) === item.value;
            });
        }
    };
};

const DropDownMultiSelect = (options) => KendoHOC(KendoMultiSelect, options);

export default DropDownMultiSelect;
