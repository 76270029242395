import React from "react";
import { GridCell } from "@progress/kendo-react-grid";
import { Rating } from "../Rating";

// TODO
class RatingCell extends GridCell {
  render() {
    const value = this.props.dataItem[this.props.field];
    const { onClick, style } = this.props;

    return (
      <td style={style} onClick={onClick}>
        <Rating value={value} readonly />
      </td>
    );
  }
}

export default RatingCell;
