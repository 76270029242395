import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
// import {bindActionCreators, compose} from "redux";
import { compose } from 'redux';
import internal from 'events';
import { CAMPRO_MODULE_ENUM } from '../../common/enums';

const AuthContext = React.createContext({ isAdmin: false, permittedModules: CAMPRO_MODULE_ENUM.NONE });
const AuthConsumer = AuthContext.Consumer;

interface AuthProviderProps {
    isAdmin: boolean;
    permittedModules: CAMPRO_MODULE_ENUM;
}

class AuthProvider extends React.Component<AuthProviderProps> {
    state = { isAdmin: false };

    render() {
        console.warn('AuthProvider.render');

        return (
            <AuthContext.Provider value={{ isAdmin: this.props.isAdmin, permittedModules: this.props.permittedModules }}>
                {this.props.children}
            </AuthContext.Provider>
        );
    }
}

const mapStateToProps = (state: any) => {
    return {
        isAdmin: state.user.isAdmin,
        permittedModules: state.user.permittedModules,
    };
};

const connectedAuthProvider = compose(connect(mapStateToProps))(AuthProvider);

export { connectedAuthProvider as AuthProvider, AuthConsumer };
