import * as types from '../actions/actionTypes';

const extraInitialState= {
  loaded: false,
  data: null,
};

const extra = (state = extraInitialState, action) => {
  switch (action.type) {
    case types.EXTRA_FETCH:
      return {
        ...state,
        data: action.data,
      };
    case types.EXTRA_LOADING_BEGIN:
      return {
        ...state,
        loading: true,
      };
    case types.EXTRA_LOADING_END:
      return {
        ...state,       
        loading: false,
      };
    default:
      return state;
  }
};

export default extra;

export const isLoading = (state) => {
  return state.extra.loading === true;
};

export const getData = (state) => {
  return state.extra.data;
};
