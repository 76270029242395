import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Grid } from '@progress/kendo-react-grid';
import { IntlProvider } from '@progress/kendo-react-intl';

import * as tableActions from '../../actions/tableActions';
import { getTable } from '../../reducers/tableReducer';
import * as propTypes from '../../common/propTypes';

class Table extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ...this.createState(this.props.skip, this.props.pageSize),
            sortable: {
                allowUnsort: true,
                mode: 'single',
            },
            lastSelectedIndex: 0,
        };
    }

    componentDidMount() {
        console.warn('Table.componentDidMount');

        const columns = this.props.children.map((column) => {
            let { props } = column;
            return { ...props };
        });

        const idColumn = columns.find((c) => {
            return c.field === 'id';
        });
        if (idColumn === undefined) {
            columns.push({ field: 'id' });
        }

        this.props.actions.reset(this.props.path, this.props.actionPath, columns);
        this.props.actions.setAfterLoadDataActionType(this.props.afterLoadDataActionType);
        this.props.actions.dataTransformationChange(this.props.dataTransformation);
        this.props.actions.sortChange(this.props.defaultSort);
        this.props.actions.loadData();
    }

    /* componentWillUnmount(){
    console.warn("Table.componentWillUnmount");
  } */

    createState = () => {
        return {
            pageable: this.state
                ? this.state.pageable
                : {
                      buttonCount: 5,
                      info: true,
                      type: 'numeric',
                      pageSizes: [10, 25, 50, 100],
                      previousNext: true,
                  },
        };
    };

    onPageChange = (event) => {
        const { page } = event;
        this.props.actions.pageChange(page.skip, page.take);
    };

    onSortChange = (event) => {
        this.props.actions.sortChange(event.sort);
    };

    onFilterChange = (event) => {
        this.props.actions.filterChange(event.filter);
    };

    onFilterClear = (event) => {
        this.props.actions.filterClear();
    };

    onSelectionChange = (event) => {
        this.props.actions.selectRow(event.dataItem.id, !event.dataItem.selected);
        const current = this.props.data.findIndex((dataItem) => dataItem === event.dataItem);
        this.setState({ lastSelectedIndex: current });
    };

    onHeaderSelectionChange = (event) => {
        const checked = event.syntheticEvent.target.checked;

        this.props.actions.selectAllRows(checked);
    };

    onRowClick = (event) => {
        let { lastSelectedIndex } = this.state;
        const { data } = this.props;
        const current = data.findIndex((dataItem) => dataItem === event.dataItem);

        if (!event.nativeEvent.shiftKey) {
            lastSelectedIndex = current;
            this.setState({ lastSelectedIndex });
        }

        if (!event.nativeEvent.ctrlKey) {
            this.props.actions.desellectAllRows();
        }

        let select = !event.dataItem.selected;
        let min = Math.min(lastSelectedIndex, current);
        let max = Math.max(lastSelectedIndex, current);
        this.props.actions.selectRows(min, max, select);
    };

    removeSelected = () => {
        this.props.actions.remove();
    };

    editSelected = () => {
        this.props.actions.edit();
    };

    cloneSelected = () => {
        this.props.actions.clone();
    };

    customRowRender = (trElement, rowProps) => {
        if (this.props.customRowRender) {
            return this.props.customRowRender(trElement, rowProps, this.props.data);
        }

        return trElement;
    };

    customFilter = (filter) => {
        this.props.actions.filterChange(filter);
    };

    render() {
        if (!this.props.loaded) {
            return null;
        }

        return (
            <IntlProvider locale={this.props.lang}>
                <Grid
                    ref={(grid) => {
                        this.grid = grid;
                    }}
                    data={this.props.data}
                    total={this.props.total}
                    skip={this.props.skip}
                    onPageChange={this.onPageChange}
                    pageSize={this.props.pageSize}
                    pageable={this.state.pageable}
                    filterable={false}
                    filter={this.props.filter}
                    onFilterChange={this.onFilterChange}
                    sort={this.props.sort}
                    sortable={this.state.sortable}
                    onSortChange={this.onSortChange}
                    selectedField={this.props.selectedField}
                    onSelectionChange={this.onSelectionChange}
                    onRowClick={this.onRowClick}
                    onHeaderSelectionChange={this.onHeaderSelectionChange}
                    rowRender={this.customRowRender}
                >
                    {this.props.children}
                </Grid>
            </IntlProvider>
        );
    }
}

Table.propTypes = {
    lang: PropTypes.string.isRequired,
    path: PropTypes.string.isRequired,
    actionPath: PropTypes.string.isRequired,
    children: PropTypes.arrayOf(PropTypes.object).isRequired,
    actions: PropTypes.object,
    total: PropTypes.number.isRequired,
    data: PropTypes.arrayOf(PropTypes.object),
    loaded: PropTypes.bool.isRequired,
    skip: PropTypes.number.isRequired,
    pageSize: PropTypes.number.isRequired,
    sort: PropTypes.arrayOf(propTypes.tableSortPropertyPropTypes),
    filter: PropTypes.object,
    selectedField: PropTypes.string,
    dataTransformation: PropTypes.string,
    name: PropTypes.string.isRequired,
    customRowRender: PropTypes.func,
    defaultSort: PropTypes.arrayOf(propTypes.tableSortPropertyPropTypes),
    afterLoadDataActionType: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
    const isLoaded = getTable(state).loaded;
    const isValidDataSource = getTable(state).actionUrl === ownProps.actionPath;
    const stateSort = getTable(state).sort;
    const defaultSort = ownProps.defaultSort ? ownProps.defaultSort : stateSort;

    return {
        lang: state.app.lang,
        total: getTable(state).total,
        data: getTable(state).data,
        skip: getTable(state).skip,
        pageSize: getTable(state).take,
        sort: getTable(state).sort,
        filter: getTable(state).gridFilter,
        loaded: isLoaded && isValidDataSource,
        defaultSort,
    };
};

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        actions: bindActionCreators(tableActions, dispatch),
    };
};

export default connect(mapStateToProps, mapDispatchToProps, null, { withRef: true })(Table);
