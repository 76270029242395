import * as types from '../actions/actionTypes';

const simCardTypeInitialState = {
    loadingDependencies: false,
};

const simCardTypeReducer = (state = simCardTypeInitialState, action) => {
    switch (action.type) {
        /* case types.REWARD_LOADING_DEPENDENCIES_BEGIN:
      return {
        ...state,
        loadingDependencies: true,
      };
    case types.REWARD_LOADING_DEPENDENCIES_END:
      return {
        ...state,
        loadingDependencies: false,
      }; */
        default:
            return state;
    }
};

export default simCardTypeReducer;

export const isLoadingDependencies = (state) => {
    return state.simCardType.loadingDependencies === true;
};
