import PushNotificationTask from '../entities/pushNotificationTask';

const PushNotificationTaskDataTransformationAfterFetch = (data: any[]) => {
    return data.map((row) => {
        return new PushNotificationTask(row);
    });
};

export default PushNotificationTaskDataTransformationAfterFetch;

